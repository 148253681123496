import React, { Component } from "react";
import { withFormik } from "formik";
import Social from './socialLogin';
import _helper from 'utils/helper';
import * as yup from "yup";

import {
  Layout,
  Form,
  Input,
  Checkbox,
  Button,
  Divider,
  Icon,
  Row,
  Col,
  Avatar,
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";

const FormItem = Form.Item;

class LoginAccountForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      email: '',
      password: '',
      missingEmail: null,
      missingPassword: null,
    };
  }

  emitEmpty = () => {
    this.emailInput.focus();
    this.setState({ email: '' });
  }

  handleInput = (e) => {

    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name == "email") {
      this.setState({
        missingEmail: false
      })
    }

    if (e.target.name == "password") {
      this.setState({
        missingPassword: false
      })
    }
  }

  handleSubmit = () => {
    let { email, password } = this.state
    if (!email) {
      this.setState({
        missingEmail: true
      })
    }

    if (!password) {
      this.setState({
        missingPassword: true
      })
    }

    if (email && password) {
      this.props.login({
        email,
        password
      })
    }



  }

  render() {
    let {
      hide,
      login,
      values,
      touched,
      errors,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
      loginLoading,
      missingEmail,
      missingPassword,
      loginModalVisible,
    } = this.props;

    let { formatMessage } = this.props.intl;

    const suffix = this.state.email ? <Icon type="close-circle" onClick={this.emitEmpty} /> : null;

    return (
      <div className="loginFormContainer">
        <Form style={{ display: hide ? "none" : null }}>
          <FormItem
            colon={false}
          >

            <Input
              prefix={<Icon type="user" />}
              size="large"
              name="email"
              placeholder={formatMessage({ id: "email_phone" })}
              onChange={this.handleInput}
              onBlur={handleBlur}
              suffix={suffix}
              ref={node => this.emailInput = node}
              value={this.state.email}
            />
            <div>
              {
                this.state.missingEmail ?
                  <div className="missing-item-message"><FormattedMessage id="email_required" /></div> : ''
              }
            </div>

          </FormItem>
          <FormItem
            colon={false}
          >
            <div className="input-spacing-top">
              <Input.Password
                prefix={<Icon type="lock" />}
                size="large"
                name="password"
                placeholder={formatMessage({ id: "password" })}
                onChange={this.handleInput}
                onBlur={handleBlur}
              />
              <div>
                {
                  this.state.missingPassword ?
                    <div className="missing-item-message"><FormattedMessage id="password_required" /></div> : ''
                }

              </div>
            </div>
          </FormItem>
          <FormItem
            className="remember-me"
            style={{ margin: "0" }}>
            <Checkbox
              name="rememberMe"
            // values={values.rememberMe}
            // onChange={handleChange}
            >
              <FormattedMessage id="remember_me" />
            </Checkbox>
          </FormItem>
          <Divider />
          <FormItem>
            <Button
              type="primary"

              className="login-form-button"
              size="large"
              onClick={this.handleSubmit}
              block
            >
              <FormattedMessage id="log_in" />
            </Button>
          </FormItem>
          <Divider />
          <Social />
        </Form>
      </div>
    );
  }
}

let validationSchema = (lan) => yup.object().shape({
  email: yup.string().required(_helper.getIsRequiredMessage(lan, 'email_phone')),
  password: yup.string().required(_helper.getIsRequiredMessage(lan, 'password'))
});

LoginAccountForm = withFormik({
  // mapPropsToValues: (props) => ({ ...props.initalValues }),
  mapPropsToValues: props => ({ email: "", password: "" }), // adding initial values will make field be touched when submitting. (https://github.com/jaredpalmer/formik/issues/445)
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.login(values);
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true
})(LoginAccountForm);
export default injectIntl(LoginAccountForm);

import React from 'react';
import classnames from 'classnames';
import _helper from '../../utils/helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from 'prop-types';
import './_floating-input.scss';

let defaultTextColor = 'rgb(177, 177, 177)';
let defaultBarColor = 'rgba(0,0,0,0.12)';

class FloatingInput extends React.Component {
  static defaultProps = {
    validColor: '#1890ff',
    invalidColor: '#d50000',
    type: 'text',
    requiredText: false,
  }

  constructor(props) {
    super(props)

    this.handleCheck = this.handleCheck.bind(this);

    this.state = {
      isFocused: false,
      showColor: true,
      isValid: true,
      errorMsg: false,
      errorType: null,
      firstName: null,
      lastName: null,
      inputValue: "",
      stateName: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.value && this.props.value && prevProps.value != this.props.value && !this.state.isFocused) {
      this.setState({
        isFocused: true,
      })
    } else if (prevProps.value && !this.props.value && prevProps.value != this.props.value && this.state.isFocused) {
      this.setState({
        isFocused: false,
      })
    }
  }

  componentDidMount() {

    let {
      defaultNumber,
      requireTableNumber,
      defaultValue,
      ref,
      canBeEmpty,
    } = this.props;

    let { stateName } = this.state;

    this.switchErrorType();
    this.checkStateName();

    canBeEmpty = this.defaultPropsCheck(defaultValue, stateName, defaultNumber, requireTableNumber, canBeEmpty);
  }

  defaultPropsCheck(defaultValue, stateName, defaultNumber, requireTableNumber, canBeEmpty) {
    if (defaultValue) {
      this.setState({
        inputValue: defaultValue,
        isFocused: true,
        showColor: true,
        isValid: true,
      });
      this.props.onInputChange(stateName, defaultValue);
      this.defaultValue = null;
    }
    //If there is default number
    if (defaultNumber) {
      this.setState({
        inputValue: defaultNumber,
        isFocused: true,
        showColor: true,
        isValid: true,
      });
      this.props.onInputChange(stateName, defaultNumber);
      this.defaultNumber = null;
    }
    if (requireTableNumber) {
      canBeEmpty = false;
    }
    return canBeEmpty;
  }

  componentWillReceiveProps() {

    let { focusRef, canBeEmpty } = this.props;

  }

  handleCheck(e, type) {
    let {
      canBeEmpty,
      isNum,
      minLength,
      inputType,
      requireTableNumber,
    } = this.props;
    let stateName = this.state.stateName || this.props.stateName;

    let value = e.target.value;

    if (canBeEmpty) {
      if (isNum) {
        this.handleNumInput(e, true);
      } else {
        this.setState({
          showColor: true,
          isValid: true,
          errorState: false,
        })
        this.props.onInputChange(stateName, e.target.value)
      }
    } else {
      //cannot be empty
      if (!e.target.value) {

        this.props.onInputChange(stateName, null)

        this.setState({
          showColor: true,
          isValid: false,
          errorState: true,
        })
      } else {
        if (isNum) {

          this.handleNumInput(e);

        } else {
          this.props.onInputChange(stateName, e.target.value)

          this.setState({
            showColor: true,
            isValid: true,
            errorState: false,
          })
        }
      }
    }

    if (type == "onBlur" && !e.target.value) {
      this.setState({
        isFocused: false,
      })
    } else {
      this.setState({
        isFocused: true,
      })
    }

    this.setState({
      inputValue: e.target.value,
    })

  }

  handleNumInput(e, warning) {
    let {
      inputType,
    } = this.props;

    let { stateName } = this.state;


    if (this.handleIsNum(e)) {

      this.props.onInputChange(stateName, e.target.value)

      this.setState({
        showColor: true,
        isValid: true,
        errorState: false,
      })
    } else {

      e.target.value = e.target.value.replace(/\D/g, '');
      this.props.onInputChange(stateName, e.target.value)

      if (warning) {
        this.setState({
          showColor: true,
          isValid: true,
          errorState: true,
        })
      } else {
        this.setState({
          showColor: true,
          isValid: false,
          errorState: true,
        })
      }
    }
  }

  checkStateName() {
    let {
      inputType,
    } = this.props;

    if (inputType == "first_name") {
      this.setState({
        stateName: "firstName"
      })
    } else if (inputType == "table_number") {
      this.setState({
        stateName: "tableNumber"
      })
    }
  }


  handleColors(e) {
    let { showColor, isFocused, isValid, errorState } = this.state;
    let { validColor, invalidColor, canBeEmpty, requireTableNumber } = this.props;

    let textColor = defaultTextColor;
    let barColor = defaultBarColor;


    if (!isFocused) {
      if (isValid) {
        barColor = validColor
      } else {
        barColor = invalidColor
      }
    } else {
      if (showColor && !isValid) {
        barColor = invalidColor;
        textColor = invalidColor;
      } else {
        if (showColor && isValid) {
          barColor = validColor
          textColor = validColor
        }
      }
    }

    return {
      textColor,
      barColor
    }
  }

  switchErrorType() {

    //Switch Error Types based on input type
    let { inputType, intl, minLength } = this.props;
    let { formatMessage } = intl;

    if (inputType == "table_number") {
      this.setState({
        errorType: formatMessage({ id: "invalid_table_number" })
      })
    } else if (inputType == "party_size") {
      this.setState({
        errorType: formatMessage({ id: "invalid_party_size" })
      })
    } else if (inputType == "first_name") {

      this.setState({
        errorType: formatMessage({ id: "please_input_first_name" })
      })

    }
    else if (inputType == "last_name") {

      this.setState({
        errorType: formatMessage({ id: "please_input_last_name" })
      })
    }
  }

  handleIsNum(e) {

    if (isNaN(e.target.value)) {
      return false;
    } else {
      return true;
    }

  }

  focusField() {
    this.focusInput.focus();
  }

  render() {
    let {
      errorState,
      errorType,
    } = this.state;

    let {
      className,
      label,
      fontSize,
      value,
      type,
      name,
      autoComplete,
      placeholder,
      requiredText,
      disabled,
      pattern,
      minLength,
      maxLength,
      width,
      inputType,
      focusRef,
    } = this.props;
    let colors = this.handleColors();

    this.focusFieldType(inputType, focusRef);

    return (

      //Component type checker 
      <div className="floating-input-container" style={{ fontSize: fontSize, width: width }}>
        <div className="input-border-wrapper">
          <div className={
            classnames({
              "input-border": true,
              "active": this.state.showColor && this.state.isFocused
            })}
            style={{
              backgroundColor: colors.barColor
            }}
          >
          </div>
          {errorState ?

            <div className="errorMsg" style={{
              color: this.props.invalidColor
            }}>

              {errorType}

            </div> : ''}
        </div>
        <div
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick()
            }
          }}
        >
          <input
            // id="floating-input"
            ref="floating-input"
            className={classnames({
              "floating-input": true,
              "unfocused": !this.state.isFocused,
              "no-disable-highlight": this.props.noDisableHighlight,
              [className]: className,
            })}
            name={name}
            onFocus={(e) => { this.handleCheck(e) }}
            onBlur={(e) => { this.handleCheck(e, "onBlur") }}
            onChange={(e) => { this.handleCheck(e) }}
            value={this.state.inputValue}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            disabled={disabled}
            pattern={pattern}
            minLength={minLength}
            maxLength={maxLength}
            width={width}
            style={{ maxLength: maxLength }}
            ref={(input) => { this.focusInput = input; }}
          />

        </div>
        {label
          ? <label
            className={
              classnames({
                "label": true,
                "floating": this.state.isFocused || value,
              })}
            style={{
              color: colors.textColor
            }}
          >
            {label}{requiredText ? '*' : ''}
          </label>
          : ''}


      </div>
    );
  }

  focusFieldType(inputType, focusRef) {
    if (inputType == focusRef) {
      this.focusField();
      this.props.onInputChange("focusField", "");
    }
  }
}

export default (injectIntl(FloatingInput));

// inspired by https://codepen.io/team/css-tricks/pen/XVBLRM?editors=1100

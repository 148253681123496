import _ from 'lodash';
import _model from '../../utils/model'
import _pageHelper from './helper'
import _config from '../../config'

const initModelData = {
    displayModal: false,
    displayModalType: '',
    displayModalHanldingType: '',
    displayModalContent: '',
    displayModalKeywords: {},
}

export function getDefaultState() {
    return {
        store: new _model.storeDetail(),
        order: new _model.orderDetail(),
        confirmCheckoutSuccess: false,
        ...initModelData
    }
}

function initDataSuccess(state, action) {
    let finalState = _.cloneDeep(state);
    const order = _.get(action, 'response.orderReponse.records', {})
    const store = _.get(action, 'response.storeReponse.records', {})
    finalState.store = new _model.storeDetail(store);
    finalState.order = new _model.orderDetail(order)
    finalState = Object.assign(finalState, _pageHelper.getInitStateFromOrder(order))
    finalState = Object.assign(finalState, initModelData)
    finalState.confirmCheckoutSuccess = false
    return finalState;
}

function updateTips(state, action) {
    let finalState = _.cloneDeep(state);
    const { amount, type } = action.payload
    const grandTotal = finalState.grandTotal

    let tips = 0
    if (type == 'flat') {
        tips = isNaN(amount) ? 0 : _.round(amount, 2)
    } else if (type === 'percent') {
        tips = _.round(grandTotal * (amount * 0.01), 2)
    }

    finalState.tips = tips
    finalState.totalDue = grandTotal + tips
    return finalState
}

function payOrderSuccess(state, action) {
    let finalState = _.cloneDeep(state);

    const RC = _.get(action, 'response.RC', 404)
    const records = _.get(action, 'response.records', 404)

    if (RC == 200) {
        const payMethod = _.get(finalState, 'paymentMethod', '')
        const redirectUrl = `${window.location.origin}/account/orders/detail/${records.ord_id}`
        const wechatPayUrl = _.get(records, 'payment.wechat.pay_url', '')
        const aliPayUrl = _.get(records, 'payment.alipay.pay_url', '')
        const unionPayUrl = _.get(records, 'payment.unionpay.pay_url', '')
        const unionPayExpresUrl = _.get(records, 'payment.unionpayexpress.pay_url', '')
        const payPalPayUrl = payMethod === 'paypal_express' && `/paypal?orderId=${records.ord_id}`
        const payUrl = aliPayUrl || wechatPayUrl || unionPayUrl || unionPayExpresUrl || payPalPayUrl || ''

        finalState.newOrderId = records.ord_id;
        finalState.confirmCheckoutSuccess = true
        finalState.displayModalType = 'success';
        finalState.displayModalHanldingType = payUrl ? 'pay_now' : 'go_to_order';
        finalState.displayModalContent = payUrl ? '' : 'pay_success';
        finalState.displayModalKeywords = { order_id: records.ord_id };
        finalState.payUrl = payUrl ? payUrl + `&redirect=${redirectUrl}` : '';

    } else if (RC == 400) {

        finalState.displayModalContent = 'invalid_request';
        finalState.displayModalHanldingType = 'continue';

    } else if (RC == 456) {

        finalState.displayModalContent = 'transaction_declined';
        finalState.displayModalHanldingType = 'continue';
    } else if (RC == 459) {

        finalState.displayModalContent = 'price_over_limit';
        finalState.displayModalHanldingType = 'continue';
    } else if (RC == 460) {

        finalState.displayModalContent = 'invalid_shp_mtd';
        finalState.displayModalHanldingType = 'continue';
    } else if (RC == 461) {

        finalState.displayModalContent = 'invalid_credit_card';
        finalState.displayModalHanldingType = 'continue';
    } else if (RC == 463) {

        finalState.displayModalContent = 'invalid_pay_mtd';
        finalState.displayModalHanldingType = 'continue';
    } else if (RC == 464) {

        finalState.displayModalContent = 'invalid_shp_mtd';
        finalState.displayModalHanldingType = 'continue';
    } else if (RC == 1000) {

        let oosItems = [];

        Object.keys(records.oos).map((responsePid) => {
            finalState.productList.map((cartItem) => {
                if (cartItem.pid == responsePid) {
                    oosItems.push(cartItem.nm)
                }
            })
        })

        finalState.displayModalContent = 'item_oos';
        finalState.displayModalHanldingType = 'back_to_store';
        finalState.alertDialogKeywords = { oos_items: oosItems.join(', ') };
    } else {

        finalState.displayModalContent = 'unsuccessful_checkout';
        finalState.displayModalHanldingType = 'continue';
        finalState.confirmCheckoutSuccess = false
    }

    return finalState
}

export default {
    'pay-now': (state = getDefaultState(), action) => {
        switch (action.type) {
            case 'PAY_NOW__SET_STATE':
                return Object.assign({}, _.cloneDeep(state), action.state);
            case 'PAY_NOW__CLEAR_DATA':
                return getDefaultState();
            case 'PAY_NOW__INIT_DATA_SUCCESS':
                return initDataSuccess(state, action);
            case 'PAY_NOW__UPDATE_TIPS':
                return updateTips(state, action);
            case 'PAY_NOW__PAY_ORDER_SUCCESS':
                return payOrderSuccess(state, action);
            default:
                return state;
        }
    }
}

import { all } from 'redux-saga/effects';
import SettingsSagas from 'containers/settings/sagas';
import AuthSagas from 'containers/auth/sagas';
import AccountPageSagas from 'containers/account-page/sagas';
import OrderListSagas from 'containers/order-list/sagas';
import OrderDetailSagas from 'containers/order-detail/sagas';
import BookmarkSagas from 'containers/bookmarks/sagas';
import GiftCardSagas from 'containers/gift-card-page/sagas';
import PointsSagas from 'containers/points-page/sagas';
import AddressBookSagas from 'containers/address-book/sagas';
import ShoppingCartSagas from 'containers/shopping-cart/sagas';
import CheckoutPageSagas from 'containers/checkout-page/sagas';
import GroupSalesSagas from 'containers/groupsale-page/sagas'
import AppConfigSagas from 'containers/app-config/sagas';
import WithStoreDataSagas from 'containers/with-store-data/sagas'
import LocationsSagas from 'containers/locations/sagas';
import PaypalSagas from 'containers/paypal-page/sagas'
import NotificationsSagas from '../notifications/sagas'
import PayNowSagas from '../pay-now-page/sagas'
import SelfCheckoutSagas from "../self-checkout-page/sagas";

export default function* rootSaga() {
	yield all([
		...SettingsSagas,
		...AuthSagas,
		...AccountPageSagas,
		...OrderListSagas,
		...OrderDetailSagas,
		...BookmarkSagas,
		...GiftCardSagas,
		...AddressBookSagas,
		...ShoppingCartSagas,
		...CheckoutPageSagas,
		...PointsSagas,
		...AppConfigSagas,
		...WithStoreDataSagas,
		...LocationsSagas,
		...GroupSalesSagas,
		...PaypalSagas,
		...NotificationsSagas,
		...PayNowSagas,
		...SelfCheckoutSagas,
	]);
}

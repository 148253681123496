import { call, put, takeEvery, select } from 'redux-saga/effects';
import _config from 'config';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import { message } from 'antd';

import _locale from 'locale';


function* updateUserProfile(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	try {
		let url = _apiRoutes['user-profile'];

		let postBody = action.payload

		const userProfileResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);

		if (userProfileResponse.RC === 200) {
			message.success(trans('profile_update_success'));
		} else {
			message.error(trans('profile_update_failed'));
		}

		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PROFILE_SUCCESS", response: { userProfileResponse }, payload: { ...action.payload } });
	} catch (e) {
		message.error(trans('profile_update_failed'));
		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PROFILE_FAILED", message: e.message, payload: action.payload });
	}
}

function* updateUserEmail(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	try {
		let { email } = action.payload
		let url = _apiRoutes['new-email'];


		let postBody = {
			email: email,
		}

		const userEmailResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);

		if (userEmailResponse.RC === 200) {
			message.success(trans('email_update_success'));
			yield put({ type: "AUTH__FETCH_USER_PROFILE" })
		} else {
			message.error(trans('email_update_failed'));
		}

		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_EMAIL_SUCCESS", response: { userEmailResponse }, payload: { ...action.payload } });
	} catch (e) {
		message.error(trans('email_update_failed'));
		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_EMAIL_FAILED", message: e.message, payload: action.payload });
	}
}
function* updateUserPhone(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	try {
		let { phone, vc, countryCode } = action.payload
		let url = _apiRoutes['new-phone'];


		let postBody = {
			phone,
			vc,
			country_code: countryCode
		}

		const userPhoneResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);

		if (userPhoneResponse.RC === 200) {
			message.success(trans('phone_update_success'));
			yield put({ type: "AUTH__FETCH_USER_PROFILE" })
		} else {
			message.error(trans('phone_update_failed'));
		}

		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PHONE_SUCCESS", response: { userPhoneResponse }, payload: { ...action.payload } });
	} catch (e) {
		message.error(trans('phone_update_failed'));
		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PHONE_FAILED", message: e.message, payload: action.payload });
	}
}

function* fetchRecentOrders(action) {
	try {
		let url = _apiRoutes['order-list'];
		let searchParams = {
			page: 1,
			limit: 5,
		}
		url = _helper.objToQuery(url, searchParams);

		if (_helper.getIndependentDomain()) {
			url += `&domain=${_helper.getIndependentDomain()}`
		}

		const recentOrders = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');


		yield put({ type: "ACCOUNT_PAGE__FETCH_RECENT_ORDERS_SUCCESS", response: { recentOrders }, payload: { ...action.payload } });
	} catch (e) {
		yield put({ type: "ACCOUNT_PAGE__FETCH_RECENT_ORDERS_FAILED", message: e.message, payload: action.payload });
	}
}


function* updateUserPassword(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	try {
		let { email, phone, code, oldPassword, newPassword } = action.payload

		let url = ''
		let postBody = {}

		if (email || (phone && code)) {
			//changed by email or phone
			url = _apiRoutes['forget-password'];
			postBody = action.payload
		} else if (oldPassword && newPassword) {
			//change by existing email
			url = _apiRoutes['update-password'];
			postBody = {
				op: oldPassword,
				password: newPassword,
			}
		}

		if (url && postBody) {
			const response = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);
			if (response && response.RC == 200) {
				if (email) {
					message.success(trans('alert_password_reset_email_send'));
				} else {
					message.success(trans('alert_password_reset_success'));
				}
				yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PASSWORD_SUCCESS" });
			} else {
				let RC = response && response.RC ? response && response.RC : 0
				let messageContent = ''
				if (email || phone) {
					switch (RC) {
						case 404:
						case 484:
							messageContent = 'alert_email_not_found'
							break;
						case 200:
							messageContent = "alert_password_reset_success"
							break;
						case 204:
						case 483:
							messageContent = 'alert_phone_vc_not_match'
							break;
						default:
							messageContent = 'unexpected_error'
					}
				} else if (newPassword) {
					if (RC == 200) {
						messageContent = 'alert_password_reset_success';
					} else if (RC == 400) {
						messageContent = 'alert_missing_fields';
					} else if (RC == 403) {
						messageContent = 'password_not_detected';
					} else {
						messageContent = 'unexpected_error';
					}
				}
				message.error(trans(messageContent));
				yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PASSWORD_SUCCESS" });
			}
		}

	} catch (e) {
		yield put({ type: "ACCOUNT_PAGE__UPDATE_USER_PASSWORD_FAILED", message: e.message, payload: action.payload });
	}
}

export default [
	takeEvery('ACCOUNT_PAGE__UPDATE_USER_PROFILE', updateUserProfile),
	takeEvery('ACCOUNT_PAGE__UPDATE_USER_EMAIL', updateUserEmail),
	takeEvery('ACCOUNT_PAGE__UPDATE_USER_PHONE', updateUserPhone),
	takeEvery('ACCOUNT_PAGE__FETCH_RECENT_ORDERS', fetchRecentOrders),
	takeEvery('ACCOUNT_PAGE__UPDATE_USER_PASSWORD', updateUserPassword)
];


import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from '../../../../utils/helper'

class InactivOrderButtons extends React.Component {
    handleRecorder = () => {
        let {
            order,
        } = this.props;

        const gid = order['group_id']
        _helper.setLocalStorage(`g${gid}`, { shoppingCart: this.generateShoppingCartFromOrder() });
        window.location.href = _helper.getIndependentDomain() ? `/` : `/store/${gid}`
    }

    generateShoppingCartFromOrder = () => {
        let {
            order,
        } = this.props;

        var cartData = [];
        order.order_item.map(currentOrderItem => {
            let tempOrderItem = {
                pid: currentOrderItem.pid,
                count: currentOrderItem.cnt,
                name: currentOrderItem.nm,
                price: currentOrderItem.pc,
                pt: (currentOrderItem['pt']) ? currentOrderItem['pt'] : 0,
            };

            //order options
            if (currentOrderItem.opts) {
                tempOrderItem.opt = {};
                tempOrderItem.optionDetail = [];

                //order option items loop

                currentOrderItem.opts.map(currentOrderOptionGroup => {
                    tempOrderItem.opt[currentOrderOptionGroup.id] = {};
                    var optionDetailItem = {
                        nm: currentOrderOptionGroup.nm,
                        opt: [],
                    };
                    currentOrderOptionGroup.opts.map(currentOrderOptionItem => {
                        tempOrderItem.opt[currentOrderOptionGroup.id][currentOrderOptionItem.id] = {
                            nm: currentOrderOptionItem.nm
                        };
                        optionDetailItem.opt.push(currentOrderOptionItem.nm);
                    });
                    tempOrderItem.optionDetail.push(optionDetailItem);
                });
            }

            cartData.push(tempOrderItem);
        });

        return cartData;
    }

    renderButton = () => {
        return (
            <Button
                onClick={() => this.handleRecorder()} type="primary">
                <FormattedMessage id="reorder" />
            </Button>
        )
    }

    render() {
        return (
            <div className="order-detail-control-panel-container">
                <div></div>
                <div>
                    {this.renderButton()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    order: _.get(state, "order-detail.orderDetailInstance.data", {})
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(InactivOrderButtons)));
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Layout, Form, Input, Checkbox, Button, Divider, Icon, Spin, Modal } from 'antd';
import queryString from 'query-string';
import LoginAccountForm from './login-form/account';
import LoginTextForm from './login-form/text';
import { FormattedMessage, injectIntl } from 'react-intl';

import _authActions from 'containers/auth/actions';

import './_login.scss';

const FormItem = Form.Item;
const {
  Header, Content, Footer, Sider,
} = Layout;

class LoginModal extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // let { checkAuthStatus } = this.props;
    // checkAuthStatus();
  }

  getLoginTypeText = () => {
    let { loginType } = this.props;

    if (loginType === 'account') {
      return [<Icon className="login-icon" type="mobile" />, <FormattedMessage id="login_with_text" />]
    } else if (loginType === 'text') {
      return [<Icon className="login-icon" type="user" />, <FormattedMessage id="login_with_account" />]
    }
  }

  handleLogin = (loginParams) => {
    let {
      login,
      push,
      redirectPath
    } = this.props;
    let finalParams = _.cloneDeep(loginParams)

    if (redirectPath) {
      //If login in from sale details page
      finalParams.redirectPath = redirectPath
      finalParams.type = 'modal'
    } else {
      //Perform a regular log in
      finalParams.stay = true
      finalParams.type = 'modal'

    }

    login(finalParams)

  }

  componentWillUnmount() {
    // let { clearAuthData } = this.props;
    // clearAuthData()
  }

  shouldVisible() {
    const { loginForceVisible, isAuthenticated, loginDialogDisplay } = this.props
    return !isAuthenticated && (loginDialogDisplay || loginForceVisible)
  }

  render() {
    let {
      toggleLoginType,
      loginType,
      getVerificationCode,
      loginLoading,
      onClose,
      fromSaleDetails,
    } = this.props;

    let { formatMessage } = this.props.intl;

    return (
      <Modal
        width={400}
        height={446}
        title={"Goopter " + formatMessage({ id: "log_in" })}
        visible={this.shouldVisible()}
        loginForm={fromSaleDetails}
        onOk={this.handleOk}
        onCancel={onClose}
        footer={null}
      >

        <Content className="content-container">

          <div className="auth-form-container">
            <div className="box">
              {/* <Spin spinning={authFormLoading}> */}

              <div className="link" style={{ textAlign: 'right', paddingBottom: '5px' }} onClick={toggleLoginType}>
                {this.getLoginTypeText()}
              </div>
              <LoginAccountForm
                login={this.handleLogin}
                hide={loginType !== 'account'}
                loginLoading={loginLoading}
              />
              <LoginTextForm
                login={this.handleLogin}
                getVerificationCode={getVerificationCode}
                hide={loginType !== 'text'}
                loginLoading={loginLoading}
              />
              {/* </Spin> */}
            </div>
          </div>
        </Content>

      </Modal>

    )
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth'];

  return {
    isAuthenticated: authState.isAuthenticated,
    loginDialogDisplay: authState.loginDialogDisplay,
    loginType: authState.loginType,
    authFormLoading: authState.authFormLoading,
    loginLoading: authState.loginLoading,
    loginFrom: authState.loginFrom
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    checkAuthStatus: bindActionCreators(_authActions.checkAuthStatus, dispatch),
    login: bindActionCreators((params) => {
      return {
        type: "AUTH__LOGIN",
        payload: params
      }
    }, dispatch),
    toggleLoginDialogDisplay: bindActionCreators((bool) => {
      return {
        type: "AUTH__SET_STATE",
        state: {
          loginDialogDisplay: bool
        }
      }
    }, dispatch),
    toggleLoginType: bindActionCreators((type) => {
      return {
        type: "AUTH__TOGGLE_LOGIN_TYPE",
        state: {
          loginType: 'account'
        }
      }
    }, dispatch),
    // clearOrderDetailData: bindActionCreators((params = {}) => {
    //   return {
    //     type: "ORDER_DETAIL__CLEAR_DATA",
    //   }
    // }, dispatch)


    getVerificationCode: bindActionCreators((params) => {
      return {
        type: "AUTH__GET_VERIFICATION_CODE",
        payload: params
      }
    }, dispatch),
    clearAuthData: bindActionCreators(_authActions.clearAuthData, dispatch)
  }
}

LoginModal = connect(mapStateToProps, mapDispatchToProps)(LoginModal);
export default injectIntl(LoginModal)


import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Menu, Icon, Input, Button, } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from 'utils/helper'

import "./_topMenu.scss"

const { SubMenu } = Menu;

class TopMenu extends Component {


  render() {
    let { push, shortcut, onShortCutClick, intl } = this.props

    let { formatMessage } = intl;

    let items = [
      {
        id: 555,
        name: "goods",
        className: 'top-menu-Selection',
      },
      {
        id: 5555,
        name: "things_to_do",
        className: 'top-menu-Selection',
      },
      {
        id: 1300,
        name: "beauty_spas",
        className: 'top-menu-longSelection',
      },
      {
        id: 1001,
        name: "restaurants",
        className: 'top-menu-Selection',
      },
      {
        id: 1400,
        name: "travel",
        className: 'top-menu-Selection',
      },
      {
        id: 5555,
        name: "sale",
        className: 'top-menu-Selection',
      },
      {
        id: 1300,
        name: "health_fitness",
        className: 'top-menu-longSelection',
      },
    ]

    return (
      <div className="top-menu-container">
        <Menu
          style={{
            backgroundColor: "#2bb8aa",
            color: "white",
            fontSize: "16px",
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          mode="horizontal"
          selectedKeys={["1", "2", "3"]}
        >
          {items.map((item, i) => {
            return (
              <Menu.Item
                key={item.id + i}
                onClick={() => onShortCutClick(formatMessage({ id: item.name }))}
              >
                {<span
                  className= {item.className ? item.className : ' '}>
                  <FormattedMessage id={item.name} />
                </span>}
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let settingsState = state['settings'];
  return {
    lan: settingsState.lan,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TopMenu))
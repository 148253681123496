import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'
import './add-comment-section.scss'
import AddCommentDialog from './add-comment-dialog'

class AmountDisplaySection extends React.Component {
    getComment = () => {
        return _.get(this.props, 'state.comment', '')
    }

    openCommentModal = () => {
        this.props.setState({ displayModalName: 'add_comment' })
    }


    getStorePhone = () => {
        return _.get(this.props, 'state.store.phone', '')
    }

    renderRightSection = () => {
        return (
            <a className="contact-merchant-btn pointer" href={`tel:${this.getStorePhone()}`}>
                <FormattedMessage id="contact_merchant" />
            </a>
        )
    }

    renderLeftSection = () => {
        return (
            <div className="pointer" onClick={() => this.openCommentModal()}>
                <FormattedMessage id="add_comment" />
            </div>
        )
    }

    renderButtons = () => {
        return (
            <div className="add-comment-section-buttons-container">
                {this.renderLeftSection()}
                {this.renderRightSection()}
            </div>
        )
    }

    renderCommentText = () => {
        return (
            this.getComment().length ?
                <div className="add-comment-comment-container pointer"
                    onClick={() => this.openCommentModal()}>
                    <div className="add-comment-comment-display">
                        {this.getComment()}
                    </div>
                </div> : null
        )
    }

    render() {
        return [
            <div className="add-comment-section-container">
                {this.renderButtons()}
                {this.renderCommentText()}
            </div>,
            <AddCommentDialog />
        ]
    }
}

const mapStateToProps = (state) => ({
    lan: state.settings.lan,
    state: _.get(state, 'self-checkout', {})
})

const mapDispatchToProps = ({
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AmountDisplaySection))
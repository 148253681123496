import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment'
import _ from 'lodash'

//wechat pay, alipay, or paypal express, union_pay, union_pay_express
const SHOW_TIMER_PAYMENT_METHOD = [6, 7, 8, 12, 13]

class DecrptionText extends React.Component {
    state = {}

    componentDidMount() {
        this.updateTimer()
    }

    componentWillUnmount() {
        this.clearTimer()
    }

    updateTimer = () => {
        this.timer = setInterval(() => {
            const remainingTime = this.getDiff().asSeconds()

            this.setState({
                remainingTimeStr: this.getCountDownString(),
                remainingTime
            })

            if (remainingTime <= 0) {
                clearInterval(this.timer)
            }
        }, 1000);
    }

    clearTimer = () => {
        clearInterval(this.timer);
    }

    getEndTime() {
        return this.props.payExpiredTime
    }

    getNow() {
        return moment().utc().format('YYYY-MM-DD HH:mm:ss')
    }

    getDiff() {
        const end = moment(this.getEndTime())
        const now = moment(this.getNow())
        const diff = moment.duration(end.diff(now))
        return diff
    }

    getValidatedValue() {
        return this.getDiff().toISOString()
    }

    //timeValue supported Value 'Days, Hours, Milliseconds, Minutes, Months, Seconds, Weeks, Years'
    isMoreThan(timeValue, valueLength = 1) {
        const valueFundtion = eval(`this.getDiff().as${timeValue}()`)
        return valueFundtion > valueLength
    }

    //supported value
    // 'years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'
    getDiffValue(timeValue) {
        const value = eval(`this.getDiff().${timeValue}()`)
        return value
    }

    string = (id) => {
        return this.props.intl.formatMessage({ id })
    }

    getCountDownString = () => {
        const values = ['Weeks', 'Days', 'Hours', 'Minutes'] //format value changed triggered values
        let result = `1 ${this.string('minutes')}`
        values.find((value, index) => {
            if (this.isMoreThan(value)) {
                result = this.getFormarttedString(index)
                return true
            }
        })
        return result
    }

    getFormarttedString = (index) => {
        let result = ''
        const formats = [
            ['days'],
            ['days', 'hours', 'minutes'],
            ['hours', 'minutes'],
            ['minutes', 'seconds']]
        const format = formats[index]
        format.map((time, index, arr) => {
            const appendTimeSpace = this.props.lan === 'en'
            const appendEndSpace = index !== (arr.length - 1) && appendTimeSpace
            result += `${this.getDiffValue(time)}${appendTimeSpace ? ' ' : ''}${this.string(time)}${appendEndSpace ? ' ' : ''}`
        })
        return result
    }

    renderCountDownText = () => {
        const getCountDownStringValues = () => {
            return (
                <span className="count-down-timer-timer-text">
                    {this.state.remainingTimeStr}
                </span>
            )
        }

        return (
            <FormattedMessage
                id='please_pay_by_x'
                values={{ x: getCountDownStringValues() }} />
        )
    }

    showCountDownText = () => {
        const validPayment = SHOW_TIMER_PAYMENT_METHOD.includes(this.props.paymentMethod)
        const currentUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss')
        const remainingMoreThanZero = this.state.remainingTime > 0
        const notExpired = moment(this.props.payExpiredTime).isAfter(currentUTC)
        return notExpired && validPayment && remainingMoreThanZero
    }

    renderNormalText = () => {
        return (
            <div className="pay-now-descrption-text">
                <span><FormattedMessage id="please_pay_now" /></span>
            </div>
        )
    }

    render() {
        return this.showCountDownText() ? this.renderCountDownText() : this.renderNormalText()
    }
}

const mapStateToProps = (state) => ({
    lan: state.settings.lan,
    payExpiredTime: _.get(state, 'order-detail.orderDetailInstance.data.order_expire_dt', ''),
    paymentMethod: _.get(state, 'order-detail.orderDetailInstance.data.payment_method', 0),
})

const mapDispatchToProps = ({

})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DecrptionText))
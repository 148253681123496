import React, { Component } from "react";
import Locations from "containers/locations";
import _actions from "containers/locations/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ReactDOM from "react-dom";
import _config from "config";
import _helper from "utils/helper";
import classnames from "classnames";
import { Input, Icon, Select } from "antd";
import jsonp from "fetch-jsonp";
import querystring from "querystring";
import "./_groupsale-mapsearch.scss";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";

const Option = Select.Option;

class GroupSaleMapSearch extends Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;
    let { formatMessage } = intl;

    this.state = {
      data: [
        {
          description: formatMessage({ id: "current_location" })
        }
      ],
      value: undefined,
      searchValue: "",
      location: undefined,
      place_id: "",
      location: [],
      isLoaded: false,
      inputValid: false
    };
  }

  checkGeoPermission() {
    let setState = (value) => this.setState({ permissionsGranted: value })
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (permissionStatus) {
        if (permissionStatus.state == "granted") {
          setState(true)
        }
        permissionStatus.onchange = function () {
          { }

        };
      });
  }

  searchOnChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (!e.target.value) {
      this.props.onValueClear();
    } else {
      this.props.onInputChanged();
    }
  };

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  listOptions = value => {
    let { intl, userLat, userLon, } = this.props;
    let { formatMessage } = intl;
    let google_service = new window.google.maps.places.AutocompleteService();

    google_service.getQueryPredictions(
      {
        input: value,
        location: new window.google.maps.LatLng({
          lat: userLat,
          lng: userLon
        }),
        radius: 20000,
      },
      displaySuggestions => {
        let data = displaySuggestions;
        if (data) {

          data.unshift({
            description: formatMessage({ id: "current_location" })
          });
          this.setState({ data: data });

        }


      }
    );
  };

  handleSelect = (value, option) => {
    let { getLocationDetails, userLat, userLon } = this.props;
    let { data, location } = this.state;
    this.setState({ value: value });
    if (value == "current_location") {

      this.checkGeoPermission();

      this.setState({ inputValid: true });
    } else {
      let placeId = data[option.key].place_id;
      getLocationDetails({ placeId });
      this.setState({ inputValid: true });
    }
  };

  getOptions() {
    let { data } = this.state;

    let result = data.map((d, i) =>
      i == 0 ? (
        <Option value="current_location" key={i}>
          <Icon style={{ marginRight: "2px" }} type="home" /> {d.description}
        </Option>
      ) : (
          <Option key={i}>
            <Icon style={{ marginRight: "2px" }} type="environment" /> {d.description}
          </Option>
        )
    );

    result.push(<Option style={{ textAlign: 'end' }} disabled key={'google'}>
      Powered by google
  </Option>)

    return result
  }

  render() {
    let { push, addressList, intl } = this.props;

    let { data } = this.state;

    let { formatMessage } = this.props.intl;

    return (
      <div>
        <Select
          showSearch
          size="default"
          value={this.state.value}
          placeholder={formatMessage({ id: "enter_a_location" })}
          style={this.props.style}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={this.listOptions}
          onFocus={this.listOptions}
          onSelect={this.handleSelect}
          inputValid={this.state.inputValid}
          notFoundContent={null}
        >
          {data.length ? this.getOptions() : ""}
        </Select>
        {this.state.permissionsGranted ? <Locations /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  //let locationState = state["locations"];
  return {
    userLat: state.locations.userLat,
    userLon: state.locations.userLon
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCurrentLatLon: bindActionCreators(params => {
      return _actions.addCurrentLatLon({
        userLat: params.userLat,
        userLon: params.userLon
      });
    }, dispatch),
    addAddressComp: bindActionCreators(params => {
      return _actions.addAddressComp(params);
    }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GroupSaleMapSearch));

import { put, takeEvery, select, call } from 'redux-saga/effects';
import _config from 'config';
import _api from 'utils/api';
import _helper from 'utils/helper';
import _ from 'lodash';

function* initAppConfig(action) {
    try {
        let appConfigResponse = yield call(_api.get, `${_config.API_ROOT}/api/v7/appconfig`);
        yield put({ type: "INIT_APP_CONFIG__SUCCESS", response: { appConfigResponse }, action: action.payload });
    } catch (e) {
        yield put({ type: "INIT_APP_CONFIG__FAILED", message: e.message, action: action.payload });
    }
}

export default [
    takeEvery('INIT_APP_CONFIG', initAppConfig),
];

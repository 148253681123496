import _ from 'lodash';
import _helper from 'utils/helper';

export function getDefaultState() {
  return {
    pageLoading: true,
    addressListLoading: false,
    addressList: [],
    saveAddressLoading: false,
  }
}

function fetchAddressList(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { addressListResponse } = action.response;

    if (addressListResponse.RC === 200) {
      finalState.addressList = addressListResponse.records
    }
  }

  finalState.addressListLoading = false;
  return finalState;
}

function saveAddress(state, action, status) {
  let finalState = _.cloneDeep(state);
  finalState.saveAddressLoading = false;
  return finalState;
}

export default {
  'address-book': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'ADDRESS_BOOK__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      // case 'ADDRESS_BOOK__CLEAR_AUTH_DATA':
      //   return clearAddressData(state, action)
      // case 'ADDRESS_BOOK__FETCH_ADDRESS_LIST':
      //   return clearAuthData(state, action)
      case 'ADDRESS_BOOK__FETCH_ADDRESS_LIST_SUCCESS':
        return fetchAddressList(state, action, true)
      case 'ADDRESS_BOOK__FETCH_ADDRESS_LIST_FAILED':
        return fetchAddressList(state, action, false)
      case 'ADDRESS_BOOK__SAVE_ADDRESS_SUCCESS':
        return saveAddress(state, action, true)
      case 'ADDRESS_BOOK__SAVE_ADDRESS_FAILED':
        return saveAddress(state, action, false)
      default:
        return state;
    }
  }
}

import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Radio, Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_shippingMethodModal.scss'

const RadioGroup = Radio.Group;

//AKA the sub Shipping Method Modal
class ShippingMethodModal extends Component {

    state = {
        currentSelectedValue: true,
    }

    componentWillReceiveProps(nextProps) {
        let { checkoutState } = nextProps
        if (checkoutState.selectedShippingType != this.state.currentSelectedValue) {
            this.setState({ currentSelectedValue: checkoutState.selectedShippingType })
        }
    }

    onChange = (e) => {
        this.setState({
            currentSelectedValue: e.target.value,
        });
    }


    onOkClick = () => {
        let {
            onCloseClick,
            onOkClick
        } = this.props;

        let {
            currentSelectedValue,
        } = this.state

        if (currentSelectedValue) {
            onOkClick(currentSelectedValue);
            onCloseClick();
        }

    }


    render() {
        let {
            displayModal,
            onCloseClick,
            intl,

            checkoutState,
            currency,
        } = this.props;

        let { formatMessage } = intl;

        let { currentSelectedValue } = this.state

        let { selectedShippingType, shippingTypes } = checkoutState

        return (
            <Modal
                title={formatMessage({ id: 'shipping_method' })}
                visible={displayModal}
                onOk={() => this.onOkClick()}
                onCancel={onCloseClick}
                okText={formatMessage({ id: 'ok' })}
                cancelText={formatMessage({ id: 'cancel' })}
            >
                <div className="shippingMethodModalContainer" >

                    <RadioGroup style={{ width: "100%" }} onChange={this.onChange} value={currentSelectedValue}>
                        {shippingTypes.map(shipping => {
                            return (
                                <div>
                                    <div className="descripTitle">{shipping.snm}</div>
                                    {shipping.methods.map((method, i) => {
                                        return (
                                            <Radio key={i} className="radioItem" value={method.code}>
                                                <Row className="radioItemWrapper">
                                                    <Col span={18}>{method.desc}</Col>
                                                    <Col span={6}>{_helper.formatCurrency(method.fee)}</Col>
                                                </Row>
                                            </Radio>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </RadioGroup>
                </div>
            </Modal>
        )
    }
}


export default injectIntl(ShippingMethodModal);
export default {
  "common": {
    " ": " ",
    "yes": "是",
    "no": "否",
    "confirm": "確認",
    "place_order": "確認購買",
    "delete": "刪除",
    "ok": "確認",
    "close": "關閉",
    "filter": "篩選",
    "product": "產品",
    "store": "商店",
    "loading": "載入中...",
    "setting": "設置",
    "back": "後退",
    "dashboard": "控制面板",
    "notification": "消息",
    "warning": "警告",
    "submit": "提交",
    "cancel": "取消",
    // "comment": "備註",
    "comment": "**Special Instructions**",
    "tel": "電話",
    "phone_number": "電話號碼",
    "not_set": "未設置",
    "name": "名字",
    "email": "電郵",
    "customer": "客戶",
    "admin": "管理",
    "current": "當前",
    "newest": "最新",
    "no_new_noti": "沒有新的通知",
    "clear_all": "清除所有",
    "from": "從",
    "to": "到",
    "all": "全部",
    "save": "保存",
    "disabled": "禁用",
    "margin": "頁邊距",
    "top": "上",
    "bottom": "下",
    "left": "左",
    "right": "右",
    "general": "通用",
    "general_settings": "通用設置",
    "status": "狀態",
    "new": "新",
    "reload": "刷新",
    "selected": "已選",
    "items": "商品",
    "clear_fliter": "清除篩選條件",
    "search": "搜索",
    "first_name": "名",
    "last_name": "姓",
    "enter_password": "請輸入密碼",
    "unlock": "解鎖",
    "qty": "數量",
    "gift_cards": "禮品卡",
    "view_all": "查看全部",
    "profile": "個人資料",
    "nick_name": "暱稱",
    "verification_code": "驗證碼",
    "get_verification_code": "獲取驗證碼",
    "edit": "編輯",
    "action": "行動",
    "send": "發送",
    "add_comment": "添加備註",
    "receiver": "接受者",
    "email_phone": "電子郵件/電話",
    "change": "改變",
    "description": "描述",
    "remove": "刪除",
    "reorder": "再來一單",
    "enter_coupon_code": "輸入優惠劵代碼",
    "apply": "使用優惠券",
    'complete_oreder': "完成訂單",
    "category": "類別",
    "expired": "已過期",
    "optional": "可選",
    "about": "關於",
    "highlights": "亮點",
    "customer_reviews": "客戶評論",
    "relevant_reviews": "相關評論",
    "ratings": "評級",
    "reviews": "評論",
    "buy": "購買",
    "quantity": "數量",
    "out_of_stock": "缺貨",
    "refresh": "刷新",
  },
  "common_order_status": {
    "pending": "待处理",
    "processing": "处理中",
    "payment_review": "付款審核",
    "completed": "已完成",
    "canceled": "已取消",
    "closed": "已关闭",
    "pending_payment": "待付款",
    "holded": "暫停",
  },
  "common_shipping_method": {
    "shipping_method": "訂單類型",
    "delivery_option": "訂單類型",
    "order_type": "訂單類型",
    "delivery": "配送",
    "pickup": "自取",
    "eatin": "堂食",
    "instore": "店內",
    "takeout": "外賣",
    "dine_in": "堂食",
    "in_store": "店內",
    "flatrate": "統一費率",
    "freeShipping": "免費配送",
    "how": "方式",
  },
  "common_payment_method": {
    "payment_method": "付款方式",
    "pay_later": "稍後刷卡支付",
    "cash": "現金",
    "credit_card": "信用卡",
    "giftcard": "禮品卡",
    "points": "積分",
    "points_and_giftcard": "禮品卡與積分",
    "wechat_pay": "微信支付",
    "paypal_express": "貝寶",
    "alipay": "支付寶",
    "union_pay": "銀聯借記卡",
    "union_pay_express": "銀聯信用卡",
    "free": "免費",
    "points_balance": "Balance",
    "remaining_balance": "Remaining Points",
    "redeemed": "Redeem",

    "card_number": "卡號",
    "name_on_card": "持卡人",
    "cvv": "安全碼",

    "cvv_descpt": '來自卡背面的3到4位數字',
    "cash_despcrition": '訂單以現金支付',
    "pay_later_despcrition": '訂單將在以後用卡支付',
    "wechat_pay_despcrition": '點擊“確認”後，您將被轉到微信付費頁面完成購買',
    "paypal_expres_despcrition": '點擊“確認”後，您將被引導至貝寶頁面完成購買',
    "alipay_despcrition": '點擊“確認”後，您將被定向到支付寶頁面以完成購買',
    "union_pay_despcrition": '點擊“確認”後，您將被定向到銀聯借記卡頁面以完成購買',
    "union_pay_express_despcrition": '點擊“確認”後，您將被定向到銀聯信用卡頁面以完成購買',
  },
  "common_price": {
    "subtotal": "小計",
    "discount": "折扣",
    "store_discount": "商家折扣",
    "coupon_discount": "打折券",
    "shipping_and_handling": "配送費",
    "total_before_tax": "稅前金額",
    "tax": "稅",
    "order_total": "訂單總額",
    "gift_card_payment": "禮品卡支付",
    "points_payment": "積分支付",
    "total_paid": "已付款",
    "total_due": "應付款",
    "tips": "小費",
    "delivery_fee": "配送費",
    "max_amount": "最高金額",
    "min_amount": "最低金額",
    "balance": "餘額",
    "amt": "總額",
    "price": "價格",
    "item(s)": "物件",
    "shopping": "購物",
    "billing_shipping": "付款及配送",
    "done": "完成",
    "customer_info": "客戶信息",
    "delivery_info": "運輸信息",
    "payment_info": "支付信息",
    "other_payment_info": "其他付款信息",
  },
  "common_†ime": {
    "date": "日期",
    "today": "今天",
    "tomorrow": "明天",
    "yesterday": "昨天",
    "this_week": "本星期",
    "last_week": "上个星期",
    "this_month": "這個月",
    "last_month": "上個月",
    "last": "上個",
    "next": "下一個",
    "now": "现在",
    "month": "月",
    "year": "年",
    "this_week": "本星期",
    "last_week": "上個星期",
    "this_month": "这个月",
    "last_month": "上个月",
    "select_date": "選擇日期",
    "select_time": "選擇時間",
    "expiry_date": "過期日",
    "delivery_time": "配送時間",
    "pickup_time": "取貨時間",
    "select_delivery_time": "選擇配送時間",
    "select_pickup_time": "選擇取貨時間",
    "due_time": "期待時間",
    "when": "時間",
    "requested_time": "要求時間",
    "scheduled_time": "預計時間",

  },
  "common_language": {
    "language": "語言",
    "en": "English",
    "zh": "简体中文",
    "zh-Hant": "繁體中文",
    "jp": "日本語",
  },
  "common_address": {
    "add_address": "添加地址",
    "address": "地址",
    "street": "街道",
    "unit": "單元",
    "buzzer": "門鈴",
    "unit_optional": "单元 (Optional)",
    "buzzer_optional": "门铃 (Optional)",
    "city": "城市",
    "province": "省份",
    "country": "國家",
    "postal_code": "郵編",
    "quick_map_search": "快速地圖搜索",
    "map_search": "地圖搜索",
    "shipping_address": "配送地址",
    "billing_address": "帳單地址",
    'set_as_default': "設為默認",
    "enter_a_location": "請輸入街道地址",
    "location": "地區",
    "van": "溫哥華",
    "vic": "維多利亞",
    "tor": "多倫多",
    "seattle": "西雅圖",
    "choose_city": "選擇城市",
    "current_location": "當前位置",
    "use_info_from_acc": "使用帳戶信息",
  },
  "common_error": {
    "x_is_invalid": "無效的{x}",
    "unable_to_load_any_data": "無法加載數據",
    "unstable_network": "網絡速度較慢或不穩定，請檢查您的網絡連接",
    "logout_force_message": "抱歉，您的帳戶已從其他設備登錄或超時，請重新登錄。",
    "incorrect_password": "密碼錯誤",
    'country_code_error': '國家/地區代碼錯誤',
    'incorrect_username_password': '用戶名/密碼不正確',
    'verification_code_expired': '驗證碼已過期',
    'verifcation_code_request_overlimit': '驗證碼請求超限',
    'incorrect_verification_code': '驗證碼不正確',
    "error_fetch_user_profile": "無法獲取用戶個人資料",
    "login_failed": "登錄失敗",
    "profile_update_success": "更新個人資料成功",
    "email_update_success": "更新電子郵件成功",
    "phone_update_success": "更新電話號碼成功",
    "failed_fetch_gift_card_detail": "無法獲取禮品卡詳細信息",
    "gift_card_transfer_success": "禮品卡轉讓成功",
    "gift_card_transfer_fail": "轉移禮品卡失敗",
    "invalid_request": "無效請求",
    "record_not_found": "未找到記錄",
    "general_error": "發生意外錯誤，請稍後再試",
    "failed_fetch_points_detail": "無法獲取積分詳細信息",
    "profile_update_failed": "更新用戶個人資料失敗",
    "email_update_failed": "更新電子郵件失敗",
    "phone_update_failed": "更新電話號碼失敗",
    "get_verification_code_success": "驗證碼已發送到您的手機",
    "verification_too_many_requests": "驗證碼已被請求過多次，請稍後再試",
    "invalid_phone_number": "無效的的手機號",
    "error": "錯誤",
    "coupon_invalid": "無效的優惠券",
    "coupon_valid": "有效優惠券",
    "invalid_coupon": "優惠券代碼 {couponCode} 無效",
    "coupon_applied": "優惠券 {couponCode} 已經使用",
    "distance_exceeded": "超出距離",
    "exceed_delivery_distance": "選擇的配送地址超過最遠的配送距離 {delivery_distance} km，請選擇其它地址。",
    "invalid_address": "無效地址",
    "item_error": "發生了一個錯誤。你的購物車已被清空，請重新添加產品到購物車後再結賬。",
    "shipping_country_text": "可配送到使用以下國家的地址: {countries}",
    "item_oos": "抱歉，以下產品缺貨: {oos_items}。請返回商家頁面調整購物車後重新結賬。",
    "invalid_shipping_method": "無效配送方式，請嘗試另一種配送方式。",
    'please_input_phone_number': '請輸入電話號碼',
    'please_input_first_name': '請輸入名字',
    'please_input_last_name': '請輸入姓氏',
    'please_input_shipping_method': '請輸入送貨方式',
    "please_input_sub_shipping_method": "請輸入送貨方式",
    'please_input_shipping_addresss': '請輸入送貨地址',
    'please_input_table_number': '請輸入桌號',
    'please_input_payment_method': '請輸入付款方式',
    "please_select_payment_method": "请選擇付款方式",
    'please_input_valid_credit_card': '請輸入有效的信用卡',
    '': '',
    'please_input_billing_address': '請輸入帳單地址',
    "invalid_credit_card": "無效信用卡，清選擇另外一個支付方式",
    "unsuccessful_checkout": "結帳不成功。請再試一次。",
    "invalid_request": "結帳不成功。無效請求。",
    "price_over_limit": "結賬不成功。價格超過付款限額。",
    "invalid_shp_mtd": "結帳不成功。無效配送方式，請嘗試另一種配送方式。",
    "invalid_pay_mtd": "結賬不成功。付款方式無效，請嘗試另一種付款方式。",
    "new_order_created": "新訂單已創建",
    "checkout_success": "您的訂單已經創建：#{order_id}。當訂單狀態更新時系統將會發送通知信息給您。",
    "pay_success": "新的訂單已經創建：#{order_id}。當訂單狀態更新時系統將會發送通知信息給您。",
    "please_input_belows": "請輸入以下缺少的部分",
    "invalid_expiry": "無效的過期日",
    "unable_to_change_here": "無法在此更改",
    "please_select_on_previous_page": "請在上一頁選擇",
    "invalid_card_number": "無效的卡號",
    "invalid_zipcd": "無效的郵政編碼",
    "click_one_more_time_to_deselect": "再點擊一次取消選擇",
    "paypal_payment_success_title": "支付成功",
    "paypal_payment_success_text": "您的付款已收到。下面將返回到訂單頁面。",
    "error_payment_failed": "支付失败",
    "internal_server_error": "抱歉，出現意外錯誤，請稍後再試或直接與商家聯繫。",
    "unexpected_error": "發生意外錯誤，請稍後再試",
    "no_items_error": "購物車裡沒有任何產品。請添加一些產品後再結算。",
    "giftcard_pays_order_total": "所選的禮品卡將支付此訂單，無需其它付款方式。",
    "points_pays_order_total": "您的積分已經支付訂單總額，無需其它付款方式。",
    "giftcard_points_pays_order_total": "您的禮品卡和積分已經支付訂單總額，無需其他付款方式。",
    "digit_only": "請只輸入數字",
    "please_selected_belows": "請完成所有選擇：",
    "alert_password_reset_success": "您的登錄密碼已經更改成功，您可以啟用新密碼登錄。",
    "alert_phone_vc_not_match": "手機驗證碼不匹配",
    "alert_phone_not_found": "此號碼尚未註冊",
    "alert_email_not_found": "此郵件地址尚未註冊",
    "alert_password_reset_email_send": "重置密碼郵件已經發送",
    "alert_email_length": "無效郵件地址",
    "alert_password_min_length": "密碼最少需要六位",
    "x_is_required": "是必填項",
    "location_permission_is_blocked_service_may_not_perform_correctly": "位置權限受限，此服務可能會受到影響",
    "order_payment_status_error": "商家已經接單，請當面支付。",
    "order_comment_status_error": "商家已經開始準備此訂單，如果有任何更改，請聯繫商家。電話：{phone}。",
  },
  "common_acccount": {
    "my_account": "我的帳戶",
    "address_book": "地址簿",
    "my_assets": "我的資產",
    "log_in": "登錄",
    "logout": "登出",
    "logout_confirm_message": "您確認要簽退嗎?",
    "login_with_text": "手機短信登錄",
    "password": "密碼",
    "remember_me": "記住我",
    "login_with_account": "帳戶登錄",
    "sign_up": "註冊",
    "sign_up_biz": "企業註冊",
    "store_mngt": "商店管理",
    "change_by_email": "通過電子郵件",
    "change_by_text_message": "通過手機短信",
    "change_by_existing_password": "通過電子郵件",
    "set_password": "設置密碼",
    "new_password": "新密碼",
    "old_password": "舊密碼",
    "confirm_password": "確認密碼",
    "change_password": "更改密碼",
    "code": "驗證碼",
    "request_pass_reset": "請求密碼重置",
    "secure_connection": "信息将通过加密方式安全传送",
  },
  "common_country": {
    "canada": "加拿大",
    "united_states": "美國",
    "china": "中國",
    "south_korea": "中國"
  },
  "common_order": {
    "no_orders": "沒有訂單",
    "order_id": "訂單號",
    "recent_orders": "訂單歷史",
    "order_history": "近期訂單",
    "order_date": "訂單日期",
    "order_detail": "訂單明細",
    "order_amount": "訂單金額",
    "ordered_on": "訂單時間",
    "order_pending_descrip": "等待商店確認訂單",
    "order_processing_descrip": "正在準備訂單",
    "order_complete_descript": "訂單已完成",
    "order_cancelled_descript": "訂單已被取消",
    "table_number": "桌號",
    "party_size": "就餐人數",
    "table_info": "餐桌詳情",
    "server": "服務員",
    "go_to_order": "前往該訂單",
    "surcharge": "附加费",
    "store_surcharge": "附加費",
    "order_surcharge": "订单附加费",
    "item_total": "商品小计",
    "surcharge_paid": "附加费(已支付)",
    "surcharge_unpaid": "附加费(未支付)",
    "related_id": "相关订单 #",
    "related": "相关",
    'surcharege_paid_message': '此订单不包括附加费。请参考订单 #{id}',
    "surcharge_paid_to_message": "本訂單是用來支付訂單 #{id}的附加費",
    "pay_now": "現在付款",
    "please_pay_now": "訂單尚未支付，您可以在線支付。",
    "are_you_sure_cancel_order": "您確定要取消訂單嗎?",
    "please_pay_by_x": "請在{x}內完成支付，超時後訂單將自動取消",
    "cancel_order_success": "您的訂單已經取消。",
    "cancel_order_failed": "未能取消訂單，請稍後再試。",
  },
  "common_store": {
    "stores": "商店",
    "view_stores": "查看商店",
    "store_name": "商家名稱",
    "to_store": "前往商店",
    "to_product": "前往商品",
    "contact_store": "聯繫商店",
    "bookmarks": "書籤",
    "my_gift_cards": "我的禮品卡",
    "gift_card_detail": "禮品卡詳情",
    "gift_card_used": "使用禮品卡支付",
    "gift_card_created": "購買禮品卡",
    "gift_card_refunded": "禮品卡退款",
    "gift_card_transferred": "禮品卡轉送",
    "gift_card_received": "收到禮品卡",
    "points": "積分",
    "back_to_store": "前往商店",
    "go_to_order": "前往訂單",
  },
  "common_product": {
    "products": "產品",
  },
  "common_group_sale": {
    'there': '',
    "welcomeMessage1": "{who}您好, ",
    "welcomeMessage2": "今天也不要忘記獎勵您自己一份大禮吧",
    "result_for": "'{keyword}'的結果",
    "select_what": "選擇'{keyword}'",
    "pop_on_groupter": "熱門優惠券",
    "brower_by_cat": "瀏覽熱門類別",
    "goods": "商品",
    "things_to_do": "要做的事",
    "beauty_spas": "美容與水療",
    "restaurants": "餐館",
    "travel": "旅行",
    "sale": "促銷",
    "health_fitness": "健康與健身",
    "food_drinks": "食物和飲料",
    "breweries_wineries_distileries": "啤酒廠，釀酒廠和釀酒廠",
    "entertainment": "娛樂",
    "collectibles": "收藏品",
    "books": "書籍",
    "face_skin": "面部和皮膚",
    "cosmetic_procedures": "化妝品",
    "gym": "健身房",
    "sport": "運動",
    "tour": "遊覽",
    "hotel": "酒店",
    "personalized": "個性化",
    "home_garden": "家居與園藝",
    "jewelry_watches": "珠寶與手錶",
    "fashion_jewelry": "時尚飾品",
    "fine_metal_jewelry": "精美金屬首飾",
    "watches": "手錶",
    "home_services": "家庭服務",
    "cleaning_services": "清潔服務",
    "flooring": "地板",
    "automotive": "汽車",
    "interior_accessories": "室內配件",
    "car_electronics": "汽車電子",
    "local_services": "本地服務",
    "fun_leisure": "娛樂與休閒",
    "sports_outdoors": "運動與戶外",
    "all_deal": "所有優惠券",
    "discount_amount": "折扣金額",
    "amount_sold": "銷售總額",
    "sort_by": "排序方式",
    "best_match": "最佳匹配",
    "distance": "距離",
    "ranking": "距離",
    "price_ascending": "價格上升",
    "price_descending": "價格下降",
    "discount_greater": "折扣大於",
    "bought": "售出",
    "OFF": "OFF",
    "view_deal": "查看優惠券",
    "viewed_today": "日瀏覽量",
    "what_you_will_get": "你會得到什麼",
    "the_fine_print": "購買須知",
    "customer_photos": "客戶照片",
    "view_image": "查看圖片",
    "view_image_gallery": "查看圖庫",
    "see_all_reviews": "查看所有評論",
    "view_all_img": "查看所有圖片",
    "show_less": "顯示更少”",
    "see_all_hours": "查看所有時間",
    "app": "App",
    "history": "歷史",
    "service": "服務",
    "business": "企業",
    "favorite": "收藏",
  },
  "common_checkout": {
    'items_not_available': '產品不能購買',
    'items_not_available_message': '以下產品現在不能購買，將從購物車中移除: {br} {items}'
  },
  "common_self_checkout": {
    "self_checkout": "自助支付",
    "amount": "金額",
    "add_comment": "添加備註",
    "contact_merchant": "聯繫商家",
    "powered_by_goopter": "由Goopter提供技術支持",
    "pay": "支付",
    "self-checkout-disabled": "此商家尚未開通自助結帳服務。",
    "surcharge_for": "訂單#{oid}的附加費",
  },
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _config from 'config';
import _helper from 'utils/helper';
import { FormattedMessage, injectIntl } from 'react-intl';
import MapSearchBar from './search-bar';
import GoogleMapReact from 'google-map-react';
import MapMarker from 'components/map-marker';
import _actionLocations from 'containers/locations/actions.jsx'
import { Button } from 'antd';
import './_map-search.scss';
import _ from 'lodash'
import { FaCrosshairs } from 'react-icons/fa';

class MapSearch extends Component {

  state = {
    lat: 0,
    lng: 0
  }

  renderMapSearchBar = () => {
    let {
      getPlace,
      userLat,
      userLon,
      getGeoLocation,
    } = this.props;

    let { addressStr } = this.state

    return (
      <MapSearchBar
        getGeoLocation={getGeoLocation}
        userLat={userLat}
        userLon={userLon}
        addressStr={addressStr}
        onPlacesChanged={(place, addressStr) => {
          let placeObj = place
          this.setState({
            lat: placeObj.geometry.location.lat(),
            lng: placeObj.geometry.location.lng(),
            addressStr
          })

          if (getPlace) {
            getPlace(placeObj)
          }
        }}
      />
    )
  }

  renderGoogleMap = () => {
    let { userLat, userLon } = this.props;
    const handleApiLoaded = (map, maps) => {
      this.map = map;
    }
    return (
      <div className="map-container" style={{ height: '200px', width: '100%', paddingTop: '10px' }}>
        <GoogleMapReact
          center={[
            this.state.lat || userLat || 49.2133696,
            this.state.lng || userLon || -122.9889389
          ]}
          zoom={14}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <MapMarker
            lat={this.state.lat || userLat || 49.2133696}
            lng={this.state.lng || userLon || -122.9889389}
          />
        </GoogleMapReact>
      </div>
    )
  }

  renderCurrentLocationButton = () => {
    let { userLat, userLon, getPlace } = this.props;
    const selectCurrentLocation = async () => {
      try {
        const userLatLon = { lat: userLat, lng: userLon }
        const geocoder = new window.google.maps.Geocoder();
        const location = new window.google.maps.LatLng(userLatLon);
        await geocoder.geocode({ latLng: location, language: 'en' }, (results, status) => {
          const statusOk = status === "OK"
          const withResult = Array.isArray(results) && results.length
          if (statusOk && withResult) {
            this.setState({
              ...userLatLon,
              addressStr: results[0].formatted_address
            })
            reCenterMap(userLatLon)
            if (getPlace) {
              getPlace(results[0])
            }
          }
        })

      } catch (e) {

      }
    }

    const reCenterMap = (userLatLon) => {
      if (this.map) {
        this.map.setCenter(userLatLon);
      }
    }

    return userLat && userLon ? (
      <div className="currentLocationButton"
        onClick={() => selectCurrentLocation()}>
        <div className="currentLocationIcon">
          <FaCrosshairs />
        </div>
        <div>
          <FormattedMessage id="current_location" />
        </div>
      </div>
    ) : ''
  }

  renderConfirmButton = () => {
    let {
      mapPlaceObj,
      confirmMapAddress,
    } = this.props;
    return (
      <Button
        className="map-search-confirm-btn"
        type="primary"
        size="large"
        onClick={() => confirmMapAddress()}
        disabled={_.isEmpty(mapPlaceObj)}
        block>
        <FormattedMessage id="confirm" />
      </Button>
    )
  }

  render() {
    return (
      <div className="map-search-container">
        {this.renderMapSearchBar()}
        {this.renderCurrentLocationButton()}
        {this.renderGoogleMap()}
        {this.renderConfirmButton()}
      </div >
    )
  }
}

const mapStateToProps = state => {
  let locationState = state["locations"];
  return {
    queryLat: locationState.queryLat,
    queryLng: locationState.queryLng,
    userLat: locationState.userLat,
    userLon: locationState.userLon
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGeoLocation: bindActionCreators(_actionLocations.getGeoLocation, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MapSearch));
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Menu, Icon, Row, Col, Input, Button, Popconfirm, Layout } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import MenuItem from 'antd/lib/menu/MenuItem';
import _actionsSettings from 'containers/settings/actions'
import _actionsAuth from 'containers/auth/actions'


import "antd/dist/antd.css";
import './_topbar.scss';

const { SubMenu } = Menu;
const MenuItemGroup = Menu.ItemGroup;
const { Header, Content, Sider } = Layout;


class TopBar extends Component {

	state = {
		loginModalVisible: false,
	}

	render() {

		let { logout, push, shortcut, onShortCutClick, intl, isAuthenticated, userProfile, changeLanguage } = this.props

		let { formatMessage } = intl;

		let menuItems = [
			{
				requirement: true,
				id: 1,
				name: "choose_city",
				type: "caret-down",
				subItems: [
					{
						id: 11,
						subName: "van",
						className: 'top-bar-submenuContainer'
					},
					{
						id: 12,
						subName: "vic",
						className: 'top-bar-submenuContainer'
					},
					{
						id: 13,
						subName: "tor",
						className: 'top-bar-submenuContainer'
					},
					{
						id: 14,
						subName: "seattle",
						className: 'top-bar-submenuContainer'
					}
				],
				className: 'top-bar-chooseCity',

			},
			{
				requirement: !isAuthenticated,
				id: 2,
				name: "log_in",
				nameSuffix: '/',
				icon: "user",
				className: 'top-bar-LogIn',
				onClick: () => {
					this.props.toggleLoginDialogDisplay(true)
				},
			},
			{
				requirement: !isAuthenticated,
				id: 3,
				name: "sign_up",
				className: 'top-bar-SignUp',
			},
			{
				requirement: isAuthenticated,
				id: 88,
				icon: "user",
				name: userProfile ? userProfile['first_name'].toUpperCase() : 'not_set',
				className: 'top-bar-User',
				onClick: () => {
					push('/account')
				},
				subItems: [
					{
						id: 881,
						icon: "user",
						subName: "my_account",
						onClick: () => {
							push('/account')
						}
					},
					{
						id: 882,
						icon: "profile",
						subName: "order_history",
						onClick: () => {
							push('/account/orders')
						}

					},
					{
						id: 883,
						icon: "folder-open",
						subName: "address_book",
						onClick: () => {
							push('/account/address-book')
						}
					},
					{
						id: 884,
						icon: "logout",
						subName: "logout",
						onClick: () => logout()
					}
				]
			},
			{
				requirement: true,
				id: 4,
				name: " ",
				className: 'top-bar-emptySpace',

			},
			{
				requirement: true,
				id: 5,
				name: "app",
				type: "caret-down",
				subItems: [
					{
						id: 41,
						imgSrc: [
							{
								id: 411,
								qrSrc: "https://res.cloudinary.com/goopter/admin/qr-ios.png",
							},
							{
								id: 412,
								qrSrc: "https://res.cloudinary.com/goopter/admin/qr-android.png",
							},
							{
								id: 413,
								qrSrc: "https://res.cloudinary.com/goopter/admin/qr-wechat.png",
							}
						],
						className: 'top-bar-submenuQR'
					}
				],
				className: 'top-bar-app'
			},
			{
				requirement: true,
				id: 6,
				name: "history",
				type: "caret-down",
				className: 'top-bar-history'
			},
			{
				requirement: true,
				id: 7,
				name: "service",
				type: "caret-down",
				className: 'top-bar-service'
			},
			{
				requirement: true,
				id: 8,
				name: "business",
				type: "caret-down",
				subItems: [
					{
						id: 11,
						subName: "sign_up_biz",
						onClick: () => window.location.href = "https://a.goopter.com/signin",
						className: 'top-bar-submenuContainer'
					},
					{
						id: 12,
						subName: "store_mngt",
						onClick: () => window.location.href = "https://a.goopter.com/signup",
						className: 'top-bar-submenuContainer'
					}
				],
				className: 'top-bar-business'
			},
			{
				requirement: true,
				id: 9,
				name: "favorite",
				icon: "star",
				className: 'top-bar-favorite'
			},
			{
				requirement: true,
				id: 10,
				name: "language",
				icon: "setting",
				type: "caret-down",
				subItems: [
					{
						id: 91,
						subName: "en",
						onClick: () => changeLanguage({ lan: 'en' }),
						className: 'top-bar-submenuContainer'
					},
					{
						id: 92,
						subName: "zh",
						onClick: () => changeLanguage({ lan: 'zh' }),
						className: 'top-bar-submenuContainer'
					},
					{
						id: 93,
						subName: "zh-Hant",
						onClick: () => changeLanguage({ lan: 'zh-Hant' }),
						className: 'top-bar-submenuContainer'
					},
					{
						id: 94,
						subName: "jp",
						onClick: () => changeLanguage({ lan: 'jp' }),
						className: 'top-bar-submenuContainer'
					}
				],
			}
		]
		return (
			<div className="topbar-container">
				<Menu mode="horizontal"
					style={{
						backgroundColor: "#f9f9f9",
						borderBottom: "none",
						lineHeight: "47px",
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "35px",
					}}
				>

					{menuItems ? menuItems.map((item, i) => {
						if (item.requirement) return (
							<SubMenu
								key={item.id + i}
								title={
									<span onClick={item.onClick} className="top-bar-submenu-title">
										<Icon style={{ marginRight: "5px" }} type={item.icon} />
										<span> <FormattedMessage id={item.name ? item.name : 'not_set'} /> {item.nameSuffix ? item.nameSuffix : ''}</span>
										<Icon className="top-bar-icon" type={item.type} />
									</span>
								}
								className={item.className ? item.className : ''}
							>
								{item.subItems ? item.subItems.map((itm, i) => {
									return (
										<MenuItem
											key={itm.id + i}
											className={itm.className ? itm.className : ''}
										>
											{itm.imgSrc ? itm.imgSrc.map((qrItm, i) => {
												return (
													<img className="top-bar-qrImg" src={qrItm.qrSrc} />
												)
											}) :
												<span className="top-bar-subItems" onClick={itm.onClick}>
													{itm.icon ?
														<Icon style={{ margin: " 2px 3px 0 0" }} type={itm.icon} /> : ' '}
													<FormattedMessage id={itm.subName} />
												</span>}
										</MenuItem>
									)
								}) : ''}

							</SubMenu>
						)
					}) : ''}
				</Menu>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	let settingsState = state['settings'];
	let authState = state['auth']
	return {
		lan: settingsState.lan,
		isAuthenticated: authState.isAuthenticated,
		userProfile: authState.userProfile,
		loginDialogDisplay: authState.loginDialogDisplay,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		push: bindActionCreators(push, dispatch),
		changeLanguage: bindActionCreators((params) => {
			return _actionsSettings.changeLanguage({ lan: params.lan })
		}, dispatch),
		logout: bindActionCreators((params) => {
			return {
				type: "AUTH__TOGGLE_LOGOUT_DIALOG",
				payload: { type: 'normal', show: true }
			}
		}, dispatch),
		toggleLoginDialogDisplay: bindActionCreators((bool) => {
			return {
				type: "AUTH__SET_STATE",
				state: {
					loginDialogDisplay: bool
				}
			}
		}, dispatch),
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TopBar))
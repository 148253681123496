import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'
import './number-pad-section.scss'
import classnames from 'classnames'
import { FiDelete } from 'react-icons/fi'
import { withRouter } from 'react-router-dom'
import pageHelper from '../../helper'

class NumberPad extends React.Component {

    showKeyPad = () => {
        return _.get(this.props, 'state.showNumberPad', true)
    }

    getCurrentPadValue = () => {
        return _.get(this.props, 'state.numberPadValue', '0')
    }

    allowPay = () => {
        return this.getCurrentPadValue() > 0
    }

    getMaxValue = () => {
        return _.get(this.props, 'maxValue', 10000)
    }

    onKeyPress = (value) => {
        if (value === 'hide') {
            this.hideKeyPad()
        }
        else if (value === 'pay') {
            this.props.onPayPress(this.getCurrentPadValue())
        }
        else if (!pageHelper.isReadOnly(this.props)) {
            this.setPrice(value)
        }
    }

    hideKeyPad = () => {
        this.props.setState({ showNumberPad: false })
    }

    setPrice = (value) => {
        const currentPrice = this.getFormattedPrice(value)
        this.props.setState({
            numberPadValue: this.getValidatedPrice(currentPrice)
        })
    }

    getFormattedPrice = (value) => {
        let price = this.getCurrentPadValue()
        if (value === 'del') {
            price = price.substring(0, price.length - 1);
        }
        else if (value === '.' && !price.includes('.')) {
            price += price.length ? value : `0${value}`;
        }
        else {
            price = this.getNumberPadFormattedPrice(value)
        }
        return price
    }

    getNumberPadFormattedPrice = (value) => {
        let price = this.getCurrentPadValue()
        const priceArray = price.split('');
        const decimalIndex = priceArray.indexOf('.')
        if (!priceArray.includes('.')) {
            price += value
            return (price - 0).toString()
        } else if (price.length <= decimalIndex + 2 && value !== '.') {
            return price += value
        } else if (value === '.' && price === '0.00') {
            return '0.'
        } else if (price === '0.00') {
            return value
        }
        return price
    }

    getValidatedPrice = (currentPrice) => {
        const currentPadValue = this.getCurrentPadValue()
        const floatValue = parseFloat(currentPrice)
        const isNaN = Number.isNaN(floatValue)
        return isNaN ? '0' : floatValue <= this.getMaxValue() ? currentPrice : currentPadValue
    }

    getGridData = () => {
        return [
            { value: '1' },
            { value: '2' },
            { value: '3' },
            {
                value: 'del',
                classes: { 'del-numpad-grid': true },
                component: <FiDelete size="2em" />
            },
            { value: '4' },
            { value: '5' },
            { value: '6' },
            {
                value: 'pay',
                classes: { 'grid-item pay-grid': true, 'allow-order': this.allowPay() },
                component: <FormattedMessage id="pay" />
            },
            { value: '7' },
            { value: '8' },
            { value: '9' },
            {
                value: '0',
                classes: { 'zero-numpad-grid': true },
            },
            { value: '.' },
        ]
    }

    renderGridComponent = (grid) => {
        const value = grid.value
        const component = grid.component
        return (
            <div className={classnames({
                'grid-item': true,
                "disable": pageHelper.isReadOnly(this.props),
                ...grid.classes
            })} onClick={(e) => { this.onKeyPress(value) }}>
                {component ? component : value}
            </div>
        )
    }

    render() {
        return [
            <div className={classnames({
                "number-pad-container": true,
                "show": this.showKeyPad(),
                "hide": !this.showKeyPad()
            })} >
                <div className="number-pad-grid">
                    {this.getGridData().map(grid => this.renderGridComponent(grid))}
                </div>
            </div>
        ]
    }
}

const mapStateToProps = (state) => ({
    lan: state.settings.lan,
    state: _.get(state, 'self-checkout', {})
})

const mapDispatchToProps = ({
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(NumberPad))) 
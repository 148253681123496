import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Rate } from 'antd';
import Img from 'react-image'
import { FormattedMessage, injectIntl } from 'react-intl';
import cellHolderImg from 'images/image_groupsale_goldenratio_gray.png'
import './_grouponCell.scss'

class GrouponCell extends Component {

  getDiscountPercentage = () => {
    let { cellData } = this.props
    return Math.round(parseFloat(cellData['pc'] - cellData['spc']) / cellData['pc'] * 100)
  }

  render() {
    let {
      onCellClick,
      cellData
    } = this.props;

    return (
      <div className="group-sale-cell-container" onClick={onCellClick}>
        <div>
          <div className="img-parent">
          <Img
            className="img"
            src={`${_config['IMG_PREFIX']}${cellData['i_url']}`}
            unloader={<Img
              className="img"
              src={cellHolderImg}
            />} />
          </div>
          <div className="name">{cellData['nm']}</div>
          <div className="descript">{cellData['s_dsc'] ? cellData['s_dsc'] : <span> &nbsp; </span>}</div>
          <div className="localsPrice">
            <div className="local">{cellData['location']}</div>
            <div>
              <span className="oldPrice">{_helper.formatCurrency(cellData['pc'], cellData['currency'])}</span>
              <span className="newPrice">{_helper.formatCurrency(cellData['spc'], cellData['currency'])}</span>
            </div>
          </div>
          <div className="rateDiscount">
            <div>{cellData['rnk'] ?
              <span>
                <span className="rating-number">{Number(cellData['rnk']).toFixed(1)} </span>
                <span className="rating-number"><Rate style={{ fontSize: "14px" }} disabled allowHalf defaultValue={cellData['rnk']} /> </span>
                <span className="rating-count">({cellData['r_cnt']})</span>
              </span>
              : <span>{cellData['s_cnt']}+ <FormattedMessage id="bought" /></span>}
            </div>
            <div className="price-discount">
              <span >{this.getDiscountPercentage()}%</span>
              <span> <FormattedMessage id="OFF" /></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default injectIntl(GrouponCell);
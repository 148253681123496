import _ from 'lodash';
// import _helper from 'utils/helper';

export function getDefaultState() {
  return {
    orderList: [],
    orderListPaging: {
      page: 1,
      total: null,
    },
    searchConditions: {

    }
  }
}

function fetchOrderList(state, action, status) {
  let finalState = _.cloneDeep(state);

  let { orderListResponse, searchConditions } = action.response;

  if (status) {
    if (orderListResponse.RC === 200) {
      finalState.orderList = orderListResponse.records
      finalState.orderListPaging.page = orderListResponse.paging.page
      finalState.orderListPaging.total = orderListResponse.paging.total
      finalState.searchConditions = searchConditions
    }
  }
  return finalState;
}

export default {
  'order-list': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'ORDER_LIST__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'ORDER_LIST__CLEAR_DATA':
        return getDefaultState();
      case 'ORDER_LIST__FETCH_ORDER_LIST_SUCCESS':
        return fetchOrderList(state, action, true);
      case 'ORDER_LIST__FETCH_ORDER_LIST_FAILED':
        return fetchOrderList(state, action, false);
      default:
        return state;
    }
  }
}

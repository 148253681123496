import React from "react";
import classnames from "classnames";
import _helper from "../../utils/helper";
import countryData from "utils/data/countryList.json";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select, Icon, Divider, Row, Col } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import "./_floating-input.scss";
import { stringify } from "querystring";

const Option = Select.Option;
let textColor = "rgb(177, 177, 177)";
let barColor = "rgba(0,0,0,0.12)";

class FloatingInputPhone extends React.Component {
  static defaultProps = {
    validColor: "#1890ff",
    invalidColor: "#d50000",
    type: "text",
    requiredText: false
  };
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      showColor: true,
      isValid: true,
      errorMsg: false,
      errorState: false,
      isNumber: false,
      defaultCtrInd: null,
      phoneNumber: "",
      stateName: null
    };
  }

  componentDidUpdate(prevProps) {
    this.setStateFocus(prevProps);
  }

  setStateFocus(prevProps) {
    if (
      !prevProps.value &&
      this.props.value &&
      prevProps.value != this.props.value &&
      !this.state.isFocused
    ) {
      this.setState({
        isFocused: true
      });
    } else if (
      prevProps.value &&
      !this.props.value &&
      prevProps.value != this.props.value &&
      this.state.isFocused
    ) {
      this.setState({
        isFocused: false
      });
    }
  }

  componentDidMount() { }

  componentWillReceiveProps() {
    this.checkStateName();

    this.setInitialPhoneInput();
  }

  getCountryName = code => {
    let { lan } = this.props;
    let result = "";
    countryData.records.map(country => {
      if (country.country_code == code) {
        result = country.name[lan] + " (+" + country.area_code + ")";
      }
    });
    return result;
  };

  setInitialPhoneInput() {
    let { defaultCountry, inputValue, defaultNumber } = this.props;

    //Check the validity of phone number and update the color accordingly
    if (defaultNumber) {
      if (_helper.checkPhonePattern(inputValue, defaultCountry)) {
        this.setState({
          showColor: true,
          isValid: true
        });
      } else {
        this.setState({
          showColor: true,
          isValid: false
        });
      }
    }
    this.setState({
      phoneNumber: inputValue
    });
  }

  handlePhoneFormat(e) {
    let { defaultCountry, inputValue } = this.props;

    let value = "";

    //Clean up any character other than a number
    e.target.value = _helper.cleanPhoneNumber(e.target.value, defaultCountry);

    //input is a phone number
    if (_helper.checkPhonePattern(e.target.value, defaultCountry)) {
      this.setState({
        isFocused: true,
        showColor: true,
        isValid: true,
        errorState: false
      });
      this.props.onInputChange("validPhone", true);
    } else {
      this.setState({
        isFocused: true,
        showColor: true,
        isValid: false,
        errorState: true
      });
      this.props.onInputChange("validPhone", false);
    }

    this.props.onInputChange("phone", e.target.value);
  }

  handleSelectOptions(value) {
    let { inputValue, defaultCountry } = this.props;

    if (_helper.checkPhonePattern(inputValue, value)) {
      this.setState({
        isFocused: true,
        showColor: true,
        isValid: true,
        errorState: false
      });
    } else {
      this.setState({
        isFocused: true,
        showColor: true,
        isValid: false,
        errorState: true
      });
    }

    this.props.onInputChange("countryCode", value);
  }

  checkStateName() {
    let { inputType } = this.props;

    if (inputType == "phone_number") {
      this.setState({
        stateName: "phone"
      });
    }
  }

  handleColors(e) {
    let { showColor, isFocused, isValid, errorState } = this.state;
    let { validColor, invalidColor, canBeEmpty, isNum } = this.props;

    if (!isFocused) {
      if (isValid) {
        barColor = validColor;
      } else {
        barColor = invalidColor;
      }
    } else {
      if (showColor && !isValid) {
        barColor = invalidColor;
        textColor = invalidColor;
      } else {
        if (showColor && isValid) {
          barColor = validColor;
          textColor = validColor;
        }
      }
    }

    return {
      textColor,
      barColor
    };
  }

  focusField() {
    this.focusInput.focus();
  }

  render() {
    let { errorState, selectedAreaCode, phoneNumber } = this.state;

    let {
      className,
      label,
      fontSize,
      value,
      type,
      name,
      autoComplete,
      placeholder,
      requiredText,
      disabled,
      pattern,
      minLength,
      maxLength,
      width,
      intl,
      inputValue,
      inputType,
      focusRef,
      defaultCountry
    } = this.props;
    let colors = this.handleColors();
    let { formatMessage } = intl;

    this.focusFieldType(inputType, focusRef);

    return (
      //Component type checker
      <div className="phone">
        <div
          className="floating-input-container"
          style={{ fontSize: fontSize, width: width }}
        >
          <div className="input-border-wrapper">
            <div
              className={classnames({
                "input-border": true,
                active: this.state.showColor && this.state.isFocused
              })}
              style={{ backgroundColor: colors.barColor }}
            />
            {errorState ? (
              <div
                className="errorMsg"
                style={{
                  color: this.props.invalidColor
                }}
              >
                {formatMessage({ id: "invalid_phone_number" })}
              </div>
            ) : ("")}
          </div>

          <Row gutter={16}>
            <Col span={12}>
              <div className="select-style">
                <Select
                  placeholder={formatMessage({ id: "country" })}
                  onSelect={value => this.handleSelectOptions(value)}
                  onChange={value => this.handleSelectOptions(value)}
                  value={defaultCountry}
                >
                  {countryData.records.map(country => (
                    <Select.Option
                      value={country.country_code}
                      key={`country-select-${country.country_code}`}
                    >
                      {_helper.getCountryName(country.country_code)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <input
                ref="floating-input"
                className={classnames({
                  "floating-input": true,
                  unfocused: !this.state.isFocused,
                  "no-disable-highlight": this.props.noDisableHighlight,
                  [className]: className
                })}
                name={name}
                onFocus={e => this.handlePhoneFormat(e)}
                onBlur={e => this.handlePhoneFormat(e)}
                onChange={e => this.handlePhoneFormat(e)}
                value={inputValue}
                type={type}
                autoComplete={autoComplete}
                placeholder={placeholder ? placeholder : formatMessage({ id: 'phone_number' })}
                disabled={disabled}
                pattern={pattern}
                minLength={minLength}
                maxLength={maxLength}
                width={width}
                style={{ maxLength: maxLength, width: "180%" }}
                ref={input => {
                  this.focusInput = input;
                }}
              />
            </Col>
          </Row>
          <div className="phone-label">
            {label ? (
              <label
                className={classnames({
                  label: true,
                  floating: this.state.isFocused || value
                })}
                style={{
                  color: colors.textColor
                }}
              >
                {label}
                {requiredText ? "*" : ""}
              </label>
            ) : (
                ""
              )}
          </div>
        </div>
      </div>
    );
  }

  focusFieldType(inputType, focusRef) {
    if (inputType == focusRef) {
      this.focusField();
      this.props.onInputChange("focusField", "");
    }
  }
}

export default injectIntl(FloatingInputPhone);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { Drawer, } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import MapSearch from 'components/map-search';

import AddressForm from 'containers/address-book/address-form'
import _addressBookActions from 'containers/address-book/actions';

import './_address-editor.scss';


class AddressEditor extends Component {
  componentDidUpdate(prevProps) {
    let { addressList } = this.props;

    if (!_.isEqual(addressList, prevProps.addressList)) {
      this.handleCloseAddressEditor()
    }
  }

  state = {
    mapDrawerDisplay: false,
    mapPlaceObj: {},
  };

  handleCloseAddressEditor = () => {
    this.props.toggleAddressEditor(false)
    this.toggleMapDrawer(false)
    this.setState({ showAddressFormError: false })
  }

  toggleMapDrawer = (bool) => {
    this.setState({
      mapDrawerDisplay: bool,
    });
  };

  confirmMapAddress = () => {
    let { mapPlaceObj } = this.state;
    this.toggleMapDrawer(false)
    if (mapPlaceObj['address_components']) {
      let parsedAddress = this.parseAddressComponents(mapPlaceObj['address_components'])
      this.props.setAddressValues(parsedAddress)
    }
  }

  parseAddressComponents = (addressComponents) => {
    let address = {
      street: '',
      zipCode: '',
      city: '',
      country: '',
      region: '',
    }

    if (addressComponents) {
      addressComponents.forEach((component) => {
        let addressType = component.types
        if (addressType.includes('street_number')) {
          address.street = component['short_name']
        } else if (addressType.includes('route')) {
          address.street += `${address.street.length ? ' ' : ''}${component['long_name']}`;
        } else if (addressType.includes('locality') || addressType.includes('sublocality')) {
          address.city = component['long_name'];
        } else if (addressType.includes('administrative_area_level_1')) {
          address.region = component['short_name'];
        } else if (addressType.includes('country')) {
          address.country = component['short_name'];
        } else if (addressType.includes('postal_code')) {
          address.zipCode = component['short_name'];
        }
      })
      return address;
    } else {
      return null;
    }
  }

  renderMapSearchDrawer = () => {
    let {
      mapDrawerDisplay,
      mapPlaceObj,
    } = this.state;
    let {
      intl,
      address
    } = this.props;
    return (
      <Drawer
        title={intl.formatMessage({ id: "map_search" })}
        width={550}
        onClose={() => this.toggleMapDrawer(false)}
        visible={mapDrawerDisplay}
        className="map-drawer-conatiner"
        style={{ color: 'red' }}
        bodyStyle={{ color: 'blue' }}
      >
        <MapSearch
          confirmMapAddress={this.confirmMapAddress}
          address={address}
          mapPlaceObj={mapPlaceObj}
          getPlace={(placeObj) => {
            this.setState({ mapPlaceObj: placeObj })
          }}
        />
      </Drawer>
    )
  }

  render() {
    let {
      lan,
      saveAddress,
      saveAddressLoading,
      addressListLoading,
      addressEditorDisplay,
      addressValues, // the initial address values that can be modified depending on if editing an address or adding a new address
      userProfile,
      intl,
    } = this.props;

    return (
      <Drawer
        title={intl.formatMessage({ id: "add_address" })}
        width={520}
        onClose={() => this.handleCloseAddressEditor()}
        visible={addressEditorDisplay}
      >
        <AddressForm
          lan={lan}
          state={this.state}
          setState={(values) => this.setState(values)}
          showMapDrawer={() => this.toggleMapDrawer(true)}
          initialValues={addressValues}
          saveAddress={saveAddress}
          saveAddressLoading={saveAddressLoading}
          addressListLoading={addressListLoading}
          userProfile={userProfile}
        />
        {this.renderMapSearchDrawer()}
      </Drawer>
    )
  }
}

const mapStateToProps = (state) => {
  let addressBookState = state['address-book'];
  let settingState = state['settings'];
  let authState = state['auth'];

  return {
    pageLoading: addressBookState.pageLoading,
    addressList: addressBookState.addressList,
    addressListLoading: addressBookState.addressListLoading,
    saveAddressLoading: addressBookState.saveAddressLoading,
    lan: settingState.lan,
    userProfile: authState.userProfile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    deleteAddress: bindActionCreators((params) => {
      return _addressBookActions.deleteAddress(params)
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressEditor));
import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { injectIntl, FormattedMessage, } from 'react-intl';
import _ from 'lodash'
import { connect } from 'react-redux';
import _pageHelper from '../../helper'
import { Modal } from 'antd';
import _shoppingCartHelper from '../../../shopping-cart/helper'
import _shoppingCartAction from '../../../shopping-cart/actions'

const CHECK_INTERVAL = 1 //in mins

class RestrictedChecker extends Component {
    state = {}

    componentDidMount = () => {
        this.setCheckTimer()
    }

    componentDidUpdate = (prepProps) => {
        this.updateCheckItemAvailable(prepProps)
    }

    componentWillUnmount = () => {
        if (this.timer) clearInterval(this.timer)
    }

    str = (id, values) => { return this.props.intl.formatMessage({ id }, values) }

    updateCheckItemAvailable = (prepProps) => {
        const productUpdates = !_.isEqual(this.props.products, prepProps.products) && !_.isEmpty(this.props.products)
        const storeUpdate = !_.isEqual(this.props.store, prepProps.store) && !_.isEmpty(this.props.store)
        if (productUpdates || storeUpdate) this.checkItemAvailable()
    }

    setCheckTimer = () => {
        this.timer = setInterval(() => {
            this.checkItemAvailable()
        }, CHECK_INTERVAL * 60 * 1000)
    }

    checkItemAvailable = () => {
        const products = this.props.products
        const timeSetting = _.get(this.props, 'store.time_setting', [])
        const storeName = _.get(this.props, 'store.nm', '')

        if (storeName && _.isEmpty(timeSetting)) {
            if (this.timer) {
                console.log('clearTimeRestrictedInterval')
                clearInterval(this.timer)
            }
            return
        }

        let unavailableItems = []
        Array.isArray(products) && products.map((p) => {
            const time = _.get(p, 'time', '')
            if (!_pageHelper.isAvailable(timeSetting, time)) {
                unavailableItems.push(p)
            }
        })

        if (!_.isEmpty(unavailableItems)) {
            this.renderWaningModel(unavailableItems)
        }
    }

    renderWaningModel = (unavailableItems) => {
        const items = () => {
            let result = ''
            Array.isArray(unavailableItems) && unavailableItems.map((p) => {
                const pName = _.get(p, 'nm', '')
                result += result ? `, ${pName}` : pName
            })
            return result
        }

        if (!this.model) {
            const gid = _pageHelper.getGid()
            const cartItems = this.props.cart
            const setShoppingCartItems = this.props.setShoppingCartItems
            this.model = Modal.error({
                title: this.str('items_not_available'),
                content: this.str('items_not_available_message', { items: items(), br: <br /> }),
                onOk() {
                    const newCart = _.differenceWith(_.get(cartItems, 'cart', []), unavailableItems, (oldCart, unavailableItem) => {
                        return String(_.get(oldCart, 'pid')) === String(_.get(unavailableItem, 'pid'))
                    })
                    const newCartItems = {
                        ...cartItems,
                        cart: newCart
                    }
                    const oldCart = _shoppingCartHelper.updateCartListToOld(newCart, gid)
                    setShoppingCartItems({ gid, items: newCartItems, cartListToOld: oldCart })
                },
                okText: this.str('ok'),
            });
        }

    }

    render() {
        return null
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "checkout-page", {}),
    cart: _.get(state, `shopping-cart.cartList.${_pageHelper.getGid()}`, []),
    products: _.get(state, `shopping-cart.productList.${_pageHelper.getGid()}`, []),
    store: _.get(state, 'checkout-page.storeInstance.data', {})
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'CHECKOUT_PAGE__SET_STATE', state }),
    setShoppingCartItems: _shoppingCartAction.setShoppingCartItems
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RestrictedChecker));
import React, { Component } from 'react';
import AdSense from 'react-adsense';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from 'antd';
import _config from 'config';
import _helper from 'utils/helper';
import _actionsSettings from 'containers/settings/actions'
import { push, goBack } from 'connected-react-router';


import './_top-ads.scss'


class TopAdsBar extends Component {

    componentDidMount () {
    }
    
    closeAds ( showAds ){
        let { closeAds } = this.props;
        closeAds({ showAds });
    }

    render() {
        let {
            showAds
        } = this.props;

        return (
            showAds ?  
            <div className="ads-container">
            <AdSense.Google
                className="top-ad-bar"
                client='ca-pub-5826350911239696'
                slot='2682780307'
                format='fluid'
            /><Icon className="close-button" shape="circle" type="close-circle" onClick={() => this.closeAds( "true" )} /></div>
            : null
        )
    }
}

const mapStateToProps = (state) => {
    let settingsState = state["settings"];

    return {
        showAds: settingsState.showAds
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeAds: bindActionCreators((params) => {
            return {
              type: "SETTINGS__CLOSE_ADS",
              payload: { show: false }
            }
          }, dispatch),
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopAdsBar);
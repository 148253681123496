import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import { Spin } from 'antd';
import _locale from 'locale';

import "./_loading_page.scss"

class LoadingPage extends Component {


    componentDidMount() {

    }

    render() {
        let {lan } = this.props;

        let trans = (id) => _locale.getIntlMessages(lan, '')[id];

        return (
            <div className="loading-page-container">
                <Spin size="large" />
                {trans("loading")}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    let settingsState = state['settings']
    return {
        lan: settingsState.lan,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);

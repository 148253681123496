import { call, put, takeEvery, select } from "redux-saga/effects";
import { replace } from "connected-react-router";
import _ from "lodash";
import _config from "config";
import _helper from "utils/helper";
import _api from "utils/api";
import _apiRoutes from "utils/api/routes";
import _authActions from "containers/auth/actions";
import { message } from "antd";
import _locale from "locale";

const getNotificationObject = (state) => {
  const token = _.get(state, 'notifications.device_token', '')
  const os = _.get(state, 'notifications.provider', '')
  return {
    token, os
  }
}

function* checkAuthStatus() {
  try {
    let url = _apiRoutes["ping"];
    const response = yield call(_api.executeOauth, url, "GET");
    if (response && response.RC === 200) {
      // yield put(replace('/'));
      /* temp until store page is written in web_v2 */
      yield call((window.location.href = `/`));
    } else {
      yield put({
        type: "AUTH__TOGGLE_AUTH_FORM",
        payload: { loading: false }
      });
    }
  } catch (e) {
    yield put({ type: "AUTH__TOGGLE_AUTH_FORM", payload: { loading: false } });
  }
}

function* login(action) {
  const settingsState = yield select(state => {
    return state.settings;
  });
  const lan = settingsState.lan;
  let trans = id => _locale.getIntlMessages(lan, "common")[id];

  try {
    yield put({ type: "AUTH__SET_STATE", state: { loginLoading: true } });

    let {
      email,
      password,
      phone,
      vc,
      countryCode,
      loginType,
      redirectPath,
      stay,
      type,
    } = action.payload;
    let url = _config["API_ROOT"] + _apiRoutes["login"];

    let postBody;

    var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email) {
      if (emailPattern.test(String(email).toLowerCase())) {
        postBody = { email, password };
      } else {
        postBody = { email: `${email}@c.goopter.com`, password };
      }
    } else if (phone) {
      postBody = { email: `${phone}@c.goopter.com`, vc };
    }
    if (countryCode) {
      postBody.country_code = countryCode;
    }

    const notificationsObj = yield select(getNotificationObject)
    const loginResponse = yield call(_api.safePost, url, { data: { ...postBody, ...notificationsObj } });

    if (loginResponse.RC) {
      let { RC } = loginResponse;
      if (RC === 200) {
        let { token, secret } = loginResponse.records;

        // temporary, remove once web_v2 is completed and PHP session is no longer needed.
        let sessionLoginResponse = yield call(
          _api.setLoginSessionOld,
          token,
          secret
        );

        let userProfileUrl = _apiRoutes["user-profile"];
        let userProfile = yield _api.callCheckingAuthentication(
          _api.executeOauth,
          userProfileUrl,
          "GET",
          null,
          token,
          secret
        );
        if (userProfile.RC === 200) {
          yield put({
            type: "AUTH__SET_AUTH_TOKEN",
            payload: { token, secret, userProfile: userProfile.records }
          });
          yield put({
            type: "AUTH__LOGIN_SUCCESS",
            response: { loginResponse },
            payload: { ...action.payload }
          });

          if (!stay) {
            let path = redirectPath ? redirectPath : "/";
            if (path === "/") {
              // temporary because front page is not part of web_v2 yet
              window.location.href = path;
            } else {
              yield put(replace(path));
            }
          }

          if (type === 'modal') {
            yield put({
              type: "AUTH__SET_STATE",
              state: {
                loginDialogDisplay: false
              }
            });
          }



        } else {
          message.error(trans("error_fetch_user_profile"));
        }
      } else {
        yield put({ type: "AUTH__LOGIN_FAILED", action: action.payload });
        if (RC === 400) {
          message.error(trans("country_code_error"));
        } else if (RC === 403) {
          message.error(trans("incorrect_username_password"));
        } else if (RC === 408) {
          message.warning(trans("verification_code_expired"));
        } else if (RC === 429) {
          message.warning(trans("verifcation_code_request_overlimit"));
        } else if (RC === 483) {
          message.error(trans("incorrect_verification_code"));
        }
      }
    }
  } catch (e) {
    message.error(trans("login_failed"));
    yield put({
      type: "AUTH__LOGIN_FAILED",
      message: e.message,
      action: action.payload
    });
  }
}

function* setAuthToken(action) {
  try {
    let { token, secret, userProfile } = action.payload;
    if (token && secret) {
      _helper.setLocalStorage("accessToken", token);
      _helper.setLocalStorage("accessSecret", secret);
      yield put(_authActions.setAuth({ userProfile }));
    } else {
      throw new Error("no token or secret");
    }
  } catch (e) {
    yield put({ type: "AUTH__UNSET_AUTH" });
  }
}

function* setAuth(action) {
  try {
    let localToken = _helper.getLocalStorage("accessToken");
    let localSecret = _helper.getLocalStorage("accessSecret");

    if (localToken && localSecret) {
      let userProfile =
        action.payload && action.payload.userProfile
          ? _.cloneDeep(action.payload.userProfile)
          : null;

      if (!userProfile) {
        let userProfileUrl = _apiRoutes["user-profile"];
        let userProfileResponse = yield _api.callCheckingAuthentication(
          _api.executeOauth,
          userProfileUrl,
          "GET"
        );
        if (userProfileResponse.RC === 200) {
          userProfile = userProfileResponse.records;
        }
      }

      if (userProfile && Object.keys(userProfile)) {
        yield put({
          type: "AUTH__SET_AUTH_SUCCESS",
          response: { userProfile },
          payload: { ...action.payload }
        });
      } else {
        yield put({ type: "AUTH__SET_AUTH_FAILED" });
      }
    } else {
      yield put({ type: "AUTH__SET_AUTH_FAILED" });
    }
  } catch (e) {
    yield put({ type: "AUTH__SET_AUTH_FAILED" });
  }
}

function* getVerificationCode(action) {
  const settingsState = yield select(state => {
    return state.settings;
  });
  const lan = settingsState.lan;
  let trans = id => _locale.getIntlMessages(lan, "common")[id];

  try {
    let { phone, countryCode } = action.payload;
    if (_helper.checkPhonePattern(phone, countryCode)) {
      let url = _config["API_ROOT"] + _apiRoutes["verification-code"];

      let postBody = {
        phone: _helper.reformatPhoneNumber(phone, countryCode),
        action: 3,
        country_code: countryCode
      };
      const response = yield call(_api.safePost, url, postBody);
      if (response.RC == 200) {
        message.success(trans("get_verification_code_success"));
      } else if ((response.RC = 429)) {
        message.error(trans("verification_too_many_requests"));
      } else {
        message.error(trans("general_error"));
      }

      yield put({
        type: "AUTH__GET_VERIFICATION_CODE_SUCCESS",
        response: { response },
        payload: { ...action.payload }
      });
    } else {
      message.error(trans("invalid_phone_number"));
    }
  } catch (e) {
    message.error(trans("general_error"));
    yield put({ type: "AUTH__GET_VERIFICATION_CODE_FAILED" });
  }
}

function* fetchUserProfile(action) {
  try {
    let userProfileUrl = _apiRoutes["user-profile"];
    let userProfile = yield _api.callCheckingAuthentication(
      _api.executeOauth,
      userProfileUrl,
      "GET"
    );
    yield put({
      type: "AUTH__FETCH_USER_PROFILE_SUCCESS",
      response: { userProfile },
      payload: { ...action.payload }
    });
  } catch (e) {
    yield put({ type: "AUTH__FETCH_USER_PROFILE_FAILED" });
  }
}

function* logout(action) {
  try {
    let url = _apiRoutes["logout"];
    let logoutResponse = yield call(_api.executeOauth, url, "GET");

    // temporary, remove once web_v2 is completed and PHP session is no longer needed.
    let sessionLogoutResponse = yield call(_api.setLogoutSessionOld);

    /* temp until store page is written in web_v2 */ window.location.href = `/`

    yield put({
      type: "AUTH__CLEAR_DATA",
      response: { logoutResponse },
      payload: { ...action.payload }
    });
  } catch (e) {
    yield put({ type: "AUTH__LOGOUT_FAILED" });
  }
}

export default [
  takeEvery("AUTH__CHECK_AUTH_STATUS", checkAuthStatus),
  takeEvery("AUTH__LOGIN", login),
  takeEvery("AUTH__SET_AUTH_TOKEN", setAuthToken),
  takeEvery("AUTH__GET_VERIFICATION_CODE", getVerificationCode),
  takeEvery("AUTH__SET_AUTH", setAuth),
  takeEvery("AUTH__FETCH_USER_PROFILE", fetchUserProfile),
  takeEvery("AUTH__LOGOUT", logout)
];

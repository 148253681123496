import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Layout, Form, Input, Select, Checkbox, Button, Divider, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from 'utils/helper';

const FormItem = Form.Item;
const Option = Select.Option;

class LoginTextForm extends Component {
  render() {
    let {
      hide,
      login,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      getVerificationCode,
      setFieldError,
      setFieldTouched,
      setFieldValue,
      loginLoading
    } = this.props;

    let { formatMessage } = this.props.intl;

    return (
      <Form onSubmit={handleSubmit} style={{ display: hide ? 'none' : null }}>
        <FormItem
          label={<FormattedMessage id="country" />}
          colon={false}
          help={touched.countryCode && errors.countryCode ? errors.countryCode : ''}
          validateStatus={touched.countryCode && errors.countryCode ? 'error' : ''}
        >
          <Select
            name="countryCode"
            onChange={(value) => { setFieldValue('countryCode', value) }}
            onBlur={handleBlur}
            value={values.countryCode}
          >
            <Option value="CA"><FormattedMessage id="canada" /> +1</Option>
            <Option value="US"><FormattedMessage id="united_states" /> +1</Option>
            <Option value="CN"><FormattedMessage id="china" /> +86</Option>
            <Option value="KR"><FormattedMessage id="south_korea" /> +82</Option>
          </Select>
        </FormItem>
        <FormItem
          label={<FormattedMessage id="phone_number" />}
          colon={false}
          help={touched.phone && errors.phone ? errors.phone : ''}
          validateStatus={touched.phone && errors.phone ? 'error' : ''}
        >
          <div className="vc-input-wrapper">
            <Input
              name="phone"
              className="login-page-phone-input"
              type="tel"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button
              className="login-page-get-vc-button"
              onClick={() => {
                if (!values.phone) {
                  setFieldTouched('phone', true)
                  setFieldError('phone', 'phone is a required field')
                } else {
                  getVerificationCode({
                    phone: values.phone,
                    countryCode: values.countryCode,
                  })
                }
              }}
              type={"primary"}
              disabled={!values.phone || !values.countryCode}>
              <FormattedMessage id="get_verification_code" /></Button>
          </div>
        </FormItem>
        <FormItem
          label={<FormattedMessage id="verification_code" />}
          colon={false}
          help={touched.vc && errors.vc ? errors.vc : ''}
          validateStatus={touched.vc && errors.vc ? 'error' : ''}
        >
          <Input
            name="vc"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormItem>
        <FormItem style={{ margin: '0' }}>
          <Checkbox
            name="rememberMe"
            values={values.rememberMe}
            onChange={handleChange}
          >
            <FormattedMessage id="remember_me" />
          </Checkbox>
        </FormItem>
        <Divider />
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            disabled={loginLoading || !values.vc || !values.phone || !values.countryCode}
            loading={loginLoading} block>
            <FormattedMessage id="log_in" />
          </Button>
        </FormItem>
      </Form>
    )
  }
}

let validationSchema = (lan) => yup.object().shape({
  countryCode: yup.string().required(_helper.getIsRequiredMessage(lan, 'country')),
  phone: yup.string().required(_helper.getIsRequiredMessage(lan, 'phone_number')),
  vc: yup.string().required(_helper.getIsRequiredMessage(lan, 'verification_code'))
});

LoginTextForm = withFormik({
  // mapPropsToValues: (props) => ({ ...props.initalValues }),
  mapPropsToValues: (props) => ({ countryCode: 'CA', phone: '', vc: '' }), // adding initial values will make field be touched when submitting. (https://github.com/jaredpalmer/formik/issues/445)
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.login(values)
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true

})(LoginTextForm)
export default injectIntl(LoginTextForm);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _shoppingCartActions from './actions'

class ShoppingCartRenderless extends Component {
  componentDidMount() {
    let { gid, initShoppingCart } = this.props;

    initShoppingCart({ gid })
  }

  componentWillUnmount() {
    let { uninitShoppingCart } = this.props;
    uninitShoppingCart();
  }

  componentDidUpdate(prevsProp) {
    if (prevsProp.displayModal != this.props.displayModal && this.props.displayModal) {
      this.showModal()
    }
  }


  showModal() {
    let { shoppingCartState, intl, setShoppingState, push, gid } = this.props
    let { displayModalContent } = shoppingCartState
    let { formatMessage } = intl
    let title = formatMessage({ id: 'error' })
    let content = displayModalContent ? formatMessage({ id: displayModalContent }) : ''
    let okayText = formatMessage({ id: 'back_to_store' })
    let goToStorePage = () => this.goToStorePage()

    Modal.error({
      title: title,
      content: content,
      onOk() {
        setShoppingState('displayModal', false)
        goToStorePage()
      },
      okText: okayText,
    });

  }

  goToStorePage = () => {
    let {
      gid,
    } = this.props;

    if (_helper.getIndependentDomain()) {
      /* temp until store page is written in web_v2 */ window.location.href = `/`
    } else {
      /* temp until store page is written in web_v2 */ window.location.href = `/store/${gid}`
    }
  }

  render() {
    return null
  }
}

ShoppingCartRenderless.propTypes = {
  gid: PropTypes.number,
}

const mapStateToProps = (state) => {
  let shoppingCartState = state['shopping-cart'];
  return {
    cartInitialized: shoppingCartState.isInitialized,
    cart: shoppingCartState.cart,
    shoppingCartState: shoppingCartState,
    displayModal: shoppingCartState.displayModal
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    initShoppingCart: bindActionCreators((params) => {
      return _shoppingCartActions.initShoppingCart(params)
    }, dispatch),
    uninitShoppingCart: bindActionCreators(() => {
      return {
        type: "SHOPPING_CART__UNINIT_SHOPPING_CART",
      }
    }, dispatch),
    setShoppingState: bindActionCreators((key, value) => {
      return {
        type: "SHOPPING_CART__SET_STATE",
        state: { [key]: value }
      }
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShoppingCartRenderless));
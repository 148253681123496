import { put, takeEvery, select } from 'redux-saga/effects';
import _helper from 'utils/helper';
import _api from '../../utils/api';
import _apiRoutes from '../../utils/api/routes';
import _ from 'lodash'
import _config from '../../config'
import qs from 'query-string';

function* initData(action) {
    yield put({ type: "SELF_CHECKOUT__SET_STATE", state: { isLoading: true } });
    try {
        let gid = _.get(action.payload, 'gid', '')
        let instantPayResponse;
        if (!gid) {
            const qrParseUrl = `${_config.API_ROOT}${_apiRoutes['QR-parser']}?${qs.stringify(action.payload)}`
            const qrParserResponse = yield _api.callCheckingAuthentication(_api.get, qrParseUrl)
            if (_.get(qrParserResponse, 'RC', 400) === 200) {
                gid = _.get(qrParserResponse, 'records.id', 400)
            }
        }

        if (gid) {
            action.payload.gid = gid
            const instantPayUrl = `${_config.API_ROOT}${_apiRoutes['instant-pay']}?${qs.stringify(action.payload)}`
            instantPayResponse = yield _api.callCheckingAuthentication(_api.get, instantPayUrl);
        }

        yield put({ type: "SELF_CHECKOUT__INIT_DATA_SUCCESS", response: { instantPayResponse, gid }, payload: action.payload });
    } catch (e) {
        yield put({ type: "SELF_CHECKOUT__INIT_DATA_FAILED", message: e.message, payload: action.payload });
    }
    yield put({ type: "SELF_CHECKOUT__SET_STATE", state: { isLoading: false } });
}

export default [
    takeEvery('SELF_CHECKOUT__INIT_DATA', initData),
];
import _ from 'lodash'
import _helper from '../../utils/helper';
import _action from './actions.jsx'

export function getDefaultState() {
  return {
    isInitialized: false,
    cartList: {
      146: {
        cart: [
          { "pid": 113179, "qty": 2, "pc": 50, "options": { "1": [2] } }, //pc single price
          { "pid": 113248, "qty": 2 },
        ],
        available_shippings: [],
        shippingMethod: "delivery",
        coupon: '',
      }
    }, // temp hardcoded
    productList: {},
    displayModal: false,
    displayModalContent: '',
  }
}

function initShoppingCart(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { gid } = action.payload;
    let { productList } = action.response;

    if (productList && gid) {
      finalState.productList[gid] = productList;
      finalState.cartList[gid] = action.cartList
    }

    finalState.isInitialized = true;
  } else {
    let { gid, reason } = action.payload;
    if (reason == 'no_items') {
      finalState.displayModalContent = 'no_items_error'
    } else {
      finalState.displayModalContent = 'unexpected_error'
    }
    finalState.displayModal = true
  }

  return finalState;
}

function uninitShoppingCart(state, action) {
  // cleans cart data so it doesnt have dynamic value such as name(based on language)
  let finalState = _.cloneDeep(state);
  finalState.productList = getDefaultState().productList;
  finalState.isInitialized = false
  finalState.cartItem = getDefaultState().cartList //for testing only
  return finalState;
}

function setShoppingCartItems(state, action) {
  let finalState = _.cloneDeep(state);

  let { gid, items, cartListToOld } = action.payload

  finalState.cartList[gid] = items

  if (cartListToOld) {
    _helper.setLocalStorage(`g${gid}`, cartListToOld)
  } else {
    _helper.removeLocalStorage(`g${gid}`)
    _helper.removeLocalStorage('checkoutShippingMethod')
  }

  return finalState;
}

function setShoppingCartShippingMethods(state, action) {
  let finalState = _.cloneDeep(state);

  let { gid, method } = action.payload

  if (finalState.cartList[gid]) {
    finalState.cartList[gid].shippingMethod = method
  }

  return finalState;
}


export default {
  'shopping-cart': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'SHOPPING_CART__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'SHOPPING_CART__INIT_SHOPPING_CART_SUCCESS':
        return initShoppingCart(state, action, true);
      case 'SHOPPING_CART__INIT_SHOPPING_CART_FAILED':
        return initShoppingCart(state, action, false);
      case 'SHOPPING_CART__UNINIT_SHOPPING_CART':
        return uninitShoppingCart(state, action);
      case 'SHOPPING_CART__SET_CART_ITEMS':
        return setShoppingCartItems(state, action);
      case 'SHOPPING_CART__SET_CART_SHIPPING_METHOD':
        return setShoppingCartShippingMethods(state, action);
      default:
        return state;
    }
  }
}


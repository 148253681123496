import _ from 'lodash';
import _model from 'utils/model';
import _helper from '../../utils/helper';

export function getDefaultState() {
  return {
    pageLoading: true,
    orderDetailInstance: new _model.orderDetail(),
    showModal: false,
    modalTitle: 'warning',
    modalContent: '',
    modalKeywords: {},
  }
}

function fetchOrderDetail(state, action, status) {
  let finalState = _.cloneDeep(state);

  const orderDetailResponse = _.get(action, 'response.orderDetailResponse', {});

  if (status && orderDetailResponse.RC === 200) {
    finalState.orderDetailInstance = new _model.orderDetail(_.get(orderDetailResponse, 'records', {}))
  }

  finalState.pageLoading = false;
  finalState.isLoading = false;
  return finalState;
}

function postComment(state, action, status) {
  let finalState = _.cloneDeep(state);
  finalState.commentsFormLoading = false;
  return finalState;
}

function showErrorDialog(state, action) {
  let finalState = _.cloneDeep(state);
  const type = _.get(action, 'error')
  finalState.showModal = true
  if (type === 1) {
    finalState.modalContent = 'order_payment_status_error'
  }
  if (type === 2) {
    const phone = _.get(finalState, 'orderDetailInstance.data.store_phone')
    finalState.modalContent = 'order_comment_status_error'
    finalState.modalKeywords = { phone: _helper.formatPhoneNumber(phone) }
  }
  return finalState
}

function hideErrorDialog(state, action) {
  let finalState = _.cloneDeep(state);
  finalState.showModal = false
  finalState.modalTitle = 'warning'
  finalState.modalContent = ''
  finalState.modalKeyword = {}
  return finalState
}

export default {
  'order-detail': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'ORDER_DETAIL__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'ORDER_DETAIL__CLEAR_DATA':
        return getDefaultState();
      case 'ORDER_DETAIL__FETCH_ORDER_DETAIL_SUCCESS':
        return fetchOrderDetail(state, action, true);
      case 'ORDER_DETAIL__FETCH_ORDER_DETAIL_FAILED':
        return fetchOrderDetail(state, action, false);
      case 'ORDER_DETAIL__POST_COMMENT_SUCCESS':
        return postComment(state, action, true);
      case 'ORDER_DETAIL__POST_COMMENT_FAILED':
        return postComment(state, action, false);
      case 'ORDER_DETAIL__SHOW_ERROR_DIALOG':
        return showErrorDialog(state, action);
      case 'ORDER_DETAIL__HIDE_ERROR_DIALOG':
        return hideErrorDialog(state, action);
      default:
        return state;
    }
  }
}

import _helper from 'utils/helper';
import _config from 'config';
import _ from 'lodash';

function initGroupSaleBrowser(params) {
  return {
    type: "GROUP_SALE__INIT_GROUP_SALE_BROWSER",
    payload: params
  }
}

function fetchGroupSaleList(params) {
  return {
    type: "GROUP_SALE__FETCH_GROUP_SALE_LIST",
    payload: params
  }
}

function fetchGroupSaleDetail(params) {
  return {
    type: "GROUP_SALE__FETCH_GROUP_SALE_DETAIL",
    payload: params
  }
}

export default {
  initGroupSaleBrowser,
  fetchGroupSaleList,
  fetchGroupSaleDetail,
}
import _ from 'lodash';
import _helper from 'utils/helper';
import _model from 'utils/model';

export function getDefaultState() {
  return {
    gsShortcut: [],
    gsList: [],
    gsListPaging: {},
    gsTop3List: [],
    sortList: [],
    catList: [],
    activeFilterIds: {},
    listLoading: false,
    detailLoading: false,
    groupSaleInstance: new _model.groupSale(),
    selectedOpts: {},
    storeInstance: {},
    storeLoading: false,
  };
}

function initGroupSale(state, action, status) {
  var finalState = _.cloneDeep(state);
  if (status) {
    let { gsShortcut, gsList, gsTop3List, sortList, gsFilterList, catList } = action.response;
    if (gsTop3List.RC == 200) {
      finalState.gsShortcut = gsShortcut.records;
      finalState.gsTop3List = gsTop3List.records;
      finalState.sortList = sortList.records
      finalState.catList = catData
    }
  }
  return finalState;
}

function fetchGroupSaleList(state, action, status) {
  var finalState = _.cloneDeep(state);

  if (status) {
    let { gsList } = action.response;

    if (gsList.RC == 200) {
      finalState.gsList = gsList.records;
      finalState.gsListPaging = gsList.paging;
    }
  }

  finalState.listLoading = false

  return finalState;
}

function fetchGroupSaleDetails(state, action, status) {
  var finalState = _.cloneDeep(state);
  if (status) {
    let { groupSaleDetail } = action.response;
    if (groupSaleDetail.RC == 200) {
      finalState.groupSaleInstance = new _model.groupSale(groupSaleDetail.records);
      //auto select options based on minimum needed
      if (finalState.groupSaleInstance.data.opts && finalState.groupSaleInstance.data.opts.length) {
        finalState.groupSaleInstance.data.opts.map((options) => {
          if (options.min >= 1 && options.opts && options.opts.length) {
            finalState.selectedOpts[options.id] = [];
            options.opts.map((optionOpt, index) => {
              if (index <= options.min - 1) {
                finalState.selectedOpts[options.id].push(optionOpt.id)
              }
            })
          }
        })
      }

    }
  }
  finalState.isLoading = false;
  return finalState;
}

function fetchStoreDetails(state, action, status) {
  var finalState = _.cloneDeep(state);
  if (status) {
    let { storeData } = action.response;
    finalState.storeInstance = storeData;
  } else {
    finalState.storeInstance = getDefaultState().storeInstance;
  }
  finalState.storeLoading = false;
  return finalState;
}

export default {
  'group-sale': (state = getDefaultState(), action) => {
    switch (action.type) {
      case "GROUP_SALE__SET_STATE":
        return Object.assign({}, _.cloneDeep(state), action.state);
      case "INIT_GROUP_SALE_BROWSER__SUCCESS":
        return initGroupSale(state, action, true);
      case "INIT_GROUP_SALE_BROWSER__FAILED":
        return initGroupSale(state, action, false);
      case "GROUP_SALE_BROWSER__FETCH_GROUP_SALE_LIST__SUCCESS":
        return fetchGroupSaleList(state, action, true);
      case "GROUP_SALE_BROWSER__FETCH_GROUP_SALE_LIST__FAILED":
        return fetchGroupSaleList(state, action, false);
      case "GROUP_SALE__FETCH_GROUP_SALE_DETAIL__SUCCESS":
        return fetchGroupSaleDetails(state, action, true);
      case "GROUP_SALE__FETCH_GROUP_SALE_DETAIL__FAILED":
        return fetchGroupSaleDetails(state, action, false);
      case "GROUP_SALE__FETCH_STORE_DETAIL_SUCCESS":
        return fetchStoreDetails(state, action, true);
      case "GROUP_SALE__FETCH_STORE_DETAIL_FAILED":
        return fetchStoreDetails(state, action, false);
      default:
        return state;
    }
  }
}

const catData = [
  {
    "level": "1",
    "name": "food_drinks",
    "parent_id": null,
    "img_url": null,
    "seq_num": "1",
    "id": 1000,
    "children": [
      {
        "level": "2",
        "name": "restaurants",
        "parent_id": 1000,
        "img_url": null,
        "seq_num": "0",
        "id": 1001
      },
      {
        "level": "2",
        "name": "breweries_wineries_distileries",
        "parent_id": 1000,
        "img_url": null,
        "seq_num": "0",
        "id": 1002
      }
    ]
  },
  {
    "level": "1",
    "name": "entertainment",
    "parent_id": null,
    "img_url": null,
    "seq_num": "2",
    "id": 1100,
    "children": [
      {
        "level": "2",
        "name": "collectibles",
        "parent_id": 1100,
        "img_url": null,
        "seq_num": "0",
        "id": 1101
      },
      {
        "level": "2",
        "name": "books",
        "parent_id": 1100,
        "img_url": null,
        "seq_num": "0",
        "id": 1102
      }
    ]
  },
  {
    "level": "1",
    "name": "beauty_spas",
    "parent_id": null,
    "img_url": null,
    "seq_num": "3",
    "id": 1200,
    "children": [
      {
        "level": "2",
        "name": "face_skin",
        "parent_id": 1200,
        "img_url": null,
        "seq_num": "0",
        "id": 1201
      },
      {
        "level": "2",
        "name": "cosmetic_procedures",
        "parent_id": 1200,
        "img_url": null,
        "seq_num": "0",
        "id": 1202
      }
    ]
  },
  {
    "level": "1",
    "name": "health_fitness",
    "parent_id": null,
    "img_url": null,
    "seq_num": "4",
    "id": 1300,
    "children": [
      {
        "level": "2",
        "name": "gym",
        "parent_id": 1300,
        "img_url": null,
        "seq_num": "0",
        "id": 1301
      },
      {
        "level": "2",
        "name": "sport",
        "parent_id": 1300,
        "img_url": null,
        "seq_num": "0",
        "id": 1302
      }
    ]
  },
  {
    "level": "1",
    "name": "travel",
    "parent_id": null,
    "img_url": null,
    "seq_num": "5",
    "id": 1400,
    "children": [
      {
        "level": "2",
        "name": "tour",
        "parent_id": 1400,
        "img_url": null,
        "seq_num": "0",
        "id": 1401
      },
      {
        "level": "2",
        "name": "hotel",
        "parent_id": 1400,
        "img_url": null,
        "seq_num": "0",
        "id": 1402
      }
    ]
  },
  {
    "level": "1",
    "name": "shopping",
    "parent_id": null,
    "img_url": null,
    "seq_num": "6",
    "id": 1500,
    "children": [
      {
        "level": "2",
        "name": "personalized",
        "parent_id": 1500,
        "img_url": null,
        "seq_num": "0",
        "id": 1501
      },
      {
        "level": "2",
        "name": "home_garden",
        "parent_id": 1500,
        "img_url": null,
        "seq_num": "0",
        "id": 1502
      },
      {
        "level": "2",
        "name": "jewelry_watches",
        "parent_id": 1500,
        "img_url": null,
        "seq_num": "0",
        "id": 1503,
        "children": [
          {
            "level": "3",
            "name": "fashion_jewelry",
            "parent_id": 1503,
            "img_url": null,
            "seq_num": "0",
            "id": 1504
          },
          {
            "level": "3",
            "name": "fine_metal_jewelry",
            "parent_id": 1503,
            "img_url": null,
            "seq_num": "0",
            "id": 1505
          },
          {
            "level": "3",
            "name": "watches",
            "parent_id": 1503,
            "img_url": null,
            "seq_num": "0",
            "id": 1506
          }
        ]
      }
    ]
  },
  {
    "level": "1",
    "name": "home_services",
    "parent_id": null,
    "img_url": null,
    "seq_num": "7",
    "id": 1600,
    "children": [
      {
        "level": "2",
        "name": "cleaning_services",
        "parent_id": 1600,
        "img_url": null,
        "seq_num": "0",
        "id": 1601
      },
      {
        "level": "2",
        "name": "flooring",
        "parent_id": 1600,
        "img_url": null,
        "seq_num": "0",
        "id": 1602
      }
    ]
  },
  {
    "level": "1",
    "name": "automotive",
    "parent_id": null,
    "img_url": null,
    "seq_num": "8",
    "id": 1700,
    "children": [
      {
        "level": "2",
        "name": "interior_accessories",
        "parent_id": 1700,
        "img_url": null,
        "seq_num": "0",
        "id": 1701
      },
      {
        "level": "2",
        "name": "car_electronics",
        "parent_id": 1700,
        "img_url": null,
        "seq_num": "0",
        "id": 1702
      }
    ]
  },
  {
    "level": "1",
    "name": "local_services",
    "parent_id": null,
    "img_url": null,
    "seq_num": "9",
    "id": 1800,
    "children": [
      {
        "level": "2",
        "name": "fun_leisure",
        "parent_id": 1800,
        "img_url": null,
        "seq_num": "0",
        "id": 1801
      },
      {
        "level": "2",
        "name": "sports_outdoors",
        "parent_id": 1800,
        "img_url": null,
        "seq_num": "0",
        "id": 1802
      }
    ]
  }
]


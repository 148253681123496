import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Layout, Form, Input, Checkbox, Button, Divider, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from 'utils/helper';
const FormItem = Form.Item;


class ByExisting extends Component {
    render() {
        let {
            hide,
            login,
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            updateProfileLoading,
        } = this.props;

        let { formatMessage } = this.props.intl;
        return (
            <Form onSubmit={handleSubmit} style={{ display: hide ? 'none' : null }}>

                <FormItem
                    label={<FormattedMessage id="old_password" />}
                    colon={false}
                    help={touched.oldPassword && errors.oldPassword ? errors.oldPassword : ''}
                    validateStatus={touched.oldPassword && errors.oldPassword ? 'error' : ''}
                >
                    <Input.Password
                        name="oldPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                    />
                </FormItem>

                <FormItem
                    label={<FormattedMessage id="new_password" />}
                    colon={false}
                    help={touched.newPassword && errors.newPassword ? errors.newPassword : ''}
                    validateStatus={touched.newPassword && errors.newPassword ? 'error' : ''}
                >
                    <Input.Password
                        name="newPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                    />
                </FormItem>

                <FormItem
                    label={<FormattedMessage id="confirm_password" />}
                    colon={false}
                    help={touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : ''}
                    validateStatus={touched.passwordConfirm && errors.passwordConfirm ? 'error' : ''}
                >
                    <Input.Password
                        name="passwordConfirm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.passwordConfirm}
                    />
                </FormItem>
                <Divider />
                <FormItem>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={updateProfileLoading || !values.oldPassword || !values.newPassword || !values.passwordConfirm}
                        loading={updateProfileLoading} block>
                        <FormattedMessage id="save" />
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

let validationSchema = (lan) => yup.object().shape({
    oldPassword: yup.string(_helper.getIsRequiredMessage(lan, 'old_password')),
    newPassword: yup.string(_helper.getIsRequiredMessage(lan, 'new_password')),
    passwordConfirm: yup.string()
        .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
        .required(_helper.getIsRequiredMessage(lan, 'confirm_password'))
});

ByExisting = withFormik({
    mapPropsToValues: (props) => ({ ...props.initialValues }),
    handleSubmit: (values, { props, setErrors, setSubmitting }) => {
        props.updateUserPassword(values)
    },
    validationSchema: (props) => validationSchema(props.lan),
    enableReinitialize: true

})(ByExisting)
export default injectIntl(ByExisting);

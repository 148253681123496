import React, { Component } from 'react';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Select, Icon, Radio } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_shippingMainMethodModal.scss'

const Option = Select.Option;
const RadioGroup = Radio.Group;

//AKA Order Type Modal
class ShippingMainMethodModal extends Component {

	state = {
		currentValue: '',
	}

	static defaultProps = {
		cartDetails: {}
	}

	componentWillReceiveProps(nextProps) {
		let { cartDetails } = nextProps
		if (cartDetails['shippingMethod'] != this.state.currentValue) {
			this.setState({ currentValue: cartDetails['shippingMethod'] })
		}
	}

	handleChange = (value) => {
		this.setState({
			currentValue: value.target.value,
			valueMissing: false
		})
	}

	onOkClick = () => {
		let {
			onCloseClick,
			onOkClick
		} = this.props;

		let {
			currentValue,
		} = this.state

		if (currentValue) {
			onOkClick(currentValue);
			onCloseClick();
		} else {
			this.setState({
				valueMissing: true
			})
		}

	}

	getOptions = () => {
		let { storeInstance, cartDetails, checkoutState, } = this.props;

		let options = []
		let subTotal = checkoutState.priceInfo.subTotal

		let storeCat = checkoutState["storeInstance"].data.c_id

		if (storeInstance.data['allow_order']) {
			if (cartDetails['shippingMethod'] == 'freeShipping') {
				options.push('freeShipping')
			} else {
				if (storeInstance.data['is_open'] && storeInstance.data['eatin']) {
					if (storeCat == 1) { // 1 is restaurant
						options.push('eatin')
					} else {
						options.push('instore')
					}
				}
				if (storeInstance.data['delivery'] && subTotal >= storeInstance.data['min_delivery_amount']) {
					options.push('delivery')
				}
				if (storeInstance.data['takeout'] || (storeInstance.data['pickup'])) {
					options.push('pickup')
				}
			}
		}

		// only works for the groupsale page, this needs to be implemented in the web v1
		options = options.filter((option) => {
			return Array.isArray(cartDetails['available_shippings'])
				&& _.includes(cartDetails['available_shippings'], Number(_config.SHIPPING_MAPPING_TO_NUMERIC[option]))
		})

		return options
	}

	render() {
		let {
			cartDetails,
			displayModal,
			onCloseClick,
			checkoutState,
			intl,
		} = this.props;

		let { valueMissing, currentValue } = this.state

		let { formatMessage } = intl;

		return (
			<Modal
				className='shipping-main-modal'
				title={formatMessage({ id: 'order_type' })}
				visible={displayModal && this.getOptions().length >= 2}
				width={250}
				onOk={() => this.onOkClick()}
				onCancel={onCloseClick}
				okText={formatMessage({ id: 'ok' })}
				cancelText={formatMessage({ id: 'cancel' })}
			>
				<div className="shipping-main-method" >
					{valueMissing ?
						<div className="missing-item-message">
							<div> <Icon type="exclamation-circle" /> </div>
							<div> &nbsp; <FormattedMessage id="please_input_belows" /> </div>
						</div>
						: ''}

					<RadioGroup
						value={currentValue}
						style={{ width: '100%' }}
						className={classnames({ 'missing-item': valueMissing })}
						onChange={(value) => this.handleChange(value)}>
						{this.getOptions().map((opt, i) => {
							return (
								<Radio key={opt + i} value={opt} style={{ display: 'block', paddingBottom: '10px' }}><FormattedMessage id={opt} /></Radio>
							)
						})}
					</RadioGroup>
				</div>
			</Modal>
		)
	}
}


export default injectIntl(ShippingMainMethodModal);
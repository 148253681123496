import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { injectIntl, FormattedMessage, } from 'react-intl';
import PriceInfoCheckout from '../../checkout-page/page-components/priceInfo'
import _ from 'lodash'
import { connect } from 'react-redux';

class ItemInfo extends Component {
    getPriceInfoData = () => {
        const order = this.props.order
        const state = this.props.state
        return {
            itemTotal: _.get(order, 'item_ttl', 0),
            storeSurcharge: _.get(order, 'store_surcharge.value', 0),
            storeSurchargeLabel: _.get(order, 'store_surcharge.label', 0),
            orderSurcharge: _.get(order, 'order_surcharge.value', 0),
            orderSuchargeLabel: _.get(order, 'order_surcharge.label', 0),
            subtotal: _.get(order, 'sub_total', 0),
            storeDiscount: _.get(order, 'store_discount', 0),
            couponDiscount: _.get(order, 'coupon_discount', 0),
            giftCardPayment: _.get(order, 'giftcard_payment', 0),
            pointsPayment: _.get(order, 'points_payment', 0),
            deliveryFee: _.get(order, 'shipping_fee', 0),
            totalBeforeTax: _.get(order, 'total_before_tax', 0),
            tax: _.get(order, 'tax', 0),
            tips: _.get(state, 'tips', 0)
        }
    }

    getDisplayPriceInfo = () => {
        const state = this.props.state
        const order = this.props.order
        return {
            orderTotal: 0,
            totalDue: _.get(state, 'totalDue', 0),
        }
    }

    render() {

        return (
            <div>
                <PriceInfoCheckout
                    propsCurrency={_.get(this.props, 'order.currency')}
                    propsStore={this.props.store}
                    propsState={{
                        priceInfo: this.getPriceInfoData(),
                        displayPriceInfo: this.getDisplayPriceInfo()
                    }}
                    propsUpdateTips={(values) => this.props.updateTips(values)}
                />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "pay-now", {}),
    order: _.get(state, 'pay-now.order.data', ''),
    store: _.get(state, 'pay-now.store.data', '')
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'PAY_NOW__SET_STATE', state }),
    updateTips: (payload) => ({ type: 'PAY_NOW__UPDATE_TIPS', payload }),
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemInfo));
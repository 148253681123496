import _ from 'lodash';
import _helper from 'utils/helper';
import _model from 'utils/model'

export function getDefaultState() {
    return {
        storeReady: false,
        storeInstance: new _model.storeDetail(),
    }
}

function initStoreData(state, action, status) {
    let finalState = _.cloneDeep(state);
    if (status) {
        if (action.response.storeIntanceResponse && action.response.storeIntanceResponse.RC == 200) {
            // finalState = action.response.appConfigResponse.records;
            finalState.storeInstance = new _model.storeDetail(action.response.storeIntanceResponse.records);
            finalState.storeReady = true;
        }

        return finalState;
    }
}

export default {
    'store-data': (state = getDefaultState(), action) => {
        switch (action.type) {
            case 'INIT_APP_STORE__SUCCESS':
                return initStoreData(state, action, true)
            case 'INIT_APP_STORE__FAILED':
                return initStoreData(state, action, false)
            default:
                return state;
        }
    }
}
import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, List } from 'antd';
import { FormattedMessage, injectIntl, } from 'react-intl';
import ReviewCell from 'containers/groupsale-page/page-components/reviewCell'
import './_reviewListModal.scss'

class ReviewListModal extends Component {


    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static defaultProps = {
        displayModal: false,
        reviewData: [],
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        let {
            intl,

            displayModal,
            onCloseClick,
            reviewData
        } = this.props;

        let {
            width,
            height
        } = this.state

        let { formatMessage } = intl;

        return (
            <div className="review-modal-container">
                <Modal
                    width={900}
                    visible={displayModal}
                    title={formatMessage({ id: 'customer_reviews' })}
                    onCancel={onCloseClick}
                    footer={null}
                >
                    <div
                        style={{ maxHeight: `${height * 0.7}px`, overflowY: 'auto', overflowX: 'hidden' }}
                        className="review-modal-content">
                        <List
                            itemLayout="horizontal"
                            dataSource={reviewData}
                            renderItem={(item, i) => (
                                <List.Item
                                    key={`review-cell-${i}`}>
                                    <ReviewCell
                                        review={item} />
                                </List.Item>
                            )}
                            pagination={{
                                pageSize: 5,
                                total: reviewData.length,
                                position: "bottom"
                            }}
                        />
                    </div>

                </Modal>
            </div>
        )
    }
}


export default injectIntl(ReviewListModal);
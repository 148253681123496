import _config from 'config';
import _helper from 'utils/helper';
import { call, put } from 'redux-saga/effects';
import _ from 'lodash'

function checkResponse(params) {
  let res = params.response;
  return res;
}

function* callCheckingAuthentication(fn, ...rest) {
  const response = yield call(fn, ...rest)
  const RC = _.get(response, 'RC', 404)

  if (RC === 401) { //401: When the resource is unauthorized
    yield put({ type: "AUTH__CLEAR_DATA" })
  }
  console.log("response", response)
  return response
}

function executeOauth(url, type, params, oauthToken, oauthTokenSecret) {
  let localAccessToken = _helper.getLocalStorage('accessToken')
  let localAccessSecret = _helper.getLocalStorage('accessSecret')
  let executeUrl = `${_config.API_ROOT}/goopter_tool/APIUtils/execute.php`
  let finalPostBody = {
    consumer: {
      oauthToken: oauthToken ? oauthToken : localAccessToken,
      oauthTokenSecret: oauthTokenSecret ? oauthTokenSecret : localAccessSecret,
    },
    apis: [
      {
        auth: "consumer",
        apiURL: url,
        requestType: type,
        data: params,
      }
    ]
  }
  let response = fetch(executeUrl, {
    method: 'POST',
    body: JSON.stringify(finalPostBody),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }).then(res => res.json())
    .then(res => { return res[0] })
    .catch(e => console.log("error: ", e));
  console.log("post", params)
  console.log("url", url)
  return response
}

function get(url) {
  let response = fetch(url)
    .then(res => res.json())
    .then(res => checkResponse({
      method: 'GET',
      url: url,
      response: res,
    }))
    .catch((e) => {
      console.log(`GET ${url} failed!`, e);
      throw new Error(`GET ${url} failed!`);
    }
    );
  console.log('url', url)
  console.log('response', response)
  return response
}

// no checkReponse because we want to accept all response codes from goopter api
function safePost(url, postbody) {
  let response = fetch(url, {
    method: 'POST',
    body: JSON.stringify(postbody),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }).then(res => res.json())
    .catch(e => console.log("error: ", e));
  console.log('url', url)
  console.log('post', postbody)
  console.log('respons', response)
  return response
}

function post(url, postbody) {
  console.log(postbody)
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(postbody),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
    .then(res => res.json())
    .then(res => checkResponse({
      method: 'POST',
      url: url,
      response: res,
    }))
    .catch((e) => {
      console.log("error: ", e);
      throw new Error(`GET ${url} failed!`);
    });
}


function setLoginSessionOld(accessToken, accessSecret) {
  return fetch(`/oauth/migrate/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      action: 'set_login_session',
      data: {
        accessToken,
        accessSecret
      },
    }),
    credentials: "same-origin",
  })
}

function setLogoutSessionOld(accessToken, accessSecret) {
  return fetch(`/oauth/migrate/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      action: 'set_logout_session',
      data: {
        accessToken,
        accessSecret
      },
    }),
    credentials: "same-origin",
  })
}

function checkLogin() {
  return fetch(`/oauth/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      type: 'POST',
      action: 'check_login_session',
      data: {
        mobileConsumerKey: process.env.REACT_APP_CONSUMER_KEY,
        mobileConsumerSecret: process.env.REACT_APP_CONSUMER_SECRET
      },
    }),
    credentials: "same-origin",
  })
}

export default {
  checkResponse,
  get,
  safePost,
  post,
  executeOauth,
  callCheckingAuthentication,
  checkLogin,
  setLoginSessionOld,
  setLogoutSessionOld
}

import _ from 'lodash';
import _helper from 'utils/helper'
import _config from 'config';
import _api from 'utils/api';
import moment from 'moment';

export default class OrderDetail {
  constructor(orderDetail) {
    orderDetail = Object.assign({}, {
      order_id: {},
      status: [1],
      status_detail: [],
      order_item: [],
      shipping_adr: {},
      billing_adr: {},
      store_address: {}
    }, orderDetail);
    this.initOrderDetailData(orderDetail);
  }

  initOrderDetailData(orderDetail) {
    this.data = {
      ...orderDetail,
      'order_id': orderDetail['id'],
      'status': orderDetail['status'],
      'status_detail': orderDetail['status_detail'],
      'quote_id': orderDetail['q_id'],
      'group_id': orderDetail['g_id'],
      'c_id': orderDetail['c_id'],
      'allow_paynow': orderDetail['allow_paynow'],

      'store_name': orderDetail['s_nm'],
      'first_name': orderDetail['firstname'],
      'last_name': orderDetail['lastname'],
      'full_name': (orderDetail['firstname'] ? orderDetail['firstname'] + ' ' : '') + (orderDetail['lastname'] ? orderDetail['lastname'] : ''),
      'billing_address': orderDetail['billing_adr'],
      'billing_address_formatted': this.formatAddress(orderDetail['billing_adr']),
      'shipping_address': orderDetail['shipping_adr'],
      'shipping_address_formatted': this.formatAddress(orderDetail['shipping_adr']),
      'store_address': orderDetail['store_address'],
      'store_address_formatted': _helper.formatAddress(orderDetail['store_address']),
      'store_phone': orderDetail['store_phone'],
      'phone': orderDetail['phone'],
      'email': orderDetail['email'],
      'coupon_code': orderDetail['coupon_cd'],


      'credit_card': orderDetail['credit_card'],
      'tips': orderDetail['tips'],
      'sub_total': orderDetail['subtotal'],
      'order_date': orderDetail['order_dt'],
      'order_date_converted': orderDetail['order_dt'] ? moment(`${orderDetail['order_dt']}+00:00`).format('MMM D, YYYY h:mm A') : '',
      'total_due': orderDetail['total_due'],
      'tax': orderDetail['tax'],
      'giftcard_payment': orderDetail['giftcard_payment'],
      'giftcard_code': orderDetail['giftcard_cd'],
      'points_payment': orderDetail['points_payment'],

      'discount': orderDetail['dis_ttl'] ? orderDetail['dis_ttl'] : 0,
      'store_discount': orderDetail['store_dis'] ? orderDetail['store_dis'] : 0,
      'coupon_discount': orderDetail['cpn_dis'] ? orderDetail['cpn_dis'] : 0,

      'refund_amt': orderDetail['refund_amt'],
      'shipping_fee': orderDetail['delivery_fee'],
      'reward_points': orderDetail['reward_points'],
      'payment_method': orderDetail['payment_method'],
      'shipping_method': orderDetail['shipping_method'],
      'shipping_code': orderDetail['shipping_cd'],
      'shipping_desc': orderDetail['shpping_desc'],
      'comments': orderDetail['comments'],
      'table_number': orderDetail['table'],
      'party_size': orderDetail['party_size'],
      'server': orderDetail['server'],
      'order_item': orderDetail['order_item'],
      'total_paid': orderDetail['total_paid'],
      'order_total': orderDetail['order_total'],
      'currency': orderDetail['currency'],
      'est_ready_dt': orderDetail['est_ready_dt'],
      'est_ready_dt_converted': orderDetail['est_ready_dt'] ? _helper.utcToLocal(orderDetail['est_ready_dt'], { asap: true, local: false }) : '',
      'exp_dt_start': orderDetail['exp_dt_start'],
      'exp_dt_end': orderDetail['exp_dt_end'],
      'exp_dt_full': this.getFullDate(orderDetail['exp_dt_start'], orderDetail['exp_dt_end']),
      'has_review': orderDetail['has_rev'],
      'allow_review_dt': orderDetail['allow_review_dt'],
      'reviews': {},
      'can_submit_review': this.canPostReview(orderDetail['allow_review_dt']),
      'logo': orderDetail['logo'],
      'total_before_tax': orderDetail['ttl_b4_tax'],
      'item_ttl': orderDetail['item_ttl'],
      'store_surcharge': orderDetail['store_surcharge'],
      'order_surcharge': orderDetail['order_surcharge'],
      'is_active': this.isOrderActive(orderDetail),
      'related_order_id': orderDetail.related_order_id,

    }
  }

  canPostReview(reviewDeadline) {
    if (reviewDeadline) {
      return !_helper.checkExpired(reviewDeadline)
    }
    return false;
  }

  getFullDate(startDate, endDate) {
    let fullDate = '';

    if (startDate == "2000-01-01 00:00") {
      return 'ASAP';
    }

    let revisedStart = moment(startDate, "YYYY-MM-DD HH:mm").format('MMM D, YYYY/h:mm A')
    let revisedEnd = moment(endDate, "YYYY-MM-DD HH:mm").format('MMM D, YYYY/h:mm A')

    if (revisedStart) {
      revisedStart = revisedStart.split('/')
    }
    if (revisedEnd) {
      revisedEnd = revisedEnd.split('/')
    }
    if (revisedStart) {
      fullDate += revisedStart[0]
      fullDate += ` ${revisedStart[1]}`
    }
    if (revisedEnd) {
      fullDate += ` - ${revisedEnd[1]}`
    }
    return fullDate
  }

  getTotalBeforeTax(totals) {
    let { subTotal, deliveryFee, discount } = totals;
    return parseFloat(subTotal) + parseFloat(deliveryFee) - Math.abs(parseFloat(discount));
  }

  updateReviews(reviews) {
    this.data.reviews.p_review = reviews['p_review'] ? reviews['p_review'] : [];
    this.data.reviews.s_review = reviews['s_review'] ? reviews['s_review'] : [];
  }

  formatAddress(address) {
    let fullName = ''
    let result = []
    if (address) {
      let result = _helper.formatAddress(address)
      if (address.fn) {
        fullName += address.fn
      }
      if (address.ln) {
        fullName += " " + address.ln
      }
      if (address.tn) {
        fullName += " (" + address.tn + ")"
      }
      if (fullName) {
        result.unshift(fullName)
      }
    }
    return result
  }

  isOrderActive(order) {
    const orderSt = String(_.get(order, 'status', 1))
    // "1": "pending",
    // "2": "processing",
    // "3": "payment_review",
    // "4": "completed",
    // "5": "canceled",
    // "6": "closed",
    // "7": "pending_payment",
    // "8": "holded"
    return orderSt === "1" || orderSt === "2"
  }
}

import _ from 'lodash';
import _model from '../../utils/model'
import _config from '../../config'

const initModelData = {
    displayModal: false,
    displayModalType: '',
    displayModalHanldingType: '',
    displayModalContent: '',
    displayModalKeywords: {},
}

export function getDefaultState() {
    return {
        store: {},
        ...initModelData,
    }
}

function initDataSuccess(state, action) {
    let finalState = _.cloneDeep(state);
    finalState.store = _.get(action, 'response.instantPayResponse.records.store', {});
    finalState.product = _.get(action, 'response.instantPayResponse.records.product', {})
    finalState.gid = _.get(action, 'payload.gid', '')
    finalState = Object.assign(finalState, initModelData)
    return finalState;
}

export default {
    'self-checkout': (state = getDefaultState(), action) => {
        switch (action.type) {
            case 'SELF_CHECKOUT__SET_STATE':
                return Object.assign({}, _.cloneDeep(state), action.state);
            case 'SELF_CHECKOUT__CLEAR_DATA':
                return getDefaultState();
            case 'SELF_CHECKOUT__INIT_DATA_SUCCESS':
                return initDataSuccess(state, action);
            default:
                return state;
        }
    }
}

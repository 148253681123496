import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { List, Row, Col, Button, Modal, Icon, Input } from 'antd';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import PaymentModal from 'containers/checkout-page/page-components/paymentInfo/paymentModal'
import AddressModal from 'containers/checkout-page/page-components/customerInfo/addressModal'
import GiftCardModal from 'containers/checkout-page/page-components/paymentInfo/giftCardModal'
import PointsModal from 'containers/checkout-page/page-components/paymentInfo/pointsModal'
import './_paymentInfo.scss'
import _ from 'lodash';

class PaymentInfo extends Component {

  state = {
    displayPaymentModal: false,
    displayAddressModal: false,
    cardType: null,
  }

  static defaultProps = {
    checkoutState: {}
  }

  getAddress = (content) => {
    return content.map((itm, i) => {
      return (
        <div style={{ width: '100%' }}>
          <p style={{ marginBottom: '6px' }} className={itm.className}>{itm.contents}</p>
        </div>
      )
    })
  }

  getPaymentInfo(checkoutState, paymentMethod) {
    if (checkoutState && checkoutState.paymentMethod == 2) {
      if (checkoutState.creditCard.type == 1) {
        paymentMethod = 'Visa';
      }
      else if (checkoutState.creditCard.type == 2) {
        paymentMethod = 'MasterCard';
      }
      else if (checkoutState.creditCard.type == 3) {
        paymentMethod = 'American Express';
      }
      else if (checkoutState.creditCard.type == 4) {
        paymentMethod = 'Discover';
      }
      else if (checkoutState.creditCard.type == 5) {
        paymentMethod = 'Union Pay';
      }
      else if (checkoutState.creditCard.type == 6) {
        paymentMethod = 'JCB';
      }
      else if (checkoutState.creditCard.type == 7) {
        paymentMethod = 'Maestro';
      }
      else {
        paymentMethod = '';
      }
      paymentMethod += " (" + checkoutState.creditCard.num.replace(/\d(?=\d{4})/g, "") + ")";
    }
    return paymentMethod;
  }

  getData = () => {
    let {
      lan,
      intl,
      storeInstance,
      checkoutState,
      setCheckoutState,
    } = this.props;

    let { formatMessage } = intl;
    let paymentMethod = _config.PAYMENT_METHOD_MAPPING[checkoutState.paymentMethod] ? formatMessage({ id: _config.PAYMENT_METHOD_MAPPING[checkoutState.paymentMethod] }) : ''

    paymentMethod = this.getPaymentInfo(checkoutState, paymentMethod);

    //Payment Info
    let billingAddress = checkoutState && checkoutState.billingAddress ? checkoutState.billingAddress : '';
    let customerPhone = checkoutState.phone ? checkoutState.phone : ''
    let billingInfo = billingAddress ? billingAddress.fn + " " + billingAddress.ln : ''
    let customerAddress = billingAddress ? _helper.formatAddress(billingAddress).join(', ') : ''

    //Other Payment Info
    let DisplayPointsModal = parseFloat(checkoutState.pointsBalance) / parseFloat(storeInstance.data['p2v']);

    let data = [
      {
        requirement: true,
        title: 'payment_method',
        content: paymentMethod,
        onClick: () => {
          setCheckoutState('displayModalName', 'paymentMethod');
        },
        missing: !checkoutState.paymentMethod || checkoutState.paymentMethod < 0 || checkoutState.paymentMethod > 11,
      },
      {
        requirement: storeInstance.data['require_billing_address'] && checkoutState.paymentMethod == 2,
        title: 'billing_address',
        content: this.getAddress([
          {
            contents: billingInfo,
            className: 'checkout-billinginfo',
          },
          {
            contents: customerAddress,
          },
          {
            contents: customerPhone,
            type: 'phone',
          }
        ]),
        onClick: () => setCheckoutState('displayModalName', 'billingAddress'),
        missing: checkoutState.paymentMethod == 2 && storeInstance.data['require_billing_address'] && !checkoutState.billingAddress,
      },
      {
        requirement: false,  // DisplayPointsModal > 5,
        title: 'points',
        content: checkoutState.pointsUsed ? _helper.formatNumber(checkoutState.pointsUsed) : 0,
        onClick: () => this.setState({ displayPointsModal: true, }),
        label: checkoutState.pointsUsed ? <FormattedMessage id="points" /> : ' '
      },
      {
        requirement: true,
        title: 'gift_cards',
        content: checkoutState.selectedGiftCard ? checkoutState.selectedGiftCard.gc : formatMessage({ id: 'not_set' }),
        onClick: () => this.setState({ displayGiftCardModal: true, })
      },
    ];
    return data
  }


  render() {
    let {
      lan,
      intl,
      storeInstance,
      checkoutState,
      setCheckoutState,
    } = this.props;

    let { formatMessage } = intl;

    let billingAddress = checkoutState && checkoutState.billingAddress ? checkoutState.billingAddress : '';
    let allowedMax = parseFloat(checkoutState.displayPriceInfo.orderTotalCal) * parseFloat(storeInstance.data['p2v'])
    let storeP2v = parseFloat(storeInstance.data['p2v']);
    let currency = storeInstance.data['currency'];

    return (
      <div className="payment-info-container" >
        <List
          className='list-information'
          dataSource={this.getData()}
          renderItem={(item, i) =>
            (<div>
              {item.requirement ?
                <List.Item
                  key={i}
                  className={classnames({
                    // 'missing': item.missing,
                  })}>
                  <Row style={{ width: '100%' }}>
                    <Col style={{ height: '100%' }} span={7}> <div className="title"> <FormattedMessage id={item.title} /> </div></Col>
                    <Col style={{ height: '100%', paddingTop: '6px', }} span={12}>{item.content} {item.label} </Col>
                    <Col style={{ height: '100%' }} span={5}> <div className="action"><Button className="change_button" onClick={item.onClick}> <FormattedMessage id="edit" /> </Button> </div> </Col>
                  </Row>
                </List.Item>
                : ''}
            </div>)}
        />
        <PaymentModal
          displayModal={checkoutState.displayModalName == 'paymentMethod'}
          onCloseClick={() => setCheckoutState('displayModalName', '')}
          storeInstance={storeInstance}
          setCheckoutState={setCheckoutState}
          checkoutState={checkoutState}
          lan={lan}
        />
        <AddressModal
          displayModal={checkoutState.displayModalName == 'billingAddress'}
          onCloseClick={() => setCheckoutState('displayModalName', '')}
          currentAddress={billingAddress}
          onSelectedAddressChange={(address) => setCheckoutState('billingAddress', address)}
        />
        <GiftCardModal
          displayModal={this.state.displayGiftCardModal}
          onCloseClick={() => this.setState({ displayGiftCardModal: false })}
          selected={checkoutState.selectedGiftCard}
          checkoutState={checkoutState}
          storeInstance={storeInstance}
          onSelectedChange={(giftCard) => {
            if (_.isEqual(giftCard, checkoutState.selectedGiftCard)) {
              setCheckoutState('selectedGiftCard', null)
            } else {
              setCheckoutState('selectedGiftCard', giftCard)
            }
          }}
        />
        <PointsModal
          // title={formatMessage({ id: 'points' }) + " ( " + formatMessage({ id: 'points_balance' }) + " : " + points + ")"}
          title={formatMessage({ id: 'points' })}
          min={0}
          max={checkoutState.pointsBalance > allowedMax ? allowedMax : checkoutState.pointsBalance}
          step={1000}
          displayModal={this.state.displayPointsModal}
          onCloseClick={() => this.setState({ displayPointsModal: false })}
          onOkClick={(value) => {
            setCheckoutState('pointsUsed', value)
          }}
          storeP2v={storeP2v}
          currency={currency}
          checkoutState={checkoutState}
        />
      </div>
    )
  }
}


export default injectIntl(PaymentInfo);


import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Layout, Form, Input, Checkbox, Button, Divider, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from 'utils/helper';

const FormItem = Form.Item;


class LoginAccountForm extends Component {
  render() {
    let {
      hide,
      login,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      loginLoading,
    } = this.props;

    let { formatMessage } = this.props.intl;
    return (
      <Form onSubmit={handleSubmit} style={{ display: hide ? 'none' : null }}>
        <FormItem
          label={<FormattedMessage id="email_phone" />}
          colon={false}
          help={touched.email && errors.email ? errors.email : ''}
          validateStatus={touched.email && errors.email ? 'error' : ''}
        >
          <Input
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormItem>
        <FormItem
          label={<FormattedMessage id="password" />}
          colon={false}
          help={touched.password && errors.password ? errors.password : ''}
          validateStatus={touched.password && errors.password ? 'error' : ''}
        >
          <Input.Password
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormItem>
        <FormItem style={{ margin: '0' }}>
          <Checkbox
            name="rememberMe"
          // values={values.rememberMe}
          // onChange={handleChange}
          >
            <FormattedMessage id="remember_me" />
          </Checkbox>
        </FormItem>
        <Divider />
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            disabled={loginLoading || !values.email || !values.password}
            loading={loginLoading} block>
            <FormattedMessage id="log_in" />
          </Button>
        </FormItem>
      </Form>
    )
  }
}

let validationSchema = (lan) => yup.object().shape({
  email: yup.string().required(_helper.getIsRequiredMessage(lan, 'email_phone')),
  password: yup.string().required(_helper.getIsRequiredMessage(lan, 'password'))
});

LoginAccountForm = withFormik({
  // mapPropsToValues: (props) => ({ ...props.initalValues }),
  mapPropsToValues: (props) => ({ email: '', password: '' }), // adding initial values will make field be touched when submitting. (https://github.com/jaredpalmer/formik/issues/445)
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.login(values)
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true

})(LoginAccountForm)
export default injectIntl(LoginAccountForm);

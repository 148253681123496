import config from '../../config'
import _ from 'lodash'

const pageAcceptedPayment = (orderInstance) => {
    const result = ['paypay_express', 'union_pay', "union_pay_express"]
    if (!hasGiftCards(orderInstance)) {
        result.unshift('alipay', 'wechat_pay')
    }
    return result
}

const getInitStateFromOrder = (orderInstance = {}) => {
    //payment 
    const orderPayment = config.PAYMENT_METHOD_MAPPING[orderInstance.payment_method]
    const isOrderPaymentAccepted = pageAcceptedPayment(orderInstance).includes(orderPayment)
    //tips
    const orderTips = orderInstance.tips
    const orderDue = orderInstance.total_due
    const ordergrandTotal = orderDue - orderTips

    return {
        paymentMethod: isOrderPaymentAccepted ? orderPayment : '',
        tips: orderTips ? orderTips : 0,
        totalDue: orderDue ? orderDue : 0,
        grandTotal: ordergrandTotal,
    }
}

const getTipsSetting = (store) => {
    return {
        percents: _.get(store, 'tips.pct', [0, 10, 12, 15, 20]), //Array of percentage tips
        amts: _.get(store, 'tips.amt', [0, 2, 3, 4, 5]), // Array of $ tips
        cut: _.get(store, 'tips.limit', 20), //if the order total is over the limit then show percentage, otherwise show dollar amount,
        default_percent: _.get(store, 'tips.dflt_pct', [12, 12, 0]), //1 delivery, 2 dine in, 3 pickup
        default_amt: _.get(store, 'tips.dflt_amt', [2, 2, 0]),
        is_enable: _.get(store, 'tips.is_enabled', 1) === 1
    }
}

const hasGiftCards = (orderInstance = {}) => {
    return _.get(orderInstance, 'has_giftcard', false)
}

export default {
    hasGiftCards,
    getInitStateFromOrder,
    getTipsSetting,
}
import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Icon, InputNumber, List, Select, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_phoneModal.scss'
import countryData from 'utils/data/countryList.json'

class PhoneModal extends Component {

    state = {
        phoneInputExisted: true,
        countryInputExisted: true,
        phoneValidated: true,
    }

    getCountryName = (code) => {
        let { lan } = this.props
        let result = ''
        countryData.records.map(country => {
            if (country.country_code == code) {
                result = country.name[lan] + " (+" + country.area_code + ")"
            }
        })
        return result
    }

    onOkClick = () => {
        let {
            onCloseClick,
            onOkClick
        } = this.props;

        let {
            phoneInput,
            country
        } = this.state

        if (phoneInput && country) {

            if (this.phoneValidated()) {
                onOkClick(phoneInput);
                onCloseClick();
            } else {
                this.setState({
                    phoneValidated: false
                })
            }

        } else {

            if (!phoneInput) {
                this.setState({
                    phoneInputExisted: false
                })
            }

            if (!country) {
                this.setState({
                    countryInputExisted: false
                })
            }

        }

    }

    phoneValidated = () => {
        let result = false
        let { country, phoneInput } = this.state
        let currentCountry = countryData.records.find(cntry => { return cntry.country_code == country })
        result = new RegExp(currentCountry['phone_format']).test(phoneInput)
        return result
    }


    render() {
        let {
            displayModal,
            onCloseClick,
            intl,
        } = this.props;

        let {
            phoneInput,
            phoneInputExisted,
            countryInputExisted,
            country,
            phoneValidated,
        } = this.state

        let { formatMessage } = intl;

        let data = [
            {
                placeholder: formatMessage({ id: 'phone_number' }),
                value: phoneInput,
                onChange: (e) => {
                    let value = e.target.value
                    value = value.replace(/\D/g, '');
                    this.setState({ phoneInput: value, phoneInputExisted: true, phoneValidated: true })

                },
                onEmptyClick: () => {
                    this.phoneInput.focus();
                    this.setState({ phoneInput: '' });
                },
                ref: node => this.phoneInput = node,
                defaultValue: phoneInput,
                require: true,
            },
        ]

        return (
            <Modal
                title={formatMessage({ id: 'phone_number' })}
                visible={displayModal}
                onOk={() => this.onOkClick()}
                onCancel={onCloseClick}
                okText={formatMessage({ id: 'ok' })}
                cancelText={formatMessage({ id: 'cancel' })}
            >
                <div className="phone-modal-container" >
                    {!phoneInputExisted || !countryInputExisted ?
                        <div className="missing-item-message">
                            <div> <Icon type="exclamation-circle" /> </div>
                            <div> &nbsp; <FormattedMessage id="please_input_belows" /> </div>
                        </div>
                        : ''}

                    <Select
                        className={classnames({
                            'missing-item': !countryInputExisted
                        })}
                        style={{ width: '100%', margin: '10px 0' }}
                        placeholder={formatMessage({ id: "country" })}
                        defaultValue={countryData.records[0].country_code}
                        value={country}
                        onChange={(value) => {
                            this.setState({ 'country': value, countryInputExisted: true, phoneValidated: true })
                        }}
                    >
                        {countryData.records.map(country =>
                            <Select.Option value={country.country_code} key={`country-select-${country.country_code}`}>{this.getCountryName(country.country_code)}</Select.Option>
                        )}
                    </Select>

                    <List
                        size="large"
                        dataSource={data}
                        renderItem={item =>
                            (<List.Item style={{ display: 'block' }}>
                                <div className="item">
                                    <Input
                                        style={{ width: '100%' }}
                                        className={classnames({
                                            'missing-item': item.require && !phoneInputExisted
                                        })}
                                        placeholder={item.placeholder}
                                        prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        suffix={item.value ? <Icon type="close-circle" onClick={item.onEmptyClick} /> : null}
                                        value={item.value}
                                        onChange={item.onChange}
                                        ref={item.ref}
                                    />
                                    <div>{!phoneValidated ?
                                        <div className="missing-item-message">
                                            <div> <Icon type="exclamation-circle" /> </div>
                                            <div> &nbsp; <FormattedMessage id="invalid_phone_number" />  </div>
                                        </div>
                                        : ''}</div>
                                </div>
                            </List.Item>)}
                    />
                </div>
            </Modal>
        )
    }
}


export default injectIntl(PhoneModal);
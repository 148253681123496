import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Icon, Spin, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import Img from 'react-image'
import ImgHolder from 'images/image_groupsale_medium_gray.png'
import './_fullImageModal.scss'

class FullImageModal extends Component {


    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static defaultProps = {
        displayModal: false,
        reviewData: [],
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevsState) {
        if (prevProps.imageData != this.props.imageData) {
            this.updateUI(this.props.imageData)
        }
    }

    updateUI = (url) => {
        let {
            imageListData
        } = this.props
        let index = -1

        if (imageListData && imageListData.length) {
            index = imageListData.indexOf(url);
        }
        if (index == -1 || imageListData.length == 1) {
            this.setState({
                nextUrl: '',
                prevUrl: '',
            })
        } else if (index == 0) {
            this.setState({
                nextUrl: imageListData[index + 1],
                prevUrl: '',
            })
        } else if (index == imageListData.length - 1) {
            this.setState({
                nextUrl: '',
                prevUrl: imageListData[index - 1],
            })
        } else {
            this.setState({
                nextUrl: imageListData[index + 1],
                prevUrl: imageListData[index - 1],
            })
        }

        this.setState({ modalUrl: url })

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        let {
            intl,

            displayModal,
            onCloseClick,
            onGoToImageGalleryClick,
        } = this.props;

        let {
            width,
            height,
            modalUrl,
            nextUrl,
            prevUrl
        } = this.state

        let { formatMessage } = intl;

        return (
            <div className="review-modal-container">
                <Modal
                    width={900}
                    visible={displayModal}
                    title={<div>
                        <FormattedMessage id="view_image" />
                        <Button onClick={() => onGoToImageGalleryClick()} style={{ marginLeft: 5 }} type="primary"> <FormattedMessage id="view_image_gallery" /> </Button>
                    </div>}
                    onCancel={onCloseClick}
                    footer={null}
                >
                    <div
                        style={{ maxHeight: `${height * 0.7}px`, overflowY: 'auto', overflowX: 'hidden' }}
                        className="content">
                        {nextUrl ? <div className="button next" onClick={() => this.updateUI(nextUrl)}><Icon className="icon" type="right" /></div> : ''}
                        {prevUrl ? <div className="button prev" onClick={() => this.updateUI(prevUrl)}><Icon className="icon" type="left" /></div> : ''}
                        <Img
                            onClick={() => { }}
                            src={`${_config['IMG_PREFIX']}${modalUrl}`}
                            loader={<Spin> <Img
                                src={ImgHolder}
                            /> </Spin>}
                            unloader={<Img
                                src={ImgHolder}
                            />}
                        />

                    </div>

                </Modal>
            </div>
        )
    }
}


export default injectIntl(FullImageModal);
import { put, takeEvery, select } from 'redux-saga/effects';
// import _config from 'config';
import _helper from '../../utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import { message } from 'antd';
import _ from 'lodash'
import _locale from 'locale';
import { allowPayNow, isAccepted } from './helper'

function* fetchOrderDetail(action) {
	yield put({ type: "ORDER_DETAIL__SET_STATE", state: { isLoading: _.get(action, 'payload.withLoading', true) } });
	try {
		let { orderId, lan, payNow, postingComment } = action.payload
		let url = _apiRoutes['order-detail'] + `/${orderId}/${lan}`; // oid/en

		const orderDetailResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');
		yield put({ type: "ORDER_DETAIL__FETCH_ORDER_DETAIL_SUCCESS", response: { orderDetailResponse }, payload: { ...action.payload } });
		const order = _.get(orderDetailResponse, 'records')
		const gid = _.get(orderDetailResponse, 'records.g_id')
		if (payNow) {
			if (allowPayNow(order)) {
				window.location.href = `/paynow?orderId=${orderId}&gid=${gid}`
			} else {
				yield put({ type: "ORDER_DETAIL__SHOW_ERROR_DIALOG", error: 1 });
			}
		}
		if (postingComment) {
			if (isAccepted(order)) {
				yield put({ type: "ORDER_DETAIL__SHOW_ERROR_DIALOG", error: 2 });
			} else {
				yield put({ type: "ORDER_DETAIL__POST_COMMENT", payload: action.payload });
			}
		}
	} catch (e) {
		yield put({ type: "ORDER_DETAIL__FETCH_ORDER_DETAIL_FAILED", payload: action.payload });
	}
}

function* postComment(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	yield put({ type: "ORDER_DETAIL__SET_STATE", state: { commentsFormLoading: true } });
	try {
		let { orderId, comment } = action.payload
		let url = _apiRoutes['order-comments'];
		let postBody = {
			order_id: orderId,
			comment,
		}

		const commentResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);
		if (commentResponse.RC === 200) {

			// get updated order detail, done here to get all loading done in one area so formik form knows when submission is resolved/completed
			let orderDetailUrl = _apiRoutes['order-detail'];
			let searchParams = {
				oid: orderId,
				lan: 'en'
			}
			orderDetailUrl = _helper.objToParams(orderDetailUrl, searchParams);

			const orderDetailResponse = yield _api.callCheckingAuthentication(_api.executeOauth, orderDetailUrl, 'GET');
			yield put({ type: "ORDER_DETAIL__FETCH_ORDER_DETAIL_SUCCESS", response: { orderDetailResponse }, payload: { ...action.payload } });

		} else if (commentResponse.RC === 400) {
			message.error(trans('invalid_request'))
		} else if (commentResponse.RC === 484) {
			message.error(trans('record_not_found'))
		} else {
			message.error(trans('general_error'))
		}


		yield put({ type: "ORDER_DETAIL__POST_COMMENT_SUCCESS", response: { commentResponse }, payload: action.payload });
	} catch (e) {
		yield put({ type: "ORDER_DETAIL__POST_COMMENT_FAILED", message: e.message, payload: action.payload });
	}
}

function* cancelOrder(action) {
	yield put({ type: "ORDER_DETAIL__SET_STATE", state: { isLoading: true } });
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	yield put({ type: "ORDER_DETAIL__SET_STATE", state: { isLoading: true } });
	try {
		let { orderId } = action.payload
		let url = _apiRoutes['all-in-one-order'];
		const postBody = {
			data: {
				ord_id: orderId,
				action: 'cancel',
			}
		}

		const response = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);
		if (response.RC === 200) {
			yield put({ type: "ORDER_DETAIL__FETCH_ORDER_DETAIL", payload: action.payload });
			message.success(trans('cancel_order_success'))
		} else {
			message.error(trans('cancel_order_failed'))
		}

		yield put({ type: "ORDER_DETAIL__CANCEL_ORDER_SUCCESS", response, payload: action.payload });
	} catch (e) {
		yield put({ type: "ORDER_DETAIL__CANCEL_ORDER_FAILED", message: e.message, payload: action.payload });
	}
	yield put({ type: "ORDER_DETAIL__SET_STATE", state: { isLoading: false } });
}

export default [
	takeEvery('ORDER_DETAIL__FETCH_ORDER_DETAIL', fetchOrderDetail),
	takeEvery('ORDER_DETAIL__POST_COMMENT', postComment),
	takeEvery('ORDER_DETAIL__CANCEL_ORDER', cancelOrder),
];


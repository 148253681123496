import _ from 'lodash';
import _model from 'utils/model';
// import _helper from 'utils/helper';

export function getDefaultState() {
  return {
    pageLoading: true,
    pointsList: [],
    giftCardInstance: new _model.giftCardDetail(),
    giftCardDetailDisplay: false,
    giftCardDetailLoading: false,
  }
}

function fetchGiftCardList(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { giftCardResponse } = action.response;

    if (giftCardResponse.records) {
      finalState.giftCardList = _.cloneDeep(giftCardResponse.records)
    }
  }

  finalState.pageLoading = false;
  return finalState;
}

function fetchGiftCardDetail(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { giftCardDetail } = action.response;

    if (giftCardDetail) {
      finalState.giftCardInstance = new _model.giftCardDetail(giftCardDetail);
    }
  } else {
    finalState.giftCardDetailDisplay = false
  }
  
  finalState.giftCardDetailLoading = false
  return finalState;
}

function clearGiftCardDetail(state, action) {
  let finalState = _.cloneDeep(state);
  finalState.giftCardInstance = getDefaultState().giftCardInstance;
  return finalState;
}

export default {
  'points': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'POINTS__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'POINTS__CLEAR_DATA':
        return getDefaultState();
      case 'POINTS__FETCH_POINTS_LIST_SUCCESS':
        return fetchGiftCardList(state, action, true);
      case 'POINTS__FETCH_POINTS_LIST_FAILED':
        return fetchGiftCardList(state, action, false);
      case 'POINTS__FETCH_POINTS_DETAIL_SUCCESS':
        return fetchGiftCardDetail(state, action, true);
      case 'POINTS__FETCH_POINTS_DETAIL_FAILED':
        return fetchGiftCardDetail(state, action, false);
      case 'POINTS__CLEAR_POINTS_DETAIL':
        return clearGiftCardDetail(state, action);
      default:
        return state;
    }
  }
}

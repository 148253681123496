import _ from 'lodash';
import _helper from 'utils/helper';

export function getDefaultState() {
    return {
        appReady: false,
        forex_rate: {},
        city: null,
        country: null,
    }
}

function initAppConfig(state, action, status) {
    let finalState = _.cloneDeep(state);
    if (status) {
        if (action.response.appConfigResponse && action.response.appConfigResponse.RC == 200) {
            // finalState = action.response.appConfigResponse.records;
            finalState = Object.assign(finalState, action.response.appConfigResponse.records);
            finalState.appReady = true;
        }

        return finalState;
    }
}

export default {
    'app-config': (state = getDefaultState(), action) => {
        switch (action.type) {
            case 'INIT_APP_CONFIG__SUCCESS':
                return initAppConfig(state, action, true)
            case 'INIT_APP_CONFIG__FAILED':
                return initAppConfig(state, action, false)
            default:
                return state;
        }
    }
}

import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _notiHelper from '../../notifications/helper'
import helper from '../../../utils/helper'
import moment from 'moment'

const RELOAD_GAP = 10 * 1000;

//use notif update on chrome
//use timer on safari
class AutoReloader extends React.Component {
    componentDidMount() {
        this.checkUseAutoReloader()
    }

    componentWillUnmount() {
        this.clearReloadTimer()
    }

    componentDidUpdate(prevProps) {
        this.checkUpdateOrder(prevProps)
    }

    checkUseAutoReloader = () => {
        this.setReloadTimer()
    }

    shouldLoad = () => {
        // as per intructed on December 22, 2020 by Michael
        // if not accepted, and req. time is ASAP, you can start refresh right away, and stop 30 min past the order time;
        // if req. time is not ASAP, then start refresh 10 mins before and 30 mins after req. time
        // if it’s accepted, then you only start refresh 10 min before the ETA time, 
        // and stop refresh 20 min after ETA time, 
        // once it’s completed/cancelled/closed..

        //handle status
        // once it’s completed/cancelled/closed..
        const stoppedRefreshedStatus = ["4", "5", "6"];
        const orderStatus = String(this.props.orderStatus);
        const statusValid = !stoppedRefreshedStatus.includes(orderStatus)

        //handle time
        var withinTimeRange = true;
        const orderTime = _.get(this.props, 'order.order_dt')
        const reqTime = _.get(this.props, 'order.exp_dt_start')
        const eta = _.get(this.props, 'order.est_ready_dt')
        const FORMAT_DATE = 'YYYY-MM-DD'
        const FORMAT_TIME = 'HH:mm'
        const FORMAT_TOTAL = `${FORMAT_DATE},${FORMAT_TIME}`
        const orderTimeFormmated = helper.utcToLocal(orderTime, {
            type: FORMAT_DATE, timeFormat: FORMAT_TIME
        })
        const reqTimeFormatted = helper.utcToLocal(reqTime, {
            type: FORMAT_DATE, timeFormat: FORMAT_TIME, local: false
        })
        const etaFormat = helper.utcToLocal(eta, {
            type: FORMAT_DATE, timeFormat: FORMAT_TIME, local: false
        })
        const now = moment()
        if (etaFormat) {
            //accepted
            //refresh 10 min before the ETA time  and stop refresh 20 min after ETA tim
            const start = moment(etaFormat, FORMAT_TOTAL).subtract(10, 'minutes')
            const end = moment(etaFormat, FORMAT_TOTAL).add(20, 'minutes')
            withinTimeRange = now.isBetween(start, end)
        }
        else if (!etaFormat && reqTimeFormatted !== 'ASAP') {
            //not accepted and req. time is not ASAP,
            //start refresh 10 mins before and 30 mins after req. time
            const start = moment(reqTimeFormatted, FORMAT_TOTAL).subtract(10, 'minutes')
            const end = moment(reqTimeFormatted, FORMAT_TOTAL).add(30, 'minutes')
            withinTimeRange = now.isBetween(start, end)
        }
        else if (!etaFormat && reqTimeFormatted === 'ASAP') {
            //not accepted and req. time is ASAP
            //refresh right away, and stop 30 min past the order time;
            const end = moment(orderTimeFormmated, FORMAT_TOTAL).add(30, 'minutes')
            withinTimeRange = now.isBefore(end)
        }
        return statusValid && withinTimeRange
    }

    setReloadTimer = () => {
        this.timer = setInterval(() => {
            if (this.shouldLoad()) {
                this.fetchOrderDetail()
            }
        }, RELOAD_GAP);
    }

    clearReloadTimer = () => {
        if (this.timer) clearInterval(this.timer);
    }

    checkUpdateOrder = (prevProps) => {
        const notiRefresh = !_.isEqual(this.props.notiRefresh, prevProps.notiRefresh)
        if (notiRefresh) this.fetchOrderDetail()
    }

    fetchOrderDetail = (props = this.props) => {
        const orderId = _.get(props, 'match.params.orderId', '')
        const lan = props.lan
        const isAuthenticated = this.props.isAuthenticated
        if (orderId && isAuthenticated) this.props.fetchOrderDetail({ orderId, lan })
    }

    render() {
        return null
    }
}

const mapStateToProps = state => ({
    isAuthenticated: _.get(state, 'auth.isAuthenticated', false),
    notiRefresh: _.get(state, 'notifications.notiRefresh', false),
    lan: _.get(state, "settings.lan", 'en'),
    order: _.get(state, "order-detail.orderDetailInstance.data", {})
})

const mapDispatchToProps = {
    fetchOrderDetail: (params) => ({
        type: "ORDER_DETAIL__FETCH_ORDER_DETAIL",
        payload: { ...params, withLoading: false }
    }),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoReloader));
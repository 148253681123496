export default {
  "common": {
    " ": " ",
    "yes": "Yes",
    "no": "No",
    "confirm": "Confirm",
    "place_order": "Confirm Order",
    "delete_but": "Del",
    "ok": "OK",
    "close": "Close",
    "filter": "Filter",
    "product": "Product",
    "store": "Store",
    "loading": "Loading...",
    "setting": "Settings",
    "back": "Back",
    "dashboard": "Dashboard",
    "notification": "Notifications",
    "warning": "Warning",
    "submit": "Submit",
    "cancel": "Cancel",
    "comment": "**Special Instructions**",
    "tel": "Tel",
    "phone_number": "Phone",
    "phone": "Phone",
    "not_set": "Not set",
    "name": "Name",
    "email": "Email",
    "customer": "Customer",
    "admin": "Admin",
    "current": "Current",
    "newest": "Newest",
    "Skipped": "Skipped",
    "clear_all": "Clear All",
    "from": "From",
    "to": "To",
    "all": "All",
    "save": "Save",
    "disabled": "Disabled",
    "margin": "Margin",
    "top": "Top",
    "bottom": "Bottom",
    "left": "Left",
    "right": "Right",
    "general": "General",
    "general_settings": "General Settings",
    "first_name": "First Name",
    "first_name_required": "First Name is required",
    "last_name": "Last Name",
    "last_name_required": "Last Name is required",
    "status": "Status",
    "new": "new",
    "reload": "Reload",
    "selected": "Selected",
    "items": "items",
    "clear_fliter": "Clear Filter",
    "search": "Search",
    "enter_password": "Enter password",
    "unlock": "Unlock",
    "qty": "Qty",
    "gift_cards": "Gift cards",
    "view_all": "View all",
    "profile": "Profile",
    "nick_name": "Nick name",
    "verification_code": "Verification code",
    "get_verification_code": "Get verification code",
    "edit": "Edit",
    "delete": "Del",
    "action": "Action",
    "send": "Send",
    "add_comment": "Add comment",
    "receiver": "Receiver",
    "email_phone": "Email/Phone",
    "change": "Change",
    "description": "Description",
    "remove": "Remove",
    "reorder": "Order Again",
    "enter_coupon_code": "Enter Coupon Code",
    "apply": "Apply",
    'complete_oreder': "Complete order",
    'change_order': "Change Order",
    "category": "Category",
    "expired": "Expired",
    "optional": "Optional",
    "about": "About",
    "highlights": "Highlights",
    "customer_reviews": "Customer Reviews",
    "relevant_reviews": "Relevant Reviews",
    "ratings": "ratings",
    "reviews": "reviews",
    "buy": "Buy",
    "quantity": "Quantity",
    "out_of_stock": "Out of stock",
    "refresh": "Refresh",
  },
  "common_order_status": {
    "pending": "Pending",
    "processing": "Processing",
    "payment_review": "Payment Review",
    "completed": "Completed",
    "canceled": "Cancelled",
    "closed": "Closed",
    "pending_payment": "Pending Payment",
    "holded": "Holded",
  },
  "common_shipping_method": {
    "shipping_method": "Order Typed",
    "delivery_option": "Order Type",
    "order_type": "Order Type",
    "delivery": "Delivery",
    "pickup": "Pick up",
    "eatin": "Dine In",
    "instore": "In-Store",
    "takeout": "Take out",
    "dine_in": "Dine In",
    "in_store": "In Store",
    "flatrate": "Flat rate",
    "freeShipping": "Free Shipping",
    "how": "How",
  },
  "common_payment_method": {
    "payment_method": "Payment Method",
    "pay_later": "Pay later with card",
    "cash": "Cash",
    "credit_card": "Credit card",
    "giftcard": "Gift card",
    "points": "Points",
    "points_balance": "Balance",
    "points_and_giftcard": "Gift card & Points",
    "wechat_pay": "Wechat Pay",
    "paypal_express": "PayPal Express",
    "alipay": "Alipay",
    "union_pay": "Union Pay",
    "union_pay_express": "Union Pay Express",
    "free": "Free",
    "remaining_balance": "Remaining Points",
    "redeemed": "Redeem",

    "card_number": "Card Number",
    "name_on_card": "Name on Card",
    "cvv": "CVV",

    "cvv_descpt": 'a 3 to 4 digit number from the back of your card',
    "cash_despcrition": 'Order is paid by cash',
    "pay_later_despcrition": 'Order will be paid later with card',
    "wechat_pay_despcrition": 'After clicking \'Confirm\', you will be directed to Wechat pay page to complete your purchase',
    "paypal_expres_despcrition": 'After clicking \'Confirm\', you will be directed to Paypal page to complete your purchase',
    "alipay_despcrition": 'After clicking \'Confirm\', you will be directed to AliPay page to complete your purchase',
    "union_pay_despcrition": 'After clicking \'Confirm\', you will be directed to Union Pay page to complete your purchase',
    "union_pay_express_despcrition": 'After clicking \'Confirm\', you will be directed to Union Pay Express page to complete your purchase',
  },
  "common_price": {
    "total": "Total",
    "subtotal": "Subtotal",
    "discount": "Discount",
    "summary": "Summary",
    "store_discount": "Store Discount",
    "coupon_discount": "Coupon Discount",
    "shipping_and_handling": "Shipping & Handling",
    "total_before_tax": "Total Before Tax",
    "tax": "Tax",
    "order_total": "Order Total",
    "gift_card_payment": "Gift card Payment",
    "points_payment": "Points Payment",
    "total_paid": "Total Paid",
    "total_due": "Total Due",
    "tips": "Tip Your Order",
    "delivery_fee": "Delivery Fee",
    "max_amount": "Max Amount",
    "min_amount": "Min Amount",
    "balance": "Balance",
    "amt": "Amount",
    "price": "Price",
    "item(s)": "Item(s)",
    "shopping": "Shopping",
    "billing_shipping": "Billing & Shipping",
    "done": "Done",
    "customer_info": "Customer Info",
    "delivery_info": "Delivery Info",
    "payment_info": "Payment Info",
    "other_payment_info": "Other Payment Info",
  },
  "common_†ime": {
    "date": "Date",
    "today": "Today",
    "tomorrow": "Tomorrow",
    "yesterday": "Yesterday",
    "this_week": "This week",
    "last_week": "Last week",
    "this_month": "This Month",
    "last_month": "Last Month",
    "last": "Last",
    "next": "Next",
    "now": "Now",
    "year": "Year",
    "month": "Month",
    "this_week": "This week",
    "last_week": "Last week",
    "this_month": "This Month",
    "last_month": "Last Month",
    "select_date": "Select date",
    "select_time": "Select time",
    "expiry_date": "Expiry date",
    "delivery_time": "Delivery Time",
    "pickup_time": "Pickup Time",
    "select_delivery_time": "Select Delivery Time",
    "select_pickup_time": "Select Pick-up Time",
    "due_time": "Due Time",
    "when": "When",
    "requested_time": "Requested time",
    "scheduled_time": "Scheduled time",
  },
  "common_language": {
    "language": "Language",
    "en": "English",
    "zh": "简体中文",
    "zh-Hant": "繁體中文",
    "jp": "日本語",
  },
  "common_address": {
    "add_address": "Add address",
    "address": "Address",
    "input_address": "Input Address*",
    "street": "Street",
    "street_name": "Street",
    "unit": "Unit",
    "buzzer": "Buzzer",
    "unit_optional": "Unit (Optional)",
    "buzzer_optional": "Buzzer (Optional)",
    "city": "City",
    "province": "Province",
    "country": "Country",
    "postal_code": "Postal Code",
    "postal_code_zip": "Postal Code / Zip Code*",
    "quick_map_search": "Quick Map Search",
    "map_search": "Map search",
    "shipping_address": "Shipping Address",
    "billing_address": "Billing Address",
    'set_as_default': "Set as default",
    "enter_a_location": "Input your street address",
    "location": "Location",
    "van": "Vancouver",
    "vic": "Victoria",
    "tor": "Toronto",
    "seattle": "Seattle",
    "choose_city": "Choose City",
    "current_location": "Current Location",
    "use_info_from_acc": "Use info from account",
  },
  "common_error": {
    "x_is_invalid": "Invalid {x}",
    "unable_to_load_any_data": "Unable to load data",
    "unstable_network": "Slow or unstable network, please check your network connection",
    "logout_force_message": "Sorry, your account has been logged in from another device or has timed out. Please login again.",
    "incorrect_password": "Incorrect password",
    'country_code_error': 'Country code error',
    'incorrect_username_password': 'Incorrect username/password',
    'verification_code_expired': 'Verification code expired',
    'verifcation_code_request_overlimit': 'Verification code request overlimit',
    'incorrect_verification_code': 'Incorrect verification code',
    "error_fetch_user_profile": "Failed to fetch user profile",
    "login_failed": "Failed to login",
    "profile_update_success": "Update profile success",
    "email_update_success": "Update email success",
    "phone_update_success": "Update phone number success",
    "failed_fetch_gift_card_detail": "Failed to fetch gift card detail",
    "gift_card_transfer_success": "Tranfered gift card success",
    "gift_card_transfer_fail": "Failed to tranfer gift card",
    "invalid_request": "Invalid request",
    "record_not_found": "Record not found",
    "general_error": "Unexpected error occured, please try again later",
    "failed_fetch_points_detail": "Failed to fetch points details",
    "profile_update_failed": "Failed to update user profile",
    "email_update_failed": "Failed to update email",
    "phone_update_failed": "Failed to update phone number",
    "get_verification_code_success": "Verification code has been sent to your phone.",
    "verification_too_many_requests": "Verification code was requested too many times, please try again later",
    "invalid_phone_number": "Invalid phone number",
    "invalid_table_number": "Please input a number",
    "invalid_party_size": "Please input a number",
    "email_required": "Email is required",
    "password_required": "Password is required",
    "phone_required": "Phone is required",
    "vc_required": "Verification code is required",
    "error": "Error",
    "coupon_invalid": "Invalid coupon",
    "coupon_valid": "Valid coupon",
    "invalid_coupon": "Coupon code {couponCode} is invalid",
    "coupon_applied": "Coupon {couponCode} has been applied",
    "distance_exceeded": "Distance Exceeded",
    "exceed_delivery_distance": "The selected delivery address exceeds the restaurants maximum delivery distance of {delivery_distance}km. Please pick another delivery address.",
    "invalid_address": "Invalid Address",
    "item_error": "A error has occurred. Your shopping cart has been cleared. Please add some items and try checking out again.",
    "shipping_country_text": "Shipping available for addresses within the following countries: {countries}",
    "item_oos": "Sorry, item(s) {oos_items} are out of stock. Please go back to the store page and check out again.",
    "invalid_shipping_method": "The shipping method is not valid. Please try another shipping method",
    'please_input_phone_number': 'Please input Phone number',
    'please_input_first_name': 'Please input First Name',
    'please_input_last_name': 'Please input Last Name',
    'please_input_at_least_two_characters': 'Please input at least two characters',
    'please_input_last_name': 'Please input last name',
    'please_input_shipping_method': 'Please input shipping method',
    "please_input_sub_shipping_method": "Please input shipping method",
    'please_input_shipping_addresss': 'Please input shipping address',
    'please_input_table_number': 'Please input table number',
    'please_input_payment_method': 'Please input payment method',
    "please_select_payment_method": "Please select a payment method",
    'please_input_valid_credit_card': 'Please input valid credit card',
    'please_input_billing_address': 'Please input billing address',
    "invalid_credit_card": "Invalid credit card. Please pick another payment method",
    "unsuccessful_checkout": "Unsuccessful check out. Please try again.",
    "invalid_request": "Unsuccessful check out. Invalid request.",
    "price_over_limit": "Unsuccessful check out. The price is over the payment limit.",
    "invalid_shp_mtd": "Unsuccessful check out. The shipping method is not valid. Please try another shipping method",
    "invalid_pay_mtd": "Unsuccessful check out. The payment method is not valid. Please try another payment method.",
    "new_order_created": "New Order Created",
    "checkout_success": "Thank you for your business, your order has been created: #{order_id}. You will be notified when the order's status is updated.",
    "pay_success": "Thank you for your payment, a new order has been created: #{order_id} for tracking this payment. You will be notified when the order's status is updated.",
    "please_input_belows": "Please enter below missing fields",
    "invalid_expiry": "Invalid expiry date",
    "unable_to_change_here": "Unable to change here",
    "please_select_on_previous_page": "Please select on previous page",
    "invalid_card_number": "Invalid card number",
    "invalid_zipcd": "Invalid postal code",
    "click_one_more_time_to_deselect": "Click on more time to deselect",
    "paypal_payment_success_title": "Payment Successful",
    "paypal_payment_success_text": "Your payment has been received. You will be redirected to the order page.",
    "error_payment_failed": "Payment Failed",
    "internal_server_error": "Sorry, there has been a error, please try again later or contact the vendor directly.",
    "unexpected_error": "An unexpected error has occurred, please try again later",
    "no_items_error": "There are no items added to your shopping cart. Please add some items and try checking out again.",
    "giftcard_pays_order_total": "Selected gift card already pays for the order total. No additional payment methods needed.",
    "points_pays_order_total": "Your points already pays for the order total. No additional payment methods needed.",
    "giftcard_points_pays_order_total": "Your gift card and points already pays for the order total. No additional payment methods needed.",
    "digit_only": "Please enter digits only",
    "please_selected_belows": "Please complete all selections:",
    "please_select_location_from_the_list": "Please select a street address from the list.",
    "alert_password_reset_success": "Your password has been updated successfully, you may now login with the new password.",
    "alert_phone_vc_not_match": "Incorrect verification code, please try again.",
    "alert_phone_not_found": "This phone number has not been registered yet!",
    "alert_email_not_found": "This Email has not been registered yet!",
    "alert_password_reset_email_send": "Reset Password email has been sent",
    "alert_email_length": "Invalid email",
    "alert_password_min_length": "Password must be at least 6 characters",
    "x_is_required": " is a required field",
    "location_permission_is_blocked_service_may_not_perform_correctly": "Location permission is blocked, this service may be affected. ",
    "order_payment_status_error": "The store has accepted this order. Please pay in person.",
    "order_comment_status_error": "The store has started preparing this order; please contact the store for any change request. Tel: {phone}.",
  },
  "common_acccount": {
    "my_account": "My account",
    "address_book": "Address book",
    "my_assets": "My assets",
    "log_in": "Log in",
    "logout": "Log out",
    "logout_confirm_message": "Are you sure you want to logout?",
    "login_with_text": "Login via Text Message",
    "password": "Password",
    "remember_me": "Remember me",
    "login_with_account": "Login with account",
    "sign_up": "Sign Up",
    "sign_up_biz": "Sign up as a Business",
    "store_mngt": "Store Management",
    "change_by_email": "By Email",
    "change_by_text_message": "By Text Message",
    "change_by_existing_password": "By Existing Password",
    "set_password": "Set Password",
    "new_password": "New Passowrd",
    "old_password": "Old Password",
    "confirm_password": "Confirm Password",
    "change_password": "Change Password",
    "update_password": "Update Password",
    "code": "Code",
    "request_pass_reset": "Request Password Reset",
    "secure_connection": "Information is sent over a secure connection",
  },
  "common_country": {
    "canada": "Canada",
    "united_states": "United States",
    "china": "China",
    "south_korea": "South Korea"
  },
  "common_order": {
    "no_orders": "No orders",
    "order_id": "Order#",
    "recent_orders": "Recent orders",
    "order_history": "Order history",
    "order_date": "Order date",
    "order_detail": "Order Detail",
    "order_amount": "Order Amount",
    "ordered_on": "Ordered on",
    "order_pending_descrip": "Waiting for store to confirm order",
    "order_processing_descrip": "Order is being prepared",
    "order_complete_descript": "Order has been completed",
    "order_cancelled_descript": "Order has been canceled",
    "table_number": "Table number",
    "party_size": "Party size",
    "table_info": "Table Info",
    "server": "Server",
    "surcharge": "Surcharge",
    "store_surcharge": "Online Surcharge Fee",
    "order_surcharge": "Order Surcharge",
    "item_total": "Item(s) Total",
    "surcharge_paid": "Surcharge (Paid)",
    "surcharge_unpaid": "Surcharge (Unpaid)",
    "related_id": "Related Order #",
    "related": "Related",
    'surcharege_paid_message': 'The surcharge amount is not included in this order. Please refer to order #{id}',
    "surcharge_paid_to_message": "This order is to pay for the surcharge of order #{id}",
    "pay_now": "Pay Now",
    "please_pay_now": "Order is not paid yet, you may pay now.",
    "are_you_sure_cancel_order": "Are you sure you want to cancel the order?",
    "please_pay_by_x": "Your order will be held for {x}. Pay now to prevent cancellation",
    "cancel_order_success": "Your order has successfully been cancelled.",
    "cancel_order_failed": "Failed to cancel order, please try again later",
  },
  "common_store": {
    "stores": "Stores",
    "view_stores": "View stores",
    "store_name": "Store name",
    "to_store": "To store",
    "to_product": "To product",
    "contact_store": "Contact store",
    "bookmarks": "Bookmarks",
    "gift_card_detail": "Gift card detail",
    "my_gift_cards": "My gift cards",
    "gift_card_used": "Gift card used",
    "gift_card_created": "Gift card created",
    "gift_card_refunded": "Gift card refunded",
    "gift_card_transferred": "Gift card tranferred",
    "gift_card_received": "Gift card received",
    "points": "Points",
    "back_to_store": "Back to store",
    "go_to_order": "Go to order",
  },
  "common_product": {
    "products": "Products",
  },
  "common_group_sale": {
    'there': 'there',
    "welcomeMessage1": "Hey {who},",
    "welcomeMessage2": "treat yourself to a great deal today",
    "result_for": "result for '{keyword}'",
    "select_what": "Select {keyword}",
    "pop_on_groupter": "Popular on Goopter",
    "brower_by_cat": "Browse By Category",
    "goods": "Goods",
    "things_to_do": "Things to do",
    "beauty_spas": "Beauty & Spas",
    "restaurants": "Restaurants",
    "travel": "Travel",
    "sale": "Sale",
    "health_fitness": "Health & Fitness",
    "food_drinks": "Food & Drinks",
    "breweries_wineries_distileries": "Breweries, Wineries & Distilleries",
    "entertainment": "Entertainment",
    "collectibles": "Collectibles",
    "books": "Books",
    "face_skin": "Face & Skin",
    "cosmetic_procedures": "Cosmetic Procedures",
    "gym": "Gym",
    "sport": "Sport",
    "tour": "Tour",
    "hotel": "Hotel",
    "personalized": "Personalized",
    "home_garden": "Home & Garden",
    "jewelry_watches": "Jewelry & Watches",
    "fashion_jewelry": "Fashion Jewelry",
    "fine_metal_jewelry": "Fine Metal Jewelry",
    "watches": "Watches",
    "home_services": "Home Services",
    "cleaning_services": "Cleaning Services",
    "flooring": "Flooring",
    "automotive": "Automotive",
    "interior_accessories": "Interior Accessories",
    "car_electronics": "Car Electronics",
    "local_services": "Local Services",
    "fun_leisure": "Fun & Leisure",
    "sports_outdoors": "Sports & Outdoors",
    "all_deal": "All deal",
    "discount_amount": "Discount amount",
    "amount_sold": "Amount sold",
    "sort_by": "Sort by",
    "best_match": "Best Match",
    "distance": "Distance",
    "ranking": "Ranking",
    "price_ascending": "Price Ascending",
    "price_descending": "Price Descending",
    "discount_greater": "Discount greater",
    "bought": "bought",
    "OFF": "OFF",
    "view_deal": "View Deal",
    "viewed_today": "viewed today",
    "what_you_will_get": "What you will get",
    "the_fine_print": "The Fine Print",
    "customer_photos": "Customer Photos",
    "view_image": "View image",
    "view_image_gallery": "View image gallery",
    "see_all_reviews": "See all reviews",
    "view_all_img": "View all images",
    "show_less": "Show less",
    "see_all_hours": "See all hours",
    "app": "App",
    "history": "History",
    "service": "Service",
    "business": "Business",
    "favorite": "Favorite",
  },
  "common_checkout": {
    'items_not_available': 'Items Not Available',
    'items_not_available_message': 'The following item(s) are not available now, and will be removed from your cart: {br} {items}'
  },
  "common_self_checkout": {
    "self_checkout": "Self Checkout",
    "amount": "Amount",
    "add_comment": "Add a Comment",
    "contact_merchant": "Contact Merchant",
    "powered_by_goopter": "Powered by Goopter",
    "pay": "Pay",
    "self_checkout_disabled": "Self checkout is not available for this store.",
    "surcharge_for": "Surcharge for order: #{oid}",
  },
}
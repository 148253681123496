function setAuth(params) {
  return {
    type: "AUTH__SET_AUTH",
    payload: params
  }
}

function checkAuthStatus() {
  return {
    type: "AUTH__CHECK_AUTH_STATUS"
  }
}

function clearAuthData() {
  return {
    type: "AUTH__CLEAR_AUTH_DATA",
  }
}

function fetchUserProfle() {
  return {
    type: "AUTH__FETCH_USER_PROLILE",
  }
}

export default {
  setAuth,
  checkAuthStatus,
  clearAuthData,
  fetchUserProfle
}
import { put, takeEvery, select } from 'redux-saga/effects';
// import _config from 'config';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';

export const selectWithOrderListState = (state) => {
	return state['order-list']
}

function* fetchOrderList(action) {
	try {
		let { page, limit, clear } = action.payload
		let url = _apiRoutes['order-list'];

		let selectWithOrderList = yield select(selectWithOrderListState);

		let oldSearch = selectWithOrderList && selectWithOrderList.searchConditions && !clear ? selectWithOrderList.searchConditions : {}

		let updatedParams = Object.assign({}, oldSearch, action.payload);

		let baseParams = {
			page: 1,
			limit: 20
		}

		let searchParams = Object.assign({}, baseParams, updatedParams);

		url = _helper.objToQuery(url, searchParams);

		if (_helper.getIndependentDomain()) {
			url += `&domain=${_helper.getIndependentDomain()}`
		}

		const orderListResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');
		yield put({ type: "ORDER_LIST__FETCH_ORDER_LIST_SUCCESS", response: { orderListResponse, searchConditions: searchParams, }, payload: { ...action.payload } });
	} catch (e) {
		yield put({ type: "ORDER_LIST__FETCH_ORDER_LIST_FAILED", message: e.message, action: action.payload });
	}
}


export default [
	takeEvery('ORDER_LIST__FETCH_ORDER_LIST', fetchOrderList),
];


import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'
import './amount-display-section.scss'

class AmountDisplaySection extends React.Component {

    getAmount = () => {
        return _.get(this.props, 'state.numberPadValue', 0)
    }

    getCurrency = () => {
        return _.get(this.props, 'state.store.currency', 'CAD')
    }

    renderTitle = () => {
        return (
            <div className="add-comment-section-title">
                <FormattedMessage id="amount" />
            </div>
        )
    }

    renderAmountText = () => {
        return (
            <div className="amount-display-amount-text">
                <span className="amount-display-currency-text">
                    {this.getCurrency()}$
                </span>
                <span>
                    {this.getAmount()}
                </span>
            </div>
        )
    }


    render() {
        return (
            <div className="amount-display-section-container">
                {this.renderTitle()}
                {this.renderAmountText()}
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    lan: state.settings.lan,
    state: _.get(state, 'self-checkout', {})
})

const mapDispatchToProps = ({

})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AmountDisplaySection))
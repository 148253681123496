import { put, takeEvery, select } from 'redux-saga/effects';
// import _config from 'config';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import { message } from 'antd';

import _locale from 'locale';

function* fetchGiftCardList(action) {
	try {
		yield put({ type: "GIFT_CARD__SET_STATE", state: { pageLoading: true } })
		let { status } = action.payload
		let url = _apiRoutes['gift-card'];

		let searchParams = {
			page: -1,
			limit: -1,
			// status: 0, // only show active gift cards
		}

		if (status != null) {
			searchParams.status = 0
		}

		url = _helper.objToQuery(url, searchParams);

		const giftCardResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');
		yield put({ type: "GIFT_CARD__FETCH_GIFT_CARD_LIST_SUCCESS", response: { giftCardResponse }, payload: { ...action.payload } });
	} catch (e) {
		yield put({ type: "GIFT_CARD__FETCH_GIFT_CARD_LIST_FAILED", message: e.message, payload: action.payload });
	}
}

function* fetchGiftCardDetail(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	try {
		yield put({ type: "GIFT_CARD__SET_STATE", state: { giftCardDetailLoading: true } })
		let { id } = action.payload;

		let detailUrl = _apiRoutes['gift-card'] + `/get/` + id;

		let historyUrl = _apiRoutes['gift-card-history'];
		let historyParams = {
			code: id,
			limit: -1,
			page: 1,
		}
		historyUrl = _helper.objToQuery(historyUrl, historyParams);

		const detailResponse = yield _api.callCheckingAuthentication(_api.executeOauth, detailUrl, 'GET');
		const historyResponse = yield _api.callCheckingAuthentication(_api.executeOauth, historyUrl, 'GET');


		if (detailResponse && historyResponse && detailResponse.records && historyResponse.records && historyResponse.records) {
			let giftCardDetail = {
				...detailResponse.records,
				history: historyResponse.records
			}

			yield put({ type: "GIFT_CARD__FETCH_GIFT_CARD_DETAIL_SUCCESS", response: { giftCardDetail }, payload: { ...action.payload } });
		} else {
			yield put({ type: "GIFT_CARD__FETCH_GIFT_CARD_DETAIL_FAILED", payload: action.payload });
			message.error(trans('failed_fetch_gift_card_detail'))
		}
	} catch (e) {
		message.error(trans('failed_fetch_gift_card_detail'))
		yield put({ type: "GIFT_CARD__FETCH_GIFT_CARD_DETAIL_FAILED", message: e.message, payload: action.payload });
	}
}

function* transferGiftCard(action) {
	const settingsState = yield select((state) => { return state.settings })
	const lan = settingsState.lan
	let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

	try {
		let { receiver, comment, code } = action.payload;
		let url = _apiRoutes['gift-card'];

		let postBody = {
			receiver,
			comment,
			code,
		}

		const giftCardResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);

		if (giftCardResponse && giftCardResponse.RC === 200) {
			message.error(trans('gift_card_transfer_success'))
			yield put({ type: "GIFT_CARD__FETCH_GIFT_CARD_LIST" })
			yield put({ type: "GIFT_CARD__TRANSFER_GIFT_CARD_SUCCESS", response: { giftCardResponse }, payload: { ...action.payload } });
		} else {
			message.error(trans('gift_card_transfer_fail'))
			yield put({ type: "GIFT_CARD__TRANSFER_GIFT_CARD_FAILED", payload: action.payload });
		}
	} catch (e) {
		message.error(trans('gift_card_transfer_fail'))
		yield put({ type: "GIFT_CARD__TRANSFER_GIFT_CARD_FAILED", message: e.message, payload: action.payload });
	}
}

export default [
	takeEvery('GIFT_CARD__FETCH_GIFT_CARD_LIST', fetchGiftCardList),
	takeEvery('GIFT_CARD__FETCH_GIFT_CARD_DETAIL', fetchGiftCardDetail),
	takeEvery('GIFT_CARD__TRANSFER_GIFT_CARD', transferGiftCard),
];


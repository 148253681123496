import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

//Icons from Icons8
import FoodAndDrinksIcon from 'images/catIcon_food_and_drinks.png';
import Entertainmenticon from 'images/catIcon_entertainment.png';
import BeautyAndSpaIcon from 'images/catIcon_beauty_and_spa.png';
import HealthAndFitnessIcon from 'images/catIcon_health_and_fitness.png';
import TravelIcon from 'images/catIcon_travel.png';
import ShoppingIcon from 'images/catIcon_shopping.png';
import HomeServicesIcon from 'images/catIcon_home_services.png';
import AutoMotiveIcon from 'images/catIcon_automotive.png';
import LocalServicesIcon from 'images/catIcon_local_services.png';

import './_catListBar.scss'

class CatListBar extends Component {

  getCatList = () => {
    let {
      catList
    } = this.props;

    let catIcons = [
      FoodAndDrinksIcon,
      Entertainmenticon,
      BeautyAndSpaIcon,
      HealthAndFitnessIcon,
      TravelIcon,
      ShoppingIcon,
      HomeServicesIcon,
      AutoMotiveIcon,
      LocalServicesIcon,
    ]

    catList.map((cat, index, arr) => {
      cat.icon = catIcons[index]
    })

    return catList
  }

  render() {

    let {
      onCatClick
    } = this.props

    return (
      <div className="category-list-bar-container">
        <div className="title"><FormattedMessage id="brower_by_cat" /></div>
        <div className="list">
          {this.getCatList() && this.getCatList().map((cat, i) => {
            return (
              <div key={`group-cat-bar-${i}`} className="item" onClick={() => onCatClick(cat)}>
                <div className="icon-parent center">
                <img className="icon" style={{ fontSize: 35 }} src={cat.icon} /> </div>
                <div className="name center"> <FormattedMessage id={cat.name} /> </div>
              </div>
            )
          }
          )}
        </div>

      </div>
    )
  }
}


export default injectIntl(CatListBar);
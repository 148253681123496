import _ from 'lodash'
import helper from '../../utils/helper'

function updateCartListToOld(currentCart, gid) {
    const oldCart = helper.getLocalStorage(`g${gid}`)
    const oldShoppingCart = _.get(oldCart, 'shoppingCart', [])
    if (!Array.isArray(oldShoppingCart)) return {}
    const shoppingCart = _.intersectionWith(oldShoppingCart, currentCart, (oldcartObj, currentCartObj) => {
        return String(_.get(oldcartObj, 'pid')) === String(_.get(currentCartObj, 'pid'))
    })
    return {
        shoppingCart,
        coupon: _.get(currentCart, 'coupon', '')
    }
}

export default {
    updateCartListToOld
}
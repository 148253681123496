import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import { List, Pagination, Row, Col, Icon, Spin, Empty, BackTop } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import GroupSaleLayout from 'containers/groupsale-page/page-components/groupsaleLayout'
import SortButton from 'containers/groupsale-page/page-components/sortButton'
import GroupSaleCell from 'containers/groupsale-page/page-components/grouponCell/index.jsx'
import CatTreeList from 'containers/groupsale-page/page-components/catTreeList'
import GroupsaleFilter from 'containers/groupsale-page/page-components/groupsaleFilters/index.jsx'
import LargeGrouponCell from 'containers/groupsale-page/page-components/largeGrouponCell'

import './_groupsaleSearch.scss'
import _actions from 'containers/groupsale-page/actions.jsx'

class GroupSaleSearchPage extends Component {

    state = {
        current: 1,
        urlParams: {},
        leftColSpan: 6,
        rightColSapn: 18,
    }

    onPaginationChange = (page) => {
        this.setState({
            current: page,
        });
        this.fetchGroupSaleList({ page: page })
    }

    componentDidMount() {
        this.getParamsAndFetchData()
    }

    componentDidUpdate(prepProp, prepState) {
        if (prepProp.routeSearch != this.props.routeSearch) {
            this.getParamAndReloadList()
        }

        if (prepProp.lan != this.props.lan) {
            this.getParamsAndFetchData()
        }
    }

    getParamsAndFetchData() {
        this.initGroupSaleData()
    }

    getParamAndReloadList() {
        let urlParams = queryString.parse(this.props.location.search);
        this.setState({ urlParams: urlParams });
        this.fetchGroupSaleList({
            urlParams: urlParams
        })
    }

    initGroupSaleData() {
        let { initGroupSaleBrowser, lan, userLat, userLon, appConfigState } = this.props;
        initGroupSaleBrowser({
            lan: lan,
            lat: userLat ? userLat : appConfigState.lat,
            lon: userLon ? userLon : appConfigState.lon,
        });
        this.getParamAndReloadList()
    }

    fetchGroupSaleList(params = {}) {
        let defaultParams = {
            urlParams: this.state.urlParams,
            page: 1,
        }
        const rParams = Object.assign({}, defaultParams, params);
        let { fetchGroupSaleList, lan, userLat, userLon, appConfigState } = this.props;

        let { query, category, category2, category3, discount, amtSold, sort, lat, lon } = rParams.urlParams;

        //filter logics
        let filter = this.getFilterFlag(discount, amtSold);

        fetchGroupSaleList({
            lan: lan,
            lat: lat ? lat : userLat ? userLat : appConfigState.lat,
            lon: lon ? lon : userLon ? userLon : appConfigState.lon,
            text: query,
            page: rParams.page,
            sortId: sort,
            catId: category3 ? category3 : category2 ? category2 : category,
            filter: filter
        })
    }

    getFilterFlag(discount, amtSold) {
        let filter = '';
        if (discount || amtSold) {
            filter += '0,';
            if (discount) {
                if (discount > 0 && discount <= 20) {
                    filter += '1000,';
                }
                else if (discount > 20 && discount <= 30) {
                    filter += '0100,';
                }
                else if (discount > 30 && discount <= 40) {
                    filter += '0010,';
                }
                else if (discount > 40 && discount <= 50) {
                    filter += '0001,';
                }
                else if (discount > 50) {
                    filter += '0000,';
                }
            }
            else {
                filter += '0,';
            }
            if (amtSold) {
                if (amtSold == '>50') {
                    filter += '1000';
                }
                else if (amtSold == '>200') {
                    filter += '0100';
                }
                else if (amtSold == '>500') {
                    filter += '0010';
                }
                else if (amtSold == '>1000') {
                    filter += '0000';
                }
            }
        }
        return filter;
    }

    getActiveCat = () => {
        let { catList } = this.props
        let { category, category2, category3 } = this.state.urlParams;

        let activeCat = {}

        if (!catList) return

        if (category3) {
            let categoryParent = catList.find(cat => { return cat.id == category })
            let categoryChildren = categoryParent && categoryParent.children.find(cat => { return cat.id == category2 })
            activeCat = categoryChildren && categoryChildren.children.find(cat => { return cat.id == category3 })
        } else if (category2) {
            let categoryParent = catList.find(cat => { return cat.id == category })
            activeCat = categoryParent && categoryParent.children.find(cat => { return cat.id == category2 })
        } else {
            activeCat = catList.find(cat => { return cat.id == category })
        }

        return activeCat;

    }

    pushToOtherPage = (params) => {
        let { push } = this.props
        let { searchValue, lat, lon, urlParams } = this.state
        let url = '/deals/browse'
        if (urlParams || params) {
            url += '?'
        }
        //add url original logics 
        if (urlParams) {
            if (urlParams.query && !params.query) {
                url += `&query=${_helper.reformatSearchString(urlParams.query)}`
            }
            if (urlParams.lat && !lat) {
                url += `&lat=${_helper.reformatSearchString(urlParams.lat)}`
            }
            if (urlParams.lon && !lon) {
                url += `&lon=${_helper.reformatSearchString(urlParams.lon)}`
            }
            if (urlParams.category && params.category && params.category.level > 1) {
                url += `&category=${urlParams.category}`
            }
            if (urlParams.category && params.category && params.category.level > 2) {
                url += `&category2=${urlParams.category2}`
            }
            if (urlParams.category && !params.category && !params.categoryClear) {
                if (urlParams.category) {
                    url += `&category=${urlParams.category}`
                }
                if (urlParams.category2) {
                    url += `&category2=${urlParams.category2}`
                }
                if (urlParams.category3) {
                    url += `&category3=${urlParams.category3}`
                }
            }
            if (urlParams.amtSold && !params.amtSold) {
                url += `&amtSold=${urlParams.amtSold}`
            }
            if (urlParams.discount && !params.discount && !params.discountClear) {
                url += `&discount=${urlParams.discount}`
            }
            if (urlParams.sort && !params.sort) {
                url += `&sort=${urlParams.sort}`
            }
        }
        //take more and add to url logics
        if (params) {
            if (params.query && params.query.replace(/\s/g, '').length) {
                url += `&query=${_helper.reformatSearchString(params.query)}`
            }
            if (params.category) {
                if (params.category.level == 1) {
                    url += `&category=${params.category.id}`
                } else {
                    url += `&category${params.category.level}=${params.category.id}`
                }
            }
            if (params.amtSold && params.amtSold.replace(/\s/g, '').length) {
                url += `&amtSold=${_helper.reformatSearchString(params.amtSold)}`
            }
            if (params.discount) {
                url += `&discount=${params.discount}`
            }
            if (params.sort) {
                url += `&sort=${params.sort}`
            }
        }
        if (searchValue || lat || lon) {
            if (searchValue) {
                url += `query=${_helper.reformatSearchString(searchValue)}`
            }
            if (lat) {
                url += `&lat=${lat}`
            }
            if (lon) {
                url += `&lon=${lon}`
            }
        }
        push(url)
    }

    pushToDetail = (id) => {
        let {
            push
        } = this.props
        push(`/deals/details/${id}`)
    }

    render() {
        let {
            intl,
            userProfile,
            listLoading,

            gsList,
            gsShortcut,
            gsListPaging,
            catList,
            sortList,

        } = this.props;

        let {
            urlParams,
            leftColSpan,
            rightColSapn,
        } = this.state

        let { query, category, category2, category3, discount, amtSold, sort } = urlParams;

        let { formatMessage } = intl;

        let activeCat = this.getActiveCat()

        return [
            <GroupSaleLayout
                onShortCutClick={(value) => this.pushToOtherPage({ query: value })}>
                <div className="group-sale-search-container">
                    <div className="pull-page-header">
                        <div className="brower-header">
                            <div className="title">{query ? formatMessage({ id: "result_for" }, { keyword: query })
                                : activeCat && activeCat.name ? formatMessage({ id: activeCat.name }) : <FormattedMessage id="all_deal" />}</div>
                            <div className="sort">
                                <SortButton currentSort={sort} onSortButtonClick={(value) => this.pushToOtherPage({ sort: value })} />
                            </div>
                        </div>
                        <div className="dotted-line"></div>
                    </div>
                    <div>
                        <Row>
                            <Col className="left-col" span={leftColSpan}>
                                <div className="reset-all-container">
                                    {query ?
                                        <div className="reset-pill" onClick={() => this.pushToOtherPage({ query: " " })}>
                                            <Icon className="icon" type="search" />
                                            <span className="content">{query}</span>
                                            <Icon className="icon" type="close" />
                                        </div> : ''}
                                </div>
                                <div>
                                    <CatTreeList
                                        catListData={catList}
                                        level1Cat={category}
                                        level2Cat={category2}
                                        level3Cat={category3}
                                        activeCat={activeCat}
                                        onCatClick={(value) => this.pushToOtherPage({ category: value })}
                                        onResetClick={() => this.pushToOtherPage({ categoryClear: true })}
                                    />
                                    <GroupsaleFilter
                                        currentDiscount={discount}
                                        currentAmtSound={amtSold}
                                        onDiscountChange={(value) => this.pushToOtherPage({ discount: value })}
                                        onAmtSoldChange={(value) => this.pushToOtherPage({ amtSold: value })}
                                        onDiscountClear={() => this.pushToOtherPage({ discountClear: true })}
                                    />
                                </div>
                            </Col>
                            <Col className="right-col" span={rightColSapn}>
                                <Spin spinning={listLoading}>
                                    {gsList && gsList.length ? <LargeGrouponCell onCellClick={() => this.pushToDetail(gsList[0].id)} cellData={gsList[0]} /> : <Empty />}
                                    {gsList && gsList.length > 1 ?
                                        <div>
                                            <List
                                                className="pop-list-container"
                                                grid={{ gutter: 16, column: 2 }}
                                                dataSource={gsList.slice(1)}
                                                renderItem={(item, i) => (
                                                    <List.Item key={i}>
                                                        <GroupSaleCell
                                                            onCellClick={() => this.pushToDetail(item.id)}
                                                            key={`pop-list-cell-${i}`}
                                                            cellData={item} />
                                                    </List.Item>
                                                )} />
                                            <Pagination className="pagination" current={this.state.current} onChange={this.onPaginationChange} total={gsListPaging.total} />
                                        </div> : ''}
                                </Spin>
                            </Col>
                        </Row>
                    </div>



                </div>
            </GroupSaleLayout>,
            <BackTop />
        ]
    }
}

const mapStateToProps = (state) => {
    let authState = state['auth']
    let settingState = state['settings']
    let locationsState = state['locations']
    let appConfigState = state['app-config']
    let groupSaleState = state['group-sale']
    let routeState = state['router']

    return {
        userProfile: authState.userProfile,
        lan: settingState.lan,
        userLat: locationsState.userLat,
        userLon: locationsState.userLon,
        appConfigState: appConfigState,

        listLoading: groupSaleState.listLoading,
        gsList: groupSaleState.gsList,
        gsListPaging: groupSaleState.gsListPaging,
        gsTop3List: groupSaleState.gsTop3List,
        catList: groupSaleState.catList,
        gsShortcut: groupSaleState.gsShortcut,
        sortList: groupSaleState.sortList,

        routeSearch: routeState.location.search
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        push: bindActionCreators(push, dispatch),
        initGroupSaleBrowser: bindActionCreators((params) => {
            return _actions.initGroupSaleBrowser(params)
        }, dispatch),
        fetchGroupSaleList: bindActionCreators((params) => {
            return _actions.fetchGroupSaleList(params)
        }, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GroupSaleSearchPage));
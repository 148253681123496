import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Input, Button, Row, Col, Icon } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import './_couponInput.scss'
import _ from 'lodash';

class CouponInput extends Component {

    state = {
        coupon: '',
    }

    static defaultProps = {
        checkoutState: {}
    }

    emitEmpty = () => {
        this.couponInput.focus();
        this.setState({ coupon: '' });
    }

    onChangeCoupon = (e) => {
        this.setState({ coupon: e.target.value });
    }

    render() {
        let {
            lan,
            onCouponAppliedClick,

            intl
        } = this.props;

        let { formatMessage } = intl;

        let { coupon } = this.state;
        let suffix = coupon ? <Icon type="close-circle" onClick={this.emitEmpty} /> : null;

        return (
            <div className="coupon-input" >
                <Row gutter={16}>
                    <Col span={16}>
                        <Input
                            suffix={suffix}
                            className="input-field"
                            size="default"
                            value={coupon}
                            style={{ width: '100%' }}
                            placeholder={formatMessage({ id: 'enter_coupon_code' })}
                            ref={node => this.couponInput = node}
                            onChange={this.onChangeCoupon}
                        />
                    </Col>
                    <Col span={8}>
                        <Button
                            className="input-button"
                            type="primary"
                            style={{ width: '100%' }}
                            size="default"
                            disabled={!coupon}
                            onClick={() => onCouponAppliedClick(coupon)} ><FormattedMessage id="apply" />
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default injectIntl(CouponInput);
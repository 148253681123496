import React, { Component } from 'react';
import _config from 'config';
import _helper from '../../../utils/helper';
import _ from 'lodash'
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { Button } from 'antd';

class SeparateSurcharge extends Component {
    getOrderSucharge = () => {
        return _.get(this.props, 'orderDetail.order_surcharge', {})
    }

    getCurrency = () => {
        return _.get(this.props, 'orderDetail.currency', '')
    }

    getRelatedId = () => {
        return _.get(this.props, 'orderDetail.related_order_id', '')
    }

    isOrderActive = () => {
        const order = this.props.order
        const orderSt = String(_.get(order, 'status', 1))
        // "1": "pending",
        // "2": "processing",
        return orderSt === "1" || orderSt === "2"
    }

    isSurchargePaid = () => {
        return this.getRelatedId() ? true : false
    }

    isSurcharegeSeparate = () => {
        return _.get(this.getOrderSucharge(), 'is_separate', 0) === 1
    }

    showSeparateSurcharge = () => {
        return _.get(this.getOrderSucharge(), 'value', 0) && this.isSurcharegeSeparate()
    }

    renderSurchargeInfo = () => {
        return (
            <div className="seperate-surcharge-sucharge-info">
                <b >
                    <FormattedMessage id={this.isSurchargePaid() ? 'surcharge_paid' : 'surcharge_unpaid'} />
                </b>
                <span>
                    {_helper.formatCurrency(_.get(this.getOrderSucharge(), 'value', 0), this.getCurrency())}
                </span>
            </div>
        )
    }

    renderButton = () => {
        const isSurchargePaid = this.isSurchargePaid()
        const shouldShowButton = () => {
            return this.isOrderActive() && !isSurchargePaid
        }
        const paySurcharge = () => {
            const { orderDetail: o, } = this.props;
            const os = this.getOrderSucharge()
            this.props.push(`/pay/${o.group_id}/amt/${os.value}/oid/${o.order_id}/tc/${os.tax_class}/ro/1`)
        }
        return shouldShowButton() ? (
            <Button
                type="primary"
                size="large"
                className="seperate-surcharge-pay-button"
                onClick={() => paySurcharge()}>
                <FormattedMessage id="pay_now" />
            </Button>
        ) : ''
    }

    renderMessage = () => {
        const relatedId = this.getRelatedId()
        const shouldShowMessage = () => {
            return this.isSurchargePaid()
        }
        const goToOrder = () => {
            this.props.push(`/account/orders/detail/${relatedId}`)
        }
        return shouldShowMessage() ? (
            <div className="seperate-surcharge-paid-message" onClick={() => goToOrder()}>
                <FormattedMessage id="surcharege_paid_message"
                    values={{ id: <span className="link">{relatedId}</span> }} />
            </div>
        ) : ''
    }

    render() {
        return this.showSeparateSurcharge() ? (
            <div className="box separate-surcharge-container">
                <div className="box-section">
                    {this.renderSurchargeInfo()}
                    {this.renderButton()}
                    {this.renderMessage()}
                </div>
            </div>
        ) : ''
    }
}
const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", 'en'),
    orderDetail: _.get(state, "order-detail.orderDetailInstance.data", {}),
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SeparateSurcharge));

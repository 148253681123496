import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InactivOrderButtons from './inactive-order-buttons'
import PayNowButtons from './pay-now-buttons'
import './_order-control-panel.scss'

class OrderControlPanel extends React.Component {
    isOrderActive = () => {
        const order = this.props.order
        const orderSt = String(_.get(order, 'status', 1))
        // "1": "pending",
        // "2": "processing",
        return orderSt === "1" || orderSt === "2"
    }

    allowPayNow = () => {
        const order = this.props.order
        return _.get(order, 'total_due', 0) > 0 && _.get(order, 'allow_paynow', 0) === 1
    }

    render() {
        if (!this.isOrderActive()) {
            return <InactivOrderButtons />
        }
        if (this.isOrderActive() && this.allowPayNow()) {
            return <PayNowButtons />
        }

        return null
    }
}

const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    order: _.get(state, "order-detail.orderDetailInstance.data", {})
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderControlPanel));
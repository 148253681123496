import React, { Component } from 'react';
import { Row, Col, Avatar } from 'antd';
import { FormattedMessage } from 'react-intl';
import _helper from 'utils/helper';

export default class SocialLogin extends Component {
  render() {
    let {
      hide,
      login,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      loginLoading
    } = this.props;

    return (
        <div>
          <h4 className="alt-login-intro">Or Log In With </h4>
          <Row gutter={45} type="flex" justify="center" align="middle">
            <Col className="mediaCol" span={5}>
              <Avatar size="large" icon="facebook" />
            </Col>
            <Col className="mediaCol" span={5}>
              <Avatar size="large" icon="twitter" />
            </Col>
            <Col className="mediaCol" span={5}>
              <Avatar size="large" icon="google" />
            </Col>
            <Col className="mediaCol" span={5}>
              <Avatar size="large" icon="wechat" />
            </Col>
          </Row>
        </div>
    )
  }
}

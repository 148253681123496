import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Layout, Form, Input, Select, Checkbox, Button, Divider, Icon, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'containers/account-page/_account-page.scss'
import _helper from 'utils/helper';
const FormItem = Form.Item;
const Option = Select.Option;

class ByPhone extends Component {

  render() {
    let {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      getVerificationCode,
      setFieldError,
      setFieldTouched,
      setFieldValue,
      loginLoading,
      isPasswordSet,
    } = this.props;

    let { formatMessage } = this.props.intl;

    return (
      <Form onSubmit={handleSubmit}>
        <FormItem
          label={<FormattedMessage id="country" />}
          colon={false}
          help={touched.countryCode && errors.countryCode ? errors.countryCode : ''}
          validateStatus={touched.countryCode && errors.countryCode ? 'error' : ''}
        >
          <Select
            disabled={true}
            name="countryCode"
            onChange={(value) => { setFieldValue('countryCode', value) }}
            onBlur={handleBlur}
            value={values.countryCode}
          >
            <Option value="CA"><FormattedMessage id="canada" /> +1</Option>
            <Option value="US"><FormattedMessage id="united_states" /> +1</Option>
            <Option value="CN"><FormattedMessage id="china" /> +86</Option>
            <Option value="KR"><FormattedMessage id="south_korea" /> +82</Option>
          </Select>
        </FormItem>
        <FormItem
          label={<FormattedMessage id="phone_number" />}
          colon={false}
          help={touched.phone && errors.phone ? errors.phone : ''}
          validateStatus={touched.phone && errors.phone ? 'error' : ''}
        >
          <div className="vc-input-wrapper" style={{ display: 'flex' }}>
            <Input
              disabled={true}
              className="vc-input-field"
              name="phone"
              type="tel"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button onClick={() => {
              if (!values.phone) {
                setFieldTouched('phone', true)
                setFieldError('phone', 'phone is a required field')
              } else {
                getVerificationCode({
                  phone: values.phone,
                  countryCode: values.countryCode,
                })
              }
            }}
              type={values.phone && values.countryCode ? "primary" : ""}
              disabled={loginLoading || !values.phone || !values.countryCode}
            >
              <FormattedMessage id="get_verification_code" /></Button>
          </div>
        </FormItem>
        <FormItem
          label={<FormattedMessage id="verification_code" />}
          colon={false}
          help={touched.vc && errors.vc ? errors.vc : ''}
          validateStatus={touched.vc && errors.vc ? 'error' : ''}
        >
          <Input
            type="number"
            name="vc"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormItem>
        <FormItem
          label={<FormattedMessage id="new_password" />}
          colon={false}
          help={touched.password && errors.password ? errors.password : ''}
          validateStatus={touched.password && errors.password ? 'error' : ''}
        >
          <Input.Password
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormItem>
        <Divider />
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            disabled={loginLoading || !values.vc || !values.password}
            loading={loginLoading} block>
            <FormattedMessage id={isPasswordSet ? "update_password" : "set_password"} />
          </Button>
        </FormItem>
      </Form>
    )
  }
}

let validationSchema = (lan) => yup.object().shape({
  countryCode: yup.string().required(_helper.getIsRequiredMessage(lan, 'country')),
  phone: yup.string().required(_helper.getIsRequiredMessage(lan, 'phone_number')),
  vc: yup.string().required(_helper.getIsRequiredMessage(lan, 'verification_code')),
  password: yup.string().required(_helper.getIsRequiredMessage(lan, 'new_password'))
});

ByPhone = withFormik({
  mapPropsToValues: (props) => ({ ...props.initialValues }),
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.updateUserPassword(values)
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true

})(ByPhone)
export default injectIntl(ByPhone);

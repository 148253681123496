import _ from 'lodash';
import _helper from 'utils/helper'
import _config from 'config';
import _api from 'utils/api';
import moment from 'moment';

export default class GiftCardDetail {
  constructor(giftCardDetail) {
    giftCardDetail = Object.assign({}, {
      gc: '',
      bal: "0",
      bk_val: '0',
      c_id: '',
      curr: 'CAD',
      exp_dt: '',
      g_id: '',
      pur_dt: '',
      s_name: '',
      history: [],
    }, giftCardDetail);
    this.initGiftCardDetailData(giftCardDetail);
  }

  initGiftCardDetailData(giftCardDetail) {
    this.data = {
      'gc': giftCardDetail['gc'],
      'bal': giftCardDetail['bal'],
      'bk_val': giftCardDetail['bk_val'],
      'c_id': giftCardDetail['c_id'],
      'curr': giftCardDetail['curr'],
      'exp_dt': giftCardDetail['exp_dt'],
      'g_id': giftCardDetail['g_id'],
      'pur_dt': giftCardDetail['pur_dt'],
      's_name': giftCardDetail['s_name'],
      'history': giftCardDetail['history']
    }
  }
}

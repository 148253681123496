import cloneDeep from 'lodash/cloneDeep'
import _helper from 'utils/helper';
import _ from 'lodash'

export function getDefaultState() {
  return {
    lan: getDefaultLan(),
    // appIsInit: false,
    showAds: true,
  }
}

function getDefaultLan() {
  const availbleValues = ['en', 'zh', 'zh-Hant', 'jp'];
  const localLan = _helper.getLocalStorage('lan')
  const paramLan = _.get(_helper.getUrlParameters(), 'lan', null)
  const naviLan = window.navigator.language.slice(0, 2)

  let result = 'en'

  if (availbleValues.includes(paramLan)) {
    result = paramLan
    _helper.setLocalStorage('lan', paramLan)
  }

  else if (availbleValues.includes(localLan)) {
    result = localLan
  }

  else if (availbleValues.includes(naviLan)) {
    result = naviLan
  }

  _helper.updateMomentLocale(result)

  return result
}

function changeLanguage(state, action, status) {
  let finalState = _.cloneDeep(state);
  let { lan } = action.payload;
  finalState.lan = lan;
  _helper.updateMomentLocale(lan)
  _helper.setLocalStorage('lan', lan)
  return finalState
}

function closeAds(state, action, status) {
  let finalState = _.cloneDeep(state);
  let { show } = action.payload;
  finalState.showAds = show;
  return finalState
}

export default {
  'settings': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'SETTINGS__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'SETTINGS__CHANGE_LANGUAGE':
        return changeLanguage(state, action);
      case 'SETTINGS__CLOSE_ADS':
        return closeAds(state, action);
      default:
        return state;
    }
  }
}


import React, { Component } from 'react';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Button, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import './_pointsModal.scss'

class PointsModal extends Component {

  state = {
    currentValue: 0,
  }

  onOkClick = () => {
    let { onCloseClick, onOkClick } = this.props;
    let { currentValue } = this.state

    onOkClick(currentValue);
    onCloseClick();

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.min !== this.state.currentValue && !this.state.currentValue) {
      this.setState({ currentValue: nextProps.min });
    }
  }

  pointsCalculate(value) {
    let { max, storeP2v } = this.props;

    if (value == "add") {
      let currentValue = this.state.currentValue + (storeP2v * 5)
      if (currentValue <= max) {
        this.setState({
          currentValue,
        })
      }
    } else {
      let currentValue = this.state.currentValue - (storeP2v * 5)
      if (currentValue > 0) {
        this.setState({
          currentValue,
        })
      }
    }
  }


  render() {
    let {
      title,
      displayModal,
      onCloseClick,
      checkoutState,
      intl,
      points,
      storeP2v,
      currency,
    } = this.props;

    let { currentValue } = this.state
    let { formatMessage } = intl;

    let pointsused = _helper.formatCurrency((currentValue / storeP2v), currency)
    let redeemedPoints = _helper.formatNumber(currentValue)
    let pointBalance = _helper.formatNumber(checkoutState.pointsBalance)

    return (
      <Modal
        title={title}
        visible={displayModal}
        onOk={() => this.onOkClick()}
        onCancel={onCloseClick}
        okText={formatMessage({ id: 'ok' })}
        cancelText={formatMessage({ id: 'cancel' })}
      >
        <div className="points-modal-container" >

          <div className="current-value"> {formatMessage({ id: 'redeemed' })}:  {redeemedPoints} {formatMessage({ id: 'points' })}  </div>

          <Button.Group className="points-button" size="small">
            <Button
              disabled={this.state.disableButton}
              onClick={() => this.pointsCalculate('minus')}
            >
              <Icon type="minus" />
            </Button>
            <Button
              disabled={this.state.disableButton}
              onClick={() => this.pointsCalculate('add')}
            >
              <Icon type="plus" />
            </Button>
          </Button.Group>

          <div style={{ marginTop: '20px' }}>
            = <span style={{ color: "#1890ff" }}> {pointsused ? pointsused : 0} </span>  < br />
            <FormattedMessage id="remaining_balance" /> : {pointBalance ? pointBalance : 0}
          </div>
        </div>
      </Modal>
    )
  }
}


export default injectIntl(PointsModal);
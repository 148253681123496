const isSafari = () => {
    return window.safari && window.safari.pushNotification
}

const isNotificationOn = () => {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        return false
    }

    // Let's check whether notification permissions have alredy been granted
    else if (Notification.permission === "granted") {
        return true
    }

    else if (Notification.permission === 'denied' || Notification.permission === "default") {
        return false
    }
}

export default {
    isSafari,
    isNotificationOn,
}
import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import DescriptionText from './description-text'
import { Button, Modal } from 'antd';
import { push } from 'connected-react-router';

class PayNowButtons extends React.Component {

    state = {}

    getButtonsData = () => {
        const orderId = _.get(this.props, 'order.order_id', '')
        const allowCanel = String(_.get(this.props, 'order.status', '')) === "1"
        return [
            {
                label: 'pay_now',
                type: 'primary',
                onClick: () => this.props.fetchOrderDetail({
                    orderId, lan: this.props.lan, payNow: true
                })
            },
            {
                hide: !allowCanel,
                label: 'cancel',
                type: 'danger',
                onClick: () => this.setState({ confirmOpen: true })
            }
        ]
    }

    renderButtons = () => {
        return this.getButtonsData().map((button) => {
            if (!button.hide) return (
                <Button className="control-panel-button" {...button}>
                    <FormattedMessage id={button.label} />
                </Button>
            )
        })
    }

    renderConfirmModel = () => {
        const cancelOrder = () => {
            const orderId = _.get(this.props, 'order.order_id', '')
            const lan = this.props.lan
            this.props.cancelOrder({ orderId, lan })
            this.setState({ confirmOpen: false })
        }
        return (
            <Modal
                visible={this.state.confirmOpen}
                onOk={() => cancelOrder()}
                onCancel={() => this.setState({ confirmOpen: false })}
                okText={<FormattedMessage id="confirm" />}
                cancelText={<FormattedMessage id="cancel" />}
            >
                <FormattedMessage id="are_you_sure_cancel_order" />
            </Modal>
        )
    }

    render() {
        return [
            <div className="order-detail-control-panel-container">
                <div>
                    <DescriptionText />
                </div>
                <div>
                    {this.renderButtons()}
                </div>
            </div>,
            this.renderConfirmModel()
        ]
    }
}

const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    order: _.get(state, "order-detail.orderDetailInstance.data", {})
})

const mapDispatchToProps = {
    push,
    cancelOrder: (payload) => ({ type: "ORDER_DETAIL__CANCEL_ORDER", payload, }),
    fetchOrderDetail: (params) => ({ type: "ORDER_DETAIL__FETCH_ORDER_DETAIL", payload: params }),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayNowButtons)));
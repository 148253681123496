import _ from 'lodash';
import _helper from 'utils/helper';

export function getDefaultState() {
    return {
        showPayPalButton: false,
        payPalId: null,
        orderId: null,
        isLoading: false,
        vaultPw: '',
        priceInfo: {},
        currency: '',
        alertDialogDisplay: false,
    };
}

function initPayPalPage(state, action, status) {
    // called after paypal script loads
    let finalState = _.cloneDeep(state);
    const payPalResponse = _.get(action, 'response.payPalResponse', {})
    const orderDetailResponse = _.get(action, 'response.orderDetailResponse', {})
    const orderId = _.get(action, 'action.orderId', '')

    finalState = getDefaultState()
    finalState.orderId = orderId;

    if (_.get(payPalResponse, 'RC', 400) === 200) {
        finalState.payPalId = payPalResponse.records.id;
        if (payPalResponse.records.trans && payPalResponse.records.trans.currency) {
            finalState.currency = payPalResponse.records.trans.currency;
            finalState.priceInfo = payPalResponse.records.trans
        }
        finalState.showPayPalButton = true;
    } else {
        finalState.alertDialogDisplay = true;
        finalState.alertDialogTitle = 'error_payment_failed';
        finalState.alertDialogText = 'internal_server_error';
        finalState.alertDialogType = 'error'
    }

    if (_.get(orderDetailResponse, 'RC', 400) === 200) {
        finalState.order = _.get(orderDetailResponse, 'records', 400)
    }

    finalState.showPayPalButton = true;
    finalState.isLoading = false;
    return finalState;
}

function resetDefault(state, action) {
    let finalState = _.cloneDeep(state);
    finalState = getDefaultState();
    return finalState;
}

function payPalOnAuthorize(state, action, status) {
    let finalState = _.cloneDeep(state);
    // 500 403 400
    let { payPalResponse } = action.response;

    if (payPalResponse.RC == 200) {
        finalState.alertDialogDisplay = true;
        finalState.alertDialogTitle = 'paypal_payment_success_title';
        finalState.alertDialogText = 'paypal_payment_success_text';
    } else if (payPalResponse.RC == 400) {
        finalState.alertDialogDisplay = true;
        finalState.alertDialogTitle = 'error_payment_failed';
        finalState.alertDialogText = 'invalid_request';
    } else if (payPalResponse.RC == 500) {
        finalState.alertDialogDisplay = true;
        finalState.alertDialogTitle = 'error_payment_failed';
        finalState.alertDialogText = 'internal_server_error';
    } else {
        finalState.alertDialogDisplay = true;
        finalState.alertDialogTitle = 'error_payment_failed';
        finalState.alertDialogText = 'error_payment_failed';
    }

    if (payPalResponse.RC == 200) {
        finalState.alertDialogType = 'success'
    } else {
        finalState.alertDialogType = 'error'
    }

    finalState.isLoading = false;
    return finalState;
}

function payPalOnCancel(state, action, status) {
    let finalState = _.cloneDeep(state);
    finalState.isLoading = false;
    finalState.alertDialogDisplay = true;
    finalState.alertDialogType = 'error'
    finalState.alertDialogTitle = 'error_payment_failed';
    finalState.alertDialogText = 'error_payment_failed';
    return finalState;
}

export default {
    'paypal-page': (state = getDefaultState(), action) => {
        switch (action.type) {
            case 'PAYPAL_PAGE__SET_STATE':
                return Object.assign({}, _.cloneDeep(state), action.state);
            case 'PAYPAL_PAGE__RESET_DEFAULT':
                return resetDefault(state, action);
            case 'PAYPAL_PAGE__INIT_PAGE_SUCCESS':
                return initPayPalPage(state, action, true);
            case 'PAYPAL_PAGE__INIT_PAGE_FAILED':
                return initPayPalPage(state, action, false);
            case 'PAYPAL_PAGE__AUTHORIZE__SUCCESS':
                return payPalOnAuthorize(state, action, true)
            case 'PAYPAL_PAGE__AUTHORIZE__FAILED':
                return payPalOnAuthorize(state, action, false)
            case 'PAYPAL_PAGE__CANCEL__SUCCESS':
                return payPalOnCancel(state, action, true)
            case 'PAYPAL_PAGE__CANCEL__FAILED':
                return payPalOnCancel(state, action, false)
            default:
                return state;
        }
    }
}

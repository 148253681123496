import { call, put, takeEvery, select } from 'redux-saga/effects';

import _config from 'config';
import _api from 'utils/api';
import _helper from 'utils/helper';
import _apiRoutes from 'utils/api/routes';
import _model from 'utils/model'
import { getLanguage } from 'containers/app/reducers'

function* initGroupSaleBrowser(action) {
  try {
    let { lan, lat, lon, topThreeSortId } = action.payload;

    let gsShortcutUrl = `${_config.API_ROOT}/api/v7/gshortcut?lan=${lan}`;
    let gsShortcutResponse = yield call(_api.get, gsShortcutUrl);

    let gsCatListUrl = `${_config.API_ROOT}/api/v7/sfc?c_id=2`;
    let gsCatListResponse = yield call(_api.get, gsCatListUrl);

    let gsFilterListUrl = `${_config.API_ROOT}/api/v7/sff?lan=${lan}&c_id=2`;
    let gsFilterListUrlResponse = yield call(_api.get, gsFilterListUrl);

    let gsTop3ListUrl = `${_config.API_ROOT}/api/v7/glst?lan=${lan}&latlon=${lat},${lon}&sort_id=${topThreeSortId}` //top 3 items 102	DISTANCE ;
    let gsTop3ListResponse = yield call(_api.get, gsTop3ListUrl);

    let sortListUrl = `${_config.API_ROOT}/api/v7/sfs?lan=${lan}`;
    let sortListResponse = yield call(_api.get, sortListUrl);

    yield put({
      type: "INIT_GROUP_SALE_BROWSER__SUCCESS", response: {
        gsShortcut: gsShortcutResponse,
        gsTop3List: gsTop3ListResponse,
        gsFilterList: gsFilterListUrlResponse,
        sortList: sortListResponse,
        catList: gsCatListResponse,
      }, action: action.payload
    });
  } catch (e) {
    yield put({ type: "INIT_GROUP_SALE_BROWSER__FAILED", message: e.message, action: action.payload });
  }
}

function* fetchGroupSaleList(action) {
  try {
    yield put({ type: "GROUP_SALE__SET_STATE", state: { listLoading: true } })

    let { lan, lat, lon, catId, sortId, page, filter, text } = action.payload;

    let gsListUrl = `${_config.API_ROOT}/api/v7/glst?lan=${lan}&latlon=${lat},${lon}`;

    if (catId) {
      gsListUrl += `&c_ids=${catId}`
    }

    if (sortId) {
      gsListUrl += `&sort_id=${sortId}`
    }

    if (text) {
      gsListUrl += `&keywords=${text}`
    }

    if (filter) {
      gsListUrl += `&flag=${filter}`
    }

    if (page) {
      gsListUrl += `&page=${page}`
    }

    let gsListResponse = yield call(_api.get, gsListUrl);

    yield put({
      type: "GROUP_SALE_BROWSER__FETCH_GROUP_SALE_LIST__SUCCESS", response: {
        gsList: gsListResponse
      }, action: action.payload
    });
  } catch (e) {
    yield put({ type: "GROUP_SALE_BROWSER__FETCH_GROUP_SALE_LIST__FAILED", message: e.message, action: action.payload });
  }
}

function* fetchGroupSaleDetail(action) {
  try {
    let { pid, lan } = action.payload;
    let gsDetailUrl = `${_config.API_ROOT}/api/v7/gdtl?pid=${pid}&lan=${lan}`;
    let gsDetailUrlResponse = yield call(_api.get, gsDetailUrl);

    //fetch store details
    if (gsDetailUrlResponse && gsDetailUrlResponse.records && gsDetailUrlResponse.records['g_id']) {
      yield put({
        type: "GROUP_SALE__FETCH_STORE_DETAIL", payload: { gid: gsDetailUrlResponse.records['g_id'] }
      });
    } else {
      yield put({ type: "GROUP_SALE__SET_STATE", state: { storeInstance: {} } })
    }

    yield put({
      type: "GROUP_SALE__FETCH_GROUP_SALE_DETAIL__SUCCESS", response: {
        groupSaleDetail: gsDetailUrlResponse
      }, action: action.payload
    });
  } catch (e) {
    yield put({ type: "GROUP_SALE__FETCH_GROUP_SALE_DETAIL__FAILED", message: e.message, action: action.payload });
  }
}

function* fetchStoreDetail(action) {
  yield put({ type: "GROUP_SALE__SET_STATE", state: { storeLoading: true } })
  try {
    let { gid } = action.payload;
    let lan = yield select(getLanguage);
    let url = _config['API_ROOT'] + _apiRoutes['store-detail'];

    let searchParams = {
      gid,
      lan,
    }
    url = _helper.objToQuery(url, searchParams);
    const storeResponse = yield call(_api.get, url);

    if (storeResponse.RC === 200) {
      yield put({ type: "GROUP_SALE__FETCH_STORE_DETAIL_SUCCESS", response: { storeData: storeResponse.records }, payload: { ...action.payload } });
    } else {
      yield put({ type: "GROUP_SALE__FETCH_STORE_DETAIL_FAILED", payload: action.payload });
    }
  } catch (e) {
    yield put({ type: "GROUP_SALE__FETCH_STORE_DETAIL_FAILED", message: e.message, payload: action.payload });
  }
}


export default [
  takeEvery('GROUP_SALE__INIT_GROUP_SALE_BROWSER', initGroupSaleBrowser),
  takeEvery('GROUP_SALE__FETCH_GROUP_SALE_LIST', fetchGroupSaleList),
  takeEvery('GROUP_SALE__FETCH_GROUP_SALE_DETAIL', fetchGroupSaleDetail),
  takeEvery('GROUP_SALE__FETCH_STORE_DETAIL', fetchStoreDetail),
];

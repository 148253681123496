import _ from 'lodash';
import _model from 'utils/model';
// import _helper from 'utils/helper';

export function getDefaultState() {
  return {
    pageLoading: true,
    recentOrders: [],
  }
}


function fetchUserProfile(state, action, status) {
  let finalState = _.cloneDeep(state);

  return finalState;
}

function fetchRecentOrders(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { recentOrders } = action.response;
    if (recentOrders.RC === 200) {
      finalState.recentOrders = recentOrders.records
    }
  }

  return finalState;
}

export default {
  'account-page': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'ACCOUNT_PAGE__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'ACCOUNT_PAGE__CLEAR_DATA':
        return getDefaultState();
      case 'ACCOUNT_PAGE__FETCH_RECENT_ORDERS_SUCCESS':
        return fetchRecentOrders(state, action, true);
      case 'ACCOUNT_PAGE__FETCH_RECENT_ORDERS_FAILED':
        return fetchRecentOrders(state, action, false);
      default:
        return state;
    }
  }
}

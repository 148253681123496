import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from 'containers/app/store';
import _helper from 'utils/helper';

const FormItem = Form.Item;


class OrderCommentsForm extends Component {
  render() {
    let {
      postComment,
      values,
      handleChange,
      handleBlur,
      isSubmitting,
      orderId,
      setSubmitting,
    } = this.props;

    let { formatMessage } = this.props.intl;
    return (<Form onSubmit={(e) => {
      e.preventDefault();
      // handleSubmit('asdf')
      if (values.comment) {
        postComment({
          orderId,
          comment: values.comment
        })
      }
    }}>
      <FormItem
      >
        <Input.TextArea
          name="comment"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.comment}
          placeholder={formatMessage({ id: "contact_store" })}
        />
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" className="" size="large" loading={isSubmitting}>
          <FormattedMessage id="add_comment" />
        </Button>
      </FormItem>
    </Form>
    )
  }
}

let validationSchema = (lan) => yup.object().shape({
  comment: yup.string().required(_helper.getIsRequiredMessage(lan, 'contact_store')),
});

OrderCommentsForm = withFormik({
  // mapPropsToValues: (props) => ({ ...props.initalValues }),
  mapPropsToValues: (props) => ({ comment: '' }), // adding initial values will make field be touched when submitting. (https://github.com/jaredpalmer/formik/issues/445)
  handleSubmit: (values, { props, setErrors, setSubmitting }, hallo) => {
    props.postComment({ orderId: props.orderId, ...values })
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true

})(OrderCommentsForm)
export default injectIntl(OrderCommentsForm);

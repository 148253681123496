import { put, takeEvery, select } from 'redux-saga/effects';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _ from 'lodash'
import _config from '../../config'

function* initData(action) {
    yield put({ type: "PAY_NOW__SET_STATE", state: { isLoading: true } });
    try {
        //order
        let { orderId, lan } = action.payload
        let orderUrl = _apiRoutes['order-detail'] + `/${orderId}/${lan}`;
        const orderReponse = yield _api.callCheckingAuthentication(_api.executeOauth, orderUrl, 'GET');

        //store
        let { gid } = action.payload
        let storeUrl = _apiRoutes['store-detail'] + `?gid=${gid}&lan=${lan}`;
        const storeReponse = yield _api.callCheckingAuthentication(_api.executeOauth, storeUrl, 'GET');

        yield put({ type: "PAY_NOW__INIT_DATA_SUCCESS", response: { orderReponse, storeReponse }, payload: action.payload });
    } catch (e) {
        yield put({ type: "PAY_NOW__INIT_DATA_FAILED", message: e.message, payload: action.payload });
    }
    yield put({ type: "PAY_NOW__SET_STATE", state: { isLoading: false } });
}

function* payOrder(action) {
    yield put({ type: "PAY_NOW__SET_STATE", state: { isLoading: true } });
    try {
        let { creditCard, paymentMethod, order,
            billingAddress, store, tips } = action.payload;
        const method = _config.PAYMENT_METHOD_NUMERIC_MAPPING[paymentMethod]
        const payment = method && method !== 0 ? method : 8
        const requireBilling = _.get(store, 'data.require_billing_address', 0) === 1
        let postBody = {}
        postBody.data = {
            "ord_id": _.get(order, 'data.id', ''),
            "pay_mtd": payment,
            "tips": tips,
            "device": "h5",
        }

        if (payment == 2 && creditCard) {
            postBody.data.card = creditCard
        }

        if (payment == 2 && billingAddress && requireBilling) {
            postBody.data.billing_adr_id = billingAddress
        }

        const response = yield _api.callCheckingAuthentication(
            _api.executeOauth, _apiRoutes['all-in-one-order'],
            'POST', postBody
        );

        yield put({ type: "PAY_NOW__PAY_ORDER_SUCCESS", response, payload: { ...action.payload } });
    } catch (e) {
        console.log(e);
        yield put({ type: "PAY_NOW__PAY_ORDER_FAILED", message: e.message, payload: action.payload });
    }
    yield put({ type: "PAY_NOW__SET_STATE", state: { isLoading: false } });
}


export default [
    takeEvery('PAY_NOW__FETCH_ORDER_DETAIL', initData),
    takeEvery('PAY_NOW__PAY_ORDER', payOrder)
];

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import _ from "lodash";
import _config from "config";
import _helper from "utils/helper";
import _reverseConfig from "config/config-reverse-map";
import { Icon } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import ShippingMainMethodModal from "./shippingMainMethodModal";
import TimePickerModal from "./timePickerModal";
import _checkoutActions from "containers/checkout-page/actions.jsx";
import "./_shippingMethod.scss";

class ShippingMainMethodAndOrderType extends Component {
  state = {
    displayTimePickerModal: false,
    displayAddressModal: false,
    displayShippingMethodModal: false,
    displayTableNumberModal: false,
    displayPartySizeModal: false
  };
  static defaultProps = {
    checkoutState: {}
  };

  getTimePicker = () => {
    let { storeInstance, checkoutState, cartDetails, lan } = this.props;

    let requirement = !_.isEmpty(checkoutState.allowedPeriods) && ((cartDetails["shippingMethod"] == "delivery" && storeInstance.data["delivery_fee"] != -1)
      || cartDetails["shippingMethod"] == "pickup" || cartDetails["shippingMethod"] == "takeout")

    //reformat date time to today
    let formattedDate = checkoutState.deliveryDisplayDate
    let dateTimes = checkoutState.deliveryDisplayDate.split(' ')
    if (Array.isArray(dateTimes) && dateTimes.length == 2) {
      let date = dateTimes[0]
      formattedDate = _helper.formatDisplayDate(date, lan) + " " + dateTimes[1]
    }

    if (requirement) {
      return (
        <div
          onClick={() => this.setState({ displayTimePickerModal: true })}
          className="shipping-method-option-row">
          <span className="option-time-picker-option-divider">|</span>
          <span > <FormattedMessage id="when" />: </span>
          <span className="option option-avaliable-reminder">
            {checkoutState.deliveryDisplayDate.includes("ASAP") ? "ASAP" : formattedDate}
          </span>
          <Icon className="option-avalable-reminder-icon" type="caret-down" />
        </div>
      )
    }
  }

  getDeliveryOption = () => {
    let {
      checkoutState,
      setCheckoutState,
    } = this.props;

    let shippingMethodInText = _config.SHIPPING_MAPPING_TO_TEXT[checkoutState["shippingMethod"]]
    let storeCat = checkoutState["storeInstance"].data.c_id
    return (
      <div className="shipping-method-option-row" onClick={() => setCheckoutState("displayModalName", "shippingMainMethod")}>
        <span> <FormattedMessage id="delivery_option" />: </span>
        <span className="option">
          {checkoutState["shippingMethod"] && shippingMethodInText ?
            <FormattedMessage id={_helper.formatShippingMethod(shippingMethodInText, storeCat)} /> :
            <span className="delivery_option_error" />}
        </span>
      </div>
    )
  }

  render() {
    let {
      lan,
      intl,
      storeInstance,
      checkoutState,
      setCheckoutState,
      cartDetails,
      updateShoppingCartMethod
    } = this.props;
    return [
      <div className="shippingmethod-page-container">
        {this.getDeliveryOption()}
        {this.getTimePicker()}
      </div>,
      <ShippingMainMethodModal
        displayModal={checkoutState.displayModalName == "shippingMainMethod"}
        onCloseClick={() => setCheckoutState("displayModalName", "")}
        onOkClick={value => {
          updateShoppingCartMethod({
            gid: storeInstance.data["group_id"],
            method: value
          });
        }}
        storeInstance={storeInstance}
        cartDetails={cartDetails}
        checkoutState={checkoutState}
      />,
      <TimePickerModal
        title={cartDetails["shippingMethod"] == "delivery" ? "select_delivery_time" : "select_pickup_time"}
        displayModal={this.state.displayTimePickerModal}
        onCloseClick={() => this.setState({ displayTimePickerModal: false })}
        allowedPeriods={checkoutState.allowedPeriods}
        deliveryDisplayDate={checkoutState.deliveryDisplayDate}
        checkoutState={checkoutState}
        onOkClick={value => {
          setCheckoutState("deliveryDisplayDate", value);
        }}
        lan={lan}
      />
    ];
  }
}


const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch)
  };
};

export default connect(
  mapDispatchToProps
)(injectIntl(ShippingMainMethodAndOrderType));

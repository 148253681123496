import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import classnames from 'classnames';
import { Avatar, Icon, Modal, Spin, Button, Tabs } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import AccountPageLayout from 'components/account-page-layout';

import ProfileForm from './page-components/profile-form.jsx';
import EmailForm from './page-components/email-form.jsx';
import PhoneForm from './page-components/phone-form.jsx';
import PasswordForm from './page-components/password-form'
// import OAuth from 'oauth-1.0a';
// import crypto from 'crypto';

import './_account-page.scss';

const TabPane = Tabs.TabPane;

class AccountPage extends Component {

  componentDidMount() {
    this.featchOrders()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.featchOrders(nextProps)
    }
  }

  featchOrders(nextProps = this.props) {
    let { fetchRecentOrders, isAuthenticated } = nextProps;
    if (isAuthenticated) {
      fetchRecentOrders();
    }
  }

  getUserIcon = () => {
    let { userProfile } = this.props;

    if (userProfile && userProfile['profile_pic']) {
      return <Avatar size={100} src={`${_config['IMG_PREFIX']}${userProfile['profile_pic']}`} />
    } else {
      return <Avatar size={100} icon="user" />
    }
  }

  render() {
    let {
      push,
      userProfile,
      toggleProfileModal,
      profileModalDisplay,
      updateUserProfile,
      updateUserEmail,
      updateUserPhone,
      updateUserPassword,
      getVerificationCode,
      recentOrders,
      intl,
    } = this.props;

    userProfile = userProfile ? userProfile : {}

    return [
      <AccountPageLayout
        key="account-page-content"
        title={<FormattedMessage id="my_account" />}
      >
        {/* <Avatar src="https://res.cloudinary.com/goopter/image/upload/v1487613621/admin/avatar.png" /> */}
        <div className="account-content-wrapper">
          <div className="user-left-section user-section">
            <div className="user-info-header">
              {this.getUserIcon()}
            </div>
            <div className="user-info-body">
              <div className="name">
                <span>{_helper.customJoin([userProfile['first_name'], userProfile['last_name']], ' ')}</span>
              </div>
              {userProfile['email'] ? <div><Icon type="mail" style={{ paddingRight: '3px', color: '#333333' }} />{userProfile['email']}</div> : ''}
              {userProfile['phone'] ? <div><Icon type="phone" style={{ paddingRight: '3px', color: '#333333' }} />{userProfile['phone']}</div> : ''}
            </div>
            <div className="user-info-footer">
              <Icon className="edit-icon" type="edit" onClick={() => toggleProfileModal(true)} />
            </div>
          </div>
          <div className="user-right-section user-section">
            {/* <Button className="action-btn" size="large" block onClick={() => toggleProfileModal(true)}><FormattedMessage id="edit_profile" /></Button>
            <Button className="action-btn" size="large" block><FormattedMessage id="change_password" /></Button>
            <Button className="action-btn" size="large" block><FormattedMessage id="add_password" /></Button> */}
            <h3><FormattedMessage id="recent_orders" /></h3>
            <div>
              {recentOrders.length
                ? recentOrders.map((order) => {
                  return (
                    <div className="order-item" onClick={() => { push(`/account/orders/detail/${order.ord_id}`) }}>
                      <div className="order-item-header"><Avatar src={`${_config['IMG_PREFIX']}${order.logo}`} /></div>
                      <div className="order-item-body">{order.s_nm}</div>
                      <div className="order-item-footer">{_helper.formatCurrency(order.amt, order.curr)}</div>
                    </div>
                  )
                })
                : <div className="no-orders-wrapper">
                  <div className="text"><FormattedMessage id="no_orders" /></div>
                  <Button type="primary"
                    // onClick={() => { push('/') }}
                    onClick={() => { /* temp until store page is written in web_v2 */ window.location.href = `/` }}
                  ><FormattedMessage id="view_stores" /></Button>
                </div>}
            </div>
            {recentOrders.length ? <div className="view-all-btn link" onClick={() => { push('/account/orders') }}><FormattedMessage id="view_all" /></div> : ''}
          </div>
        </div>
      </AccountPageLayout>,
      <Modal
        title="Edit Profile"
        visible={profileModalDisplay}
        footer={null}
        onCancel={() => { toggleProfileModal(false) }}
      >
        <Tabs defaultActiveKey={"user-profile"}>
          <TabPane tab={intl.formatMessage({ id: "profile" })} key="user-profile">
            <ProfileForm
              initialValues={{
                firstName: userProfile['first_name'] ? userProfile['first_name'] : '',
                lastName: userProfile['last_name'] ? userProfile['last_name'] : '',
                nickName: userProfile['nick_name'] ? userProfile['nick_name'] : ''
              }}
              updateUserProfile={updateUserProfile}
            />
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: "email" })} key="user-email">
            <EmailForm
              initialValues={{
                email: userProfile['email'] ? userProfile['email'] : ''
              }}
              updateUserEmail={updateUserEmail}
            />
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: "phone_number" })} key="user-phone">
            <PhoneForm
              initialValues={{
                countryCode: 'CA',
                phone: userProfile['phone'] ? userProfile['phone'] : '',
                vc: '',
              }}
              updateUserPhone={updateUserPhone}
              getVerificationCode={getVerificationCode}
            />
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: "password" })} key="user-password">
            <PasswordForm
              userProfile={userProfile}
              getVerificationCode={getVerificationCode}
              updateUserPassword={updateUserPassword}
            />
          </TabPane>
        </Tabs>
      </Modal>
    ]
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth'];
  let accountPageState = state['account-page'];
  return {
    isAuthenticated: authState.isAuthenticated,
    userProfile: authState.userProfile,
    pageLoading: accountPageState.pageLoading,
    profileModalDisplay: accountPageState.profileModalDisplay,
    recentOrders: accountPageState.recentOrders,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleProfileModal: bindActionCreators((bool) => {
      return {
        type: "ACCOUNT_PAGE__SET_STATE",
        state: {
          profileModalDisplay: bool
        }
      }
    }, dispatch),
    updateUserProfile: bindActionCreators((params) => {
      return {
        type: "ACCOUNT_PAGE__UPDATE_USER_PROFILE",
        payload: {
          first_name: params.firstName,
          last_name: params.lastName,
          nick_name: params.nickName,
        }
      }
    }, dispatch),
    updateUserEmail: bindActionCreators((params) => {
      return {
        type: "ACCOUNT_PAGE__UPDATE_USER_EMAIL",
        payload: params
      }
    }, dispatch),
    updateUserPhone: bindActionCreators((params) => {
      return {
        type: "ACCOUNT_PAGE__UPDATE_USER_PHONE",
        payload: params
      }
    }, dispatch),
    getVerificationCode: bindActionCreators((params) => {
      return {
        type: "AUTH__GET_VERIFICATION_CODE",
        payload: params
      }
    }, dispatch),
    fetchRecentOrders: bindActionCreators((params) => {
      return {
        type: "ACCOUNT_PAGE__FETCH_RECENT_ORDERS",
      }
    }, dispatch),
    updateUserPassword: bindActionCreators((params) => {
      return {
        type: "ACCOUNT_PAGE__UPDATE_USER_PASSWORD",
        payload: params
      }
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AccountPage));
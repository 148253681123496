import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { FormattedMessage } from 'react-intl';
import AccountPageLayout from 'components/account-page-layout';

import './_address-book-page.scss';
import AddressBookWrapper from '../address-book-wrapper';


class AddressBookPage extends Component {

  render() {
    let { userProfile } = this.props

    return (
      <AccountPageLayout
        key="account-page-content"
        title={<FormattedMessage id="address_book" />}
      >
        <AddressBookWrapper />
      </AccountPageLayout>
    )
  }
}

const mapStateToProps = (state) => {

  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressBookPage);
import { put, takeEvery, select } from 'redux-saga/effects';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _ from 'lodash'

function* updateDeviceToken(action) {
    try {
        const auth = yield select((state) => { return state.auth })
        const isLogin = _.get(auth, 'isAuthenticated', false)

        if (isLogin) {
            let url = _apiRoutes['user-profile'];
            let postBody = action.payload
            const userProfileResponse = yield _api.callCheckingAuthentication(
                _api.executeOauth,
                url,
                'POST',
                postBody);
            yield put({
                type: "NOTIFICATIONS__UPDATE_DEVICE_TOKEN_SUCCESS",
                response: { userProfileResponse },
                payload: { ...action.payload }
            });
        }
    } catch (e) {
        yield put({ type: "NOTIFICATIONS__UPDATE_DEVICE_TOKEN_FAILED", message: e.message, payload: action.payload });
    }
}

export default [
    takeEvery('NOTIFICATIONS__UPDATE_DEVICE_TOKEN', updateDeviceToken),
];


import React, { Component } from 'react';
import { Provider } from 'react-redux';
import history from './history';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { store, persistor } from './store';
import AppContent from './app-content'

import './_app.scss';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<div>loaddingggg persistor</div>} persistor={persistor}>
          <ConnectedRouter history={history}>
            <AppContent />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Layout, Form, Input, Checkbox, Button, Divider, Icon, Spin, Modal } from 'antd';
import queryString from 'query-string';
import LoginAccountForm from './login-form/account';
import LoginTextForm from './login-form/text';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from '../../utils/helper'

import _authActions from 'containers/auth/actions';

import './_login.scss';

const FormItem = Form.Item;
const {
  Header, Content, Footer, Sider,
} = Layout;

class LoginPage extends Component {

  componentDidMount() {

  }

  getLoginTypeText = () => {
    let { loginType } = this.props;

    if (loginType === 'account') {
      return <FormattedMessage id="login_with_text" />
    } else if (loginType === 'text') {
      return <FormattedMessage id="login_with_account" />
    }
  }

  handleLogin = (loginParams) => {
    let { login, location } = this.props;
    let finalParams = _.cloneDeep(loginParams)
    if (location && location.state && location.state.from && location.state.from.pathname) {
      finalParams.redirectPath = location.state.from.pathname
    }
    //redirect from url 
    let urlParams = queryString.parse(this.props.location.search);
    if (urlParams) {
      let urlPath = urlParams.ref
      if (urlParams.refquery) {
        urlPath += `?query=${urlParams.refquery}`
      }
      finalParams.redirectPath = urlPath
    }

    login(finalParams)
  }

  componentWillUnmount() {
    // let { clearAuthData } = this.props;
    // clearAuthData()
  }

  render() {
    let {
      isAuthenticated,
      loginDialogDisplay,
      toggleLoginDialogDisplay,
      toggleLoginType,
      loginType,
      getVerificationCode,
      login,
      authFormLoading,
      loginLoading
    } = this.props;

    let { formatMessage } = this.props.intl;

    //  let urlParams = queryString.parse(this.props.location.search);

    return (
      <Layout style={{ minHeight: '100vh', background: '#ffffff' }}>
        <Content className="content-container">
          <div className="login-form">
            <div className="logo-wrapper">
              <img className="logo" src="https://res.cloudinary.com/goopter/image/upload/v1548698298/admin/goopter_logo_color.png" />
            </div>
            <div className="auth-form-container">
              <div className="box">
                {/* <Spin spinning={authFormLoading}> */}

                <h1 style={{ textAlign: 'center' }}><FormattedMessage id="log_in" /></h1>
                <div className="link" style={{ textAlign: 'right', paddingBottom: '5px', paddingTop: '5px' }} onClick={toggleLoginType}>
                  <icon />{this.getLoginTypeText()}
                </div>
                <LoginAccountForm
                  login={this.handleLogin}
                  hide={loginType !== 'account'}
                  loginLoading={loginLoading}
                />
                <LoginTextForm
                  login={this.handleLogin}
                  getVerificationCode={getVerificationCode}
                  hide={loginType !== 'text'}
                  loginLoading={loginLoading}
                />
                {/* </Spin> */}
              </div>
            </div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', background: '#ffffff' }}>
          © 2015-{_helper.getCurrentYear()}, Goopter Holdings Ltd.
        </Footer>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth'];

  return {
    isAuthenticated: authState.isAuthenticated,
    loginDialogDisplay: authState.loginDialogDisplay,
    loginType: authState.loginType,
    authFormLoading: authState.authFormLoading,
    loginLoading: authState.loginLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    checkAuthStatus: bindActionCreators(_authActions.checkAuthStatus, dispatch),
    login: bindActionCreators((params) => {
      return {
        type: "AUTH__LOGIN",
        payload: params
      }
    }, dispatch),
    toggleLoginDialogDisplay: bindActionCreators((bool) => {
      return {
        type: "AUTH__SET_STATE",
        state: {
          loginDialogDisplay: bool
        }
      }
    }, dispatch),
    toggleLoginType: bindActionCreators((type) => {
      return {
        type: "AUTH__TOGGLE_LOGIN_TYPE",
      }
    }, dispatch),
    // clearOrderDetailData: bindActionCreators((params = {}) => {
    //   return {
    //     type: "ORDER_DETAIL__CLEAR_DATA",
    //   }
    // }, dispatch)
    getVerificationCode: bindActionCreators((params) => {
      return {
        type: "AUTH__GET_VERIFICATION_CODE",
        payload: params
      }
    }, dispatch),
    clearAuthData: bindActionCreators(_authActions.clearAuthData, dispatch)
  }
}

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default injectIntl(LoginPage)


import en from './en';
import zh from './zh';
import zhHant from './zh-Hant';
import jp from './jp';

let messages = {
  en,
  zh,
  'zh-Hant': zhHant,
  jp,
}

function getIntlMessages(language, namespace) {
  let currentMessages = messages[language];
  let pageMessages = Object.assign({}, currentMessages['common'], currentMessages['common_order_status'],
    currentMessages['common_shipping_method'], currentMessages['common_payment_method'],
    currentMessages['common_price'], currentMessages['common_†ime'],
    currentMessages['common_language'], currentMessages['common_address'],
    currentMessages['common_error'], currentMessages["common_acccount"],
    currentMessages['common_country'], currentMessages['common_order'],
    currentMessages['common_store'], currentMessages['common_product'],
    currentMessages['common_group_sale'], currentMessages['common_self_checkout'],
    currentMessages['common_checkout'],
    (currentMessages[namespace] ? currentMessages[namespace] : {}))
  return pageMessages;
}

export default {
  getIntlMessages,
}

import { put, takeEvery, select, call } from 'redux-saga/effects';
import _config from 'config';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _helper from 'utils/helper';
import _ from 'lodash';

function* fetchStoreInstance(action) {
    try {
        let url = `${_config.API_ROOT}${_apiRoutes['store']}`
        if (_helper.getIndependentDomain()) {
			url += `?&domain=${_helper.getIndependentDomain()}` 
        }
        let storeIntanceResponse = yield call(_api.get, url);
        yield put({ type: "INIT_APP_STORE__SUCCESS", response: { storeIntanceResponse }, action: action.payload });
    } catch (e) {
        yield put({ type: "INIT_APP_STORE__FAILED", message: e.message, action: action.payload });
    }
}

export default [
    takeEvery('INIT_STORE_DETAILS', fetchStoreInstance)
];
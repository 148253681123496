import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Layout, Divider, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import _helper from 'utils/helper';
import NavBar from 'components/navbar';
import SideBar from './page-components/sidebar';

// import OrderDetail from './page-components/order-detail';
// import OrderList from './page-components/order-list';

const {
	Header, Content, Footer, Sider,
} = Layout;

class AccountPageLayout extends Component {

	getHeader = () => {
		let { title, subTitle } = this.props;
		if (title) {
			return (
				<div>
					{title ? <div><h1>{title}</h1></div> : ''}
					{subTitle ? <div>{subTitle}</div> : ''}
					<Divider />
				</div>
			)
		}
	}

	getLogoText = () => {
		let {
			logoText,
			storeInstance
		} = this.props;
		let text = ''
		if (logoText) {
			text = logoText
		} else if (_helper.getIndependentDomain() && storeInstance && storeInstance.data && storeInstance.data['name']) {
			text = storeInstance.data['name']
		}
		return text
	}

	render() {
		let {
			title,
			children,

			hideSideBar,
			logoText,
			logoUrl,
			gid,
		} = this.props;
		return (
			<Layout>
				<Header
					style={{
						backgroundColor: '#f9f9f9',
						minWidth: '1000px',
						borderBottom: '2px solid rgba(34,36,38,.10)',
						position: 'sticky',
						top: '0',
						width: '100%',
						zIndex: '500'
					}}>
					<NavBar gid={gid} logoText={this.getLogoText()} logoUrl={logoUrl} />
				</Header>
				<Layout style={{
					minWidth: '1000px',
					maxWidth: '1500px',
					width: '100%',
					margin: 'auto',
					// paddingTop: '60px',
				}}>
					{hideSideBar ? null : <SideBar />}
					<Layout style={{
						minHeight: `calc(100vh - 64px)`
					}}>
						<Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
							<div style={{ padding: 24, background: '#fff' }}>
								{this.getHeader()}
								{children}
							</div>
						</Content>
						<Footer style={{ textAlign: 'center' }}>
							© 2015-{_helper.getCurrentYear()}, Goopter Holdings Ltd.
            </Footer>
					</Layout>
				</Layout>
			</Layout>
		)
	}
}

const mapStateToProps = (state) => {
	let storeDataState = state['store-data'];

	return {
		// page: accountState.page
		storeInstance: storeDataState.storeInstance
	}
}

const mapDispatchToProps = (dispatch) => {
	return {

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLayout);
import React, { Component, useState } from 'react';
import _ from 'lodash';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { Form, Input, Button, Divider, Icon, Row, Col, DatePicker, Tooltip, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FaCcVisa, FaCcAmex, FaCcMastercard, FaCcDiscover, FaCcJcb, } from "react-icons/fa";
import moment from 'moment';
import classnames from 'classnames';
import countryData from 'utils/data/countryList.json'


import './_creditCardDetails.scss'

const FormItem = Form.Item;
const { Option } = Select;
const { MonthPicker } = DatePicker;

class CreditCardDetail extends Component {

    state = {
        missingValue: [],
    }

    componentDidMount() {
        this.updateDefaultCardValue(this.props)
    }

    componentDidUpdate(prevsProps, prevsState) {
        this.updateMissingValue(prevsProps);
        this.updateValidate(prevsProps)
    }

    componentWillUpdate(nextProps) {
        this.updateDefaultCardValue(nextProps)
    }

    getCountryName = (code) => {
        let { lan } = this.props
        let result = ''
        countryData.records.map(country => {
            if (country.country_code == code) {
                result = country.name[lan]
            }
        })
        return result
    }

    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }


    handleCcNumberChange = (ccInputNumber) => {
        //https://stackoverflow.com/questions/9315647/regex-credit-card-number-tests
        let { setFieldValue } = this.props;
        let ccNumVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
        let ccNumMastercard = /^5[1-5][0-9]{14}$/;
        let ccNumAE = /^3[47][0-9]{13}$/;
        let ccNumDiscovery = /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;
        let ccNumUnion = /^(62[0-9]{14,17})$/;
        let ccNumJCB = /^(?:2131|1800|35\d{3})\d{11}$/;
        let ccNumMaestro = /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/;
        let ccNumRegMap = {
            1: ccNumVisa,
            2: ccNumMastercard,
            3: ccNumAE,
            4: ccNumDiscovery,
            5: ccNumUnion,
            6: ccNumJCB,
            7: ccNumMaestro,
        }
        let height = "3em"
        let ccTypesIconMap = {
            1: <FaCcVisa size={height} />,
            2: <FaCcMastercard size={height} />,
            3: <FaCcAmex size={height} />,
            4: <FaCcDiscover size={height} />,
            5: <FaCcVisa size={height} />,
            6: <FaCcJcb size={height} />,
            7: <FaCcMastercard size={height} />,
        }

        let ccNumberValue = ccInputNumber.replace(/[^0-9]/g, '').replace(/\s+/g, '')
        let cardType = Object.keys(ccNumRegMap).find((ccType) => {
            return (ccNumRegMap[ccType].test(ccNumberValue))
        })

        let displayValue = this.ccDisplayFormatter(ccInputNumber);
        setFieldValue('cardNumber', ccNumberValue)
        setFieldValue('cardDisplayNumber', displayValue)
        setFieldValue('cardType', cardType)
        setFieldValue('cardDisplayType', ccTypesIconMap[cardType])
    }

    ccDisplayFormatter = (value) => {
        var va = value.replace(/\s+/g, '')
        var v = va.replace(/[^0-9]/g, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value.replace(/[^0-9]/g, '')
        }
    }

    validateCcInfo = (values, showError = true) => {
        let { cardHolder, cardType, country, cvv, zipcd, expDateSting, expDate } = values
        let currentCountry = countryData.records.find(cnty => { return cnty.country_code == country })
        let missingValue = []

        if (!cardHolder) {
            missingValue.push('cardHolder')
        }
        if (!cardType) {
            missingValue.push('cardType')
        }
        if (!country) {
            missingValue.push('country')
        }
        if (!cvv) {
            missingValue.push('cvv')
        }
        if (!expDateSting) {
            missingValue.push('expDateSting')
        }
        if (!expDate) {
            missingValue.push('expDate')
        }
        if (!zipcd || (currentCountry && currentCountry['zipcode_required'] && !new RegExp(currentCountry['zipcode_format']).test(zipcd))) {
            missingValue.push('zipcd')
        }

        if (showError) {
            this.setState({ missingValue: missingValue })
        }

        return missingValue.length
    }

    updateMissingValue(prevsProps) {
        let { values } = this.props;
        if (this.state.missingValue && prevsProps.values !== values) {
            let value = [];
            if (values.cardHolder) {
                value.push('cardHolder');
            }
            if (values.cardType) {
                value.push('cardType')
            }
            if (values.country) {
                value.push('country')
            }
            if (values.cvv) {
                value.push('cvv')
            }
            if (values.zipcd) {
                value.push('zipcd')
            }
            if (values.expDateSting) {
                value.push('expDateSting')
            }
            if (values.expDate) {
                value.push('expDate')
            }
            let missingValue = _.difference(this.state.missingValue, value);

            this.setState({ missingValue: missingValue });
        }
    }

    updateValidate(prevsProps, showError = true) {
        let { validate, values, setCheckoutState, checkoutState } = this.props
        let { cardHolder, cardNumber, cardType, country, cvv, zipcd, expDateSting, expDate } = values
        if (prevsProps.validate != validate) {
            if (!this.validateCcInfo(values, showError)) {
                let card = {
                    num: cardNumber,
                    owner: cardHolder,
                    type: cardType,
                    expm: expDate,
                    expy: expDateSting,
                    cvv: cvv,
                    cntry: country,
                    zipcd: zipcd,
                }
                setCheckoutState('creditCard', card)
                setCheckoutState('creditCardValidated', true)
            } else {
                setCheckoutState('creditCard', null)
                setCheckoutState('creditCardValidated', false)
            }
            setCheckoutState('creditCardValidating', !checkoutState.creditCardValidating)
        }

    }

    updateDefaultCardValue(nextProps) {
        const { values = {}, appConigState = {}, setFieldValue, } = nextProps;

        if (!values.country && appConigState.country) {
            //formit is having issue on setFieldValue on did mount, a workabout is setTimeOut
            //waiting for the fix, solution a bit "hacky"
            //https://github.com/jaredpalmer/formik/issues/930
            setTimeout(() => { setFieldValue('country', appConigState.country) }, 1)
        }
    }

    expiryCheck = () => {
        const expDateSting = _.get(this.props, 'values.expDateSting', null)
        const expDate = _.get(this.props, 'values.expDate', null)
        if (expDateSting && expDate) {
            const today = moment();
            const input = moment(`${expDateSting}-${expDate}-1`, "YYYY-MM-DD");
            return today.isBefore(input)
        }
        return true
    }

    getExpiryYearsData = () => {
        const yearAdditionNumber = 20
        const today = moment();
        const currentYear = today.year()
        let years = []

        for (let i = currentYear; i <= (currentYear + yearAdditionNumber); i++) {
            years.push({
                value: i,
                label: i
            })
        }

        return years;
    }

    getExpiryMonthsData = () => {
        const labels = moment.months();
        const months = []

        labels.forEach((label, i) => {
            months.push({
                value: i + 1,
                label
            })
        })

        return months;
    }


    getOptions = (options) => {
        return options.map((option) => (
            <Option key={option.value} value={option.value}>
                {option.label}
            </Option>
        ))
    }

    handleBlur = (values) => {
        let { handleBlur, withValidateOnBlur } = this.props;
        handleBlur(values)
        if (withValidateOnBlur) {
            this.updateValidate({ validate: !this.props.validate }, false)
        }
    }

    render() {
        let {
            hide,
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
        } = this.props;

        let { missingValue } = this.state

        let { formatMessage } = this.props.intl;

        let handleBlur = (values) => this.handleBlur(values)

        return (
            <Form style={{ display: hide ? 'none' : null }}>
                {missingValue.length ?
                    <div className="missing-item-message">
                        <div> <Icon type="exclamation-circle" /> </div>
                        <div> &nbsp; <FormattedMessage id="please_input_belows" /> </div>
                    </div>
                    : ''}
                {/* card holder */}
                <FormItem
                    className={classnames({
                        "formItem": true,
                        "missing-item": _.includes(missingValue, 'cardHolder')
                    })}
                    help={touched.cardHolder && errors.cardHolder ? errors.cardHolder : ''}
                    validateStatus={touched.cardHolder && errors.cardHolder ? 'error' : ''}
                >
                    <Input
                        size="large"
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={formatMessage({ id: "name_on_card" })}
                        name="cardHolder"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cardHolder}
                    />
                </FormItem>

                {/* card number */}
                <FormItem
                    className={classnames({
                        "formItem": true,
                        "missing-item": _.includes(missingValue, 'cardType')
                    })}
                    colon={false}
                    help={touched.cardNumber && errors.cardNumber ? errors.cardNumber : ''}
                    validateStatus={touched.cardNumber && errors.cardNumber ? 'error' : ''}
                >
                    <Input
                        size="large"
                        prefix={<Icon type="credit-card" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={values.cardDisplayType ? values.cardDisplayType : null}
                        placeholder={formatMessage({ id: "card_number" })}
                        name="cardDisplayNumber"
                        onChange={(e) => this.handleCcNumberChange(e.target.value)}
                        onBlur={handleBlur}
                        value={values.cardDisplayNumber}
                    />
                </FormItem>
                {_.includes(missingValue, 'cardType') ?
                    <div className="missing-item-message">
                        <div>  <FormattedMessage id="invalid_card_number" /> </div>
                    </div> : ''}

                {/* exp and vcc */}
                <Row gutter={6}>
                    <Col span={12}>
                        <FormItem
                            className={classnames({
                                "formItem": true,
                                "missing-item": !this.expiryCheck() || _.includes(missingValue, 'expDateSting') || _.includes(missingValue, 'expDate')
                            })}
                            colon={false}
                            help={touched.expDate && errors.expDate ? errors.expDate : ''}
                            validateStatus={touched.expDate && errors.expDate ? 'error' : ''}
                        >
                            <div className="float-input-container">
                                <Select
                                    handleBlur={handleBlur}
                                    style={{ width: "50%" }}
                                    size="large"
                                    placeholder={formatMessage({ id: "year" })}
                                    value={values.expDateSting}
                                    onChange={(dateString) => {
                                        setFieldValue('expDateSting', dateString)
                                    }}
                                >
                                    {this.getOptions(this.getExpiryYearsData())}
                                </Select>
                                <Select
                                    handleBlur={handleBlur}
                                    style={{ width: "50%" }}
                                    size="large"
                                    placeholder={formatMessage({ id: "month" })}
                                    value={values.expDate}
                                    onChange={(date) => {
                                        setFieldValue('expDate', date)
                                    }}
                                >
                                    {this.getOptions(this.getExpiryMonthsData())}
                                </Select>
                            </div>
                        </FormItem>

                        {!this.expiryCheck() ?
                            <div className="missing-item-message">
                                <div>  <FormattedMessage id="invalid_expiry" /> </div>
                            </div> : ''}

                    </Col>
                    <Col span={12}>
                        <FormItem
                            className={classnames({
                                "formItem": true,
                                "missing-item": _.includes(missingValue, 'cvv')
                            })}
                            colon={false}
                            help={touched.cvv && errors.cvv ? errors.cvv : ''}
                            validateStatus={touched.cvv && errors.cvv ? 'error' : ''}
                        >
                            <Input
                                size="large"
                                placeholder={formatMessage({ id: "cvv" })}
                                suffix={<Tooltip placement="top" title={formatMessage({ id: "cvv_descpt" })}>
                                    <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.25)' }} />
                                </Tooltip>}
                                name="cvv"
                                maxLength={4}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cvv}
                            />
                        </FormItem>
                    </Col>
                </Row>


                {/* country and zipcode */}
                <Row gutter={6}>
                    <Col span={12}>
                        <FormItem
                            className={classnames({
                                "formItem": true,
                                "missing-item": _.includes(missingValue, 'country')
                            })}
                            colon={false}
                            help={touched.country && errors.country ? errors.country : ''}
                            validateStatus={touched.country && errors.country ? 'error' : ''}
                        >
                            <Select
                                handleBlur={handleBlur}
                                placeholder={formatMessage({ id: "country" })}
                                size="large"
                                value={values.country}
                                onChange={(value) => {
                                    setFieldValue('country', value)
                                }}
                            >
                                {countryData.records.map(country =>
                                    <Select.Option value={country.country_code} key={`country-select-${country.country_code}`}>{this.getCountryName(country.country_code)}</Select.Option>
                                )}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            className={classnames({
                                "formItem": true,
                                "missing-item": _.includes(missingValue, 'zipcd')
                            })}
                            colon={false}
                            help={touched.zpcd && errors.zpcd ? errors.zpcd : ''}
                            validateStatus={touched.zpcd && errors.zpcd ? 'error' : ''}
                        >
                            <Input
                                size="large"
                                placeholder={formatMessage({ id: "postal_code" })}
                                suffix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                name="zipcd"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.zipcd && values.zipcd.toUpperCase()}
                            />
                        </FormItem>
                        {_.includes(missingValue, 'zipcd') ?
                            <div className="missing-item-message">
                                <div>  <FormattedMessage id="invalid_zipcd" /> </div>
                            </div> : ''}
                    </Col>
                </Row>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    const appConigState = state['app-config']
    return {
        appConigState: appConigState,
    }
}

CreditCardDetail = withFormik({
    mapPropsToValues: (props) => ({ ...props.initialValues }),
    enableReinitialize: true
})(CreditCardDetail)
export default connect(mapStateToProps)(injectIntl(CreditCardDetail));
import React, { Component } from 'react';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Icon, Input, List, } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_giftCardModal.scss'
import GiftCardWrapper from 'containers/gift-card-page/giftCardPageWrapper'

class GiftCardModal extends Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static defaultProps = {
        displayModal: false,
        reviewData: [],
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        let {
            displayModal,
            onCloseClick,
            intl,

            selected,
            onSelectedChange,
            storeInstance,
        } = this.props;

        let {
            height,
            width,
        } = this.state

        let { formatMessage } = intl;


        return (
            <Modal
                width={1100}
                title={formatMessage({ id: 'gift_cards' })}
                visible={displayModal}
                onCancel={onCloseClick}
                footer={null}
            >
                <div
                    style={{ maxHeight: `${height * 0.7}px`, overflowY: 'auto', overflowX: 'hidden' }}
                    className="giftCardModal" >
                    <GiftCardWrapper
                        gid={storeInstance.data['group_id']}
                        selected={selected}
                        selectedMode={true}
                        onSelectedChange={onSelectedChange} />
                </div>
            </Modal>
        )
    }
}


export default injectIntl(GiftCardModal);
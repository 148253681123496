import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Avatar, Row, Col, Icon, Divider, Rate } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import './_reviewCell.scss'

class ReviewCell extends Component {

    state = {
        currentSelected: 0
    }

    static defaultProps = {
        review: {}

    }

    getUserIcon = () => {
        let { review } = this.props;

        if (review['profile_photo']) {
            return <Avatar style={{ width: 42, height: 42 }} size={100} src={`${_config['IMG_PREFIX']}${review['profile_photo']}`} />
        } else {
            return <Avatar style={{ width: 42, height: 42 }} size={100} icon="user" />
        }
    }


    render() {

        let {
            review
        } = this.props;


        return (
            <div className="review-cell-container">
                <Row>
                    <Col span={2}>
                        <div className="user-icon"> {this.getUserIcon()}</div>
                    </Col>
                    <Col span={22}>
                        <div className="user-text-container">
                            <div className="reviewer-name">
                                {review['author']}
                            </div>
                            <div className="user-stats">
                                <span className="reviewer-review-count"> <Icon type="star" /> {review['author_cnt'] ? review['author_cnt'] : 0} <FormattedMessage id="ratings" /> </span>
                                <span className="reviewer-review-count"> <Icon type="message" /> {review['author_reviews'] ? review['author_reviews'] : 0} <FormattedMessage id="reviews" /> </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="ratings-date">
                    <span><Rate style={{ fontSize: "17px" }} disabled allowHalf defaultValue={review['rat']} /> </span>
                    <Divider type="vertical" />
                    <span>{_helper.utcToLocal(review['review_dt'], { local: false, time: false })}</span>
                </div>
                <div className="review-text">
                    <p>{review['cmt']}</p>
                </div>
            </div>
        )
    }
}


export default injectIntl(ReviewCell);
import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from '../../../../utils/helper';
import { injectIntl, } from 'react-intl';
import _ from 'lodash'
import { connect } from 'react-redux';
import { Modal } from 'antd';
import _pageHelper from '../../helper'
import { push } from 'connected-react-router';

class AlertModel extends Component {

    componentDidUpdate(prevProps) {
        this.checkShowModel(prevProps)
    }

    str = (id, values) => { return this.props.intl.formatMessage({ id }, values) }

    checkShowModel = (prevProps) => {
        const showModel = !_.isEqual(this.getModelContent(prevProps), this.getModelContent())
            && this.getModelContent()
        if (showModel) this.showModel()
    }

    getHandleType = () => {
        return _.get(this.props, 'state.displayModalHanldingType', false)
    }

    getModelTitle = () => {
        const title = _.get(this.props, 'state.displayModalTitle', false)
        return title ? this.str(title) : '';
    }

    getModelContent = (props = this.props) => {
        const content = _.get(props, 'state.displayModalContent', false)
        const keywords = _.get(props, 'state.displayModalKeywords', false)
        return content ? this.str(content, keywords) : ''
    }

    getOkText = () => {
        const handleType = this.getHandleType()
        return handleType ? this.str(handleType) : this.str('ok')
    }

    onOkClick = () => {
        const handleType = this.getHandleType()
        this.props.setState({ displayModalContent: '' })

        if (handleType === 'back_to_store') { this.goToStorePage() }
    }

    goToStorePage = () => {
        let {
            store,
        } = this.props;

        if (_helper.getIndependentDomain()) {
            /* temp until store page is written in web_v2 */
            window.location.href = `/`
        } else {
            /* temp until store page is written in web_v2 */
            window.location.href = `/store/${_pageHelper.getGid(this.props)}`
        }
    }

    getModelProps = () => {
        return {
            title: this.getModelTitle(),
            content: this.getModelContent(),
            onOk: () => this.onOkClick(),
            okText: this.getOkText()
        }
    }

    showModel = () => {
        const displayModalContent = _.get(this.props, 'state.displayModalContent', '')
        if (displayModalContent.includes('success')) {
            Modal.success(this.getModelProps());
        } else {
            Modal.error(this.getModelProps());
        }
    }

    render() {
        return null
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "pay-now", {}),
    store: _.get(state, 'pay-now.store.data', '')
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AlertModel));
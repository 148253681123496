function initCheckoutPage(params) {
  return {
    type: "CHECKOUT_PAGE__INIT_CHECKOUT_PAGE",
    payload: {
      gid: params.gid
    }
  }
}

function updateTips(params) {
  return {
    type: "CHECKOUT_PAGE__UPDATE_TIPS",
    payload: params
  }
}

export default {
  initCheckoutPage,
  updateTips
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import { Table, Divider, Icon, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import AccountPageLayout from 'components/account-page-layout';

const { Column, ColumnGroup } = Table;

class OrderList extends Component {

  componentDidMount() {
    let { fetchOrderList } = this.props;
    fetchOrderList()
  }

  componentWillUnmount() {
    let { clearOrderListData } = this.props;

    clearOrderListData()
  }

  componentDidUpdate(prevProps) {
    this.updateOrderList(prevProps);
  }

  updateOrderList(prevProps) {
    let { notificationOrderId, orderList, fetchOrderList, isNotificationUpdated, isAuthenticated } = this.props;
    if (isNotificationUpdated !== prevProps.isNotificationUpdated && isAuthenticated) {
      let updatedOrder = orderList.find((order) => { return order.ord_id == notificationOrderId })
      if (updatedOrder) {
        fetchOrderList();
      }
    }

    if (isAuthenticated !== prevProps.isAuthenticated && isAuthenticated) {
      fetchOrderList();
    }
  }

  render() {
    let {
      push,
      orderList,
      fetchOrderList,
      orderListPaging
    } = this.props;
    return (
      <AccountPageLayout title={<FormattedMessage id="order_history" />}>
        <Table
          dataSource={orderList}
          pagination={{
            pageSize: 20,
            total: orderListPaging.total,
            current: parseInt(orderListPaging.page),
            onChange: (page, pageSize) => {
              fetchOrderList({
                page,
              })
            }
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                push(`/account/orders/detail/${record['ord_id']}`)
              }
            }
          }}
        >
          <Column
            title={<FormattedMessage id="order_id" />}
            dataIndex="ord_id"
          />
          <Column
            title={<FormattedMessage id="order_type" />}
            dataIndex="shp_mtd"
            render={(shpMtd) => <span>{<FormattedMessage id={_config.SHIPPING_MAPPING_TO_TEXT[shpMtd] ?
              _config.SHIPPING_MAPPING_TO_TEXT[shpMtd] : 'delivery'} />}</span>}
          />
          <Column
            title={<FormattedMessage id="store_name" />}
            dataIndex="s_nm"
          />
          <Column
            title={<FormattedMessage id="order_date" />}
            dataIndex="dt"
            render={(date) => <span>{_helper.utcToLocal(date)}</span>}
          />
          <Column
            title={<FormattedMessage id="order_amount" />}
            dataIndex="amt"
            render={(total, record) => <span>{_helper.formatCurrency(total, record.curr)}</span>}
          />
          <Column
            title={<FormattedMessage id="status" />}
            dataIndex="st"
            render={(status) => <span><FormattedMessage id={_config.ORDER_STATUS_MAPPING[status]} /></span>}
          />
        </Table>
      </AccountPageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  let orderListState = state['order-list'];
  let notificationsState = state['notifications']
  let authState = state['auth'];
  return {
    isAuthenticated: authState.isAuthenticated,
    isNotificationUpdated: notificationsState.isNotificationUpdated,
    notificationOrderId: notificationsState.notificationOrderId,
    orderList: orderListState.orderList,
    orderListPaging: orderListState.orderListPaging
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    fetchOrderList: bindActionCreators((params = {}) => {
      return {
        type: "ORDER_LIST__FETCH_ORDER_LIST",
        payload: params
      }
    }, dispatch),
    clearOrderListData: bindActionCreators((params = {}) => {
      return {
        type: "ORDER_LIST__CLEAR_DATA",
      }
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
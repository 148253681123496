import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';

class OrderTotals extends Component {

  render() {
    let {
      table_number,
      party_size,
      server,
      shipping_method,
    } = this.props.orderDetailInstance.data;


    if (shipping_method == 3 && (table_number || party_size || server)) { //3 == eatin
      return (
        <div className="box order-items-wrapper">
          <div className="box-section">
            {
              ['table_number', 'party_size', 'server'].map((extraKey) => {
                if (this.props.orderDetailInstance.data[extraKey]) {
                  return (
                    <div key={`extra-info-${extraKey}`}>
                      <span className="bold"><FormattedMessage id={extraKey} /></span>: {this.props.orderDetailInstance.data[extraKey]}
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}


export default OrderTotals;
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import moment from 'moment';
import 'moment/locale/zh-cn'
import 'moment/locale/zh-hk'
import 'moment/locale/ja'
import _config from 'config'
import _locale from 'locale';
import countryData from 'utils/data/countryList.json'

const APP_LAN_MOMENT_LAN_MAP = {
  "zh": 'zh-cn',
  "en": "en",
  "zh-Hant": "zh-hk",
  "jp": "ja"
}

const _ = { cloneDeep, compact }

function getLocalStorage(key) {
  let result;
  try {
    result = localStorage.getItem(key);
    return JSON.parse(result);
  } catch (e) {
    return result;
  }
}

function setLocalStorage(key, value) {
  try {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
}

function removeLocalStorage(key) {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

function utcToLocal(time, params = {}) {
  let defaultParams = {
    type: 'short',
    time: true,
    date: true,
    asap: false,
    local: true,
    dateTimeSeperator: ',',
    timeFormat: 'HH:mm'
  }
  const rParams = Object.assign({}, defaultParams, params);

  if (time) {
    if (rParams.asap && time.includes('2000-01-01 00:00')) {
      return 'ASAP'
    }
    let revisedTime = time
    if (rParams.local) {
      revisedTime = time.includes('UTC') ? time : `${time} UTC`;
      revisedTime = revisedTime.replace(/-/g, "/");
    }

    let todayDate = moment().date()
    let todayMonth = moment().month()
    let todayYear = moment().year()

    let parsedDate = moment(revisedTime).date()
    let parsedMonth = moment(revisedTime).month()
    let parsedYear = moment(revisedTime).year()

    let sameDate = todayDate == parsedDate && todayMonth == parsedMonth && todayYear == parsedYear
    let sameMonth = todayMonth === parsedMonth && todayYear === parsedYear
    let sameYear = todayYear === parsedYear

    let dateFormat;
    if (rParams.date) {
      switch (rParams.type) {
        case 'short':
          dateFormat = sameYear ? 'MMM D' : 'MMM D, YYYY';
          break
        case 'medium':
          dateFormat = 'MMMM D, YYYY';
          break
        case 'long':
          dateFormat = 'MMMM Do YYYY';
          break
        case 'shortest':
          dateFormat = sameDate ? '' : sameYear ? 'MMM DD' : 'MMM DD YYYY';
          break
        case 'YYYY-MM-DD':
          dateFormat = 'YYYY-MM-DD';
          break
        case 'date-only':
          dateFormat = 'DD';
          break
        default:
          dateFormat = 'MMM D YYYY';
      }
    }

    if (rParams.time) {
      const timeFormat = rParams.timeFormat;
      if (dateFormat) {
        dateFormat += `${rParams.dateTimeSeperator}${timeFormat}`
      } else {
        dateFormat = timeFormat
      }
    }

    return rParams.local ? moment(new Date(revisedTime)).local().format(dateFormat) : moment(new Date(revisedTime)).format(dateFormat)
  }

}

function getTransString(stringMap, lan = 'en') {
  let returnStr = ''
  if (stringMap[lan]) {
    returnStr = stringMap[lan]
  } else if (lan === 'zh' && stringMap['zh-Hant']) {
    returnStr = stringMap['zh-Hant']
  } else if (lan === 'zh-Hant' && stringMap['zh']) {
    returnStr = stringMap['zh']
  } else if (stringMap['en']) {
    returnStr = stringMap['en']
  } else if (Object.keys(stringMap) && Object.keys(stringMap).length) {
    returnStr = stringMap[Object.keys(stringMap)[0]]
  } else {
    return ''
  }

  return returnStr.trim();
}

function roundToTwo(num) {
  return (Math.round(num * 100) / 100).toFixed(2)
}

function addrToString(params) {
  let { street, city, region, country_code, post_code } = params;
  return (_.compact([street, city, region, country_code, post_code])).join(', ');
}

function generateId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function formatCurrency(amount, currency = 'CAD', prefix = '') {
  let symbol = currency ? _config.CURRENCY_SYMBOL[currency] : _config.CURRENCY_SYMBOL['CAD'];

  if (typeof (amount) !== 'undefined') {
    // regex adds comma every third number
    return prefix + symbol + parseFloat(Math.round(Math.abs(amount) * 100) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  } else {
    return false
  }
}

function getTaxNumber(vat, country, province) {
  let taxPrefix = ''
  if (vat) {
    if (country == 'CA') {
      switch (province) {
        case ("ON"):
        case ("NB"):
        case ("NL"):
        case ("NS"):
        case ("PE"):
          taxPrefix = "HST";
          break;
        default:
          taxPrefix = "GST";
          break;
      }
    } else if (country == 'US') {
      taxPrefix = "VAT";
    }
  }
  return vat ? taxPrefix + " #" + vat : ''
}

function catToTree(catList) {
  let maxLevel = 1;

  catList.forEach((cat) => {
    if (parseInt(cat.level) > maxLevel) {
      maxLevel = parseInt(cat.level)
    }
  })

  let sortedList = {};

  for (let currLevel = 1; currLevel <= maxLevel; currLevel++) {
    let rList = [];
    catList.forEach((cat) => {
      if (cat.level === currLevel) {
        rList.push(cat)
      }
    })
    sortedList[currLevel] = rList
  }
  for (let currLevel = maxLevel; currLevel >= 1; currLevel--) {
    let prevLevel = currLevel - 1;
    if (prevLevel >= 1) {
      sortedList[prevLevel].forEach((prevCat) => {
        prevCat.children = [];
        sortedList[currLevel].forEach((currCat) => {
          if (currCat.parent_id === prevCat.category_id) {
            prevCat.children.push(currCat)
          }
        })
      })
    }
  }

  let tree = _.cloneDeep(sortedList[1]);
  return tree;
}

function objToQuery(url, obj) {
  if (!url) return
  var query = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      query.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  if (query) {
    url = url + '?' + query.join("&")
  }
  return url;
}

function objToParams(url, obj) {
  if (!url) return
  // object keys must be order
  var params = [];
  for (var p in obj) {
    params.push(obj[p])
  }
  if (params.length) {
    url = url + '/' + params.join("/")
  }
  return url;
}

function checkExpired(edt) {
  let revisedEdtTime = edt.includes('UTC') ? edt : `${edt} UTC`;

  let currentUTCTime = Date.now();
  let milliEdtTime = Date.parse(revisedEdtTime.replace(/-/g, "/"))

  return currentUTCTime >= milliEdtTime;
}

function customJoin(values = [], symbol = ', ') {
  if (Array.isArray(values) && values.length) {
    return _.compact(values).join(symbol)
  } else {
    return '';
  }
}

function formatAddress(address = {}, params) {
  let defaultParams = {
    size: 3,
    withCustInfo: false,
    compactWith: ', ',
    without: [],
  }
  let toReturn = []
  let rParams = Object.assign({}, defaultParams, params)
  let addressObj = _.cloneDeep(address)

  rParams.without.map((key) => {
    delete addressObj[key]
  })

  let { buzz, unit, street, city, region, zipcd, } = addressObj;
  let cntry = addressObj.cntry || addressObj.country
  let unitCode = unit ? "#" + unit : ''
  let buzzCode = buzz ? "buzz: " + buzz : ''

  let compactWith = rParams.compactWith

  if (rParams.size == 1) {
    toReturn = [
      (_.compact([street, city, region, zipcd, cntry])).join(compactWith)
    ]
  } else if (rParams.size == 2) {
    toReturn = [
      (_.compact([street])).join(', '),
      (_.compact([city, region, zipcd, cntry])).join(compactWith)
    ]
  } else if (rParams.size == 3) {
    let line1 = _.compact([buzzCode, unitCode, street]).join(', ');
    let line2 = _.compact([city, region, zipcd]).join(', ');
    let line3 = cntry;
    toReturn = _.compact([line1, line2, line3])
  }
  return toReturn;
}


function getUrlParameters() {
  var a = window.location.search.substr(1).split('&');
  if (a == "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split('=', 2);
    if (p.length != 2) continue;
    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
}

function checkPhonePattern(phone, code = "CA") {

  let result = ''
  countryData.records.map(country => {
    if (country.country_code == code) {
      result = country.phone_format
    }
  })
  let reg = new RegExp(result)
  return reg.test(phone)

}

function validateCountryCode(code) {

  countryData.records.map(country => {
    if (country.country_code == code) {
      return true
    }
  })
  return false

}


function getCountryName(code) {

  let result = ''
  countryData.records.map(country => {
    if (country.country_code == code) {
      result = country.country_code + " (+" + country.area_code + ")"
    }
  })
  return result;

}
function cleanPhoneNumber(phone, countryCode = 'CA') {
  if (phone && phone.length > 2) {
    countryData.records.map(country => {
      if (country.country_code == countryCode) {
        let areaCode = country.area_code

        //removes (), - and white space
        phone = phone.replace(/[^\d]/g, "");

        //checks if the leading number is the same with the area_code
        let lengthAreaCode = areaCode.length;
        if (phone.substring(0, lengthAreaCode) == areaCode) {
          phone = phone.substring(lengthAreaCode);
        }
      }
    })
  }
  return phone;
}



function getCountryInd(code) {
  //let { lan } = this.props
  let records = countryData.records;
  let ind = 0;
  for (const record of records) {
    if (record.country_code == code) {
      return ind;
    } else {
      ++ind;
    }
  }
}


function reformatPhoneNumber(phone, code = "CA") {
  let result = ''
  countryData.records.map(country => {
    if (country.country_code == code) {
      result = country.phone_format
    }
  })
  let reg = new RegExp(result)
  if (phone) {
    return phone.replace(reg, "$1$2$3")
  } else {
    return ''
  }
}

function formatPhoneNumber(phone) {
  let phoneStr = ''
  if (phone) {
    phoneStr = phone
    phone = phone.replace(/[^\d]/g, "");
    let country = countryData.records.find(country => {
      let reg = new RegExp(country.phone_format)
      return phone.match(reg)
    })
    if (country) {
      if (country.country_code == 'CA' || country.country_code == 'US') {
        phoneStr = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      } else if (country.country_code == 'CN') {
        phoneStr = phone.replace(/(\d{3})(\d{4})(\d{4})/, "($1)$2-$3");
      } else {
        phoneStr = phone
      }
    }
  }
  return phoneStr
}

function reformatSearchString(string) {
  let reformat = '';
  if (string) {
    reformat = string.replace(/&/g, "%26")
    reformat = reformat.replace(/ /g, '+');
  }
  return reformat;
}

function getIndependentDomain() {
  const currentDomain = document.location.host;

  if (!currentDomain.includes('goopter.com') && !currentDomain.includes('goopter.cn') && !currentDomain.includes('localhost') && !currentDomain.includes('192.168')) {
    return currentDomain
  }
  return false;
}

function getFullDate(startDate, endDate) {
  let fullDate = '';

  if (startDate && startDate.includes("2000-01-01 00:00")) {
    return 'ASAP';
  }

  let revisedStart = moment(startDate, "YYYY-MM-DD HH:mm").format('MMMM D, YYYY/hh:mm')
  let revisedEnd = moment(endDate, "YYYY-MM-DD HH:mm").format('MMMM D, YYYY/hh:mm')

  if (revisedStart) {
    revisedStart = revisedStart.split('/')
  }
  if (revisedEnd) {
    revisedEnd = revisedEnd.split('/')
  }
  fullDate += utcToLocal(startDate, {
    type: 'shortest',
    asap: true
  })
  if (revisedEnd && revisedEnd[1] && revisedStart[1] !== revisedEnd[1]) {
    fullDate += `-${revisedEnd[1]}`
  }
  return fullDate
}

function formatShippingMethod(shippingMethod, storeCategory = 1) {
  if (shippingMethod == 'eatin') {
    if (storeCategory == 1) {
      shippingMethod = 'eatin'
    } else {
      shippingMethod = 'instore'
    }
  }
  return shippingMethod
}

// formating the display date to today, tomorrow or dafault depending on the date user input
function formatDisplayDate(date, lan, params = {}) {
  let defaultParams = {
    parsedFormat: 'YYYY-MM-DD',
    local: true,
  }
  let trans = (id) => _locale.getIntlMessages(lan, 'common')[id];

  const rParams = Object.assign({}, defaultParams, params);

  if (date) {
    let revisedTime = date
    if (rParams.local) {
      revisedTime = date.includes('UTC') ? date : `${date} UTC`;
      revisedTime = revisedTime.replace(/-/g, "/");
    }

    let todayDate = moment().date()
    let todayMonth = moment().month()
    let todayYear = moment().year()

    let parsedDate = moment(revisedTime, rParams.parsedFormat).date()
    let parsedMonth = moment(revisedTime, rParams.parsedFormat).month()
    let parsedYear = moment(revisedTime, rParams.parsedFormat).year()

    let yesterday = todayDate - 1 == parsedDate && todayMonth == parsedMonth && todayYear == parsedYear
    let today = todayDate == parsedDate && todayMonth == parsedMonth && todayYear == parsedYear
    let tomorrow = todayDate + 1 == parsedDate && todayMonth == parsedMonth && todayYear == parsedYear

    if (today) {
      date = trans("today");
    } else {
      date = moment(date).format('ddd, MMM DD')
    }

    return date
  }
}

// function adds comma every third number using regular expression
function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

//function to check if the parsed day is today
function isToday(date, params) {
  let defaultParams = {
    parsedFormat: 'YYYY-MM-DD',
    local: false,
  }
  const rParams = Object.assign({}, defaultParams, params);

  let today = false
  if (date) {
    let revisedTime = date
    if (rParams.local) {
      revisedTime = date.includes('UTC') ? date : `${date} UTC`;
      revisedTime = revisedTime.replace(/-/g, "/");
    }

    let todayDate = moment().date()
    let todayMonth = moment().month()
    let todayYear = moment().year()

    let parsedDate = moment(revisedTime, rParams.parsedFormat).date()
    let parsedMonth = moment(revisedTime, rParams.parsedFormat).month()
    let parsedYear = moment(revisedTime, rParams.parsedFormat).year()


    today = todayDate == parsedDate && todayMonth == parsedMonth && todayYear == parsedYear
  }

  return today
}

function getIsRequiredMessage(lan, id) {
  return _locale.getIntlMessages(lan, 'common')[id] + _locale.getIntlMessages(lan, 'common')['x_is_required']
}

function getCurrentYear() {
  return moment().year();
}

function updateMomentLocale(result) {
  const locale = APP_LAN_MOMENT_LAN_MAP[result] ? APP_LAN_MOMENT_LAN_MAP[result] : 'en'
  moment.locale(locale)
}

function generateSubPaths(combos) {
  function permutate(src, minLen, maxLen) {
    minLen = minLen - 1 || 0;
    maxLen = maxLen || src.length + 1;
    var Asource = src.slice(); // copy the original so we don't apply results to the original.

    var Aout = [];

    var minMax = function (arr) {
      var len = arr.length;
      if (len > minLen && len <= maxLen) {
        Aout.push(arr);
      }
    }

    var picker = function (arr, holder, collect) {
      if (holder.length) {
        collect.push(holder);
      }
      var len = arr.length;
      for (var i = 0; i < len; i++) {
        var arrcopy = arr.slice();
        var elem = arrcopy.splice(i, 1);
        var result = holder.concat(elem);
        minMax(result);
        if (len) {
          picker(arrcopy, result, collect);
        } else {
          collect.push(result);
        }
      }
    }


    picker(Asource, [], []);


    return Aout;
  }

  let result = [];
  permutate(combos, 0, combos.length).map((combination) => {
    let path = ''
    combination.map((com) => path += `/${com}/:${com}`)
    result.push(path)
  })

  return result;
}

export default {
  getLocalStorage,
  setLocalStorage,
  getCountryName,
  getCountryInd,
  removeLocalStorage,
  utcToLocal,
  getTransString,
  getIsRequiredMessage,
  roundToTwo,
  addrToString,
  generateId,
  getKeyByValue,
  formatCurrency,
  getTaxNumber,
  catToTree,
  objToQuery,
  objToParams,
  checkExpired,
  customJoin,
  formatAddress,
  getUrlParameters,
  checkPhonePattern,
  formatPhoneNumber,
  reformatPhoneNumber,
  reformatSearchString,
  getIndependentDomain,
  getFullDate,
  cleanPhoneNumber,
  validateCountryCode,
  formatShippingMethod,
  formatDisplayDate,
  formatNumber,
  isToday,
  getCurrentYear,
  updateMomentLocale,
  generateSubPaths,
}
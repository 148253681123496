import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FirebaseNotifications from './fire-base-notifications'

class Notifications extends React.Component {
  render() {
    return (
      <div>
        <FirebaseNotifications />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})
const mapDispatchToProps = ({})

Notifications = connect(mapStateToProps, mapDispatchToProps)(Notifications);
export default withRouter(Notifications)
import cloneDeep from 'lodash/cloneDeep'
import _helper from 'utils/helper';
import _ from 'lodash'

export function getDefaultState() {
    return {
        device_token: '',
        provider: '',
        notiRefresh: false,
    }
}

export default {
    'notifications': (state = getDefaultState(), action) => {
        switch (action.type) {
            case 'NOTIFICATIONS__SET_STATE':
                return Object.assign({}, _.cloneDeep(state), action.state);
            default:
                return state;
        }
    }
}


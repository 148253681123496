import { call, put, takeEvery } from 'redux-saga/effects';
import _config from 'config';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _bookmarkActions from 'containers/bookmarks/actions';

function* fetchBookmarkList(action) {
	try {
		let url = _apiRoutes['bookmarks'];
		let bookmarksResponse, storeResponse, productResponse;

		bookmarksResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');
		if (bookmarksResponse && bookmarksResponse.RC === 200) {
			let { store, product } = bookmarksResponse.records;
			if (store && store.length) {
				let gids = store.join(',')
				let storeListUrl = `${_config['API_ROOT'] + _apiRoutes['store-list']}?g_ids=${gids}`;
				storeResponse = yield call(_api.get, storeListUrl);
			}
			if (product && product.length) {
				let pids = product.join(',')
				let productListUrl = `${_config['API_ROOT'] + _apiRoutes['product-search']}?pids=${pids}`;
				productResponse = yield call(_api.get, productListUrl);
			}
		}

		yield put({ type: "BOOKMARKS__FETCH_BOOKMARK_LIST_SUCCESS", response: { bookmarksResponse, storeResponse, productResponse }, payload: { ...action.payload } });
	} catch (e) {
		yield put({ type: "BOOKMARKS__FETCH_BOOKMARK_LIST_FAILED", message: e.message, action: action.payload });
	}
}

function* removeBookmark(action) {
	let { type, id } = action.payload;
	try {
		let typeId;

		yield put({type: "BOOKMARKS__REMOVE_BOOKMARK_LOADING", payload: {type, id, loading: true}})


		switch (type) {
			case 'store':
				typeId = 7;
				break
			case 'product':
				typeId = 6;
				break
			case 'groupsale':
				typeId = 2;
				break
			default:
				typeId = 7;
		}
		let url = _apiRoutes['bookmarks'];

		let postBody = {
			data: {
				ids: [id],
				type: typeId,
				action: 'delete'
			}
		}

		let bookmarksResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);
		yield put({ type: "BOOKMARKS__FETCH_BOOKMARK_LIST" })

		yield put({type: "BOOKMARKS__REMOVE_BOOKMARK_LOADING", payload: {type, id, loading: false}})
	} catch (e) {
		yield put({type: "BOOKMARKS__REMOVE_BOOKMARK_LOADING", payload: {type, id, loading: false}})
	}
}


export default [
	takeEvery('BOOKMARKS__FETCH_BOOKMARK_LIST', fetchBookmarkList),
	takeEvery('BOOKMARKS__REMOVE_BOOKMARK', removeBookmark),
];


import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Icon, Steps, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

const Step = Steps.Step;

class StatusSteps extends Component {

  getOrderStatus = () => {
    let { status, intl, isLoading, fetchOrderDetail } = this.props;
    if (status) {
      let currentStep = [1, 2, 4].indexOf(status); // 1: pending, 2: processing, 4: completed
      let stepParams = {
        pending: { title: <FormattedMessage id="pending" />, description: '' },
        processing: { title: <FormattedMessage id="processing" />, description: '' },
        completed: { title: <FormattedMessage id="completed" />, description: '' }
      }
      let refreshButton = <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
        <Icon className="refresh-button" onClick={() => { fetchOrderDetail() }} type="redo" />
      </Tooltip>
      if (status === 1) {
        stepParams.pending.icon = isLoading ? <Icon type="loading" /> : refreshButton;
        stepParams.processing.icon = <Icon type="fire" />;
        stepParams.completed.icon = <Icon type="check" />;
        stepParams.pending.description = intl.formatMessage({ id: 'order_pending_descrip' });
      } else if (status === 2) {
        stepParams.pending.icon = <Icon type="shake" />;
        stepParams.processing.icon = isLoading ? <Icon type="loading" /> : refreshButton;
        stepParams.completed.icon = <Icon type="check" />;
        stepParams.processing.description = intl.formatMessage({ id: 'order_processing_descrip' });
      } else if (status === 4) {
        stepParams.pending.icon = <Icon type="shake" />;
        stepParams.processing.icon = <Icon type="fire" />;
        stepParams.completed.icon = <Icon type="check" />;
        stepParams.completed.description = intl.formatMessage({ id: 'order_complete_descript' });
      } else if (status === 5) {
        currentStep = 2; // 2 is the last step
        stepParams.pending.icon = <Icon type="shake" />;
        stepParams.pending.status = 'wait'
        stepParams.processing.icon = <Icon type="fire" />;
        stepParams.processing.status = 'wait'
        stepParams.completed.icon = <Icon type="close" />;
        stepParams.completed.status = 'error';
        stepParams.completed.title = <FormattedMessage id="canceled" />;
        stepParams.completed.description = intl.formatMessage({ id: 'order_cancelled_descript' });
      }

      return (
        <div className="status-wrapper">
          <Steps current={currentStep}>

            {Object.keys(stepParams).map((statusKey, i) => {
              return (
                <Step
                  key={`${statusKey}-${i}`}
                  title={stepParams[statusKey].title}
                  icon={stepParams[statusKey].icon}
                  description={stepParams[statusKey].description}
                  status={stepParams[statusKey].status ? stepParams[statusKey].status : null}
                />
              )
            })}

          </Steps>
        </div>
      )
    } else {
      return null;
    }
  }


  render() {
    let {
      status
    } = this.props;

    return this.getOrderStatus()
  }
}


export default injectIntl(StatusSteps);
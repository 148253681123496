import _config from 'config';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _helper from 'utils/helper';

function getLatLonFromAddress(address, callBack) {
    let lat = 0
    let lon = 0
    let queryString = _helper.formatAddress(address, { size: 1, compactWith: ' ' })[0]
    let googleUrl = `${_apiRoutes['googleGeo']}?address=${queryString}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    _api.get(googleUrl).then(res => {
        let { status, results } = res
        if (status == 'OK' && Array.isArray(results) && results.length) {
            let details = results[0]
            if (details.geometry && details.geometry.location) {
                let geoDetail = details.geometry.location
                lat = geoDetail.lat
                lon = geoDetail.lng
                callBack({ lat, lon })
            }
        }
    })

}

function getBoundsFromAddress(address, callBack) {

    let queryString = _helper.formatAddress(address, { size: 1, compactWith: ' ' })[0]
    let googleUrl = `${_apiRoutes['googleGeo']}?address=${queryString}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    _api.get(googleUrl).then(res => {
        let { status, results } = res
        if (status == 'OK' && Array.isArray(results) && results.length) {
            let details = results[0]
            // return exmample from google
            // "bounds" : {
            //     "northeast" : {
            //        "lat" : 42.1282269,
            //        "lng" : -87.7108162
            //     },
            //     "southwest" : {
            //        "lat" : 42.0886089,
            //        "lng" : -87.7708629
            //     }
            //  },
            if (details.geometry && details.geometry.bounds
                && details.geometry.bounds.northeast
                && details.geometry.bounds.southwest) {
                let boundsObj = details.geometry.bounds
                callBack({ bounds: boundsObj })
            }
        }
    })
}


export default {
    getLatLonFromAddress,
    getBoundsFromAddress,
}
import _ from 'lodash';
import _helper from 'utils/helper';

export function getDefaultState() {
  return {
    authLoading: true, // used on app first to load setAuth before routes are loaded
    isAuthenticated: false,
    authFormLoading: false,
    loginDialogDisplay: false,
    logoutDialogDisplay: false,
    logoutDialogType: '',
    loginType: 'account', /* account or text */
    userProfile: null,
    loginLoading: false,
  }
}

function setAuth(state, action, status) {
  let finalState = _.cloneDeep(state);
  finalState.authLoading = false;
  if (status) {
    let { userProfile } = action.response;
    finalState.userProfile = _.cloneDeep(userProfile);
    finalState.isAuthenticated = true;
  } else {
    finalState.isAuthenticated = false;
  }

  return finalState;
}

function unsetAuth(state, action) {
  let finalState = _.cloneDeep(state);
  finalState.isAuthenticated = false;
  return finalState;
}

function toggleLoginType(state, action) {
  let finalState = _.cloneDeep(state);
  finalState.loginType = finalState.loginType === 'account' ? 'text' : 'account';
  return finalState;
}

function login(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    finalState.loginDialogDisplay = false;
    finalState.loginType = 'account';
  }

  finalState.loginLoading = false;
  return finalState;
}

function toggleAuthForm(state, action, status) {
  let finalState = _.cloneDeep(state);
  let { loading } = action.payload
  finalState.authFormLoading = loading;
  return finalState;
}

function clearAuthData(state, action, status) {
  // reseting page after unmount
  let finalState = _.cloneDeep(state);
  finalState.authFormLoading = getDefaultState().authFormLoading;
  return finalState;
}

function fetchUserProfile(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { userProfile } = action.response;
    if (userProfile.RC === 200) {
      finalState.userProfile = _.cloneDeep(userProfile.records);
    }
  }

  return finalState;
}

function toggleLogoutDialog(state, action, status) {
  let finalState = _.cloneDeep(state);
  let { type, show } = action.payload;

  finalState.logoutDialogDisplay = show ? true : false;
  finalState.logoutDialogType = show && type ? type : getDefaultState().logoutDialogType;
  return finalState;
}

function logoutClearData(state, action, status) {
  let finalState = _.cloneDeep(state);

  finalState.isAuthenticated = false
  finalState.userProfile = null
  _helper.removeLocalStorage('accessToken');
  _helper.removeLocalStorage('accessSecret');

  return finalState;
}


export default {
  'auth': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'AUTH__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'AUTH__CLEAR_AUTH_DATA':
        return clearAuthData(state, action)
      case 'AUTH__SET_AUTH_SUCCESS':
        return setAuth(state, action, true)
      case 'AUTH__SET_AUTH_FAILED':
        return setAuth(state, action, false)
      case 'AUTH__UNSET_AUTH':
        return unsetAuth(state, action)
      case 'AUTH__TOGGLE_LOGIN_TYPE':
        return toggleLoginType(state, action)
      case 'AUTH__TOGGLE_LOGOUT_DIALOG':
        return toggleLogoutDialog(state, action);
      case 'AUTH__TOGGLE_AUTH_FORM':
        return toggleAuthForm(state, action)
      case 'AUTH__LOGIN_SUCCESS':
        return login(state, action, true);
      case 'AUTH__LOGIN_FAILED':
        return login(state, action, false);
      case 'AUTH__FETCH_USER_PROFILE_SUCCESS':
        return fetchUserProfile(state, action, true);
      case 'AUTH__FETCH_USER_PROFILE_FAILED':
        return fetchUserProfile(state, action, false);
      case 'AUTH__CLEAR_DATA':
        return logoutClearData(state, action, false);
      default:
        return state;
    }
  }
}

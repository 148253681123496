import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Layout, Form, Input, Checkbox, Button, Divider, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from 'utils/helper';

const FormItem = Form.Item;


class ByEmail extends Component {
    render() {
        let {
            hide,
            login,
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            updateEmailLoading,
            isPasswordSet,
        } = this.props;

        let { formatMessage } = this.props.intl;
        return (
            <Form onSubmit={handleSubmit} style={{ display: hide ? 'none' : null }}>

                <FormItem
                    label={<FormattedMessage id="email" />}
                    colon={false}
                    help={touched.email && errors.email ? errors.email : ''}
                    validateStatus={touched.email && errors.email ? 'error' : ''}
                >
                    <Input
                        disabled
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                    />
                </FormItem>

                <Divider />
                <FormItem>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={updateEmailLoading}
                        loading={updateEmailLoading} block>
                        <FormattedMessage id="request_pass_reset" />
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

let validationSchema = (lan) => yup.object().shape({
    email: yup.string().required(_helper.getIsRequiredMessage(lan, 'email')),
});

ByEmail = withFormik({
    mapPropsToValues: (props) => ({ ...props.initialValues }),
    handleSubmit: (values, { props, setErrors, setSubmitting }) => {
        props.updateUserPassword(values)
    },
    validationSchema: (props) => validationSchema(props.lan),
    enableReinitialize: true

})(ByEmail)
export default injectIntl(ByEmail);
import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { Collapse, Radio } from 'antd';
import { injectIntl, FormattedMessage, } from 'react-intl';
import CreditCardDetail from '../../checkout-page/page-components/paymentInfo/credit-card-details'
import _ from 'lodash'
import { connect } from 'react-redux';
import _pageHelper from '../helper'

import CreditCardIcon from 'images/CreditCard.png';
import WeChatPayIcon from 'images/Wechat_Pay.png';
import PaypalIcon from 'images/Paypal.png';
import AlipayIcon from 'images/Alipay.png';
import UnionPayIcon from 'images/UnionPay.png'
import UnionPayExpressIcon from 'images/UnionPayExpress.png'


const Panel = Collapse.Panel;

class PaymentMethodCollapse extends Component {

    state = {}

    getPaymentsData = () => {
        const { state, store, setState, lan, order } = this.props;
        const { validate } = this.state
        const { accept_alipay, accept_creditcard,
            accept_express, accept_wechat,
            accept_union_pay, accept_union_pay_express,
        } = store
        return [
            {
                requirement: accept_creditcard,
                header_icon: CreditCardIcon,
                header: 'credit_card',
                special: <CreditCardDetail
                    lan={lan}
                    validate={validate}
                    checkoutState={state}
                    withValidateOnBlur={true}
                    setCheckoutState={(key, value) => {
                        if (key === 'creditCardValidated' && value) {
                            setState({ paymentMethod: 'credit_card' })
                        } else if (key === 'creditCardValidated' && !value) {
                            setState({ paymentMethod: '' })
                        } else if (key === 'creditCard') {
                            setState({ [key]: value })
                        }
                    }}
                />
            },
            {
                requirement: accept_wechat && !_pageHelper.hasGiftCards(order),
                header_icon: WeChatPayIcon,
                header: 'wechat_pay',
            },
            {
                requirement: accept_express,
                header_icon: PaypalIcon,
                header: 'paypal_express',
            },
            {
                requirement: accept_alipay && !_pageHelper.hasGiftCards(order),
                header_icon: AlipayIcon,
                header: 'alipay',
            },
            {
                requirement: accept_union_pay,
                header_icon: UnionPayIcon,
                header: 'union_pay',
            },
            {
                requirement: accept_union_pay_express,
                header_icon: UnionPayExpressIcon,
                header: 'union_pay_express',
            },
        ]
    }

    getActiveId = () => {
        return ['credit_card']
    }

    setPaymentMethod = (key) => {
        if (key === 'credit_card') {
            this.setState({ validate: !this.state.validate })
        } else {
            this.props.setState({ paymentMethod: key })
        }
    }

    renderPanel = (data) => {
        const paymentMethod = _.get(this.props, 'state.paymentMethod', '')
        const customPanelStyle = {
            background: 'white',
            borderRadius: 4,
            overflow: 'hidden',
        };

        const renderPanelHeader = () => {
            return (
                <div onClick={() => this.setPaymentMethod(data.header)} >
                    <Radio checked={data.header === paymentMethod} >
                        <img className="header-img" src={data.header_icon} />
                        <span className="header-text">
                            <FormattedMessage id={data.header} />
                        </span>
                    </Radio>
                </div>
            )
        }

        const renderPanelContent = () => {
            return data.special || ''
        }

        return (
            <Panel
                showArrow={false}
                header={renderPanelHeader()}
                key={data.header}
                style={customPanelStyle}>
                {renderPanelContent()}
            </Panel>
        )
    }

    renderPaymentCollapse = () => {
        return (
            <Collapse
                activeKey={this.getActiveId()}
            >
                {this.getPaymentsData().map((data, i) => {
                    if (data.requirement) return this.renderPanel(data)
                })}
            </Collapse>
        )
    }

    render() {
        return (
            <div>
                {this.renderPaymentCollapse()}
            </div>

        )
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "pay-now", {}),
    store: _.get(state, 'pay-now.store.data', ''),
    order: _.get(state, 'pay-now.order.data', '')
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'PAY_NOW__SET_STATE', state })
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentMethodCollapse));
import _ from 'lodash';
import config from '../../config'
import _helper from '../../utils/helper'
import moment from 'moment'

const getTipsSetting = (store) => {
    return {
        percents: _.get(store, 'tips.pct', [0, 10, 12, 15, 20]), //Array of percentage tips
        amts: _.get(store, 'tips.amt', [0, 2, 3, 4, 5]), // Array of $ tips
        cut: _.get(store, 'tips.limit', 20), //if the order total is over the limit then show percentage, otherwise show dollar amount,
        default_percent: _.get(store, 'tips.dflt_pct', [12, 12, 0]), //1 delivery, 2 dine in, 3 pickup
        default_amt: _.get(store, 'tips.dflt_amt', [2, 2, 0]),
    }
}

const getDefaultTips = (store, method, isPercent = true) => {
    const key = isPercent ? 'default_percent' : 'default_amt'
    const defaultPertentage = getTipsSetting(store)[key]
    if (Array.isArray(defaultPertentage)) {
        const tipsIndex = config.SHIPPING_METHOD_TIPS_INDEX_MAP[method]
        return defaultPertentage[tipsIndex ? tipsIndex : 0]
    }
    return defaultPertentage
}

const getGid = () => {
    return _.get(_helper.getUrlParameters(), 'query', '')
}

const getCurrentHours = (timeSetting, code) => {
    const time = Array.isArray(timeSetting) && timeSetting.find(t => t.code === code);
    if (_.isEmpty(time)) return []

    const now = moment();
    const nowDay = now.locale('en').format('dd').toLowerCase();
    const availableTimes = _.get(time, 'available_time', []);

    var hours = [];
    Array.isArray(availableTimes) && availableTimes.find(t => {
        const days = _.get(t, 'days', [])
        if (Array.isArray(days) && days.includes(nowDay)) {
            hours = _.get(t, 'hours', [])
        }
    })
    return hours
}

const TIME_SETTING_HOURS_FORMAT = 'H:mm';
const isAvailable = (timeSetting, code) => {
    if (!code || _.isEmpty(timeSetting)) return true
    const hours = getCurrentHours(timeSetting, code);
    if (_.isEmpty(hours) || !Array.isArray(hours)) return false
    const now = moment();
    return hours.find(h => {
        const open = moment(_.get(h, 'open'), TIME_SETTING_HOURS_FORMAT);
        const close = moment(_.get(h, 'close'), TIME_SETTING_HOURS_FORMAT);
        return now.isBetween(open, close)
    })
}


export default {
    getTipsSetting,
    getDefaultTips,
    getGid,
    isAvailable,
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from '../../../utils/helper';
import { Icon, Spin, Divider, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import AddressEditor from 'containers/address-book/address-editor';

import _addressBookActions from 'containers/address-book/actions';

import './_address-book-wrapper.scss';


class AddressBookWrapper extends Component {

  componentDidMount() {
    this.fetchAddressList()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.fetchAddressList(nextProps)
    }
  }

  fetchAddressList(props = this.props) {
    let { fetchAddressList, isAuthenticated } = props;
    if (isAuthenticated) {
      fetchAddressList();
    }
  }

  defaultAddress = {
    id: '',
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    zipCode: '',
    city: '',
    country: '',
    region: '',
    unit: '',
    buzz: '',
    is_default: false,
    selectedMode: false,
    use_name_on_acc: false,
  }

  state = {
    addressEditorDisplay: false,
    addressValues: this.defaultAddress,
  };

  toggleAddressEditor = (bool) => {
    this.setState({
      addressEditorDisplay: bool,
    });
  };

  handleChangeSelected = (address) => {
    if (this.props.selectedMode) {
      this.props.onSelectedChange(_.cloneDeep(address))
    }
  }

  handleEditAddress = (address) => {
    // when clicking on edit address. Set addressValues to the address values then open address editor

    let editBody = {
      id: address.id,
      firstName: address.fn ? address.fn : '',
      lastName: address.ln ? address.ln : '',
      phone: address.tn,
      street: address.street,
      zipCode: address.zipcd,
      city: address.city,
      country: address.cntry,
      region: address.region,
      unit: address.unit ? address.unit : '',
      buzz: address.buzz ? address.buzz : '',
      is_default: address.is_default,
    }

    // let default
    this.setState({
      addressValues: editBody
    }, () => this.toggleAddressEditor(true))
  }

  toggleAddNewAddress = () => {
    // when clicking on add new address. Set addressValues to defaultAddress then open address editor
    let { userProfile, } = this.props;

    let defaultAddress = this.defaultAddress
    defaultAddress.firstName = userProfile.first_name;
    defaultAddress.lastName = userProfile.last_name;
    defaultAddress.phone = _helper.cleanPhoneNumber(userProfile.phone);

    this.setState({
      addressValues: defaultAddress
    }, () => this.toggleAddressEditor(true))
  }

  render() {
    let {
      addressList,
      deleteAddress,
      addressListLoading,
      setDefaultAddress,
      selectedMode,
      selected,
      userProfile,
      address
    } = this.props;

    let {
      addressEditorDisplay,
      addressValues,
    } = this.state;

    return (

      <Spin spinning={addressListLoading}>
        <div className="account-book-page-wrapper">

          <div className="address-item-wrapper add-address-btn-wrapper"
            onClick={() => this.toggleAddNewAddress()}>
            <div className="add-address-btn-content">
              <div><Icon type="plus" style={{ paddingBottom: '5px', color: '#afafaf', fontSize: '45px' }} /></div>
              <div><FormattedMessage id="add_address" /></div>
            </div>
          </div>

          {addressList.map((address) => {
            let addressArr = _helper.formatAddress(address, { without: ['cntry'] });
            return (
              <div
                className={classnames({
                  "address-item-wrapper": true,
                  "selected": selected && selected.id == address.id && selectedMode,
                })}
                key={`address-${address.id}`}
              >
                <div className="address-item-content">
                  <div className="address-data" onClick={() => !selectedMode ?
                    this.handleEditAddress(address) : this.handleChangeSelected(address)}>
                    <div><b>{[address.fn, address.ln].join(' ')}</b></div>
                    {addressArr.map((addressLine, i) => {
                      return <div key={`address-line-${address.id}-${i}`}>{addressLine}</div>
                    })}
                    <div><Icon type="phone" style={{ paddingRight: '3px', color: '#333333' }} />{_helper.formatPhoneNumber(address.tn)}</div>
                  </div>
                  <div className="address-actions">
                    <Checkbox className="link" checked={address.is_default} onChange={() => setDefaultAddress({ addressId: address.id, isDefault: !address.is_default })}><FormattedMessage id="set_as_default" />  </Checkbox>
                    <Divider type="vertical" />
                    <span className="link" onClick={() => this.handleEditAddress(address)}><FormattedMessage id="edit" /></span>
                    <Divider type="vertical" />
                    {<span className="link" onClick={() => {
                      deleteAddress({ id: address.id });
                    }}><FormattedMessage id="delete" /></span>}
                  </div>
                </div>
              </div>
            )
          })}

          <AddressEditor
            toggleAddressEditor={(bool) => this.toggleAddressEditor(bool)}
            addressEditorDisplay={addressEditorDisplay}
            addressValues={addressValues}
            setAddressValues={(values) => {
              let editedAddress = Object.assign({}, this.state.addressValues, _.cloneDeep(values))
              this.setState({ addressValues: editedAddress });
            }}
            saveAddress={(values) => {
              this.props.saveAddress(values)
              if (this.props.selectedMode) {
                //reformat address then save to seleted
                const { firstName, lastName, phone, street,
                  buzz, unit, country, region, city, zipCode, } = values;
                this.handleChangeSelected({
                  fn: firstName,
                  ln: lastName,
                  tn: phone,
                  cntry: country,
                  region,
                  zipcd: zipCode,
                  city,
                  street,
                  buzz,
                  unit,
                })
                this.setState({ addressEditorDisplay: false })
              }
            }}
            userProfile={userProfile}
            address={address}
          />

        </div>
      </Spin>

    )
  }
}

const mapStateToProps = (state) => {
  let addressBookState = state['address-book'];
  let authState = state['auth'];

  return {
    isAuthenticated: authState.isAuthenticated,
    pageLoading: addressBookState.pageLoading,
    addressList: addressBookState.addressList,
    addressListLoading: addressBookState.addressListLoading,
    saveAddressLoading: addressBookState.saveAddressLoading,
    userProfile: authState.userProfile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    fetchAddressList: bindActionCreators((bool) => {
      return _addressBookActions.fetchAddressList()
    }, dispatch),
    saveAddress: bindActionCreators((params) => {
      return _addressBookActions.saveAddress(params)
    }, dispatch),
    deleteAddress: bindActionCreators((params) => {
      return _addressBookActions.deleteAddress(params)
    }, dispatch),
    setDefaultAddress: bindActionCreators((params) => {
      return _addressBookActions.setDefaultAddress(params)
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressBookWrapper);
import _ from 'lodash';
// import _helper from 'utils/helper';

export function getDefaultState() {
  return {
    bookmarkPageLoading: true,
    bookmarkList: {
      store: [],
      product: []
    },
    removeBookmarkLoading: { // to see whether button is loading so its not clicked repeatedly
      store: [],
      product: [],
    }
  }
}

function fetchBookmarkList(state, action, status) {
  let finalState = _.cloneDeep(state);

  let { bookmarksResponse, storeResponse, productResponse } = action.response;

  if (status) {
    if (bookmarksResponse.RC === 200) {
      if (storeResponse && storeResponse.RC === 200) {
        finalState.bookmarkList.store = storeResponse.records
      } else {
        finalState.bookmarkList.store = [];
      }
      if (productResponse && productResponse.RC === 200) {
        finalState.bookmarkList.product = productResponse.records
      } else {
        finalState.bookmarkList.product = [];
      }
    }
  }

  finalState.bookmarkPageLoading = false;
  return finalState;
}

function removeBookmarkLoading(state, action) {
  let finalState = _.cloneDeep(state);
  let { type, id, loading} = action.payload;

  if (loading) { // make button loading
    finalState.removeBookmarkLoading[type].push(id)
    finalState.loadd = true
  } else {
    finalState.removeBookmarkLoading[type] = _.pull(finalState.removeBookmarkLoading[type], id)
    finalState.loadd = false

  }

  return finalState;
}

export default {
  'bookmarks': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'BOOKMARKS__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'BOOKMARKS__CLEAR_DATA':
        return getDefaultState();
      case 'BOOKMARKS__FETCH_BOOKMARK_LIST_SUCCESS':
        return fetchBookmarkList(state, action, true);
      case 'BOOKMARKS__FETCH_BOOKMARK_LIST_FAILED':
        return fetchBookmarkList(state, action, false);
      case 'BOOKMARKS__REMOVE_BOOKMARK_LOADING':
        return removeBookmarkLoading(state, action);
      default:
        return state;
    }
  }
}

import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tabs } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import ByExisting from 'containers/account-page/page-components/password-form/by-exising.jsx'
import ByEmail from 'containers/account-page/page-components/password-form/by-email.jsx'
import ByPhone from 'containers/account-page/page-components/password-form/by-phone.jsx'

const TabPane = Tabs.TabPane;

class ProfileForm extends Component {

    getTabs = () => {
        let { userProfile, updateUserPassword, getVerificationCode } = this.props
        let isPasswordSet = userProfile.is_password_set
        let isEmailSet = userProfile.email
        let isPhoneNumSet = userProfile.phone
        let tabs = []


        if (isPasswordSet) {
            tabs.push({
                key: "change_by_existing_password",
                component: <ByExisting
                    updateUserPassword={(values) => {
                        updateUserPassword({
                            oldPassword: values.oldPassword,
                            newPassword: values.newPassword
                        })
                    }}
                />
            })
        }

        if (isEmailSet) {
            tabs.push({
                key: "change_by_email",
                component: <ByEmail
                    initialValues={{
                        email: userProfile['email'] ? userProfile['email'] : ''
                    }}
                    updateUserPassword={(values) => {
                        updateUserPassword(values)
                    }}
                />
            })
        }

        if (isPhoneNumSet) {
            tabs.push({
                key: "change_by_text_message",
                component: <ByPhone
                    initialValues={{
                        countryCode: 'CA',
                        phone: userProfile['phone'] ? userProfile['phone'] : '',
                        vc: '',
                    }}
                    updateUserPassword={(values) => {
                        updateUserPassword({
                            phone: values.phone,
                            code: values.vc,
                            password: values.password
                        })
                    }}
                    isPasswordSet={isPasswordSet}
                    getVerificationCode={getVerificationCode}
                />
            })
        }

        return tabs
    }

    render() {
        let {
            intl
        } = this.props;

        return (
            <Tabs defaultActiveKey={this.getTabs().length ? this.getTabs()[0].key : ''}>
                {this.getTabs().map((tab) =>
                    <TabPane tab={intl.formatMessage({ id: tab.key })} key={tab.key}>
                        {tab.component}
                    </TabPane>
                )}
            </Tabs>
        )
    }
}

export default injectIntl(ProfileForm);
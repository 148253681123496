import { call, put, takeEvery } from 'redux-saga/effects';

import _config from 'config';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _helper from 'utils/helper';
import _ from 'lodash';

function* initPayPalPage(action) {
    try {
        yield put({ type: "PAYPAL_PAGE__SET_STATE", state: { isLoading: true } })
        let { orderId, lan } = action.payload;

        let payPalUrl = _apiRoutes['paypal-express'];
        const payPalResponse = yield _api.callCheckingAuthentication(_api.executeOauth, payPalUrl, 'POST', { data: { action: "1", oid: orderId } })

        let orderUrl = _apiRoutes['order-detail'] + `/${orderId}/${lan}`; // oid/en
        const orderDetailResponse = yield _api.callCheckingAuthentication(_api.executeOauth, orderUrl, 'GET');

        yield put({ type: "PAYPAL_PAGE__INIT_PAGE_SUCCESS", response: { payPalResponse, orderDetailResponse }, action: action.payload });
    } catch (e) {
        yield put({ type: "PAYPAL_PAGE__INIT_PAGE_FAILED", message: e.message, action: action.payload });
    }
}

function* payPalOnAuthorize(action) {
    try {
        let { orderId, paymentID, payerID } = action.payload;
        yield put({ type: "PAYPAL_PAGE__SET_STATE", state: { isLoading: true } });
        let url = _apiRoutes['paypal-express'];
        let postBody = {
            data: {
                action: 2,
                paymentID,
                payerID,
                oid: orderId,
            }
        }

        const payPalResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody)

        yield put({ type: "PAYPAL_PAGE__AUTHORIZE__SUCCESS", response: { payPalResponse }, action: action.payload })
    } catch (e) {
        yield put({ type: "PAYPAL_PAGE__AUTHORIZE__FAILED", message: e.message, action: action.payload })

    }
}

function* payPalOnCancel(action) {
    try {
        let { orderId } = action.payload;
        yield put({ type: "PAYPAL_PAGE__SET_STATE", state: { isLoading: true } });
        let url = _apiRoutes['paypal-express'];
        let postBody = {
            data: {
                action: 3,
                oid: orderId,
            }
        }

        const payPalResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody)

        yield put({ type: "PAYPAL_PAGE__CANCEL__SUCCESS", response: { payPalResponse }, action: action.payload })
    } catch (e) {
        yield put({ type: "PAYPAL_PAGE__CANCEL__FAILED", message: e.message, action: action.payload })

    }
}



export default [
    takeEvery('PAYPAL_PAGE__INIT_PAYPAL', initPayPalPage),
    takeEvery('PAYPAL_PAGE__AUTHORIZE', payPalOnAuthorize),
    takeEvery('PAYPAL_PAGE__CANCEL', payPalOnCancel),
];


export default {
  "common": {
    " ": " ",
    "yes": "はい",
    "no": "いいえ",
    "confirm": "確認",
    "place_order": "Place Order",
    "delete": "削除",
    "ok": "OK",
    "close": "閉じる",
    "filter": "絞込み",
    "product": "製品",
    "store": "ストア",
    "loading": "ロード中",
    "setting": "設定",
    "back": "戻る",
    "dashboard": " ダッシュボード",
    "notification": "お知らせ",
    "warning": "注意",
    "submit": "送信",
    "cancel": "キャンセル",
    // "comment": "コメント",
    "comment": "**Special Instructions**",
    "tel": "電話",
    "phone_number": "電話番号",
    "not_set": "Not set",
    "name": "名前",
    "email": "Email",
    "customer": "顧客",
    "admin": "管理者",
    "current": "現在",
    "newest": "最新",
    "Skipped": "スキップ",
    "clear_all": "全て削除",
    "from": "From",
    "to": "To",
    "all": "All",
    "save": "保存",
    "disabled": "表示しない",
    "margin": "マージン",
    "top": "トップ",
    "bottom": "ボトム",
    "left": "左",
    "right": "右",
    "general": "一般",
    "general_settings": "一般設定",
    "first_name": "名",
    "last_name": "姓",
    "status": "ステータス",
    "new": "new",
    "reload": "リロード",
    "selected": "選択済",
    "items": "アイテム",
    "clear_fliter": "絞込み解除",
    "search": "検索",
    "enter_password": "パスワード入力",
    "unlock": "ロック解除",
    "qty": "Qty",
    "gift_cards": "ギフト券",
    "view_all": "全て見る",
    "profile": "プロフィール",
    "nick_name": "ニックネーム",
    "verification_code": "認証コード",
    "get_verification_code": "認証コードを作成",
    "edit": "編集",
    "action": "アクション",
    "send": "送信",
    "add_comment": "Add comment",
    "receiver": "受信",
    "email_phone": "Email/電話",
    "change": "変更",
    "description": "説明",
    "remove": "削除",
    "reorder": "Reorder",
    "enter_coupon_code": "クーポンコードを入力",
    "apply": "適用",
    'complete_oreder': "Complte order",
    "category": "Category",
    "expired": "Expired",
    "optional": "Optional",
    "about": "About",
    "highlights": "Highlights",
    "customer_reviews": "Customer Reviews",
    "relevant_reviews": "Relevant Reviews",
    "ratings": "ratings",
    "reviews": "reviews",
    "buy": "Buy",
    "quantity": "Quantity",
    "out_of_stock": "Out of stock",
    "refresh": "Refresh",
  },
  "common_order_status": {
    "pending": "準備中",
    "processing": "進行中",
    "payment_review": "支払いレビュー",
    "completed": "完了",
    "canceled": "キャンセル済",
    "closed": "閉じる",
    "pending_payment": "支払い未完了",
    "holded": "Holded",
  },
  "common_shipping_method": {
    "shipping_method": "Order Type",
    "delivery_option": "Delivery Option",
    "order_type": "Order Type",
    "delivery": "配達",
    "pickup": "受け取り",
    "eatin": "店内",
    "instore": "In-Store",
    "takeout": "Take out",
    "dine_in": "店内",
    "in_store": "店内",
    "flatrate": "定額料金",
    "freeShipping": "無料配送",
    "how": "方法",
  },
  "common_payment_method": {
    "payment_method": "支払い方法",
    "pay_later": "後日払い(カード)",
    "cash": "現金",
    "credit_card": "クレジットカード",
    "giftcard": "ギフト券",
    "points": "ポイント",
    "points_balance": "Balance",
    "points_and_giftcard": "ギフト券 & ポイント",
    "wechat_pay": "Wechat Pay",
    "paypal_express": "PayPal Express",
    "alipay": "Alipay",
    "union_pay": "Union Pay",
    "union_pay_express": "Union Pay Express",
    "free": "無料",
    "points_balance": "Balance",
    "remaining_balance": "Remaining Points",
    "redeemed": "Redeem",

    "card_number": "カード番号",
    "name_on_card": "カード名義人",
    "cvv": "CVV",

    "cvv_descpt": 'a 3 to 4 digit number from the back of your card',
    "cash_despcrition": 'Order is paid by cash',
    "pay_later_despcrition": 'Order will be paid later with card',
    "wechat_pay_despcrition": 'After clicking \'Confirm\', you will be directed to Wechat pay page to complete your purchase',
    "paypal_expres_despcrition": 'After clicking \'Confirm\', you will be directed to Paypal page to complete your purchase',
    "alipay_despcrition": 'After clicking \'Confirm\', you will be directed to AliPay page to complete your purchase',
    "union_pay_despcrition": 'After clicking \'Confirm\', you will be directed to Union Pay page to complete your purchase',
    "union_pay_express_despcrition": 'After clicking \'Confirm\', you will be directed to Union Pay Express page to complete your purchase',
  },
  "common_price": {
    "total": "合計",
    "subtotal": "小計",
    "discount": "割引",
    "store_discount": "店舗割引",
    "coupon_discount": "クーポン割引",
    "shipping_and_handling": "配送料 & 手数料",
    "total_before_tax": "合計(税抜き)",
    "tax": "消費税",
    "order_total": "注文合計",
    "gift_card_payment": "ギフト券支払い",
    "points_payment": "ポイント支払い",
    "total_paid": "合計",
    "total_due": "総額",
    "tips": "チップ",
    "delivery_fee": "配送料",
    "max_amount": "最大",
    "min_amount": "最小",
    "balance": "残高",
    "amt": "Amount",
    "price": "Price",
    "item(s)": "Item(s)",
    "shopping": "Shopping",
    "billing_shipping": "Billing & Shipping",
    "done": "Done",
    "customer_info": "Customer Info",
    "delivery_info": "Delivery Info",
    "payment_info": "Payment Info",
    "other_payment_info": "Other Payment Info",
  },
  "common_†ime": {
    "date": "日付",
    "today": "今日",
    "tomorrow": "Tomorrow",
    "yesterday": "昨日",
    "this_week": "This week",
    "last_week": "Last week",
    "this_month": "This Month",
    "last_month": "Last Month",
    "last": "Last",
    "next": "Next",
    "now": "Now",
    "month": "月",
    "year": "年",
    "this_week": "今週",
    "last_week": "先週",
    "this_month": "今月",
    "last_month": "先月",
    "select_date": "日付選択",
    "select_time": "時間選択",
    "expiry_date": "有効期限",
    "delivery_time": "配達時間",
    "pickup_time": "受け取り時間",
    "select_delivery_time": "Select Delivery Time",
    "select_pickup_time": "Select Pick-up Time",
    "due_time": "Due Time",
    "when": "When",
    "requested_time": "Requested time",
    "scheduled_time": "Scheduled time",
  },
  "common_language": {
    "language": "言語",
    "en": "英語",
    "zh": "简体中文",
    "zh-Hant": "繁體中文",
    "jp": "日本語",
  },
  "common_address": {
    "add_address": "住所追加",
    "address": "住所",
    "street": "通り/番地",
    "unit": "ユニット",
    "buzzer": "ブザー",
    "city": "市",
    "province": "州",
    "country": "国名",
    "postal_code": "郵便番号",
    "quick_map_search": "すぐにマップ検索",
    "map_search": "マップ検索",
    "shipping_address": "配達住所",
    "billing_address": "請求先",
    'set_as_default': "Set as default",
    "enter_a_location": "Input your street address",
    "location": "Location",
    "van": "Vancouver",
    "vic": "Victoria",
    "tor": "Toronto",
    "seattle": "Seattle",
    "choose_city": "Choose City",
    "current_location": "Current Location",
    "use_info_from_acc": "Use info from account",
  },
  "common_error": {
    "x_is_invalid": "Invalid {x}",
    "unable_to_load_any_data": "データを読み込めません",
    "unstable_network": "ネットワークが不安定です。接続を確認してください。",
    "logout_force_message": "申し訳ありません。アカウントが他のデバイスで使用されているあるいはタイムアウトになりました。",
    "incorrect_password": "パスワードが間違っています。",
    'country_code_error': '国番号が間違っています。',
    'incorrect_username_password': 'ユーザー名またはパスワードが間違っています。',
    'verification_code_expired': '認証コードが期限切れです。',
    'verifcation_code_request_overlimit': '認証コードリクエストが上限に達しました。',
    'incorrect_verification_code': '認証コードが間違っています。',
    "error_fetch_user_profile": "ユーザープロフィールの読み込みに失敗しました。",
    "login_failed": "ログインに失敗しました。",
    "profile_update_success": "プロフィールの更新が完了しました。",
    "email_update_success": "Emailの更新が完了しました。",
    "phone_update_success": "電話番号の更新が完了しました。",
    "failed_fetch_gift_card_detail": "ギフト券情報の読み込みに失敗しました。",
    "gift_card_transfer_success": "ギフト券を送信しました。",
    "gift_card_transfer_fail": "ギフト券の送信に失敗しました。",
    "invalid_request": "無効なリクエスト",
    "record_not_found": "記録が見つかりません。",
    "general_error": "不明なエラーが発生しました。もう一度お試しください。",
    "failed_fetch_points_detail": "ポイント情報の読み取りに失敗しました。",
    "profile_update_failed": "ユーザー情報が更新できませんでした。",
    "email_update_failed": "Emailが更新できませんでした。",
    "phone_update_failed": "電話番号が更新できませんでした。",
    "get_verification_code_success": "認証コードをリクエストしました。",
    "verification_too_many_requests": "認証コードリクエストが上限に達しました。時間をおいてからもう一度お試しください",
    "invalid_phone_number": "無効な電話番号です。",
    "error": "エラー",
    "coupon_invalid": "無効なクーポ",
    "coupon_valid": "有効クーポン",
    "invalid_coupon": "このクーポン {couponCode} は無効です。",
    "coupon_applied": "クーポン {couponCode} は適用されています",
    "distance_exceeded": "配送可能距離を超えています。",
    "exceed_delivery_distance": "選択された配送先はレストランの配達可能な範囲　{delivery_distance}km　を超えています。別の配送先を指定してください。",
    "invalid_address": "無効な住所",
    "item_error": "エラーが発生しました。ショッピングカートが空のままです。商品を選択してから支払いに進んでください。",
    "shipping_country_text": "以下の国内での配送が可能です: {countries}",
    "item_oos": "申し訳ございません, アイテム {oos_items} は現在在庫切れとなっております。 もう一度ストアページに戻って確認してください。",
    "invalid_shipping_method": "The shipping method is not valid. Please try another shipping method",
    'please_input_phone_number': 'Please input phone number',
    'please_input_first_name': 'Please input first name',
    'please_input_last_name': 'Please input last name',
    'please_input_shipping_method': 'Please input shipping method',
    "please_input_sub_shipping_method": "Please input shipping method",
    'please_input_shipping_addresss': 'Please input shipping address',
    'please_input_table_number': 'Please input table number',
    'please_input_payment_method': 'Please input payment method',
    "please_select_payment_method": "Please select a payment method",
    'please_input_valid_credit_card': 'Please input valid credit card',
    'please_input_billing_address': 'Please input billing address',
    "invalid_credit_card": "無効なクレジットカードです。別の支払方法を指定してください。",
    "unsuccessful_checkout": "支払いに失敗しました。もう一度確かめてください。",
    "invalid_request": "支払いに失敗しました。無効なリクエストです。",
    "price_over_limit": "支払いに失敗しました。価格が支払い可能上限を超えています。",
    "invalid_shp_mtd": "支払いに失敗しました。配送方法が無効です。別の配送方法を指定してください。",
    "invalid_pay_mtd": "支払いに失敗しました。支払い方法が無効です。別の支払い方法を指定してください。",
    "new_order_created": "新規注文が作成されました。",
    "checkout_success": "ご利用ありがとうございます。注文が作成されました: #{order_id}。オーダー情報が更新され次第、連絡いたします。",
    "pay_success": "Thank you for your payment, a new order has been created: #{order_id} for tracking this payment. You will be notified when the order's status is updated.",
    "please_input_belows": "Please enter below missing fields",
    "invalid_expiry": "有効期限が無効です",
    "unable_to_change_here": "Unable to change here",
    "please_select_on_previous_page": "Please select on previous page",
    "invalid_card_number": "Invalid card number",
    "invalid_zipcd": "Invalid postal code",
    "click_one_more_time_to_deselect": "Click on more time to deselect",
    "paypal_payment_success_title": "支払いが完了しました。",
    "paypal_payment_success_text": "支払いが完了しました。注文ページにリダイレクトします。",
    "error_payment_failed": "支払いに失敗しました。",
    "internal_server_error": "エラーが発生しました。しばらくしてからもう一度お試しいただくあるいは販売者に直接お問い合わせください。",
    "unexpected_error": "An unexpected error has occurred, please try again later",
    "no_items_error": "ショッピングカートが空のままです。商品を選択してから支払いに進んでください。",
    "giftcard_pays_order_total": "選択されたギフト券はすでに注文合計に反映されています 追加の支払い方法は必要ありません。",
    "points_pays_order_total": "選択されたポイントはすでに注文合計に反映されています 追加の支払い方法は必要ありません。",
    "giftcard_points_pays_order_total": "ギフト券およびポイントはすでに注文合計に反映されています 追加の支払い方法は必要ありません。",
    "digit_only": "Please enter digits only",
    "please_selected_belows": "Please complete all selections:",
    "please_select_location_from_the_list": "Please select a street address from the list.",
    "alert_password_reset_success": "パスワードのアップデートに成功しました, 新しいパスワードでのログインが可能になります。",
    "alert_phone_vc_not_match": "認証コードが間違っています, もう一度確認してください。",
    "alert_phone_not_found": "この電話番号は登録されていません!",
    "alert_email_not_found": "このEmailアドレスは登録されていません。",
    "alert_password_reset_email_send": "パスワードリセット用のメールが送られました",
    "alert_email_length": "無効なEmailアドレス",
    "alert_password_min_length": "パスワードは6文字以上に設定してください。",
    "x_is_required": " is a required field",
    "location_permission_is_blocked_service_may_not_perform_correctly": "Location permission is blocked, this service may be affected. ",
    "order_payment_status_error": "The store has accepted this order. Please pay in person.",
    "order_comment_status_error": "The store has started preparing this order; please contact the store for any change request. Tel: {phone}.",
  },
  "common_acccount": {
    "my_account": "アカウント",
    "address_book": "住所一覧",
    "my_assets": "使用可能一覧",
    "log_in": "ログイン",
    "logout": "ログアウト",
    "logout_confirm_message": "本当にログアウトしますか?",
    "login_with_text": "認証コードでログイン",
    "password": "パスワード",
    "remember_me": "パスワードを保存する",
    "login_with_account": "アカウント情報でログインする",
    "sign_up": "Sign Up",
    "sign_up_biz": "Sign up as a Business",
    "store_mngt": "Store Management",
    "change_by_email": "By Email",
    "change_by_text_message": "By Text Message",
    "change_by_existing_password": "By Existing Password",
    "set_password": "Set Password",
    "new_password": "新しいパスワード",
    "old_password": "Old Password",
    "confirm_password": "Confirm Password",
    "change_password": "Change Password",
    "update_password": "Update Password",
    "code": "Code",
    "request_pass_reset": "Request Password Reset",
    "secure_connection": "Information is sent over a secure connection",
  },
  "common_country": {
    "canada": "カナダ",
    "united_states": "アメリカ合衆国",
    "china": "中国",
    "south_korea": "韓国"
  },
  "common_order": {
    "no_orders": "注文がありません",
    "order_id": "注文番号",
    "recent_orders": "最近の注文",
    "order_history": "注文履歴",
    "order_date": "注文日時",
    "order_detail": "注文詳細",
    "order_amount": "Order Amount",
    "ordered_on": "注文完了",
    "order_pending_descrip": "注文を確認しています。もうしばらくお待ちください。",
    "order_processing_descrip": "配送準備が整いました。",
    "order_complete_descript": "配送が完了しました",
    "order_cancelled_descript": "注文がキャンセルされました。",
    "table_number": "テーブル番号",
    "party_size": "人数",
    "table_info": "Table Info (JP)",
    "server": "サーバー",
    "surcharge": "Surcharge",
    "store_surcharge": "Online Surcharge Fee",
    "order_surcharge": "Order Surcharge",
    "item_total": "Item(s) Total",
    "surcharge_paid": "Surcharge (Paid)",
    "surcharge_unpaid": "Surcharge (Unpaid)",
    "related_id": "Related Order #",
    "related": "Related",
    'surcharege_paid_message': 'The surcharge amount is not included in this order. Please refer to order #{id}',
    "surcharge_paid_to_message": "This order is to pay for the surcharge of order #{id}",
    "pay_now": "Pay Now",
    "please_pay_now": "Order is not paid yet, you may pay now.",
    "are_you_sure_cancel_order": "Are you sure you want to cancel the order?",
    "please_pay_by_x": "Your order will be held for {x}. Pay now to prevent cancellation",
    "cancel_order_success": "Your order has successfully been cancelled.",
    "cancel_order_failed": "Failed to cancel order, please try again later",
  },
  "common_store": {
    "stores": "ストア",
    "view_stores": "店舗一覧",
    "store_name": "店名",
    "to_store": "詳細へ",
    "to_product": "To product",
    "contact_store": "ストアに連絡",
    "bookmarks": "ブックマーク",
    "gift_card_detail": "ギフト券詳細",
    "my_gift_cards": "ギフト券",
    "gift_card_used": "使用済",
    "gift_card_created": "ギフト券の作成",
    "gift_card_refunded": "ギフト券の払い戻し",
    "gift_card_transferred": "ギフト券を送る",
    "gift_card_received": "ギフトカードを受け取る",
    "points": "ポイント",
    "back_to_store": "Back to store",
    "go_to_order": "go_to_order",
  },
  "common_product": {
    "products": "製品",
  },
  "common_group_sale": {
    'there': 'こんにちは,',
    "welcomeMessage1": "{who}",
    "welcomeMessage2": "クーポンを使って今日を素敵な１日に！",
    "result_for": "result for '{keyword}'",
    "select_what": "Select '{keyword}'",
    "pop_on_groupter": "人気クーポン",
    "brower_by_cat": "人気のカテゴリーをみる",
    "goods": "Goods",
    "things_to_do": "Things to do",
    "beauty_spas": "Beauty & Spas",
    "restaurants": "Restaurants",
    "travel": "Travel",
    "sale": "Sale",
    "health_fitness": "Health & Fitness",
    "food_drinks": "Food & Drinks",
    "breweries_wineries_distileries": "Breweries, Wineries & Distilleries",
    "entertainment": "Entertainment",
    "collectibles": "Collectibles",
    "books": "Books",
    "face_skin": "Face & Skin",
    "cosmetic_procedures": "Cosmetic Procedures",
    "gym": "Gym",
    "sport": "Sport",
    "tour": "Tour",
    "hotel": "Hotel",
    "personalized": "Personalized",
    "home_garden": "Home & Garden",
    "jewelry_watches": "Jewelry & Watches",
    "fashion_jewelry": "Fashion Jewelry",
    "fine_metal_jewelry": "Fine Metal Jewelry",
    "watches": "Watches",
    "home_services": "Home Services",
    "cleaning_services": "Cleaning Services",
    "flooring": "Flooring",
    "automotive": "Automotive",
    "interior_accessories": "Interior Accessories",
    "car_electronics": "Car Electronics",
    "local_services": "Local Services",
    "fun_leisure": "Fun & Leisure",
    "sports_outdoors": "Sports & Outdoors",
    "all_deal": "All deal",
    "discount_amount": "Discount amount",
    "amount_sold": "Amount sold",
    "sort_by": "Sort by",
    "best_match": "Best Match",
    "distance": "Distance",
    "ranking": "Ranking",
    "price_ascending": "Price Ascending",
    "price_descending": "Price Descending",
    "discount_greater": "Discount greater",
    "bought": "bought",
    "OFF": "OFF",
    "view_deal": "View Deal",
    "viewed_today": "viewed today",
    "what_you_will_get": "What you will get",
    "the_fine_print": "The Fine Print",
    "customer_photos": "Customer Photos",
    "view_image": "View image",
    "view_image_gallery": "View image gallery",
    "see_all_reviews": "See all reviews",
    "view_all_img": "View all images",
    "see_all_hours": "See all hours",
    "show_less": "Show less",
    "see_all_hours": "See all hours",
    "app": "App",
    "history": "History",
    "service": "Service",
    "business": "Business",
    "favorite": "Favorite",
  },
  "common_checkout": {
    'items_not_available': 'Items Not Available',
    'items_not_available_message': 'The following item(s) are not available now, and will be removed from your cart: {br} {items}'
  },
  "common_self_checkout": {
    "self_checkout": "Self Checkout",
    "amount": "Amount",
    "add_comment": "Add a Comment",
    "contact_merchant": "Contact Merchant",
    "powered_by_goopter": "Powered by Goopter",
    "pay": "Pay",
    "self_checkout_disabled": "Self checkout is not available for this store.",
    "surcharge_for": "Surcharge for order: #{oid}",
  },
}
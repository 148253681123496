import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import { Avatar, List, Spin, Rate, Divider, Icon, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import AccountPageLayout from 'components/account-page-layout';

import _bookmarkActions from 'containers/bookmarks/actions';
import './_bookmark-page.scss';

// const TabPane = Tabs.TabPane;

class BookmarkPage extends Component {

  componentDidMount() {
    this.fetchBookmarkList()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.fetchBookmarkList(nextProps)
    }
  }

  fetchBookmarkList(props = this.props) {
    let { fetchBookmarkList, isAuthenticated } = props;
    if (isAuthenticated) {
      fetchBookmarkList()
    }
  }

  componentWillUnmount() {
    let { clearBookmarkPageData } = this.props;

    clearBookmarkPageData()
  }

  render() {
    let {
      push,
      bookmarkPageLoading,
      bookmarkList,
      removeBoomark,
      removeBookmarkLoading,

      storeInstance,
    } = this.props;

    let getIndependentDomain = _helper.getIndependentDomain();

    let bookMarkBase = window.location.href.includes('-qa') || window.location.href.includes('localhost:') ? 'https://shop-qa.goopter.com' : 'https://shop.goopter.com'

    let fitlteredStoreBookMarks = _helper.getIndependentDomain() ?
      bookmarkList && bookmarkList.store && bookmarkList.store.length ? bookmarkList.store.filter(bookmark => { return bookmark.gid == storeInstance.data['group_id'] }) : []
      : bookmarkList.store

    let fitlteredProductBookMarks = _helper.getIndependentDomain() ?
      bookmarkList && bookmarkList.product && bookmarkList.product.length ? bookmarkList.product.filter(bookmark => { return bookmark.gid == storeInstance.data['group_id'] }) : []
      : bookmarkList.product

    return (
      <AccountPageLayout title={<FormattedMessage id="bookmarks" />}>
        <Spin spinning={bookmarkPageLoading}>
          <div className="bookmarks-content-container">
            <div className="bookmark-section-container">
              <h2><FormattedMessage id="stores" /></h2>
              <div className="bookmark-list-wrapper">
                <List
                  itemLayout="vertical"
                  dataSource={fitlteredStoreBookMarks}
                  renderItem={(store, i) => {
                    return (
                      <List.Item actions={[
                        <Button size="small" type="danger" loading={removeBookmarkLoading.store.includes(store.gid)} onClick={() => {
                          removeBoomark({
                            type: 'store',
                            id: store['gid']
                          })
                        }}><FormattedMessage id="remove" /></Button>
                      ]}>
                        <List.Item.Meta
                          style={{ cursor: 'pointer' }}
                          onClick={() => { /* temp until store page is written in web_v2 */ window.location = bookMarkBase + `/store/${store['gid']}` }}
                          avatar={<Avatar className="avatar-wrapper" size="large" shape="square" src={_config['IMG_PREFIX'] + store['logo']} />}
                          title={<span>{store['nm']}</span>}
                          description={<div>
                            <Rate defaultValue={store['rnk']} className="rating-wrapper" allowHalf={true} disabled />
                            <div>{store['adr']}</div>
                          </div>}
                        />
                        {/* <div>hello</div> */}
                      </List.Item>
                    )
                  }}
                />
              </div>
            </div>
            <div className="bookmark-section-container">
              <h2><FormattedMessage id="products" /></h2>
              <div className="bookmark-list-wrapper">
                <List
                  itemLayout="vertical"
                  dataSource={fitlteredProductBookMarks}
                  renderItem={(product, i) => {
                    return (
                      <List.Item key={i} actions={[
                        <Button size="small" type="danger" loading={removeBookmarkLoading.product.includes(product.pid)} onClick={() => {
                          removeBoomark({
                            type: 'product',
                            id: product['pid']
                          })
                        }}><FormattedMessage id="remove" /></Button>
                      ]}>
                        <List.Item.Meta
                          style={{ cursor: 'pointer' }}
                          onClick={() => { /* temp until store page is written in web_v2 */ window.location.href = `/product/${product['pid']}` }}
                          avatar={<Avatar className="avatar-wrapper" size="large" shape="square" src={_config['IMG_PREFIX'] + product.iurl} />}
                          title={<span>{product['nm']}</span>}
                          description={<div>
                            <Rate defaultValue={product['rat']} className="rating-wrapper" allowHalf={true} disabled />
                            <div>{product['pc']}</div>
                          </div>}
                        />
                        {/* <div>hello</div> */}
                      </List.Item>
                    )
                  }}
                >
                </List>
              </div>
            </div>
          </div>
        </Spin>
      </AccountPageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  let orderListState = state['bookmarks'];
  let storeDataState = state['store-data'];
  let authState = state['auth'];
  return {
    isAuthenticated: authState.isAuthenticated,
    bookmarkList: orderListState.bookmarkList,
    bookmarkPageLoading: orderListState.bookmarkPageLoading,
    removeBookmarkLoading: orderListState.removeBookmarkLoading,
    storeInstance: storeDataState.storeInstance,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    fetchBookmarkList: bindActionCreators((params = {}) => {
      return {
        type: "BOOKMARKS__FETCH_BOOKMARK_LIST",
        payload: params
      }
    }, dispatch),
    clearBookmarkPageData: bindActionCreators((params = {}) => {
      return {
        type: "BOOKMARKS__CLEAR_DATA",
      }
    }, dispatch),
    removeBoomark: bindActionCreators((params) => {
      return _bookmarkActions.removeBoomark(params)
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkPage);
import React, { Component } from 'react';
import _config from 'config';
import _helper from '../../../utils/helper';
import _ from 'lodash'
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';

class SurchargePaidToMessage extends Component {
    getOrderSucharge = () => {
        return _.get(this.props, 'orderDetail.order_surcharge', {})
    }

    getRelatedId = () => {
        return _.get(this.props, 'orderDetail.related_order_id', '')
    }

    showSeparateSurcharge = () => {
        return !_.get(this.getOrderSucharge(), 'value', 0) && this.getRelatedId()
    }

    renderMessage = () => {
        const relatedId = this.getRelatedId()
        const goToOrder = () => {
            this.props.push(`/account/orders/detail/${relatedId}`)
        }
        return (
            <div className="seperate-surcharge-paid-message"
                onClick={() => goToOrder()}>
                <FormattedMessage
                    id="surcharge_paid_to_message"
                    values={{ id: <span className="link">{relatedId}</span> }} />

            </div>
        )
    }

    render() {
        return this.showSeparateSurcharge() ? (
            <div className="box surcharge-paid-to-message-container">
                <div className="box-section">
                    {this.renderMessage()}
                </div>
            </div>
        ) : ''
    }
}
const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", 'en'),
    orderDetail: _.get(state, "order-detail.orderDetailInstance.data", {}),
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SurchargePaidToMessage));

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _api from 'utils/api/index.jsx';
import _actions from './actions.jsx'

class Locations extends Component {

  componentDidMount() {
    this.handleGetLocation();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldGetLoction !== this.props.shouldGetLoction) {
      this.handleGetLocation()
    }
  }

  handleGetLocation() {
    let { addCurrentLatLon, addAddressComp } = this.props;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          addCurrentLatLon({
            userLat: position.coords.latitude,
            userLon: position.coords.longitude
          })
          let location = _api.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`)
          location.then((res) => {
            if (res && res.results && res.results.length) {
              let addressComponent = res.results[0]['address_components'];
              addAddressComp(addressComponent)
            }
          })
        },
        (error) => {
          if (error.code === error.TIMEOUT) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                if (position.coords.latitude && position.coords.longitude) {
                  addCurrentLatLon({
                    userLat: position.coords.latitude,
                    userLon: position.coords.longitude
                  })
                  let location = _api.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`)
                  location.then((res) => {
                    if (res && res.results && res.results.length) {
                      let addressComponent = res.results[0]['address_components'];
                      addAddressComp(addressComponent)
                    }
                  })
                  console.log('geolocation failed', position)
                }
              }
            );
          }
          console.log('unable to get user location', error);
        },
        { timeout: 10000, enableHighAccuracy: false }
      );
      console.log('asked for user location')
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  let locationState = state['locations']

  return {
    shouldGetLoction: locationState.shouldGetLoction
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCurrentLatLon: bindActionCreators((params) => {
      return _actions.addCurrentLatLon({
        userLat: params.userLat,
        userLon: params.userLon
      })
    }, dispatch),
    addAddressComp: bindActionCreators((params) => {
      return _actions.addAddressComp(params)
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
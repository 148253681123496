import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Modal, Button, Form, Input, Checkbox, Divider, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

const FormItem = Form.Item;

class LoginDialog extends Component {

  getLoginTypeText = () => {
    let { loginType } = this.props;

    if (loginType === 'account') {
      return <FormattedMessage id="login_with_text" />
    } else if (loginType === 'text') {
      return <FormattedMessage id="login_with_account" />
    }
  }

  getLoginForm = () => {
    let { loginType } = this.props;
    let { formatMessage } = this.props.intl;
    if (loginType === 'account') {
      return (
        <Form onSubmit={(e) => {
          e.preventDefault();
          this.props.login('a')
        }}>
          <FormItem>
            <Input
              // placeholder={formatMessage({ id: 'account_sign_in_placeholder' })}
            />
          </FormItem>
          <FormItem>
            <Input
              // placeholder={formatMessage({ id: 'password_placeholder' })}
            />
          </FormItem>
          <FormItem style={{ margin: '0' }}>
            <Checkbox
              name="rememberMe"
            // values={values.rememberMe}
            // onChange={handleChange}
            >
              <FormattedMessage id="remember_me" />
            </Checkbox>
          </FormItem>
          <Divider />
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button" size="large" block>
              <FormattedMessage id="log_in" />
            </Button>
          </FormItem>
        </Form>
      )
    } else if (loginType === 'text') {
      return (
        <Form>
          <FormItem>
            <Input
              // placeholder={formatMessage({ id: 'asdf' })}
            />
          </FormItem>
          <FormItem>
            <Input
              // placeholder={formatMessage({ id: 'password_placeholder' })}
            />
          </FormItem>
          <FormItem style={{ margin: '0' }}>
            <Checkbox
              name="rememberMe"
            // values={values.rememberMe}
            // onChange={handleChange}
            >
              <FormattedMessage id="remember_me" />
            </Checkbox>
          </FormItem>
          <Divider />
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button" size="large" block>
              <FormattedMessage id="log_in" />
            </Button>
          </FormItem>
        </Form>
      )
    }
  }

  render() {
    let {
      isAuthenticated,
      loginDialogDisplay,
      toggleLoginDialogDisplay,
      toggleLoginType,
      loginType,
    } = this.props;

    let { formatMessage } = this.props.intl;

    return (
      <Modal
        title={<FormattedMessage id="sign_in" />}
        //visible={!isAuthenticated && loginDialogDisplay}
        onCancel={() => { toggleLoginDialogDisplay(false) }}
        footer={null}
      >
        <div className="link" style={{ textAlign: 'right' }} onClick={toggleLoginType}>
          {this.getLoginTypeText()}
        </div>
        {this.getLoginForm()}
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth'];

  return {
    isAuthenticated: authState.isAuthenticated,
    loginDialogDisplay: authState.loginDialogDisplay,
    loginType: authState.loginType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    login: bindActionCreators((params) => {
      return {
        type: "AUTH__LOGIN",
        payload: params
      }
    }, dispatch),
    toggleLoginDialogDisplay: bindActionCreators((bool) => {
      return {
        type: "AUTH__SET_STATE",
        state: {
          loginDialogDisplay: bool
        }
      }
    }, dispatch),
    toggleLoginType: bindActionCreators((type) => {
      return {
        type: "AUTH__TOGGLE_LOGIN_TYPE",
      }
    }, dispatch),
    // clearOrderDetailData: bindActionCreators((params = {}) => {
    //   return {
    //     type: "ORDER_DETAIL__CLEAR_DATA",
    //   }
    // }, dispatch)
  }
}

LoginDialog = connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
export default injectIntl(LoginDialog)


import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import _helper from '../../../utils/helper';
import _locale from 'locale';
import { Layout, Form, Input, Select, Button, Divider, Icon, Row, Col, Switch } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import regionData from 'utils/data/regionList.json';
import countryData from 'utils/data/countryList.json'
import './_address-form.scss'

const FormItem = Form.Item;
const Option = Select.Option;

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showAlert: false,
      validate: []
    }
  }
  state = {
    imageLoaded: true
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  componentWillMount() {
    let { values, userProfile } = this.props;

    values.firstName = userProfile.first_name;
    values.lastName = userProfile.last_name;
    values.phone = _helper.cleanPhoneNumber(userProfile.phone);
  }

  componentWillReceiveProps(props) {
    if (this.props.values.use_name_on_acc !== props.values.use_name_on_acc && props.values.use_name_on_acc) {
      this.updateDefaultValues(props)
    }
  }

  updateDefaultValues(props) {
    let { values, userProfile, setFieldValue } = props;
    if (values.use_name_on_acc && userProfile) {
      setFieldValue('firstName', userProfile.first_name ? userProfile.first_name : '')
      setFieldValue('lastName', userProfile.last_name ? userProfile.last_name : '')
      setFieldValue('phone', userProfile.phone ? userProfile.phone : '')
    }
  }

  validateAddress = () => {
    let { firstName, phone, street, city, region, country: cntry, zipCode } = this.props.values;
    let validate = [];
    let currentCountry = countryData.records.find(country => { return country.country_code == cntry });

    if (!firstName) {
      validate.push("fn")
    }

    if (!cntry) {
      validate.push("cntry")
    }

    if (!phone) {
      validate.push("tn")
    }

    if (!street) {
      validate.push("street")
    }

    if (!city) {
      validate.push("city")
    }

    if (!region) {
      validate.push("region")
    }

    if (!zipCode) {
      validate.push("zipcd")
    } else if (currentCountry
      && currentCountry['zipcode_required']
      && !new RegExp(currentCountry['zipcode_format']).test(zipCode)) {
      validate.push("zipcd")
    }

    if (street && !/^\d/.test(street)) {
      validate.push("invalid_street")
    }

    return validate;
  }

  handleSubmit = () => {
    const validate = this.validateAddress();
    if (validate.length > 0) {
      this.props.setState({ showAddressFormError: true })
    } else {
      this.props.saveAddress({
        id: this.props.initialValues.id ? this.props.initialValues.id : null,
        ...this.props.values
      })
      this.props.setState({ showAddressFormError: false })
    }
  }

  getInvalidMessage = (key) => {
    let { formatMessage } = this.props.intl;
    const x = formatMessage({ id: key })
    return formatMessage({ id: "x_is_invalid" }, { x })
  }

  errors = () => {
    return _.get(this.props, 'state.showAddressFormError', false) ? this.validateAddress() : []
  }

  render() {
    let {
      hide,
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      addressListLoading,
      saveAddressLoading,
      userProfile,
      showMapDrawer,
    } = this.props;
    let { formatMessage } = this.props.intl;
    let validate = this.errors()
    return (

      <Form style={{ display: hide ? 'none' : null }}>

        <div style={{ marginBottom: 10 }} class="address-form-switch-row">
          <Switch
            style={{ marginRight: '10px' }}
            size="small"
            checked={values.use_name_on_acc}
            onChange={(value) => {
              setFieldValue('use_name_on_acc', value)
              if (value && userProfile) {
                setFieldValue('firstName', userProfile.first_name ? userProfile.first_name : '')
                setFieldValue('lastName', userProfile.last_name ? userProfile.last_name : '')
                setFieldValue('phone', userProfile.phone ? userProfile.phone : '')
              }
            }}
          />
          <FormattedMessage id="use_info_from_acc" />
        </div>

        <Row gutter={12}>
          <Col span={12}>
            <FormItem
              label={<FormattedMessage id="first_name" />}
              colon={false}
              help={validate.includes('fn') ? this.getInvalidMessage('first_name') : ''}
              validateStatus={validate.includes('fn') ? 'error' : ''}
            >
              <Input
                disabled={values.use_name_on_acc && userProfile.first_name}
                name="firstName"
                placeholder={formatMessage({ id: "first_name" })}
                onChange={handleChange}
                value={values.firstName}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={<FormattedMessage id="last_name" />}
              colon={false}
            >
              <Input
                name="lastName"
                disabled={values.use_name_on_acc && userProfile.last_name}
                placeholder={formatMessage({ id: "last_name" })}
                onChange={handleChange}
                value={values.lastName}
              />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={25}>
            <FormItem
              label={<FormattedMessage id="phone_number" />}
              colon={false}
              help={validate.includes('tn') ? this.getInvalidMessage('phone_number') : ''}
              validateStatus={validate.includes('tn') ? 'error' : ''}
            >
              <Input
                name="phone"
                disabled={values.use_name_on_acc && userProfile.phone}
                placeholder={formatMessage({ id: "phone_number" })}
                onChange={handleChange}
                value={values.phone}
              />
            </FormItem>
          </Col>
        </Row>


        <Row gutter={12}>
          <Col span={25}>
            <FormItem
              label={<FormattedMessage id="address" />}
              colon={false}
              help={validate.includes('street') ? this.getInvalidMessage('address') : ''}
              validateStatus={validate.includes('street') ? 'error' : ''}
            >
              <Input
                readOnly
                onClick={() => showMapDrawer()}
                placeholder={formatMessage({ id: "address" })}
                value={_helper.formatAddress(values, { size: 1, without: ['zipcd'] })}
              />
            </FormItem>

          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={25}>
            <FormItem
              label={<FormattedMessage id="postal_code" />}
              colon={false}
              help={validate.includes('zipcd') ? this.getInvalidMessage('postal_code') : ''}
              validateStatus={validate.includes('zipcd') ? 'error' : ''}
            >
              <Input
                name="zipCode"
                placeholder={formatMessage({ id: "postal_code" })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zipCode.toUpperCase()}
              />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <FormItem
              label={<FormattedMessage id="unit" />}
              colon={false}
            >
              <Input
                name="unit"
                placeholder={formatMessage({ id: "unit_optional" })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.unit}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={<FormattedMessage id="buzzer" />}
              colon={false}
            >
              <Input
                name="buzz"
                placeholder={formatMessage({ id: "buzzer_optional" })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.buzz}
              />
            </FormItem>
          </Col>
        </Row>

        <div style={{ marginTop: 10 }} class="address-form-switch-row">
          <Switch
            style={{ marginRight: '10px' }}
            size="small"
            checked={values.is_default}
            onChange={(value) => { setFieldValue('is_default', value) }} />
          <FormattedMessage id="set_as_default" />
        </div >

        <Divider />
        <FormItem>
          <Button
            onClick={() => this.handleSubmit()}
            type="primary"
            size="large"
            loading={saveAddressLoading || addressListLoading} block>
            <FormattedMessage id="save" />
          </Button>
        </FormItem>
      </Form >
    )
  }
}

AddressForm = withFormik({
  mapPropsToValues: (props) => ({ ...props.initialValues }),
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.saveAddress({
      id: props.initialValues.id ? props.initialValues.id : null,
      ...values
    })
  },
  enableReinitialize: true
})(AddressForm)

export default injectIntl(AddressForm);

function setNotificationsState(params) {
    return {
        type: "NOTIFICATIONS__SET_STATE",
        state: params
    }
}

function updateDeviceToken(params) {
    return {
        type: "NOTIFICATIONS__UPDATE_DEVICE_TOKEN",
        payload: params
    }
}

export default {
    setNotificationsState,
    updateDeviceToken,
}
import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import {
  routerMiddleware
} from 'connected-react-router';
import history from './history';
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducers';

import {
  persistStore,
  persistReducer
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createFilter from 'redux-persist-transform-filter';

import rootSagas from './sagas';
import logger from 'redux-logger';

import createReduxPromiseListener from 'redux-promise-listener';

const sagaMiddleware = createSagaMiddleware();

const reduxPromiseListener = createReduxPromiseListener();

const rootReducer = createRootReducer(history);

const persistConfig = {
  key: 'cart',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['shopping-cart'],
  transforms: [
    createFilter('shopping-cart', ['cartList']),
  ]
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reduxStore = createStore(
  persistReducer(persistConfig, rootReducer),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      //logger,
      reduxPromiseListener.middleware
    )
  )
)
sagaMiddleware.run(rootSagas);

console.log({
  persistConfig,
  reduxStore: reduxStore.getState()
})

export const promiseListener = reduxPromiseListener;
export const store = reduxStore;
export const persistor = persistStore(reduxStore);
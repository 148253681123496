import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Button, Modal } from 'antd';
import { injectIntl } from 'react-intl';
import _authActions from 'containers/auth/actions';
import _locale from 'locale';
import './_logout.scss';

class LogoutDialog extends Component {

  getLogoutMessage = () => {
    let { logoutDialogType, lan } = this.props;

    let trans = (id) => _locale.getIntlMessages(lan, '')[id];

    if (logoutDialogType === 'force') {
      return trans("logout_force_message")
    } else if (logoutDialogType === 'normal') {
      return trans("logout_confirm_message")
    } else {
      return trans("logout_confirm_message")
    }
  }


  getButtons = () => {
    let { push, logoutDialogType, toggleLogoutDialog, logout, lan } = this.props;

    let trans = (id) => _locale.getIntlMessages(lan, '')[id];

    let buttons = []

    if (logoutDialogType !== 'force') {
      buttons.push(<Button key='cancel' onClick={() => {
        toggleLogoutDialog({ show: false })   
      }}> {trans("cancel")}  </Button>)
    }

    buttons.push(
      <Button key='ok' type="primary" onClick={() => {
        logout()
        toggleLogoutDialog({ show: false })
      
        // push('/login')
      }}>
        {trans("ok")}
      </Button>
    )

    return buttons
  }

  render() {
    let {
      isAuthenticated,
      logoutDialogDisplay,
      lan,
    } = this.props;

    let trans = (id) => _locale.getIntlMessages(lan, '')[id];

    return [
      <Modal
        key='logout-dialog'
        visible={logoutDialogDisplay}
        title={trans("logout")}
        footer={this.getButtons()}
      >
        {this.getLogoutMessage()}
      </Modal >
    ]
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth'];
  let settingState = state['settings'];

  return {
    isAuthenticated: authState.isAuthenticated,
    logoutDialogDisplay: authState.logoutDialogDisplay,
    logoutDialogType: authState.logoutDialogType,
    lan: settingState.lan,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    checkAuthStatus: bindActionCreators(_authActions.checkAuthStatus, dispatch),
    toggleLogoutDialog: bindActionCreators((params) => {
      return {
        type: "AUTH__TOGGLE_LOGOUT_DIALOG",
        payload: params
      }
    }, dispatch),
    logout: bindActionCreators(() => {
      return {
        type: "AUTH__LOGOUT",
      }
    }, dispatch),
  }
}

LogoutDialog = connect(mapStateToProps, mapDispatchToProps)(LogoutDialog);
export default injectIntl(LogoutDialog)


import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import LoginModal from "containers/groupsale-page/page-components/loginModal";
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, data, ...rest }) => {
  return <Route {...rest} render={props => (
    <div>
      <Component {...props} {...data} />
      <LoginModal
        loginForceVisible={!props.isAuthenticated}
        onClose={() => { }}
      />
    </div>
  )} />
};

const mapStateToProps = (state) => {
  let authState = state['auth'];
  return {
    authLoading: authState.authLoading,
    isAuthenticated: authState.isAuthenticated,
  };
}


export default withRouter(connect(mapStateToProps)(PrivateRoute));

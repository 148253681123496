import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import SettingsReducers from 'containers/settings/reducers';
import AuthReducers from 'containers/auth/reducers';
import AccountPageReducers from 'containers/account-page/reducers';
import OrderListReducers from 'containers/order-list/reducers';
import OrderDetailReducers from 'containers/order-detail/reducers';
import BookmarkPageReducers from 'containers/bookmarks/reducers';
import GiftCardPageReducers from 'containers/gift-card-page/reducers';
import PointsReducers from 'containers/points-page/reducers'
import AddressBookReducers from 'containers/address-book/reducers';
import ShoppingCartReducers from 'containers/shopping-cart/reducers';
import CheckoutPageReducers from 'containers/checkout-page/reducers';
import GroupSaleReducers from 'containers/groupsale-page/reducers'
import AppConigReducers from 'containers/app-config/reducers'
import LocationReducers from 'containers/locations/reducers'
import WithStoreDataReducers from 'containers/with-store-data/reducers'
import PaypalReducers from 'containers/paypal-page/reducers'
import NotificationsReducers from 'containers/notifications/reducers'
import PayNowReducers from '../pay-now-page/reducers'
import SelfCheckoutReducers from "../self-checkout-page/reducers";

export const getLanguage = (state) => {
  return state.settings['lan'];
}

export const getAuthState = (state) => {
  return state.auth
}

export const getCartState = (state) => {
  return state['shopping-cart']
}

export const getDeviceToken = (state) => {
  return state.notifications['deviceToken'];
}

export default (history) => combineReducers({
  router: connectRouter(history),
  ...SettingsReducers,
  ...AuthReducers,
  ...AccountPageReducers,
  ...OrderListReducers,
  ...OrderDetailReducers,
  ...BookmarkPageReducers,
  ...GiftCardPageReducers,
  ...AddressBookReducers,
  ...ShoppingCartReducers,
  ...CheckoutPageReducers,
  ...PointsReducers,
  ...AppConigReducers,
  ...LocationReducers,
  ...GroupSaleReducers,
  ...WithStoreDataReducers,
  ...PaypalReducers,
  ...NotificationsReducers,
  ...PayNowReducers,
  ...SelfCheckoutReducers,
})
import React, { Component } from "react";
import _config from "config";
import _helper from "utils/helper";
import { connect } from "react-redux";
import { List, Row, Col, Button, Modal, Icon, Input } from "antd";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import NamesModal from "containers/checkout-page/page-components/customerInfo/namesModal";
import PhoneModal from "containers/checkout-page/page-components/customerInfo/phoneModal";
import FloatingInput from "components/floating-input";
import FloatingInputPhone from "components/floating-input-phone";

import "./_customerInfo.scss";

class CustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.focusRef = React.createRef();
    this.focus = this.focus.bind(this);
  }

  focus() {
    this.focusInput.focus();
  }

  state = {
    displayNamesModal: false,
    displayPhoneModal: false
  };

  static defaultProps = {
    checkoutState: {}
  };

  componentDidMount() { }

  render() {
    let {
      lan,
      checkoutState,
      storeInstance,
      intl,
      defaultFirstName,
      defaultLastName,
      setCheckoutState,
      userProfile
    } = this.props;

    let defaultCountryCode = checkoutState.countryCode;

    //validate country code, otherwise set default value to "CA"
    defaultCountryCode = this.validateCountryCode(defaultCountryCode);

    let defaultPhoneNumber = checkoutState.phone;

    let currentFocus = "";
    if (checkoutState.focusField) {
      currentFocus = checkoutState.focusField;
    }

    defaultFirstName =
      userProfile && userProfile.first_name ? userProfile.first_name : "";
    defaultLastName =
      userProfile && userProfile.last_name ? userProfile.last_name : "";



    let fullName = checkoutState.firstName + " " + checkoutState.lastName;

    let { formatMessage } = intl;

    return (
      <div className="customer-info-container">
        <Row>
          <Col span={12}>
            <FloatingInput
              label={formatMessage({ id: "first_name" })}
              onFocus={this.handleCheck}
              onBlur={this.handleCheck}
              onChange={this.handleCheck}
              isNum={false}
              canBeEmpty={false}
              minLength={2}
              onInputChange={(key, value) => setCheckoutState(key, value)}
              inputType="first_name"
              defaultValue={defaultFirstName}
              focusRef={currentFocus}
            />
          </Col>
          <Col span={12}>
            <FloatingInput
              label={formatMessage({ id: "last_name" })}
              onFocus={this.handleCheck}
              onBlur={this.handleCheck}
              onChange={this.handleCheck}
              isNum={false}
              canBeEmpty={true}
              stateName={'lastName'}
              minLength={2}
              onInputChange={(key, value) => setCheckoutState(key, value)}
              focusRef={currentFocus}
              defaultValue={defaultLastName}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FloatingInputPhone
              label={formatMessage({ id: "phone_number" })}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnBlur}
              minLength={10}
              maxLength={15}
              isNum={true}
              canBeEmpty={false}
              inputValue={defaultPhoneNumber}
              inputType="phone_number"
              defaultNumber={defaultPhoneNumber}
              defaultCountry={defaultCountryCode}
              checkoutState={checkoutState}
              storeInstance={storeInstance}
              onInputChange={(key, value) => setCheckoutState(key, value)}
              focusRef={currentFocus}
            />
          </Col>
        </Row>
      </div>
    );
  }

  validateCountryCode(defaultCountryCode) {
    if (!_helper.validateCountryCode(defaultCountryCode)) {
      defaultCountryCode = "CA";
    }
    return defaultCountryCode;
  }
}

const mapStateToProps = state => {
  let authState = state["auth"];

  return {
    userProfile: authState.userProfile
  };
};

export default connect(mapStateToProps)(injectIntl(CustomerInfo));

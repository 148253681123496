import React, { Component } from 'react';
import { connect } from 'react-redux';
import _config from 'config';
import _helper from 'utils/helper';
import { Layout } from 'antd';
import NavBar from 'containers/groupsale-page/page-components/navbar';
import TopMenu from 'containers/groupsale-page/page-components/topMenu';
import TopAdsBar from 'containers/groupsale-page/page-components/top-ads';

const {
    Header, Content, Footer, Sider,
} = Layout

class GroupSaleLayout extends Component {

    render() {
        let {
            children,
            shortcut,
            onShortCutClick,
        } = this.props;

        return [
            <Layout style={{
                background: "white"
            }}>
                <Header style={{
                    background: "#1e89e0",
                    minWidth: "1000px",
                    padding: "0",
                    lineHeight: "104px",
                    height: "90px",
                    margin: "auto 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                }}>
                    <NavBar />
                </Header>
                <TopMenu shortcut={shortcut} onShortCutClick={onShortCutClick} />
                {/* <TopAdsBar /> */}
                <Layout style={{
                    minWidth: '1000px',
                    maxWidth: '1500px',
                    width: '100%',
                    margin: 'auto',
                    background: "white"
                }}>

                    <Layout style={{
                        minHeight: `calc(100vh - 64px)`,
                        background: "white"
                    }}>
                        <Content style={{ margin: '0', overflow: 'initial' }}>
                            <div style={{ paddingp: 24, background: '#fff' }}>
                                {children}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center', width: "100%", background: "white" }}>
                            © 2015-{_helper.getCurrentYear()}, Goopter Holdings Ltd.
                       </Footer>
                    </Layout>
                </Layout>
            </Layout >,
        ]
    }
}

const mapStateToProps = (state) => {

    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSaleLayout);
import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { injectIntl, FormattedMessage, } from 'react-intl';
import _ from 'lodash'
import { connect } from 'react-redux';
import { FaLock } from 'react-icons/fa';
import { Button } from 'antd';

class PayButton extends Component {

    getCheckoutMessage = () => {
        let { state, } = this.props
        let { paymentMethod, creditCard, } = state

        if (!paymentMethod) {
            return 'please_input_payment_method'
        }

        if (paymentMethod == 'credit_card' && !creditCard) {
            return 'please_input_valid_credit_card'
        }

        return 'pay_now'
    }

    onCheckoutClick = () => {
        if (this.getCheckoutMessage() === 'pay_now') {
            this.props.payOrder(this.props.state)
        }
    }

    renderDisclainmer = () => {
        return (
            <div className='checkout-disclaimer'>
                By clicking below I accept the current <span onClick={() => this.goToConditions()}> Conditions of Use </span> and <span onClick={() => this.goToPrivacy()}>Privacy Notice</span>.
            </div>
        )
    }

    renderCheckoutButton = () => {

        return (
            <Button
                disabled={_.get(this.props, 'order.total_due', 0) <= 0}
                size='large'
                style={{ width: '100%', fontWeight: 'bold', }}
                type="primary"
                onClick={() => this.onCheckoutClick()}
            >
                <FormattedMessage id={this.getCheckoutMessage()} />
            </Button>
        )
    }

    renderSecureMessage = () => {
        return (
            <div className="secure-inner-wrapper">
                <FaLock style={{ marginRight: '5px' }} />
                <FormattedMessage id="secure_connection" className="secure-text" />
            </div>
        )
    }

    render() {

        return (
            <div>
                {this.renderDisclainmer()}
                {this.renderCheckoutButton()}
                {this.renderSecureMessage()}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "pay-now", {}),
    order: _.get(state, 'pay-now.order.data', '')
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'PAY_NOW__SET_STATE', state }),
    payOrder: (payload) => ({ type: 'PAY_NOW__PAY_ORDER', payload })
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayButton));
import React, { Component } from 'react';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { Select, List, Button, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import './_selectItems.scss'
import classnames from 'classnames';

const Option = Select.Option;

class ItemSelect extends Component {

	state = {
		optsData: [],
		missingItems: [],
	}

	static defaultProps = {
		optsData: []
	}

	componentDidUpdate(prevProp, prepState) {
		if (prevProp.optsData != this.props.optsData && this.props.optsData) {
			this.reformatData()
		}
		if (prevProp.oldPrice != this.props.oldPrice) {
			this.setState({ oldPrice: this.props.oldPrice })
		}
		if (prevProp.newPrice != this.props.newPrice) {
			this.setState({ newPrice: this.props.newPrice })
		}
	}

	reformatData = () => {
		let { optsData, intl, limit, pickup, delivery } = this.props

		let newOptsData = []

		let { formatMessage } = intl;

		if (optsData && optsData.length) {
			optsData.map(opt => {
				for (let i = 0; i < opt.max; i++) {
					let nm = opt.max > 1 ? opt.nm + " " + (i + 1) : opt.nm
					let nameOpt = i + 1 > opt.min ? "(" + formatMessage({ id: 'optional' }) + ")" : ''
					let fullnm = nm + " " + nameOpt
					newOptsData.push({
						id: _helper.generateId(),
						opts: opt.opts,
						nm: fullnm,
						originalId: opt.id,
						require: i + 1 <= opt.min,
						selected: null
					})
				}
			})


		}

		//quantity
		if (limit) {
			let quantities = [];
			let quantity ={}
			for (let i = 0; i < limit; i++) {
				quantity = {
					id: i,
					nm: i + 1,
					pc: 0,
					scnt: 0,
					sq: 10000,
				}
				quantities.push(quantity)
			}
			let quantityId = _helper.generateId()
			if (limit == 1) {
				this.setState({
					[quantityId]: 1
				})
			}
			newOptsData.push({
				id: quantityId,
				opts: quantities,
				nm: formatMessage({ id: 'quantity' }),
				originalId: 'quantity',
				require: true,
				selected: limit == 1? quantity: null
			})
		}

		//shipping method
		if (pickup || delivery) {
			let methods = []
			let method = {}
			if (pickup) {
				method = {
					id: _config.SHIPPING_MAPPING_TO_NUMERIC['pickup'],
					nm: formatMessage({ id: 'pickup' }),
					pc: 0,
					scnt: 0,
					sq: 10000,
				}
				methods.push(method)
			}
			if (delivery) {
				method = {
					id: _config.SHIPPING_MAPPING_TO_NUMERIC['delivery'],
					nm: formatMessage({ id: 'delivery' }),
					pc: 0,
					scnt: 0,
					sq: 10000,
				}
				methods.push(method)
			}
			let id = _helper.generateId()
			if (!(pickup && delivery)) {
				this.setState({
					[id]: method.nm 
				})
			}
			newOptsData.push({
				id: id,
				opts: methods,
				nm: formatMessage({ id: 'shipping_method' }),
				originalId: 'shippingmethod',
				require: true,
				selected: pickup && delivery? null: method 
			})
		}
		this.setState({ optsData: newOptsData })
	}

	onSelctItemChange = (itemId, value) => {

		let { missingItems, optsData, newPrice } = this.state

		this.setState({ [itemId]: value })

		//handle selected value
		let index = optsData.findIndex(opt => { return itemId == opt.id })
		let optId = optsData[index].opts.find(opt => { return opt.nm == value })
		optsData[index].selected = optId
		this.setState({ optsData: optsData })

		//handle price 
		if (value) {
			//added new opt value to price
			let selectedOpt = optsData.find(opt => { return itemId == opt.id })
			let selectedSubOpt = selectedOpt.opts.find(opt => { return value == opt.nm })
			let addedPrice = selectedSubOpt && selectedSubOpt.pc
			if (addedPrice) {
				let totalPrice = Number.parseFloat(newPrice) + Number.parseFloat(addedPrice)
				let priceState = itemId + 'price'
				if (this.state[priceState]) {
					totalPrice -= Number.parseFloat(this.state[priceState])
				}
				this.setState({
					newPrice: totalPrice.toFixed(2),
					[priceState]: addedPrice,

				})
			}

			//adjust missing value
			if (missingItems) {
				let newValue = []
				newValue = missingItems.filter(item => {
					return item.id !== itemId
				})
				this.setState({
					missingItems: newValue
				})
			}
		} else {
			//remove the opt value from price
			let priceState = itemId + 'price'

			if (this.state[priceState]) {
				let totalPrice = Number.parseFloat(newPrice) - Number.parseFloat(this.state[priceState])
				this.setState({
					newPrice: totalPrice.toFixed(2),
					[priceState]: 0
				})
			}
		}

	}

	getDiscountPercentage = () => {
		let { oldPrice, newPrice } = this.props
		return Math.round(parseFloat(oldPrice - newPrice) / oldPrice * 100)
	}

	verifyItems = () => {
		let { optsData } = this.state
		let missingValue = []
		missingValue = optsData.filter(opt => {
			if (opt.require && !this.state[opt.id] && opt.id) {
				return opt.id
			}
		})
		this.setState({ missingItems: missingValue })
		if (!missingValue.length) {
			this.props.onBuyClick(this.state)
		}

	}

	render() {

		let {
			expired,
			currency,
			amtSold,
			limit,

			intl,
		} = this.props;

		let {
			optsData,
			newPrice,
			oldPrice,
			missingItems
		} = this.state

		let { formatMessage } = intl;

		return (
			<div className="select-items-container">
				<div className="breakout-pricing-messages">
					<div className="price-discount">
						<div className="price">
							<span className="oldPrice">{_helper.formatCurrency(oldPrice ? oldPrice : this.props.oldPrice, currency)}</span>
							<span className="newPrice">{_helper.formatCurrency(newPrice ? newPrice : this.props.newPrice, currency)}</span>
						</div>
						<span className="discount-message-wrapper">
							<span >{this.getDiscountPercentage()}%</span>
							<span> <FormattedMessage id="OFF" /></span>
						</span>
					</div>
					<div className="message">
						{amtSold && amtSold > 10 ?
							<div >
								<span>{amtSold}+</span>
								<span> <FormattedMessage id="solds" /></span>
							</div> : ''
						}
					</div>
				</div>
				{optsData && optsData.length ?
					<div className="select-item-container">
						{missingItems && missingItems.length ?
							<div className="missing-item-message">
								<div> <Icon type="exclamation-circle" /> </div>
								<div> &nbsp; <FormattedMessage id="please_selected_belows" /> </div>
							</div>
							: ''}
						<List
							itemLayout="horizontal"
							dataSource={optsData}
							renderItem={(item, i) => (
								<List.Item
									style={{ border: 'none' }}
									key={`select-item-${i}`}>
									<div className={classnames({
										"select-item": true,
										"missing-item": missingItems.indexOf(item) != -1
									})} >
										<Select
											allowClear={true}
											size="large"
											style={{ width: "100%" }}
											placeholder={formatMessage({ id: "select_what" }, { keyword: item.nm })}
											value={this.state[item.id]}
											onChange={(value) => this.onSelctItemChange(item.id, value)}>
											{item.opts.map((opt, i) => {
												return (
													<Option disabled={opt.sq > 0 ? false : true} className="select-dropdown-class" key={`select-item-opt-${i}`} value={opt.nm}>
														<div className="select-dropdown-class">
															<span className="select-dropdown-nm">{opt.nm}</span>
															<span className="select-dropdown-pc">{opt.pc ? <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{_helper.formatCurrency(opt.pc, currency)}</span> : ''}</span>
															<span>{opt.sq > 0 ? '' : <span> &nbsp;(<FormattedMessage id="sold_out" />)</span>}</span>
														</div>
													</Option>
												)
											})}
										</Select>
									</div>
								</List.Item>
							)}
						/>
					</div> : ''}
				<Button disabled={expired || !limit} className="buy-button" onClick={() => this.verifyItems()} style={{ width: '100%' }} size="large" type="primary">
					{expired ? <FormattedMessage id="expired" /> :
						!limit ? <FormattedMessage id="out_of_stock" /> :
							<FormattedMessage id="buy" />
					}

				</Button>
			</div>
		)
	}
}


export default injectIntl(ItemSelect);
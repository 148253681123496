import _ from "lodash";
import _helper from "utils/helper";

export function getDefaultState() {
  return {
    mode: "current",
    userLat: null,
    userLon: null,
    latlngDisplay: {
      lat: 40.73,
      lng: -73.93
    },
    userAddr: {
      street: null,
      city: null,
      region: null,
      cntry: null,
      zipcd: null
    },
    searchValue: "",
    placeId: null,
    shouldGetLoction: false,
  };
}

function addCurrentLatLon(state, action) {
  let finalState = _.cloneDeep(state);
  let { userLat, userLon } = action.payload;
  finalState.userLat = userLat;
  finalState.userLon = userLon;

  return finalState;
}

function addAddressComp(state, action) {
  let finalState = _.cloneDeep(state);
  let addressComp = action.payload;

  addressComp.map(component => {
    let addressType = component.types;
    if (addressType.includes("street_number")) {
      finalState.userAddr.street = component["short_name"];
    } else if (addressType.includes("route")) {
      finalState.userAddr.street += `${
        finalState.userAddr.street.length ? " " : ""
        }${component["long_name"]}`;
    } else if (
      addressType.includes("locality") ||
      addressType.includes("sublocality")
    ) {
      finalState.userAddr.city = component["long_name"];
    } else if (addressType.includes("administrative_area_level_1")) {
      finalState.userAddr.region = component["short_name"];
    } else if (addressType.includes("country")) {
      finalState.userAddr.cntry = component["short_name"];
    } else if (addressType.includes("postal_code")) {
      finalState.userAddr.zipcd = component["short_name"];
    }
  });
  let shortAddress = _.compact([
    finalState.userAddr.street,
    finalState.userAddr.city,
    finalState.userAddr.region
  ]).join(", ");
  let longAddress = _.compact([
    finalState.userAddr.street,
    finalState.userAddr.city,
    finalState.userAddr.region,
    finalState.userAddr.cntry,
    finalState.userAddr.zipcd
  ]).join(", ");
  finalState.userAddr.shortAddress = shortAddress;
  finalState.userAddr.longAddress = longAddress;
  return finalState;
}

function updateQueryLocal(state, action, status) {
  let finalState = _.cloneDeep(state);
  if (status) {
    let { response } = action.response
    finalState.queryLat = response.lat
    finalState.queryLng = response.lng
  }

  return finalState
}

function getGeoLocation(state) {
  let finalState = _.cloneDeep(state);
  finalState.shouldGetLoction = !finalState.shouldGetLoction
  return finalState
}

export default {
  locations: (state = getDefaultState(), action) => {
    switch (action.type) {
      case "LOCATIONS__SET_STATE":
        return Object.assign({}, _.cloneDeep(state), action.state);
      case "LOCATIONS_ADD_CURRENT_LAT_LON":
        return addCurrentLatLon(state, action);
      case "LOCATIONS_ADD_ADDRESS_COMP":
        return addAddressComp(state, action);
      case "LOCATIONS_GET_LOCATION_DETIALS_SUCCESS":
        return updateQueryLocal(state, action, true);
      case "LOCATIONS_GET_LOCATION_DETIALS_FAILED":
        return updateQueryLocal(state, action, false);
      case 'LOCATIONS_GET_GEOLOACTION':
        return getGeoLocation(state, action)
      default:
        return state;
    }
  }
};

import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Carousel, Icon, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import Img from 'react-image'
import ImgHolder from 'images/image_groupsale_goldenratio_gray.png'
import ImgHolderMedium from 'images/image_groupsale_medium_gray.png'
import ReactPlayer from 'react-player'
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import { FaRegPlayCircle } from "react-icons/fa";
import './_picVideoCarousel.scss'
import styled, { css } from 'styled-components';


class PicVideoCarousel extends Component {

    state = {
        currentSelected: 0
    }

    static defaultProps = {
        imgs: [],
        video: ''
    }

    onCarouselChange = (index, b, c) => {
        this.setState({ currentSelected: index })
    }

    onBottomButtonClick = (index) => {
        this.setState({ currentSelected: index })
        this.slider.goTo(index, true)
    }

    moveLeft = (index) => {
        this.slider.goTo(index - 1, true)
        this.setState({ currentSelected: index - 1 })
    }

    moveRight = (index) => {
        this.slider.goTo(index + 1, true)
        this.setState({ currentSelected: index + 1 })
    }

    render() {

        let {
            imgs,
            video,
            nextUrl,
            prevUrl
        } = this.props;

        return (

            <div className="picture-video-carousel-container">
                <div className="nav-arrows">
                    <div
                        className="button prev"
                        onClick={() => this.moveLeft(this.state.currentSelected)}
                    >
                        <Icon className="carousel-icon" type="left" />
                    </div>

                    <div
                        className="button next"
                        onClick={() => this.moveRight(this.state.currentSelected)}
                    >
                        <Icon className="carousel-icon" type="right" />
                    </div>

                </div>

                <Carousel
                    ref={c => (this.slider = c)} afterChange={this.onCarouselChange}>
                    {imgs && imgs.length ?
                        imgs.map((img, i) => {
                            return (
                                <div key={i}>
                                    <Img
                                        className="img"
                                        src={`${_config['IMG_PREFIX']}${img}`}
                                        unloader={<Img
                                            className="img"
                                            src={ImgHolder}
                                        />} />
                                </div>
                            )
                        }) : ''
                    }
                    {video ? <ReactPlayer url={video} className="img" width="100%" height="412.2px"></ReactPlayer> : ''}
                </Carousel>
                <div className="imgs-button-wrapper">
                    {imgs && imgs.length ?
                        imgs.map((img, i) => {
                            return (
                                <div onClick={() => this.onBottomButtonClick(i)} key={i}>
                                    <Img
                                        className={classnames({
                                            "img-small": true,
                                            "selected-border": this.state.currentSelected == i,
                                        })}
                                        src={`${_config['IMG_PREFIX']}${img}`}
                                        unloader={<Img
                                            className="img-small"
                                            src={ImgHolderMedium}
                                        />} />
                                </div>
                            )
                        }) : ''}
                    {video ?
                        <div onClick={() => this.onBottomButtonClick(imgs.length)}
                            className={classnames({
                                "img-small": true,
                                "selected-border": this.state.currentSelected == imgs.length,
                            })}>
                            {/* <img url={this.state.video} width="57px" height="57px" /> */}
                            <div className={classnames({
                                "icon": true,
                                "icon-selected": this.state.currentSelected == imgs.length,
                            })}>  <FaRegPlayCircle /></div>

                        </div> : ''}
                </div>
            </div>
        )
    }
}


export default injectIntl(PicVideoCarousel);
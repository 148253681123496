import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Layout, Menu, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';


import './_sidebar.scss';

const {
  Header, Content, Footer, Sider,
} = Layout;
const { SubMenu } = Menu;


class AccountSideBar extends Component {

  getCurrentTab = () => {
    let pathName = window.location.pathname;

    switch (pathName) {
      case '/account/orders':
        return ['order-history-tab']
      case '/account/order':
        return ['order-detail-tab']
      case '/account/bookmarks':
        return ['favorite-tab']
      case '/account/gift-cards':
        return ['gift-cards-tab']
      case '/account':
        return ['my-account-tab']
      case '/account/address-book':
        return ['address-book-tab']
    }
  }

  render() {
    let {
      push
    } = this.props;

    let currentSelectedKey = this.getCurrentTab()

    return (
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        className="myaccount-sidebar-container"
      >
        <Menu
          mode="inline"
          defaultOpenKeys={['profile-tab', 'my-orders-tab', 'my-assets-tab']}
          selectedKeys={currentSelectedKey}
          style={{ height: '100%', borderRight: 0 }}
        >
          <Menu.Item key="my-account-tab" onClick={() => { push('/account') }}>
            <Icon type="user" />
            <FormattedMessage id="my_account" />
          </Menu.Item>
          <Menu.Item key="address-book-tab" onClick={() => { push('/account/address-book') }}>
            <Icon type="folder-open" />
            <FormattedMessage id="address_book" />
          </Menu.Item>
          <Menu.Item key="order-history-tab" onClick={() => { push('/account/orders') }}>
            <Icon type="profile" />
            <FormattedMessage id="order_history" />
          </Menu.Item>
          <SubMenu key="my-assets-tab" title={<span><Icon type="wallet" /><FormattedMessage id="my_assets" /></span>}>
            <Menu.Item key="gift-card-tab" onClick={() => { push('/account/gift-cards') }}>
              <Icon type="gift" />
              <FormattedMessage id="gift_cards" />
            </Menu.Item>
            <Menu.Item key="points-tab" disabled={true}  onClick={() => { push('/account/points') }}>
              <Icon type="heart" />
              <FormattedMessage id="points" />
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="favorite-tab" onClick={() => {
            push('/account/bookmarks')
          }}>
            <Icon type="book" />
            <FormattedMessage id="bookmarks" />
          </Menu.Item>
        </Menu>
      </Sider>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch)
  }
}
export default connect(null, mapDispatchToProps)(AccountSideBar);
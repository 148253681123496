import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Dropdown, Button, Menu, Radio, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import './_sortButton.scss'

const RadioGroup = Radio.Group;

class SortButton extends Component {

    state = {
        value: 106,
        name: "best_match",
        icon: "",
        format: "sort_by"
    }

    static defaultProps = {
        sortData: [
            {
                id: 106,
                name: "best_match",
                icon: "",
                format: "sort_by"
            },
            {
                id: 102,
                name: "distance",
                icon: "",
                format: "distance"
            },
            {
                id: 103,
                name: "ranking",
                icon: "",
                format: "ranking"
            },
            {
                id: 104,
                name: "price_ascending",
                icon: "arrow-up",
                format: "price"
            },
            {
                id: 105,
                name: "price_descending",
                icon: "arrow-down",
                format: "price"
            }
        ]
    }


    componentDidUpdate(prevProps, prevState) {
        let { currentSort, sortData } = this.props
        if (prevProps.currentSort != currentSort) {
            let sort = sortData.find(data => { return data.id == currentSort })
            this.setState({
                value: sort.id,
                name: sort.name,
                icon: sort.icon,
                format: sort.format
            });
        }
    }

    onSortChange = (e) => {
        let {
            onSortButtonClick,
        } = this.props;
        onSortButtonClick(e.target.value)
    }

    getMenu = () => {
        let {
            sortData,
            currentSort,
        } = this.props;

        return (
            <Menu className="menu">
                <RadioGroup style={{ padding: 15 }} onChange={(e) => this.onSortChange(e)} value={this.state.value}>
                    {sortData.map((sort, i) => {
                        return (
                            <Radio
                                key={i}
                                style={{
                                    display: 'block',
                                    height: '30px',
                                    fontSize: '15px',
                                    lineHeight: '30px',
                                    width: '250px',
                                }}
                                value={sort.id}>
                                <FormattedMessage id={sort.name} />
                            </Radio>
                        )
                    })}
                </RadioGroup>
            </Menu>
        )
    }

    render() {

        let { value, name, icon, format } = this.state

        return (
            <div className="sort_button-container">
                <Dropdown overlay={this.getMenu} placement="bottomRight">
                    <Button size="large" type={value == 106 ? 'default' : 'primary'}>
                        <div>
                            {icon ? <Icon className="icon" type={icon} /> : ''}
                            <span className="sort_title"> <FormattedMessage id={format} /> </span>
                            <Icon type="down" />
                        </div>
                    </Button>
                </Dropdown>

            </div>
        )
    }
}


export default injectIntl(SortButton);
import React, { Component } from 'react';
import { Menu, Row, Col, Icon, Spin } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FaStoreAlt } from "react-icons/fa";
import GoogleMapReact from 'google-map-react';
import MapMarker from 'components/map-marker';
import _helper from 'utils/helper'

import "./_storeInfo.scss"

const { SubMenu } = Menu;

class StoreInfo extends Component {

    state = {
        seeAllHour: false,
    }

    render() {
        let { storeInstance, storeName, storeLoading } = this.props

        let weekly_hours_keys = storeInstance['op_status'] && storeInstance['op_status']['weekly_hours'] && Object.keys(storeInstance['op_status']['weekly_hours']).length ?
            Object.keys(storeInstance['op_status']['weekly_hours']) : []

        return (
            <div className="store-info-container">
                <Spin spinning={storeLoading}>
                    <div className="merchant-header">
                        <div className="icon-merchant">
                            <FaStoreAlt size="1em" />
                        </div>
                        <div className="header-text">
                            <div className="store-name">{storeName}</div>
                            <div className="store-services">{storeInstance['kws']}</div>
                        </div>
                    </div>

                    {storeInstance.adr && storeInstance.adr['lat'] && storeInstance.adr['lon']
                        ? <div className="store-map-wrapper">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                                defaultCenter={[
                                    storeInstance.adr['lat'],
                                    storeInstance.adr['lon']
                                ]}
                                center={[
                                    storeInstance.adr['lat'],
                                    storeInstance.adr['lon']
                                ]}
                                zoom={11}
                            >
                                <MapMarker
                                    lat={storeInstance.adr['lat']}
                                    lng={storeInstance.adr['lon']}
                                />
                            </GoogleMapReact>

                        </div> : ''}

                    {storeInstance.adr ?
                        <div className="address-bar c-bdr-gray-lt">
                            <div className="marker">
                                <Icon type="environment" size="irem" />
                            </div>
                            <div className="address-area">
                                <div className="address-top">{storeInstance.adr['city']}</div>
                                <div className="address-bottom">{_helper.formatAddress(storeInstance.adr).join(', ')}</div>
                            </div>
                        </div> : ''}


                    <div className="merchant-attribute c-bdr-gray-lt">
                        <ul className="contact-info">
                            <div className="contact-row">
                                <Icon type="phone" theme="filled" style={{ paddingRight: '5px', color: "#333333" }} />
                                <li className="phone-number contact-row"> <a
                                    href={`tel:${storeInstance['phone']}`}
                                    className="link">{storeInstance['phone']}</a></li>
                            </div>
                        </ul>
                        {storeInstance['op_status'] ?
                            <div className="merchant-hours">
                                <div className="merchant-status">
                                    <div className="mechant-icon"> <Icon className="mechant-icon" type="clock-circle" /> </div>
                                    <div className="mechant-status"> <FormattedMessage id="today" /> : {storeInstance['op_status']['current_hours']}</div>
                                </div>
                                {storeInstance['op_status'] && this.state.seeAllHour && weekly_hours_keys && weekly_hours_keys.length ?
                                    <div>
                                        {weekly_hours_keys.map((day, i) => {
                                            return (
                                                <div className="open-hour" key={i}>
                                                    <div className="day">{day} :</div>
                                                    <div className="hours"> {storeInstance['op_status']['weekly_hours'][day]}</div >
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : ''}
                                {weekly_hours_keys && weekly_hours_keys.length ?
                                    <div onClick={() => this.setState({ seeAllHour: !this.state.seeAllHour })} className="toggle">
                                        {this.state.seeAllHour ?
                                            <FormattedMessage id="show_less" /> : <FormattedMessage id="see_all_hours" />} </div>
                                    : ''
                                }

                            </div> : ''
                        }

                    </div>


                </Spin>
            </div>
        )
    }
}


export default injectIntl(StoreInfo)
export default {
  "common": {
    " ": " ",
    "yes": "是",
    "no": "否",
    "confirm": "确认",
    "place_order": "确认购买",
    "delete": "删除",
    "ok": "确认",
    "close": "关闭",
    "filter": "筛选",
    "product": "产品",
    "store": "商店",
    "loading": "载入中...",
    "setting": "设置",
    "back": "后退",
    "dashboard": "控制面板",
    "notification": "消息",
    "warning": "警告",
    "submit": "提交",
    "cancel": "取消",
    // "comment": "备注",
    "comment": "**特别指示**",
    "tel": "电话",
    "phone_number": "电话号码",
    "not_set": "未设置",
    "name": "姓名",
    "email": "电邮",
    "customer": "客户",
    "admin": "管理",
    "current": "当前",
    "newest": "最新",
    "no_new_noti": "没有新的通知",
    "clear_all": "清除所有",
    "from": "从",
    "to": "到",
    "all": "全部",
    "save": "保存",
    "disabled": "禁用",
    "margin": "页边距",
    "top": "上",
    "bottom": "下",
    "left": "左",
    "right": "右",
    "general": "通用",
    "general_settings": "通用设置",
    "status": "狀態",
    "new": "新",
    "reload": "刷新",
    "selected": "已选",
    "items": "商品",
    "clear_fliter": "清除篩選條件",
    "search": "搜索",
    "first_name": "名",
    "last_name": "姓",
    "enter_password": "请输入密码",
    "unlock": "解锁",
    "qty": "数量",
    "gift_cards": "礼品卡",
    "view_all": "查看全部",
    "profile": "个人资料",
    "nick_name": "昵称",
    "verification_code": "验证码",
    "get_verification_code": "获取验证码",
    "edit": "编辑",
    "action": "行动",
    "send": "发送",
    "add_comment": "添加备注",
    "receiver": "接受者",
    "email_phone": "电子邮件/电话",
    "change": "改变",
    "description": "描述",
    "remove": "删除",
    "reorder": "再来一单",
    "enter_coupon_code": "输入优惠劵代码",
    "apply": "使用优惠券",
    'complete_oreder': "完成订单",
    "category": "类别",
    "expired": "已过期",
    "optional": "可选",
    "about": "关于",
    "highlights": "亮点",
    "customer_reviews": "客户评论",
    "relevant_reviews": "相关评论",
    "ratings": "评级",
    "reviews": "评论",
    "buy": "购买",
    "quantity": "数量",
    "out_of_stock": "缺货",
    "refresh": "刷新",
  },
  "common_order_status": {
    "pending": "待处理",
    "processing": "处理中",
    "payment_review": "付款审核",
    "completed": "已完成",
    "canceled": "已取消",
    "closed": "已关闭",
    "pending_payment": "待付款",
    "holded": "暂停",
  },
  "common_shipping_method": {
    "shipping_method": "订单类型",
    "delivery_option": "订单类型",
    "order_type": "订单类型",
    "delivery": "配送",
    "pickup": "自取",
    "eatin": "堂食",
    "instore": "店内",
    "takeout": "外卖",
    "dine_in": "堂食",
    "in_store": "店内",
    "flatrate": "统一费率",
    "freeShipping": "免费配送",
    "how": "方式",
  },
  "common_payment_method": {
    "payment_method": "付款方式",
    "pay_later": "稍后刷卡支付",
    "cash": "现金",
    "credit_card": "信用卡",
    "giftcard": "礼品卡",
    "points": "积分",
    "points_balance": "Balance",
    "points_and_giftcard": "礼品卡与积分",
    "wechat_pay": "微信支付",
    "paypal_express": "贝宝",
    "union_pay": "银联借记卡",
    "union_pay_express": "银联信用卡",
    "alipay": "支付宝",
    "union_pay": "Union Pay",
    "union_pay_express": "Union Pay Express",
    "free": "免费",
    "points_balance": "Balance",
    "remaining_balance": "Remaining Points",
    "redeemed": "Redeem",

    "card_number": "卡号",
    "name_on_card": "持卡人",
    "cvv": "安全码",

    "cvv_descpt": '来自卡背面的3到4位数字',
    "cash_despcrition": '订单以现金支付',
    "pay_later_despcrition": '订单将在以后用卡支付',
    "wechat_pay_despcrition": '点击“确认”后，您将被转到微信付费页面完成购买',
    "paypal_expres_despcrition": '点击“确认“后，您将被引导至贝宝页面完成购买',
    "alipay_despcrition": '点击“确认”后，您将被定向到支付宝页面以完成购买',
    "union_pay_despcrition": '点击“确认”后，您将被定向到银联借记卡页面以完成购买',
    "union_pay_express_despcrition": '点击“确认”后，您将被定向到银联信用卡页面以完成购买',
  },
  "common_price": {
    "subtotal": "小计",
    "discount": "折扣",
    "store_discount": "商家折扣",
    "coupon_discount": "打折券",
    "shipping_and_handling": "配送费",
    "total_before_tax": "税前金额",
    "tax": "税",
    "order_total": "订单总额",
    "gift_card_payment": "礼品卡支付",
    "points_payment": "积分支付",
    "total_paid": "已付款",
    "total_due": "应付款",
    "tips": "小费",
    "delivery_fee": "配送费",
    "max_amount": "最高金额",
    "min_amount": "最低金额",
    "balance": "余额",
    "amt": "总额",
    "price": "价格",
    "item(s)": "物件",
    "shopping": "购物",
    "billing_shipping": "付款及配送",
    "done": "完成",
    "customer_info": "客户信息",
    "delivery_info": "运输信息",
    "payment_info": "支付信息",
    "other_payment_info": "其他付款信息",
  },
  "common_†ime": {
    "date": "日期",
    "today": "今天",
    "tomorrow": "明天",
    "yesterday": "昨天",
    "this_week": "本星期",
    "last_week": "上個星期",
    "this_month": "这个月",
    "last_month": "上个月",
    "last": "上个",
    "next": "下一个",
    "now": "現在",
    "month": "月",
    "year": "年",
    "this_week": "本星期",
    "last_week": "上个星期",
    "this_month": "這個月",
    "last_month": "上個月",
    "select_date": "选择日期",
    "select_time": "选择时间",
    "expiry_date": "过期日",
    "delivery_time": "配送时间",
    "pickup_time": "取货时间",
    "select_delivery_time": "选择配送时间",
    "select_pickup_time": "选择取货时间",
    "due_time": "期待时间",
    "when": "时间",
    "requested_time": "要求时间",
    "scheduled_time": "预计时间",
  },
  "common_language": {
    "language": "语言",
    "en": "English",
    "zh": "简体中文",
    "zh-Hant": "繁體中文",
    "jp": "日本語",
  },
  "common_address": {
    "add_address": "添加地址",
    "address": "地址",
    "street": "街道",
    "unit": "单元",
    "buzzer": "门铃",
    "unit_optional": "单元 (Optional)",
    "buzzer_optional": "门铃 (Optional)",
    "city": "城市",
    "province": "省份",
    "country": "国家",
    "postal_code": "邮编",
    "quick_map_search": "快速地图搜索",
    "map_search": "地图搜索",
    "shipping_address": "配送地址",
    "billing_address": "账单地址",
    'set_as_default': "设为默认",
    "enter_a_location": "请输入街道地址",
    "location": "地区",
    "van": "温哥华",
    "vic": "维多利亚",
    "tor": "多伦多",
    "seattle": "西雅图",
    "choose_city": "选择城市",
    "current_location": "当前位置",
    "use_info_from_acc": "使用帐户信息",
  },
  "common_error": {
    "x_is_invalid": "无效的{x}",
    "unable_to_load_any_data": "无法加载数据",
    "unstable_network": "网络速度较慢或不稳定，请检查您的网络连接",
    "logout_force_message": "抱歉，您的帐户已从其他设备登录或超时，请重新登录。",
    "incorrect_password": "密码错误",
    'country_code_error': '国家/地区代码错误',
    'incorrect_username_password': '用户名/密码不正确',
    'verification_code_expired': '验证码已过期',
    'verifcation_code_request_overlimit': '验证码请求超限',
    'incorrect_verification_code': '验证码不正确',
    "error_fetch_user_profile": "无法获取用户个人资料",
    "login_failed": "登录失败",
    "profile_update_success": "更新个人资料成功",
    "email_update_success": "更新电子邮件成功",
    "phone_update_success": "更新电话号码成功",
    "failed_fetch_gift_card_detail": "无法获取礼品卡详细信息",
    "gift_card_transfer_success": "礼品卡转让成功",
    "gift_card_transfer_fail": "无法转让礼品卡",
    "invalid_request": "无效请求",
    "record_not_found": "未找到记录",
    "general_error": "发生意外错误，请稍后再试",
    "failed_fetch_points_detail": "无法获取积分详细信息",
    "profile_update_failed": "更新用户个人资料失败",
    "email_update_failed": "更新电子邮件失败",
    "phone_update_failed": "更新电话号码失败",
    "get_verification_code_success": "验证码已发送到您的手机",
    "verification_too_many_requests": "验证码已被请求过多次，请稍后再试",
    "invalid_phone_number": "无效的手机号",
    "invalid_table_number": "无效的桌号",
    "invalid_party_size": "无效的人数",
    "invalid_phone_number": "无效的的手机号",
    "error": "错误",
    "coupon_invalid": "无效的优惠券",
    "coupon_valid": "有效优惠券",
    "invalid_coupon": "优惠券代码 {couponCode} 无效",
    "coupon_applied": "优惠券 {couponCode} 已经使用",
    "distance_exceeded": "超出距离",
    "exceed_delivery_distance": "选择的配送地址超过最远的配送距离 {delivery_distance} km，请选择其它地址。",
    "invalid_address": "无效地址",
    "item_error": "发生了一个错误。你的购物车已被清空，请重新添加产品到购物车后再结账。",
    "shipping_country_text": "可配送到使用以下国家的地址: {countries}",
    "item_oos": "抱歉，以下产品缺货: {oos_items}。请返回商家页面调整购物车后重新结账。",
    "invalid_shipping_method": "无效配送方式，请尝试另一种配送方式。",
    'please_input_phone_number': '请输入电话号码',
    'please_input_first_name': '请输入名字',
    'please_input_last_name': '请输入姓氏',
    'please_input_shipping_method': '请输入送货方式',
    "please_input_sub_shipping_method": "请输入送货方式",
    'please_input_shipping_addresss': '请输入送货地址',
    'please_input_table_number': '请输入桌号',
    'please_input_payment_method': '请输入付款方式',
    "please_select_payment_method": "请选择付款方式",
    'please_input_valid_credit_card': '请输入有效的信用卡',
    'please_input_billing_address': '请输入帐单地址',
    "invalid_credit_card": "无效信用卡，清选择另外一个支付方式。",
    "unsuccessful_checkout": "结帐不成功。请再试一次。",
    "invalid_request": "结帐不成功。无效请求。",
    "price_over_limit": "结账不成功。价格超过付款限额。",
    "invalid_shp_mtd": "结帐不成功。无效配送方式，请尝试另一种配送方式。",
    "new_order_created": "新订单已创建",
    "checkout_success": "您的订单已经创建：#{order_id}。当订单状态更新时系统将会发送通知信息给您。",
    "pay_success": "新的订单已经创建：#{order_id}。当订单状态更新时系统将会发送通知信息给您。",
    "please_input_belows": "请输入以下缺少的部分",
    "invalid_expiry": "无效的过期日",
    "unable_to_change_here": "无法在此更改",
    "please_select_on_previous_page": "请在上一页选择",
    "invalid_card_number": "无效的卡号",
    "invalid_zipcd": "无效的邮政编码",
    "click_one_more_time_to_deselect": "再点击一次取消选择",
    "paypal_payment_success_title": "支付成功",
    "paypal_payment_success_text": "您的付款已收到。下面将返回到订单页面。",
    "error_payment_failed": "支付失敗",
    "internal_server_error": "抱歉，出现意外错误，请稍后再试或直接与商家联系。",
    "unexpected_error": "发生意外错误，请稍后再试",
    "no_items_error": "购物车里没有任何产品。请添加一些产品后再结算。",
    "giftcard_pays_order_total": "所选的礼品卡将支付此订单，无需其它付款方式。",
    "points_pays_order_total": "您的积分已经支付订单总额，无需其它付款方式。",
    "giftcard_points_pays_order_total": "您的礼品卡和积分已经支付订单总额，无需其他付款方式。",
    "digit_only": "请只输入数字",
    "please_selected_belows": "请完成所有选择：",
    "please_select_location_from_the_list": "请从目录中选择地址。",
    "alert_password_reset_success": "您的登录密码已经更改成功，您可以启用新密码登录。",
    "alert_phone_vc_not_match": "手机验证码不匹配",
    "alert_phone_not_found": "此号码尚未注册",
    "alert_email_not_found": "此邮件地址尚未注册",
    "alert_password_reset_email_send": "重置密码邮件已经发送",
    "alert_email_length": "无效邮件地址",
    "alert_password_min_length": "密码必须至少为6个字符",
    "x_is_required": "是必填项",
    "location_permission_is_blocked_service_may_not_perform_correctly": "位置权限受限，此服务可能会受到影响 ",
    "order_payment_status_error": "商家已经接单，请当面支付。",
    "order_comment_status_error": "商家已经开始准备此订单，如果有任何更改，请联系商家。电话：{phone}.",
  },
  "common_acccount": {
    "my_account": "我的帐户",
    "address_book": "地址簿",
    "my_assets": "我的资产",
    "log_in": "登录",
    "logout": "登出",
    "logout_confirm_message": "您确认要签退吗?",
    "login_with_text": "手机短信登陆",
    "password": "密码",
    "remember_me": "记住我",
    "login_with_account": "账户登陆",
    "sign_up": "注册",
    "sign_up_biz": "企业注册",
    "store_mngt": "商店管理",
    "change_by_email": "通过电子邮件",
    "change_by_text_message": "通过手机短信",
    "change_by_existing_password": "通过电子邮件",
    "set_password": "设置密码",
    "new_password": "新密码",
    "old_password": "旧密码",
    "confirm_password": "确认密码",
    "change_password": "更改密码",
    "code": "验证码",
    "request_pass_reset": "请求密码重置",
    "secure_connection": "信息將通過加密方式安全傳送",
  },
  "common_country": {
    "canada": "加拿大",
    "united_states": "美国",
    "china": "中国",
    "south_korea": "韩国"
  },
  "common_order": {
    "no_orders": "没有订单",
    "order_id": "订单号",
    "order_history": "订单历史",
    "recent_orders": "近期订单",
    "order_date": "订单日期",
    "order_detail": "订单明细",
    "order_amount": "订单金额",
    "ordered_on": "订单时间",
    "order_pending_descrip": "等待商店确认订单",
    "order_processing_descrip": "正在准备订单",
    "order_complete_descript": "订单已完成",
    "order_cancelled_descript": "订单已被取消",
    "table_number": "桌号",
    "party_size": "就餐人数",
    "table_info": "餐桌详情",
    "server": "服务员",
    "go_to_order": "前往该订单",
    "surcharge": "附加费",
    "store_surcharge": "附加費",
    "order_surcharge": "订单附加费",
    "item_total": "商品小计",
    "surcharge_paid": "附加费(已支付)",
    "surcharge_unpaid": "附加费(未支付)",
    "related_id": "相关订单 #",
    "related": "相关",
    'surcharege_paid_message': '此订单不包括附加费。请参考订单 #{id}',
    "surcharge_paid_to_message": "本订单是用来支付订单 #{id}的附加费",
    "pay_now": "现在付款",
    "please_pay_now": "订单尚未支付，您可以在线支付。",
    "are_you_sure_cancel_order": "您确定要取消订单吗?",
    "please_pay_by_x": "请在{x}内完成支付，超时后订单将自动取消",
    "cancel_order_success": "您的订单已经取消。",
    "cancel_order_failed": "未能取消订单，请稍后再试。",
  },
  "common_store": {
    "stores": "商店",
    "view_stores": "查看商店",
    "store_name": "商家名称",
    "to_store": "前往商店",
    "to_product": "前往商品",
    "contact_store": "联系商店",
    "bookmarks": "书签",
    "gift_card_detail": "礼品卡详情",
    "my_gift_cards": "我的礼品卡",
    "gift_card_used": "使用礼品卡支付",
    "gift_card_created": "购买礼品卡",
    "gift_card_refunded": "礼品卡退款",
    "gift_card_transferred": "礼品卡转送",
    "gift_card_received": "收到礼品卡",
    "points": "积分",
    "back_to_store": "前往商店",
  },
  "common_product": {
    "products": "产品",
  },
  "common_group_sale": {
    'there': ' ',
    "welcomeMessage1": "{who}您好, ",
    "welcomeMessage2": "今天也不要忘记奖励您自己一份大礼吧",
    "result_for": "'{keyword}'的结果",
    "select_what": "选择'{keyword}'",
    "pop_on_groupter": "热门优惠券",
    "brower_by_cat": "浏览热门类别",
    "goods": "商品",
    "things_to_do": "要做的事",
    "beauty_spas": "美容与水疗",
    "restaurants": "餐馆",
    "travel": "旅行",
    "sale": "促销",
    "health_fitness": "健康与健身",
    "food_drinks": "食物和饮料",
    "breweries_wineries_distileries": "啤酒厂，酿酒厂和酿酒厂",
    "entertainment": "娱乐",
    "collectibles": "收藏品",
    "books": "书籍",
    "face_skin": "面部和皮肤",
    "cosmetic_procedures": "化妆品",
    "gym": "健身房",
    "sport": "运动",
    "tour": "游览",
    "hotel": "酒店",
    "personalized": "个性化",
    "home_garden": "家居与园艺",
    "jewelry_watches": "珠宝与手表",
    "fashion_jewelry": "时尚饰品",
    "fine_metal_jewelry": "精美金属首饰",
    "watches": "手表",
    "home_services": "家庭服务",
    "cleaning_services": "清洁服务",
    "flooring": "地板",
    "automotive": "汽车",
    "interior_accessories": "室内配件",
    "car_electronics": "汽车电子",
    "local_services": "本地服务",
    "fun_leisure": "娱乐与休闲",
    "sports_outdoors": "运动与户外",
    "all_deal": "所有优惠券",
    "discount_amount": "折扣金额",
    "amount_sold": "销售总额",
    "sort_by": "排序方式",
    "best_match": "最佳匹配",
    "distance": "距离",
    "ranking": "排名",
    "price_ascending": "价格上升",
    "price_descending": "价格下降",
    "discount_greater": "折扣大于",
    "bought": "售出",
    "OFF": "OFF",
    "view_deal": "查看优惠券",
    "viewed_today": "日浏览量",
    "what_you_will_get": "你会得到什么",
    "the_fine_print": "购买须知",
    "customer_photos": "客户照片",
    "view_image": "查看图片",
    "view_image_gallery": "查看图库",
    "see_all_reviews": "查看所有评论",
    "view_all_img": "查看所有图片",
    "show_less": "显示更少",
    "see_all_hours": "查看所有时间",
    "app": "App",
    "history": "历史",
    "service": "服务",
    "business": "企业",
    "favorite": "收藏",
  },
  "common_checkout": {
    'items_not_available': '产品不能购买',
    'items_not_available_message': '以下产品现在不能购买，将从购物车中移除: {br} {items}'
  },
  "common_self_checkout": {
    "self_checkout": "自助支付",
    "amount": "金额",
    "add_comment": "添加备注",
    "contact_merchant": "联系商家",
    "powered_by_goopter": "由Goopter提供技术支持",
    "pay": "支付",
    "self-checkout-disabled": "此商家尚未开通自助结帐服务。",
    "surcharge_for": "订单#{oid}的附加费",
  },
}
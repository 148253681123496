import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import AccountPageLayout from 'components/account-page-layout';
import GiftCardWrapper from 'containers/gift-card-page/giftCardPageWrapper'

const { Column, ColumnGroup } = Table;

class GiftCardPage extends Component {


  render() {

    return (
      <AccountPageLayout
        key="giftcard-page-content"
        title={<FormattedMessage id="my_gift_cards" />}>
        <GiftCardWrapper />
      </AccountPageLayout>
    )
  }
}

const mapStateToProps = (state) => {


  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardPage);
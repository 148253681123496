import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import _config from 'config';
import _helper from '../../utils/helper';
import _google from 'utils/google'
import classnames from 'classnames';
import { Input, Icon, Select, message, Alert } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoogleMapReact from 'google-map-react';

const Option = Select.Option

class MapSearchBar extends Component {

  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {
      data: [],
      showAlert: false
    }
  }

  initMap(map, maps) {
    this.map = map;
  }

  componentDidMount() {

  }

  checkGeoPermission() {
    let { getGeoLocation } = this.props
    let getGeoLocationLocal = () => getGeoLocation()
    let setState = (value) => this.setState(value)
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (permissionStatus) {
        if (permissionStatus.state != "granted") {
          getGeoLocationLocal()
          setState({ showAlert: true })
        }
        permissionStatus.onchange = function () {
          { }

        };
      });
  }

  componentWillUnmount() {

  }

  listOptions = value => {
    this.checkGeoPermission()
    let { intl, data, userLat, userLon, address } = this.props;
    let { formatMessage } = intl;
    let { region, lat, lon } = address ? address : {}
    let googleAutoService = new window.google.maps.places.AutocompleteService();
    if (googleAutoService) {

      googleAutoService.getPlacePredictions({

        input: value,
        location: new window.google.maps.LatLng({
          lat: lat ? lat : userLat ? userLat : 49.2133696,
          lng: lon ? lon : userLon ? userLon : -122.9889389
        }),
        radius: 20000,
      }, displaySuggestions => {
        let data = displaySuggestions;
        if (data) {
          this.setState({ data: data });
        }
      })
    }
  }

  handleSelect = (value, option) => {
    let { data, } = this.state;
    let { onPlacesChanged } = this.props
    let onPlacesChangedLocal = (value, addressStr) => onPlacesChanged(value, addressStr)
    let googlePlacesService = new window.google.maps.places.PlacesService(this.map);
    googlePlacesService.getDetails({
      reference: data[value].reference
    }, function (details, status) {
      if (details) {
        onPlacesChangedLocal(details, data[value].description);
      }
    });
  }

  getOptions() {
    let { data } = this.state;

    let result = data.map((d, i) =>
      <Option key={i}>
        <Icon style={{ marginRight: "2px" }} type="environment" />
        {d.description}
      </Option>
    );

    result.push(<Option style={{ textAlign: 'end' }} disabled key={'google'}>
      Powered by google
    </Option>)

    return result
  }

  getPlaceHolder = () => {
    return (
      <div className="map-search-bar-place-holder">
        <div><FormattedMessage id="enter_a_location" /></div>
        <div className="map-search-bar-place-holder-google-text"> Powered by google </div>
      </div>
    )
  }

  render() {
    let {
      intl,
      userLat,
      userLon,
      mapPlaceObj,
    } = this.props;

    let { formatMessage } = intl

    let { data, showAlert } = this.state

    return (
      <div>
        {showAlert ? (
          <Alert
            style={{ marginBottom: 10 }}
            message={formatMessage({ id: 'location_permission_is_blocked_service_may_not_perform_correctly' })}
            type="warning"
            showIcon
          />
        ) : null}
        <Select
          showSearch
          className="search-bar"
          size="large"
          value={this.props.addressStr || undefined}
          placeholder={this.getPlaceHolder()}
          style={this.props.style}
          onSearch={(value) => value.length > 5 && this.listOptions(value)}
          onSelect={this.handleSelect}
          notFoundContent={null}
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
        >
          {data.length ? this.getOptions() : ""}
        </Select>

        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
          center={{
            lat: userLat,
            lng: userLon,
          }}
          defaultZoom={11}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.initMap(map, maps)}
          className="map"
        >
        </GoogleMapReact>
      </div>
    )
  }
}

export default injectIntl(MapSearchBar);
import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Spin } from 'antd'
import './_self-checkout-page.scss'
import PageLayout from '../../components/account-page-layout'
import _helper from '../../utils/helper'
import _pageHelper from './helper'
import AlertModel from './page-components/alert-model'
import DisplayAmount from './page-components/amount-display-section'
import PowerBy from './page-components/power-by-section'
import AddComment from './page-components/add-comment-section'
import NumberPad from './page-components/number-pad-section'

class PayNowPage extends React.Component {
    componentDidMount() {
        this.initData()
        this.setInitAmt()
        this.setInitComment()
    }

    getStoreName = () => {
        return _.get(this.props, 'store.nm', '')
    }

    initData = () => {
        const gid = _pageHelper.getGid(this.props)
        const url = window.location.origin
        const lan = this.props.lan
        this.props.initData({ gid, url, lan })
    }

    setInitAmt = () => {
        const amt = _pageHelper.getAmt(this.props)
        this.props.setState({ numberPadValue: amt })
    }

    setInitComment = () => {
        const oid = _pageHelper.getOid(this.props)
        if (oid) {
            this.props.setState({ comment: this.props.intl.formatMessage({ id: 'surcharge_for' }, { oid }) })
        }
    }

    render() {
        return [
            <div className="pay-now-page-container">
                <PageLayout
                    hideSideBar={true}
                    gid={_pageHelper.getGid(this.props)}
                    logoText={this.getStoreName()}
                    key="self-checkout-page-layout">
                    <Spin spinning={this.props.isLoading}>
                        <DisplayAmount />
                        <AddComment />
                        <NumberPad />
                        <PowerBy />
                    </Spin>
                </PageLayout>
            </div>,
            <AlertModel />
        ]
    }
}

const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    isLoading: _.get(state, "self-checkout.isLoading", false),
    store: _.get(state, 'self-checkout.store', ''),
    state: _.get(state, 'self-checkout', {})
})

const mapDispatchToProps = {
    initData: (payload) => ({ type: 'SELF_CHECKOUT__INIT_DATA', payload }),
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayNowPage)));
import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Divider, Row, Col, Button, InputNumber, } from 'antd';
import { injectIntl, FormattedMessage, } from 'react-intl';
import classnames from 'classnames';
import './_priceInfo.scss'
import _ from 'lodash';
import _action from '../../actions'
import { connect } from 'react-redux';
import _pageHelper from '../../helper'

class PriceInfo extends Component {

    static defaultProps = {
        checkoutState: {}
    }

    getStore = () => {
        return this.props.propsStore || this.props.store
    }

    getCurrency = () => {
        return this.props.propsCurrency || this.props.currency
    }

    updateTips = (values) => {
        const func = this.props.propsUpdateTips || this.props.updateTips
        func(values)
    }

    getState = () => {
        return this.props.propsState || this.props.checkoutState
    }

    getParamsData = () => {
        const storeSucharge = _.get(this.getState(), 'priceInfo.storeSurcharge', 0)
        const orderSucharge = _.get(this.getState(), 'priceInfo.orderSurcharge', 0)
        const storeSuchargeLabel = _.get(this.getState(), 'priceInfo.storeSurchargeLabel', '')
        const orderSuchargeLabel = _.get(this.getState(), 'priceInfo.orderSurchargeLabel', '')
        const subTotal = _.get(this.getState(), 'priceInfo.subTotal', 0)
        const totalBeforeTax = _.get(this.getState(), 'priceInfo.totalBeforeTax', 0)

        return {
            itemTotal: {
                title: "item_total",
                number: "itemTotal",
                hide: !(storeSucharge || orderSucharge)
            },
            storeSucharge: {
                title: storeSuchargeLabel || "store_surcharge",
                number: "storeSurcharge",
            },
            orderSurcharge: {
                title: orderSuchargeLabel || "order_surcharge",
                number: "orderSurcharge",
            },
            surcharge: {
                title: "surcharge",
                number: "surcharge",
            },
            subtotal: {
                title: "subtotal",
                number: "subTotal"
            },
            storeDiscount: {
                title: "store_discount",
                number: "storeDiscount",
                prefix: "-",
            },
            couponDiscount: {
                title: "coupon_discount",
                number: "couponDiscount",
                prefix: "-"
            },
            giftCardPayment: {
                title: "gift_card_payment",
                number: "giftcardPayment",
                prefix: "-"
            },
            pointsPayment: {
                title: "points_payment",
                number: "pointsPayment",
                prefix: "-"
            },
            deliveryFee: {
                title: "shipping_and_handling",
                number: "shippingFee",
            },
            totalBeforeTax: {
                title: "total_before_tax",
                number: "totalBeforeTax",
                hide: subTotal === totalBeforeTax,
            },
            tax: {
                title: "tax",
                number: "tax",
            },
        }
    }

    getTotals = () => {
        return Object.keys(this.getParamsData()).map((totalKey, i) => {
            let { size, title, number, prefix, hide } = this.getParamsData()[totalKey];
            let validInfo = _.get(this.getState(), `priceInfo.${number}`, 0) ||
                _.get(this.getState(), `displayPriceInfo.${number}`, 0)

            if (validInfo && !hide) {
                return (
                    <div
                        key={`order-total-${totalKey}-${i}`}
                        className={classnames({
                            "totals-item": true,
                            "large": size === "large"
                        })}>
                        <div className={classnames({
                            "totals-name": true,
                            "light": title !== "order_total",
                        })}>
                            <FormattedMessage id={title} />
                        </div>
                        <div className="totals-price">
                            <h4 className={classnames({
                                "large": size === "large"
                            })}>{_helper.formatCurrency(validInfo, this.getCurrency(), prefix ? prefix : '')}
                            </h4>
                        </div>
                    </div>
                )
            }
        })
    }

    renderTipsButton = () => {
        const currentTips = _.get(this.getState(), 'priceInfo.tips', 0)
        const grantTotal = _.get(this.getState(), 'priceInfo.grandTotal', 0)
        const shippingFee = _.get(this.getState(), 'priceInfo.shippingFee', 0)
        const isPercent = grantTotal > _pageHelper.getTipsSetting(this.getStore()).cut
        const buttonData = isPercent ? _pageHelper.getTipsSetting(this.getStore()).percents :
            _pageHelper.getTipsSetting(this.getStore()).amts

        const type = (buttonValue) => {
            const buttonTips = isPercent ? _.round(((grantTotal + shippingFee) * (buttonValue * 0.01)), 2) : buttonValue
            const sameValue = currentTips === buttonTips
            return sameValue ? 'primary' : 'default'
        }

        return (
            <div
                className="tips-row"
                gutter={8}>
                {buttonData.map((tip, i) => {
                    return (
                        <div key={i} style={{ width: `${(100 / buttonData.length) - 2}%` }} >
                            <Button
                                className="tips-button"
                                onClick={() => this.updateTips({
                                    type: isPercent ? 'percent' : 'flat',
                                    amount: tip
                                })}
                                type={type(tip)}
                            >
                                {isPercent ? '' : '$'}{tip}{isPercent ? '%' : ''}
                            </Button>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const orderTotal = _.get(this.getState(), 'displayPriceInfo.orderTotal', 0)
        const totatDue = _.get(this.getState(), 'displayPriceInfo.totalDue', 0)
        return (
            <div className="price-info" >

                {this.getTotals()}

                <div>
                    <div className="totals-item">
                        <div className="totals-name light"><FormattedMessage id="tips" /></div>
                    </div>
                </div>

                <div>
                    <div className="totals-item">
                        {this.renderTipsButton()}
                        <div className="totals-price">
                            <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                precision={2}
                                value={_.get(this.getState(), 'priceInfo.tips', 0)} min={.00} max={99999}
                                onChange={(value) => this.updateTips({
                                    type: 'flat',
                                    amount: value
                                })} />
                        </div>
                    </div>
                </div>

                {orderTotal ?
                    <div>
                        <Divider style={{ margin: '10px 0' }} />
                        <div className="totals-item large">
                            <div className="totals-name"><FormattedMessage id="order_total" /></div>
                            <div className="totals-price"><h4 className="large">{_helper.formatCurrency(orderTotal, this.getCurrency())}</h4></div>
                        </div>
                    </div>
                    : null}

                {totatDue ?
                    <div>
                        <Divider style={{ margin: '10px 0' }} />
                        <div className="totals-item large">
                            <div className="totals-name"><FormattedMessage id="total_due" /></div>
                            <div className="totals-price"><h4 className="large">{_helper.formatCurrency(totatDue, this.getCurrency())}</h4></div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    checkoutState: _.get(state, 'checkout-page', {}),
    currency: _.get(state, 'checkout-page.storeInstance.data.currency', {}),
    store: _.get(state, 'checkout-page.storeInstance.data', {}),
})

const mapDispatchToProps = ({
    updateTips: _action.updateTips
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PriceInfo));
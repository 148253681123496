import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Layout, Form, Input, Select, Checkbox, Button, Divider, Icon, Row, Col, Avatar } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _helper from 'utils/helper';
import Social from './socialLogin';

const FormItem = Form.Item;
const Option = Select.Option;

class LoginTextForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      vc: '',
      missingPhone: null,
      missingVC: null,
    };
  }

  emitEmptyPhone = () => {
    this.userPhoneInput.focus();
    this.setState({ phone: '' });
  }

  emitEmptyVC = () => {
    this.userVCInput.focus();
    this.setState({ vc: '' });
  }

  handleInput = (e) => {

    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name == "phone") {
      this.setState({
        missingPhone: false
      })
    }

    if (e.target.name == "vc") {
      this.setState({
        missingVC: false
      })
    }
  }

  handleSubmit = () => {
    let { phone, vc } = this.state
    if (!phone) {
      this.setState({
        missingPhone: true
      })
    }

    if (!vc) {
      this.setState({
        missingVC: true
      })
    }

    if (phone && vc) {
      this.props.login({
        phone,
        vc
      })
    }

  }

  render() {
    let {
      hide,
      login,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      getVerificationCode,
      setFieldError,
      setFieldTouched,
      setFieldValue,
      loginLoading
    } = this.props;

    let { formatMessage } = this.props.intl;

    const suffix = this.state.phone ? <Icon type="close-circle" onClick={this.emitEmptyPhone} /> : null;
    const suffixVC = this.state.vc ? <Icon type="close-circle" onClick={this.emitEmptyVC} /> : null;

    return (
      <Form onSubmit={handleSubmit} style={{ display: hide ? 'none' : null }}>
        <FormItem
          colon={false}
          help={touched.countryCode && errors.countryCode ? errors.countryCode : ''}
          validateStatus={touched.countryCode && errors.countryCode ? 'error' : ''}
        >
          <Select
            size="large"
            name="countryCode"
            onChange={(value) => { setFieldValue('countryCode', value) }}
            value={values.countryCode}
          >
            <Option value="CA"><FormattedMessage id="canada" /> +1</Option>
            <Option value="US"><FormattedMessage id="united_states" /> +1</Option>
            <Option value="CN"><FormattedMessage id="china" /> +86</Option>
            <Option value="KR"><FormattedMessage id="south_korea" /> +82</Option>
          </Select>
        </FormItem>
        <FormItem
          colon={false}
        >
          <div className="vc-input-wrapper">
            <Input
              size="large"
              className="phone-input"
              placeholder={formatMessage({ id: "phone_number" })}
              name="phone"
              type="tel"
              onChange={this.handleInput}
              onBlur={handleBlur}
              suffix={suffix}
              ref={node => this.userPhoneInput = node}
              value={this.state.phone}
            />
            <Button
              className="ver-button"
              onClick={() => {
                if (!values.phone) {
                  setFieldTouched('phone', true)
                  setFieldError('phone', 'phone is a required field')
                } else {
                  getVerificationCode({
                    phone: values.phone,
                    countryCode: values.countryCode,
                  })
                }
              }}
              size="large"
              type={"dashed"}
              disabled={!this.state.phone || !this.state.countryCode}>
              <FormattedMessage id="get_verification_code" /></Button>


          </div>
          <div>
            {
              this.state.missingPhone ?
                <div className="missing-item-message"><FormattedMessage id="phone_required" /></div> : ''
            }
          </div>
        </FormItem>
        <FormItem
          colon={false}
        >
          <Input
            className="input-spacing-top"
            size="large"
            placeholder={formatMessage({ id: "verification_code" })}
            name="vc"
            onChange={this.handleInput}
            onBlur={handleBlur}
            suffix={suffixVC}
            ref={node => this.userVCInput = node}
            value={this.state.vc}
          />

          <div>
            {
              this.state.missingPhone ?
                <div className="missing-item-message"><FormattedMessage id="vc_required" /></div> : ''
            }
          </div>
        </FormItem>
        <FormItem style={{ margin: '0' }}>
          <Checkbox
            name="rememberMe"
            values={values.rememberMe}
            onChange={handleChange}
          >
            <FormattedMessage id="remember_me" />
          </Checkbox>
        </FormItem>
        <Divider />
        <FormItem>
          <Button
            type="dashed"
            className="login-form-button"
            size="large"
            disabled
            onClick={this.handleSubmit}

            block>
            <FormattedMessage id="log_in" />
          </Button>
        </FormItem>
        <Divider />
        <Social />
      </Form>
    )
  }
}

let validationSchema = (lan) => yup.object().shape({
  countryCode: yup.string().required(_helper.getIsRequiredMessage(lan, 'country')),
  phone: yup.string().required(_helper.getIsRequiredMessage(lan, 'phone_number')),
  vc: yup.string().required(_helper.getIsRequiredMessage(lan, 'verification_code'))
});

LoginTextForm = withFormik({
  // mapPropsToValues: (props) => ({ ...props.initalValues }),
  mapPropsToValues: (props) => ({ countryCode: 'CA', phone: '', vc: '' }), // adding initial values will make field be touched when submitting. (https://github.com/jaredpalmer/formik/issues/445)
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.login(values)
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true

})(LoginTextForm)
export default injectIntl(LoginTextForm);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import classnames from 'classnames';
import { Table, Divider, Icon, Button, Card, Col, Row, Avatar, Modal, Spin, Empty, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import TransferForm from 'containers/gift-card-page/transfer-form';
import './_gift-card-page.scss';

const { Column, ColumnGroup } = Table;

class GiftCardWrapper extends Component {

    componentDidMount() {
        this.fetchGiftCardList()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            this.fetchGiftCardList(nextProps)
        }
    }

    fetchGiftCardList(props = this.props) {
        let { fetchGiftCardList, selectedMode, isAuthenticated } = props;
        if (isAuthenticated) {
            fetchGiftCardList({
                status: selectedMode ? 0 : null
            })
        }
    }

    componentWillUnmount() {
        let { clearGiftCardPageData } = this.props;

        clearGiftCardPageData()
    }

    reformatGiftCard = () => {
        let { giftCardList, storeInstance, gid } = this.props;

        let formattedGc = [];

        if (giftCardList && giftCardList.length) {

            if (_helper.getIndependentDomain() && storeInstance) {
                giftCardList = giftCardList.filter(gc => { return gc.g_id == storeInstance.data['group_id'] })
            }

            if (gid) {
                giftCardList = giftCardList.filter(gc => { return gc.g_id == gid })
            }


            formattedGc = giftCardList.reduce((resultsArray, gc, index) => {
                const chunkIndex = Math.floor(index / 3)

                if (!resultsArray[chunkIndex]) {
                    resultsArray[chunkIndex] = [] // start a new chunk
                }

                resultsArray[chunkIndex].push(gc)

                return resultsArray
            }, [])

        }



        return formattedGc;
    }

    handleOpenGiftCardDetail = (giftCardId) => {
        let { toggleGiftCardDetail, fetchGiftCardDetail } = this.props;
        if (giftCardId) {
            toggleGiftCardDetail(true);
            fetchGiftCardDetail({ id: giftCardId })
        }
    }

    getGiftCardHistoryDescription = (giftCard) => {
        let { type, extra } = giftCard;
        let description = '';
        if (type === 0) {
            description = <FormattedMessage id="gift_card_created" values={{ orderId: extra }} />
        } else if (type === 1) {
            description = <FormattedMessage id="gift_card_used" values={{ orderId: extra }} />
        } else if (type === 5) {
            description = <FormattedMessage id="gift_card_refunded" />
        } else if (type === 6) {
            description = <FormattedMessage id="gift_card_transferred" values={{ username: extra }} defaultMessage="Sent to {username}" />
        } else if (type === 7) {
            description = <FormattedMessage id="gift_card_received" values={{ username: extra }} />
        }

        return description;
    }

    handleChangeSelected = (giftCard) => {
        if (this.props.selectedMode) {
            this.props.onSelectedChange(giftCard)
        }
    }

    render() {
        let {
            push,
            pageLoading,
            giftCardList,
            toggleGiftCardDetail,
            giftCardInstance,
            giftCardDetailDisplay,
            giftCardDetailLoading,
            clearGiftCardDetail,
            transferGiftCard,

            selectedMode,
            selected,
        } = this.props;

        let formattedGiftCardList = this.reformatGiftCard();
        let giftCardBase = window.location.href.includes('-qa') || window.location.href.includes('localhost:') ? 'https://shop-qa.goopter.com' : 'https://shop.goopter.com'

        return (
            <div>
                <Spin spinning={pageLoading}>
                    <div className="gift-card-list-wrapper">
                        {formattedGiftCardList.length ?
                            formattedGiftCardList.map((giftCardRow, i) => {
                                if (giftCardRow) {
                                    return (
                                        <Row gutter={16} key={i} className="gift-card-row">
                                            {giftCardRow.map((giftCard, i) => {
                                                return (
                                                    <Col span={8}
                                                        className={classnames({
                                                            "gift-card-col": selectedMode
                                                        })}
                                                        key={i}
                                                    >
                                                        <Card
                                                            className={classnames({
                                                                "selectable": true,
                                                                "gift-card-card": true,
                                                            })}
                                                            bodyStyle={{ height: '180px' }}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar className="gift-card-info"
                                                                    onClick={() => {
                                                                        this.handleOpenGiftCardDetail(giftCard['gc'])
                                                                    }} icon="info" />}
                                                                title={<span className="link" onClick={() => {
                                                                    if (selectedMode) {
                                                                        this.handleChangeSelected(giftCard)
                                                                    } else {
                                                                        /* temp until store page is written in web_v2 */
                                                                        window.location = giftCardBase + `/store/${giftCard['g_id']}`
                                                                    }
                                                                }}>{giftCard['s_name']}</span>}
                                                                description={<div onClick={() => {
                                                                    if (selectedMode) {
                                                                        this.handleChangeSelected(giftCard)
                                                                    } else {
                                                                        this.handleOpenGiftCardDetail(giftCard['gc'])
                                                                    }
                                                                }}>
                                                                    <div><FormattedMessage id="balance" />: {`${_helper.formatCurrency(giftCard['bal'], giftCard['curr'])}/${_helper.formatCurrency(giftCard['bk_val'], giftCard['curr'])}`}</div>
                                                                    <div><FormattedMessage id="expiry_date" />: {giftCard['exp_dt']}</div>
                                                                </div>}
                                                            />
                                                            <div
                                                                onClick={() => {
                                                                    if (selectedMode) {
                                                                        this.handleChangeSelected(giftCard)
                                                                    } else {
                                                                        this.handleOpenGiftCardDetail(giftCard['gc'])
                                                                    }
                                                                }} className="selected-card-display-row">
                                                                {selectedMode ?
                                                                    <div>
                                                                        {_.isEqual(giftCard, selected) ?
                                                                            <Icon className="checked-box selected" type="check-square" theme="filled" /> :
                                                                            <Icon className="checked-box" type="border" />}
                                                                    </div> : ''}
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    )
                                }
                            }) : <Empty />}
                    </div>
                </Spin>
                <Modal
                    visible={giftCardDetailDisplay}
                    onCancel={() => {
                        toggleGiftCardDetail(false)
                        clearGiftCardDetail()
                    }}
                    footer={false}
                    title={<FormattedMessage id="gift_card_detail" />}
                    style={{ minWidth: '700px', maxWidth: '1000px' }}
                    destroyOnClose={true} //added so form is deleted and reset (values are not saved between modals) everytime modal is closed 
                >
                    <Spin spinning={giftCardDetailLoading}>
                        <div className="gift-card-detail-content">
                            <div className="gift-card-detail-top">
                                <div className="general-info-wrapper">
                                    <h3 className="link" onClick={() => window.location.href = `/store/${giftCardInstance.data['g_id']}`}><b>{giftCardInstance.data['s_name']}</b></h3>
                                    <div><FormattedMessage id="balance" />: {`${_helper.formatCurrency(giftCardInstance.data['bal'], giftCardInstance.data['curr'])}/${_helper.formatCurrency(giftCardInstance.data['bk_val'], giftCardInstance.data['curr'])}`}</div>
                                    <div><FormattedMessage id="expiry_date" />: {giftCardInstance.data['exp_dt']}</div>
                                </div>
                                <div className="transfer-form-wrapper">
                                    <TransferForm
                                        transferGiftCard={transferGiftCard}
                                        code={giftCardInstance.data['gc']}
                                    />
                                </div>
                            </div>
                            <div className="gift-card-detail-bottom">
                                {giftCardInstance.data['history'] && giftCardInstance.data['history'].length
                                    ? <Table dataSource={giftCardInstance.data['history']} pagination={false} size={'small'} bordered={false}>
                                        <Column
                                            title={<FormattedMessage id="date" />}
                                            dataIndex="date"
                                            key="date"
                                            render={(date) => <span>{_helper.utcToLocal(date)}</span>}
                                        />
                                        <Column
                                            title={<FormattedMessage id="description" />}
                                            dataIndex="type"
                                            key="type"
                                            render={(type, record) => <span>{this.getGiftCardHistoryDescription(record)}</span>}
                                        />
                                        <Column
                                            title={<FormattedMessage id="change" />}
                                            dataIndex="delta"
                                            key="dela"
                                            render={(delta, record) => <span>{_helper.formatCurrency(delta, record['curr'], delta.toString().includes('-') ? '-' : '')}</span>}
                                        />
                                        <Column
                                            title={<FormattedMessage id="balance" />}
                                            dataIndex="balance"
                                            key="balance"
                                            render={(balance, record) => <span>{_helper.formatCurrency(balance, record['curr'])}</span>}
                                        />
                                    </Table> : ''}
                            </div>
                        </div>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let giftCardState = state['gift-card'];
    let authState = state['auth'];
    return {
        isAuthenticated: authState.isAuthenticated,
        pageLoading: giftCardState.pageLoading,
        giftCardList: giftCardState.giftCardList,
        giftCardInstance: giftCardState.giftCardInstance,
        giftCardDetailDisplay: giftCardState.giftCardDetailDisplay,
        giftCardDetailLoading: giftCardState.giftCardDetailLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        push: bindActionCreators(push, dispatch),
        fetchGiftCardList: bindActionCreators((params = {}) => {
            return {
                type: "GIFT_CARD__FETCH_GIFT_CARD_LIST",
                payload: params
            }
        }, dispatch),
        clearGiftCardPageData: bindActionCreators((params = {}) => {
            return {
                type: "GIFT_CARD__CLEAR_DATA",
            }
        }, dispatch),
        toggleGiftCardDetail: bindActionCreators((bool) => {
            return {
                type: "GIFT_CARD__SET_STATE",
                state: {
                    giftCardDetailDisplay: bool
                }
            }
        }, dispatch),
        clearGiftCardDetail: bindActionCreators(() => {
            return {
                type: "GIFT_CARD__CLEAR_GIFT_CARD_DETAIL",
            }
        }, dispatch),
        fetchGiftCardDetail: bindActionCreators((params) => {
            return {
                type: "GIFT_CARD__FETCH_GIFT_CARD_DETAIL",
                payload: {
                    id: params.id,
                }
            }
        }, dispatch),
        transferGiftCard: bindActionCreators((params) => {
            return {
                type: "GIFT_CARD__TRANSFER_GIFT_CARD",
                payload: params
            }
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardWrapper);
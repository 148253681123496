import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash'
import './number-pad-section.scss'
import NumberPad from './number-pad'
import { withRouter } from 'react-router-dom';
import pageHelper from '../../helper'
import { push } from 'connected-react-router';

class NumberPadSection extends React.Component {

    getAmount = () => {
        return _.get(this.props, 'state.numberPadValue', 0)
    }

    getCart = () => {
        const product = _.get(this.props, 'state.product', {})
        return {
            pid: _.get(product, 'pid', 0),
            qty: 1,
            pc: this.getAmount(),
            selfCheckout: true,
        }
    }

    onPayPress = (currentPadValue) => {
        const gid = _.get(this.props, 'state.store.gid', '')
        const cart = [this.getCart()]
        const shippingMethod = 'freeShipping'
        const relatedOid = pageHelper.getOid(this.props)
        const taxClass = pageHelper.getTaxClass(this.props)
        const comment = _.get(this.props, 'state.comment', '')
        if (parseFloat(currentPadValue)) {
            this.props.updateCart({
                gid,
                items: {
                    cart,
                    shippingMethod,
                    relatedOid,
                    taxClass
                },
            })
            this.props.setCheckoutState({
                comment
            })
            this.props.push(`/checkout?query=${gid}`)
        }
    }

    render() {
        return [
            <div >
                <NumberPad
                    onPayPress={(currentPadValue) => this.onPayPress(currentPadValue)}
                    state={this.props.state}
                    setState={this.props.setState} />
            </div>
        ]
    }
}

const mapStateToProps = (state) => ({
    lan: state.settings.lan,
    state: _.get(state, 'self-checkout', {})
})

const mapDispatchToProps = ({
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
    updateCart: (payload) => ({ type: 'SHOPPING_CART__SET_CART_ITEMS', payload }),
    setCheckoutState: (state) => ({ type: 'CHECKOUT_PAGE__SET_STATE', state }),
    push
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(NumberPadSection)))
import { put, takeEvery, select } from 'redux-saga/effects';
// import _config from 'config';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import { message } from 'antd';
import _ from 'lodash';
import _locale from 'locale';

function* fetchGiftCardList(action) {
	try {
		yield put({ type: "POINTS__SET_STATE", state: { pageLoading: true } })

		let url = _apiRoutes['gift-card'];

		let searchParams = {
			page: -1,
			limit: -1,
			// status: 0, // only show active gift cards
		}

		if (!_.isEmpty(searchParams)) {
			url = _helper.objToQuery(url, searchParams);
		}

		const giftCardResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');
		yield put({ type: "POINTS__FETCH_POINTS_LIST_SUCCESS", response: { giftCardResponse }, payload: { ...action.payload } });
	} catch (e) {
		yield put({ type: "POINTS__FETCH_POINTS_LIST_FAILED", message: e.message, payload: action.payload });
	}
}

function* fetchGiftCardDetail(action) {
	try {
		yield put({ type: "POINTS__SET_STATE", state: { giftCardDetailLoading: true } })
		let { id } = action.payload;
		
		let detailUrl = _apiRoutes['gift-card'];
		let detailParams = {
			id: id
		}
		if (!_.isEmpty(detailParams)) {
			detailUrl =  _helper.objToQuery(detailUrl, detailParams);
		}
			
		let historyUrl = _apiRoutes['gift-card-history'];
		let historyParams = {
			code: id,
			limit: -1,
			page: 1,
		}
		if (!_.isEmpty(historyParams)) {
			historyUrl = _helper.objToQuery(detailUrl, detailParams);
		}
		
		const detailResponse = yield _api.callCheckingAuthentication(_api.executeOauth, detailUrl, 'GET');
		const historyResponse = yield _api.callCheckingAuthentication(_api.executeOauth, historyUrl, 'GET');
		
		
		if (detailResponse && historyResponse && detailResponse.records && historyResponse.records && historyResponse.records) {
			let giftCardDetail = {
				...detailResponse.records,
				history: historyResponse.records
			}
			
			yield put({ type: "POINTS__FETCH_POINTS_DETAIL_SUCCESS", response: { giftCardDetail }, payload: { ...action.payload } });
		} else {
			yield put({ type: "POINTS__FETCH_POINTS_DETAIL_FAILED", payload: action.payload });
			message.error('failed_fetch_points_detail')
		}
	} catch (e) {
		message.error('failed_fetch_points_detail')
		yield put({ type: "POINTS__FETCH_POINTS_DETAIL_FAILED", message: e.message, payload: action.payload });
	}
}

export default [
	takeEvery('POINTS__FETCH_POINTS_LIST', fetchGiftCardList),
	takeEvery('POINTS__FETCH_POINTS_DETAIL', fetchGiftCardDetail),
];


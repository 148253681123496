import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Icon, } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import './_catTreeList.scss'

class CatTreeList extends Component {

    getCatList = () => {
        let {
            level1Cat,
            level2Cat,
            level3Cat,
            catListData,
            activeCat,
            onCatClick,
            intl
        } = this.props;

        let { formatMessage } = intl;

        if (!catListData) return

        if (level3Cat) {
            let categoryParent = catListData.find(cat => { return cat.id == level1Cat })
            activeCat = categoryParent && categoryParent.children.find(cat => { return cat.id == level2Cat })
        }

        if (!activeCat) {
            return catListData.map((cat, i) => {
                return (
                    <div key={`cat-item-${i}`} className={`cat-item`} onClick={() => onCatClick(cat)}>{formatMessage({ id: cat.name })} {cat.children ? <span>({cat.children.length})</span> : ''}</div>
                )
            })
        } else {
            let parent = activeCat.parent_id ? catListData.find(cat => { return cat.id == activeCat.parent_id }) : null
            return (
                <div>
                    {parent ? <div className="cat-item" onClick={() => onCatClick(parent)} > <Icon type="left" style={{ color: "#1e89e0" }} /> {formatMessage({ id: parent.name })}</div> : ''}
                    <div onClick={() => onCatClick(activeCat)} className={`cat-item margin-left ${!level3Cat ? 'highlighted' : ''}`}  > {formatMessage({ id: activeCat.name })} </div>
                    {activeCat.children && activeCat.children.length ?
                        activeCat.children.map((child, i) => {
                            return (
                                <div key={`cat-item-chilren-${i}`} className={`cat-item left-padding ${level3Cat == child.id ? 'highlighted' : ''}`} onClick={() => onCatClick(child)}>{formatMessage({ id: child.name })} {child.children ? <span>({child.children.length})</span> : ''}</div>
                            )
                        }) : ''}
                </div>
            )
        }

    }

    render() {

        let {
            activeCat,
            onResetClick,
        } = this.props;

        return (
            <div className="category-tree-list-container">
                <h4 className="cat-title"><FormattedMessage id="category" /></h4>
                {activeCat ? <div onClick={() => onResetClick()} className="cat-item"> <Icon type="left" style={{ color: "#1e89e0" }} /> <FormattedMessage id="all_deal" /></div> : ''}
                <div className="list">
                    {this.getCatList()}
                </div>

            </div>
        )
    }
}


export default injectIntl(CatTreeList);
import { put, takeEvery, select, call } from 'redux-saga/effects';
import _config from 'config';
import _api from 'utils/api';
import _helper from 'utils/helper';
import _ from 'lodash';

function* getLocationDetails(action) {
    try {
      let { placeId } = action.payload;
      let url = `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${_config.GOOGLE_API_KEY}`
  
      const response = yield call(_api.get, url);
  
      if (response && response.result && response.result.geometry && response.result.geometry.location) {
        yield put({ type: "LOCATIONS_GET_LOCATION_DETIALS_SUCCESS", response: { response: response.result.geometry.location }, payload: { ...action.payload } });
      } else {
        yield put({ type: "LOCATIONS_GET_LOCATION_DETIALS_FAILED", payload: action.payload });
      }
    } catch (e) {
      yield put({ type: "LOCATIONS_GET_LOCATION_DETIALS_FAILED", message: e.message, payload: action.payload });
    }
  }
  

export default [
 takeEvery('LOCATIONS_GET_LOCATION_DETIALS', getLocationDetails),
];

import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Icon, Input, List, } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_addressModal.scss'

import AddressBookWrapper from 'containers/address-book/address-book-wrapper/index.jsx'
class AddressModal extends Component {


  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  static defaultProps = {
    displayModal: false,
    reviewData: [],
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let {
      displayModal,
      onCloseClick,
      intl,
      currentAddress,
      onSelectedAddressChange,
      storeAddress,
    } = this.props;

    let {
      height,
      width,
    } = this.state

    let { formatMessage } = intl;


    return (
      <Modal
        width={800}
        title={formatMessage({ id: 'address' })}
        visible={displayModal}
        onCancel={onCloseClick}
        footer={null}
      >
        <div
          style={{ maxHeight: `${height * 0.7}px`, overflowY: 'auto', overflowX: 'hidden' }}
          className="names-modal-container" >
          <AddressBookWrapper
            selected={currentAddress}
            selectedMode={true}
            onSelectedChange={onSelectedAddressChange}
            address={storeAddress}
          />
        </div>
      </Modal>
    )
  }
}


export default injectIntl(AddressModal);
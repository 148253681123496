import React, { Component } from "react";
import _ from "lodash";
import _config from "config";
import _helper from "utils/helper";
import { List, Row, Col, Button, Modal, Icon, Input } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import classnames from "classnames";
import AddressModal from "../customerInfo/addressModal";
import ShippingMethodModal from "./shippingMethodModal";
import FloatingInput from "components/floating-input";
import "./_shippingInfo.scss";

class ShippingInfo extends Component {
  state = {};

  static defaultProps = {
    checkoutState: {},
    cartDetails: {}
  };

  getAddress = (address = {}) => {
    let result = _helper.formatAddress(address)
    let fullName = ''
    if (address.fn) {
      fullName += address.fn
    }
    if (address.ln) {
      fullName += " " + address.ln
    }
    if (fullName) {
      result.unshift(fullName)
    }
    return (
      <div>
        {result.map(str => {
          return (
            <div key={`${str}`}>{str}</div>
          )
        })}
        {address.tn ?
          <div style={{ paddingTop: '5px' }}>
            <Icon type="phone" theme="filled" style={{ paddingRight: '3px', color: "#333333" }} />
            <a
              href={`tel:${address.tn}`}
              className="link">{_helper.formatPhoneNumber(address.tn)}</a>
          </div> : ''}
      </div>
    )
  }

  getSubShippingMethodContent = () => {
    let { checkoutState } = this.props
    let { shippingTypes, selectedShippingType } = checkoutState

    let result = ""
    if (Array.isArray(shippingTypes)
      && shippingTypes.length
      && selectedShippingType) {
      let selectedMethod = {}
      shippingTypes.find((type) => {
        let currentMethods = type.methods
        if (Array.isArray(currentMethods) && currentMethods.length) {
          return currentMethods.find((method) => {
            if (method.code === selectedShippingType) {
              selectedMethod = method
              return true
            }
          })
        }
      })

      if (selectedMethod.desc) {
        result += selectedMethod.desc
        if (selectedMethod.fee) {
          result += ` - ${_helper.formatCurrency(selectedMethod.fee)}`
        }
      }
    }

    return result
  }

  shippingInfoContents() {
    let {
      intl,
      checkoutState,
      setCheckoutState,
      cartDetails,
    } = this.props;

    let { formatMessage } = intl;

    let currentFocus = ""
    if (checkoutState.focusField) { currentFocus = checkoutState.focusField; }

    let shippingAddress = checkoutState.shippingAddress ? checkoutState.shippingAddress : '';
    return [
      {
        requirement: cartDetails["shippingMethod"] == "delivery" ||
          cartDetails["shippingMethod"] == "flatrate" ||
          cartDetails["shippingMethod"] == "delivery" ||
          cartDetails["shippingMethod"] == "flatrate",
        title: "shipping_address",
        content: this.getAddress(shippingAddress),
        onClick: () => setCheckoutState("displayModalName", "shippingAddress"),
        missing: checkoutState.shippingMethod == 1 && !checkoutState.shippingAddress
      },
      {
        requirement: checkoutState.shippingTypes.length,
        title: "shipping_method",
        content: this.getSubShippingMethodContent(),
        onClick: () => setCheckoutState("displayModalName", "shippingSubMethod"),
        missing: checkoutState.shippingMethod == 1 &&
          checkoutState.shippingTypes.length &&
          !checkoutState.selectedShippingType
      },
      {
        requirement: cartDetails["shippingMethod"] == "eatin",
        title: "table_number",
        content: checkoutState.tableNumber,
        onClick: () => setCheckoutState("displayModalName", "tableNumber"),
        requiredFloating: true
      },
      {
        requirement: cartDetails["shippingMethod"] == "eatin",
        title: "party_size",
        content: checkoutState.partySize,
        onClick: () => this.setState({ displayPartySizeModal: true }),
        requiredFloating: true
      }
    ];
  }

  render() {
    let {
      intl,
      storeInstance,
      checkoutState,
      setCheckoutState,
      cartDetails,
      defaultTableNumber,
      defaultPartySize,
    } = this.props;

    let { formatMessage } = intl;

    defaultTableNumber = checkoutState.tableNumber;
    let tableNumber = checkoutState.storeInstance.data ? checkoutState.storeInstance.data.require_table_no : 0;
    defaultPartySize = checkoutState.partySize;


    let currentFocus = ""
    if (checkoutState.focusField) { currentFocus = checkoutState.focusField; }

    let shippingAddress = checkoutState.shippingAddress;

    let data = this.shippingInfoContents();

    let count = 0,
      q = 0;
    data.map(data => {
      if (data.requirement) { count++; }
    });
    data.map(data => {
      if (data.requirement) {
        if (q != count - 1) { data.borderBottom = true; }
        q++;
      }
    });

    return (
      <div className="payment-info-container">
        {cartDetails["shippingMethod"] == "eatin" ? (
          <Row>
            <Col span={12}>
              <FloatingInput
                label={formatMessage({ id: "table_number" })}
                onFocus={this.handleCheck}
                onBlur={this.handleCheck}
                onChange={this.handleCheck}
                minLength={1}
                maxLength={3}
                isNum={true}
                inputType="table_number"
                onInputChange={(key, value) => setCheckoutState(key, value)}
                defaultNumber={defaultTableNumber}
                requireTableNumber={tableNumber}
                focusRef={currentFocus}
              />
            </Col>
            <Col span={12}>
              <FloatingInput
                label={formatMessage({ id: "party_size" })}
                onFocus={this.handleCheck}
                onBlur={this.handleCheck}
                onChange={this.handleCheck}
                minLength={1}
                maxLength={3}
                canBeEmpty={true}
                isNum={true}
                inputType="party_size"
                onInputChange={(key, value) => setCheckoutState(key, value)}
                defaultNumber={defaultPartySize}
                focusRef={currentFocus}
              />
            </Col>
          </Row>
        ) : (<List
          className="list-information"
          dataSource={data}
          renderItem={(item, i) => (
            <div>
              {item.requirement ? (
                <List.Item
                  key={i}
                  className={classnames({
                    missing: item.missing
                  })}
                >
                  <Row style={{ width: "100%" }}>
                    {!item.requiredFloating ? (
                      <div className="section-form-row">
                        <Col
                          className="section-form-col"
                          span={7}>
                          <div className="title">
                            <FormattedMessage id={item.title} />
                          </div>
                        </Col>
                        <Col
                          className="section-form-col align-center"
                          span={12}
                        >
                          {item.content}
                        </Col>
                        <Col
                          className="section-form-col align-center"
                          span={5}>
                          <div className="action">
                            <Button
                              className="change_button"
                              onClick={item.onClick}
                            >
                              <FormattedMessage id="change" />
                            </Button>
                          </div>
                        </Col>
                      </div>
                    ) : (
                        ""
                      )}
                  </Row>
                </List.Item>
              ) : (
                  ""
                )}
            </div>
          )}
        />
          )}
        <AddressModal
          storeAddress={storeInstance && storeInstance.data ? storeInstance.data["adr"] : {}}
          displayModal={checkoutState.displayModalName == "shippingAddress"}
          onCloseClick={() => setCheckoutState("displayModalName", "")}
          currentAddress={shippingAddress}
          checkoutState={checkoutState}
          onSelectedAddressChange={address => {
            setCheckoutState("shippingAddress", address)
            setCheckoutState("displayModalName", "")
          }}
        />
        <ShippingMethodModal
          displayModal={checkoutState.displayModalName == "shippingSubMethod"}
          onCloseClick={() => setCheckoutState("displayModalName", "")}
          checkoutState={checkoutState}
          currency={storeInstance.data["currency"]}
          onOkClick={value => {
            setCheckoutState("selectedShippingType", value);
          }}
        />
      </div>
    );
  }
}

export default (injectIntl(ShippingInfo));

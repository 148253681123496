import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import queryString from 'query-string';
import _reverseConfig from 'config/config-reverse-map'

import { Row, Col, Divider, Spin, Modal, Input, Button, Steps, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import ShoppingCartRenderless from 'containers/shopping-cart/renderless';
import PageLayout from 'components/account-page-layout/index.jsx'
import CustomerInfo from 'containers/checkout-page/page-components/customerInfo/'
import PaymentInfo from 'containers/checkout-page/page-components/paymentInfo/'
import ShippingInfo from 'containers/checkout-page/page-components/shippingInfo'
import ShippingMainMethodAndTime from 'containers/checkout-page/page-components/shippingMainMethodAndTime'
import ItemInfo from 'containers/checkout-page/page-components/itemInfo'
import CouponInput from 'containers/checkout-page/page-components/couponInput'
import PriceInfo from './page-components/priceInfo'
import TimeRestrictedItemChecker from './page-components/timeRestrictedItemChecker'
import _checkoutActions from './actions';
import './_checkout.scss'
import { FaLock } from 'react-icons/fa';
import _pageHelper from './helper'

const { TextArea } = Input;
const Step = Steps.Step;

class CheckoutPage extends Component {

  constructor(props) {

    super(props);

  }

  state = {
    imageLoaded: true
  }
  static defaultProps = {
    checkoutState: {}
  }

  componentDidMount() {
    this.initCheckoutPage()
  }

  initCheckoutPage(props = this.props) {
    let { initCheckoutPage } = props;
    let urlParams = queryString.parse(this.props.location.search);

    initCheckoutPage({
      gid: urlParams.query
    })
  }

  componentWillReceiveProps(nextProps) {
    this.callCheckoutCart(nextProps);
  }

  componentDidUpdate(prevProps) {
    const { checkoutState, updateDisplayPriceInfo, } = this.props;
    const { priceInfo, pointsUsed, selectedGiftCard, confirmCheckoutSuccess } = checkoutState

    if (!_.isEqual(prevProps.checkoutState.priceInfo, priceInfo)
      || prevProps.checkoutState.pointsUsed != pointsUsed
      || !_.isEqual(prevProps.checkoutState.selectedGiftCard, selectedGiftCard)
    ) {
      updateDisplayPriceInfo()
    }

    if (prevProps.checkoutState.displayModal != checkoutState.displayModal && checkoutState.displayModal) {
      this.showModal()
    }

    if (!_.isEqual(prevProps.checkoutState, checkoutState)) {
      this.getCheckoutMessage()
    }

    // if aio success with PayPal Express, WeChat or Alipay payment method
    if (prevProps.checkoutState.confirmCheckoutSuccess != confirmCheckoutSuccess && confirmCheckoutSuccess) {
      this.goToPayPage();
    }

    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.initCheckoutPage()
    }
  }

  callCheckoutCart(nextProps) {
    const { checkoutState, cart } = nextProps;
    const { shippingAddress, isInitialized } = checkoutState;
    const addressIsDiff = !_.isEqual(this.props.checkoutState.shippingAddress, shippingAddress) && !_.isEmpty(shippingAddress);
    const causedByInit = !_.isEqual(this.props.checkoutState.isInitialized, isInitialized) && isInitialized
    const cartIsDiff = !_.isEqual(this.props.cart, cart) && !_.isEmpty(cart)
    if (addressIsDiff || causedByInit || cartIsDiff) {
      this.checkoutCart(nextProps);
    }
  }

  checkoutCart = (props = this.props, coupon = '') => {
    let { cart, fetchCartTotal, userProfile, checkoutState, isAuthenticated } = props;
    let gid = _pageHelper.getGid()
    let { shippingAddress } = checkoutState
    let shpAddressParams = !_.isEmpty(shippingAddress) ? shippingAddress : userProfile && userProfile.defaultAddress ? userProfile.defaultAddress : {}

    if (cart && isAuthenticated) {
      fetchCartTotal({
        gid: gid,
        cart: _.get(cart, 'cart', []),
        shippingMethod: _config.SHIPPING_MAPPING_TO_NUMERIC[_.get(cart, 'shippingMethod', '')],
        address: shpAddressParams,
        coupon: coupon ? coupon : _.get(cart, 'coupon', '')
      })
    }

  }

  showModal() {
    let { checkoutState, intl, setCheckoutState, push } = this.props
    let { displayModalTitle, displayModalContent, displayModalKeywords, displayModalType, displayModalHanldingType, } = checkoutState
    let { formatMessage } = intl
    let title = displayModalTitle ? formatMessage({ id: displayModalTitle }) : '';
    let content = displayModalContent ? formatMessage({ id: displayModalContent }, displayModalKeywords) : ''
    let okayText = displayModalHanldingType == 'back_to_store' ? formatMessage({ id: 'back_to_store' }) : formatMessage({ id: 'ok' })
    let goToStorePage = () => this.goToStorePage()

    if (displayModalContent == 'checkout_success') {
      Modal.confirm({
        title: title,
        content: content,
        onOk() {
          setCheckoutState('displayModal', false)
          goToStorePage()
        },
        onCancel() {
          setCheckoutState('displayModal', false)
          push(`/account/orders/detail/${displayModalKeywords.order_id}`)
        },
        okText: okayText,
        cancelText: formatMessage({ id: 'go_to_order' }),
      });
    } else if (displayModalType == "error") {
      Modal.error({
        title: title,
        content: content,
        onOk() {
          setCheckoutState('displayModal', false)
          if (displayModalHanldingType == 'back_to_store') {
            goToStorePage()
          }
        },
        okText: okayText,
      });
    } else {
      Modal.success({
        title: title,
        content: content,
        onOk() {
          setCheckoutState('displayModal', false)
          if (displayModalHanldingType == 'back_to_store') {
            goToStorePage()
          }
        },

        okText: okayText,
      });
    }

  }

  goToStorePage = () => {
    let {
      storeInstance,
    } = this.props;

    if (_helper.getIndependentDomain()) {
      /* temp until store page is written in web_v2 */ window.location.href = `/`
    } else {
      /* temp until store page is written in web_v2 */ window.location.href = `/store/${storeInstance.data['group_id']}`
    }
  }

  goToPayPage() {
    let { checkoutState } = this.props;
    let { payUrl } = checkoutState
    if (payUrl) {
      window.location.replace(payUrl);
    }
  }

  getCheckoutMessage = () => {
    let { checkoutState, storeInstance, cartList } = this.props
    let urlParams = queryString.parse(this.props.location.search)
    let gid = urlParams.query
    let cartDetails = cartList[gid] ? cartList[gid] : {}
    let { firstName, phone, validPhone, shippingAddress,
      billingAddress, paymentMethod, shippingMethod, tableNumber,
      creditCard, shippingTypes, selectedShippingType } = checkoutState

    if (!firstName && cartDetails['shippingMethod'] !== 'delivery') {
      return 'please_input_first_name'
    }

    if ((!phone || !validPhone) && cartDetails['shippingMethod'] !== 'delivery') {
      return 'please_input_phone_number'
    }

    if (shippingMethod < 1 || shippingMethod > 5) {
      return 'please_input_shipping_method'
    }

    if (!_.isEmpty(shippingTypes) && !selectedShippingType) {
      return 'please_input_sub_shipping_method'
    }

    if (shippingMethod == 1 && _.isEmpty(shippingAddress)) {
      return 'please_input_shipping_addresss'
    }

    if (shippingMethod == 3 && storeInstance.data['require_table_no'] && !tableNumber) {
      return 'please_input_table_number'
    }

    if (paymentMethod < 0) {
      return 'please_input_payment_method'
    }

    if (paymentMethod == 2 && !creditCard) {
      return 'please_input_valid_credit_card'
    }

    if (paymentMethod == 2 && storeInstance.data['require_billing_address'] && !billingAddress) {
      return 'please_input_billing_address'
    }
    return 'place_order'

  }

  onCheckoutClick = () => {

    let { checkout, setCheckoutState, checkoutState, intl } = this.props
    let { formatMessage } = intl
    if (this.getCheckoutMessage() == 'please_input_first_name') {
      setCheckoutState('focusField', 'first_name')
    } else if (this.getCheckoutMessage() == 'please_input_phone_number') {
      setCheckoutState('focusField', 'phone_number')
    } else if (this.getCheckoutMessage() == 'please_input_shipping_method') {
      setCheckoutState('displayModalName', 'shippingMainMethod')
    } else if (this.getCheckoutMessage() == 'please_input_sub_shipping_method') {
      setCheckoutState('displayModalName', 'shippingSubMethod')
    } else if (this.getCheckoutMessage() == 'please_input_shipping_addresss') {
      setCheckoutState('displayModalName', 'shippingAddress')
    } else if (this.getCheckoutMessage() == 'please_input_table_number') {
      setCheckoutState('focusField', 'table_number')
    } else if (this.getCheckoutMessage() == 'please_input_payment_method') {
      setCheckoutState('displayModalName', 'paymentMethod')
    } else if (this.getCheckoutMessage() == 'please_input_valid_credit_card') {
      setCheckoutState('displayModalName', 'paymentMethod')
    } else if (this.getCheckoutMessage() == 'please_input_billing_address') {
      setCheckoutState('displayModalName', 'billingAddress')
    } else {
      checkout(checkoutState)
    }
  }

  goToConditions = () => {
    window.open("https://shop.goopter.com/common/conditions_of_use")
  }

  goToPrivacy = () => {
    window.open("https://shop.goopter.com/common/privacy_notice")
  }

  getLeftSections() {
    let {
      lan,
      intl,
      storeInstance,
      checkoutState,
      cartList,
      productList,
      userProfile,
      setCheckoutState,
      updateTips,
      updateShoppingCartMethod,
      checkout,
      orderDetailInstance,
    } = this.props;

    let { formatMessage } = intl
    let urlParams = queryString.parse(this.props.location.search)
    let gid = urlParams.query
    let cartDetails = cartList[gid] ? cartList[gid] : {}

    let storeCat = checkoutState["storeInstance"].data.c_id

    let sectionTitle = ''
    if (cartDetails['shippingMethod'] == 'eatin') {
      sectionTitle = formatMessage({ id: 'table_info' })
    } else {
      sectionTitle = 'delivery_info'
    }

    let leftSections = [
      {
        requirement: cartDetails['shippingMethod'] !== 'delivery',
        title: 'customer_info',
        content: <CustomerInfo lan={lan} customerInfo={userProfile} cartDetails={cartDetails} setCheckoutState={setCheckoutState} checkoutState={checkoutState} />,
      },
      {
        requirement: true,
        title: 'payment_info',
        content: <PaymentInfo lan={lan} checkoutState={checkoutState} storeInstance={storeInstance} setCheckoutState={setCheckoutState} />,
      },
    ];

    if (checkoutState.shippingMethod != 2) {
      leftSections.unshift({
        requirement: !_.isEmpty(checkoutState.allowedPeriods) || checkoutState.shippingTypes.length || cartDetails['shippingMethod'] == 'eatin' && storeCat == 1
          || cartDetails['shippingMethod'] == 'delivery' || cartDetails['shippingMethod'] == 'flatrate',
        title: sectionTitle,
        content: <ShippingInfo lan={lan} cartDetails={cartDetails} checkoutState={checkoutState} storeInstance={storeInstance} setCheckoutState={setCheckoutState} updateShoppingCartMethod={updateShoppingCartMethod} userProfile={userProfile} />,
      })
    }

    return leftSections
  }

  render() {
    let {
      lan,
      intl,
      storeInstance,
      checkoutState,
      cartList,
      productList,
      setCheckoutState,
      updateShoppingCartMethod,
    } = this.props;

    let { formatMessage } = intl
    let urlParams = queryString.parse(this.props.location.search)
    let gid = urlParams.query
    let cartDetails = cartList[gid] ? cartList[gid] : {}

    return (
      <div>
        <TimeRestrictedItemChecker />
        <ShoppingCartRenderless gid={Number(gid)} />
        <PageLayout
          hideSideBar={true}
          gid={storeInstance.data['group_id']}
          logoText={storeInstance.data['name']}
          key="checkout-page-layout"
        >
          <div className="checkout-page-container">
            <Spin spinning={checkoutState.getQuoteLoading}>
              <Row className="row">
                <Col span={13} className="left-col">

                  <Steps className="steps-bar" size="small">
                    <Step status="finish" title={formatMessage({ id: "shopping" })} icon={<Icon type="shopping-cart" />} />
                    <Step status="process" title={formatMessage({ id: "billing_shipping" })} icon={<Icon type="loading" />} />
                    <Step status="wait" title={formatMessage({ id: "done" })} icon={<Icon type="smile-o" />} />
                  </Steps>

                  <ShippingMainMethodAndTime
                    cartDetails={cartDetails}
                    storeInstance={storeInstance}
                    checkoutState={checkoutState}
                    setCheckoutState={setCheckoutState}
                    updateShoppingCartMethod={updateShoppingCartMethod}
                    lan={lan}
                  />

                  {this.getLeftSections().map((item, i) => {
                    if (item.requirement) return (
                      <div className="checkout-section-container" key={i}>
                        <div className="checkout-section">
                          <FormattedMessage id={item.title} />
                        </div>
                        {item.content}
                      </div>
                    )
                  })}

                </Col>
                <Col span={11} className="right-col">
                  <ItemInfo
                    cartList={cartList}
                    productList={productList}
                    currency={storeInstance.data['currency']}
                    gid={storeInstance.data['group_id']}
                  />

                  <Divider />

                  <CouponInput onCouponAppliedClick={value => this.checkoutCart(this.props, value)} />

                  <Divider />

                  <PriceInfo />

                  <TextArea
                    style={{ width: '100%', margin: '10px 0' }}
                    placeholder={formatMessage({ id: 'comment' })}
                    autosize={{ minRows: 2, maxRows: 6 }}
                    onChange={(e) => setCheckoutState('orderComment', e.target.value)}
                  />
                  <div className='checkout-disclaimer'>
                    By clicking below I accept the current <span onClick={() => this.goToConditions()}> Conditions of Use </span> and <span onClick={() => this.goToPrivacy()}>Privacy Notice</span>.
                  </div>
                  <Button
                    size='large'
                    style={{ width: '100%', fontWeight: 'bold', }}
                    type="primary"
                    onClick={() => this.onCheckoutClick()}
                  >
                    <FormattedMessage id={this.getCheckoutMessage()} />
                  </Button>
                  <div className="secure-inner-wrapper">
                    <FaLock style={{ marginRight: '5px' }} />
                    <FormattedMessage id="secure_connection" className="secure-text" />
                  </div>

                </Col>
              </Row>
            </Spin>
          </div>
        </PageLayout>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let shoppingCartState = state['shopping-cart'];
  let checkoutState = state['checkout-page'];
  let authState = state['auth'];
  let settingState = state['settings'];
  return {
    isAuthenticated: authState.isAuthenticated,
    checkoutState: checkoutState,
    checkoutInitialized: checkoutState.checkoutInitialized,
    storeInstance: checkoutState.storeInstance,
    cartList: shoppingCartState.cartList,
    cartIsInit: shoppingCartState.isInitialized,
    productList: shoppingCartState.productList,
    userProfile: authState.userProfile,
    lan: settingState.lan,
    cart: _.get(state, `shopping-cart.cartList.${_pageHelper.getGid()}`, []),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    initCheckoutPage: bindActionCreators((params) => {
      return _checkoutActions.initCheckoutPage(params)
    }, dispatch),
    fetchCartTotal: bindActionCreators((params) => {
      return {
        type: "CHECKOUT_PAGE__FETCH_CART_TOTAL",
        payload: {
          cart: params.cart,
          coupon: params.coupon,
          gid: params.gid,
          qid: params.qid,
          address: params.address,
          shippingMethod: params.shippingMethod,
        }
      }
    }, dispatch),
    setCheckoutState: bindActionCreators((key, value) => {
      return {
        type: "CHECKOUT_PAGE__SET_STATE",
        state: { [key]: value }
      }
    }, dispatch),
    updateDisplayPriceInfo: bindActionCreators(() => {
      return {
        type: "CHECKOUT_PAGE__UPDATE_DISPLAY_PRICE",
      }
    }, dispatch),
    checkout: bindActionCreators((params) => {
      return {
        type: "CHECKOUT_PAGE__CHECKOUT",
        payload: params
      }
    }, dispatch),
    updateShoppingCartMethod: bindActionCreators((params) => {
      return {
        type: "SHOPPING_CART__SET_CART_SHIPPING_METHOD",
        payload: params
      }
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckoutPage));
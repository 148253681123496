import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Divider, Spin } from 'antd'
import './_page-now-page.scss'
import PageLayout from '../../components/account-page-layout'
import _helper from '../../utils/helper'
import PaymentPicker from './page-components/payment-picker'
import ItemInfo from './page-components/item-info'
import PriceInfo from './page-components/price-info'
import PayButton from './page-components/pay-button'
import Steps from './page-components/steps'
import AlertModel from './page-components/alert-model'
import _pageHelper from "./helper";

class PayNowPage extends React.Component {
    componentDidMount() {
        this.initData()
    }

    componentDidUpdate(prevProps) {
        this.checkCheckoutSuccess(prevProps)
    }

    getCheckoutSuccess(props = this.props) {
        return _.get(props, 'state.confirmCheckoutSuccess', false)
    }

    getPayUrl(props = this.props) {
        return _.get(props, 'state.payUrl', false)
    }

    getOrderId = () => {
        const urlParams = _helper.getUrlParameters()
        return _.get(urlParams, 'orderId', '')
    }

    getGid = () => {
        const urlParams = _helper.getUrlParameters()
        return _.get(urlParams, 'gid', '')
    }

    getStoreName = () => {
        return _.get(this.props, 'store.name', '')
    }

    checkCheckoutSuccess = (prevProps) => {
        const checkoutSucess = !_.isEqual(this.getCheckoutSuccess(prevProps), this.getCheckoutSuccess()) && this.getCheckoutSuccess()
        const payUrl = this.getPayUrl()
        if (checkoutSucess && payUrl) this.goToPay()
    }

    goToPay = () => {
        window.location.href = this.getPayUrl()
    }

    initData = () => {
        this.props.initData({
            gid: this.getGid(),
            orderId: this.getOrderId(),
            lan: this.props.lan
        })
    }

    render() {
        return [
            <div className="pay-now-page-container">
                <PageLayout
                    hideSideBar={true}
                    gid={this.getGid()}
                    logoText={this.getStoreName()}
                    key="pay-now-page-layout">
                    <Spin spinning={this.props.isLoading}>
                        <Row className="row">
                            <Col span={13} className="left-col">
                                <Steps />
                                <PaymentPicker />
                            </Col>
                            <Col span={11} className="right-col">
                                <ItemInfo />
                                <Divider />
                                <PriceInfo />
                                <PayButton />
                            </Col>
                        </Row>
                    </Spin>

                </PageLayout>
            </div>,
            <AlertModel />
        ]
    }
}

const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    isLoading: _.get(state, "pay-now.isLoading", false),
    store: _.get(state, 'pay-now.store.data', ''),
    state: _.get(state, 'pay-now', {})
})

const mapDispatchToProps = {
    initData: (payload) => ({ type: 'PAY_NOW__FETCH_ORDER_DETAIL', payload })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayNowPage)));
import React, { Component } from 'react';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import moment from "moment";
import { Modal, Row, Col, Button, Divider, Radio } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_timePickerModal.scss'
import { connect } from 'react-redux';

const SERVER_TIME_DATE_FORMAT = 'YYYY-MM-DD'
const SERVER_TIME_FORMAT = 'hh:mma'
const TIME_FORMAT_DISPLAY = 'hh:mmA'

const RadioGroup = Radio.Group;

class DatePickerModal extends Component {

  state = {
    currentDisplayDate: true,
    selectedValues: '',
    invalidDate: false,
    defaultSelectedValue: '',
    currentGroup: ''
  }

  static defaultProps = {
    allowedPeriods: {},
    deliveryDisplayDate: '',
  }

  componentWillReceiveProps(nextProps) {
    this.scrollMenu(nextProps)
    this.updateValue();
    this.currentGroup();
  }

  string = id => this.props.intl.formatMessage({ id })

  scrollMenu = (nextProps) => {
    let { currentGroup } = nextProps
    if (this.props.currentGroup !== currentGroup) {
      if (this[currentGroup]) {
        var rect = this[currentGroup].getBoundingClientRect();
      }
    }
  }

  updateValue() {
    let { defaultSelectedValue, selectedValues, currentGroup } = this.state;

    defaultSelectedValue = this.props.allowedPeriods[currentGroup];
    this.setState({
      selectedValues: selectedValues ? selectedValues : defaultSelectedValue ? defaultSelectedValue[0] : ''
    });
  }

  currentGroup() {
    let { allowedPeriods } = this.props;
    this.setState({
      currentGroup: Object.keys(allowedPeriods)[0]
    });
  }

  onButtonClick = (value) => {
    this.setState({
      currentGroup: value
    })
  }

  onOkClick = () => {
    let {
      onCloseClick,
      onOkClick,
      lan,
    } = this.props;

    let { selectedValues, currentGroup } = this.state
    let newDisplayDate = currentGroup + " " + selectedValues

    this.setState({
      defaultSelectedValue: selectedValues
    })
    onOkClick(newDisplayDate);
    onCloseClick();
  }

  getTime = (time) => {
    const times = time.split("-")
    const startTime = _.get(times, '0')
    const endTime = _.get(times, '1')
    const startMoment = moment(startTime, SERVER_TIME_FORMAT)
    const endMoment = moment(endTime, SERVER_TIME_FORMAT)

    const startStr = startMoment.isValid() ? startMoment.format(TIME_FORMAT_DISPLAY) : ''
    const endStr = endMoment.isValid() ? endMoment.format(TIME_FORMAT_DISPLAY) : ''

    if (startStr && endStr) {
      return `${startStr}-${endStr}`
    }
    if (startStr) {
      return startStr
    }
    if (endStr) {
      return endStr
    }
    return time
  }

  getDate = (date) => {
    const seperator = ':'
    const formats = {
      nextDay: `[${this.string('tomorrow')}${seperator}]ddd`,
      sameDay: `[${this.string('today')}${seperator}]ddd`,
      lastDay: `[${this.string('yesterday')}${seperator}]ddd`,
      sameWeek: `${SERVER_TIME_DATE_FORMAT}${seperator}ddd`,
      nextWeek: `${SERVER_TIME_DATE_FORMAT}${seperator}ddd`,
      lastWeek: `${SERVER_TIME_DATE_FORMAT}${seperator}ddd`,
      sameYear: `${SERVER_TIME_DATE_FORMAT}${seperator}ddd`,
      sameElse: `${SERVER_TIME_DATE_FORMAT}${seperator}ddd`
    }
    const dateStr = moment(date, SERVER_TIME_DATE_FORMAT).isValid() ? moment(date, SERVER_TIME_DATE_FORMAT).calendar(null, formats) : ' '
    return dateStr.split(seperator)
  }

  renderTimeOptions = () => {
    let { selectedValues, currentGroup } = this.state
    let { allowedPeriods, } = this.props;
    let options = allowedPeriods[currentGroup]

    let onChange = (value) => {
      this.setState({
        selectedValues: value,
        invalidDate: false
      })
    }

    return (
      <RadioGroup
        key={options}
        value={selectedValues}
        onChange={(e) => onChange(e.target.value)}
        style={{
          width: '100%',
          height: '280px',
          overflowX: 'hidden',
        }}
        allowClear={false}
        changeOnSelect
      >
        {options ? options.map((opt, i) => {
          return (
            <div>
              <Row
                onClick={() => onChange(opt)}
                className={classnames({
                  "radio-row": true,
                  "selected-time": selectedValues == opt,
                })} >
                <Col span={22}>
                  <span className="opt-descript"> {this.getTime(opt)}</span>
                </Col>
                <Col span={2}>
                  <Radio
                    className="radio-button"
                    key={i}
                    value={opt} />
                </Col>
              </Row>
              <Divider className="radio-divider" />
            </div>
          )
        }) : ''}
      </RadioGroup>
    )
  }

  renderDatePickers = () => {
    let {
      intl,
      allowedPeriods,
    } = this.props;

    let { currentGroup } = this.state;
    return (
      <div ref={node => this.scrollBar = node} key='dateScrollMenu' className="dateScrollMenu">
        {Object.keys(allowedPeriods).map((date, i) => {
          const dateArr = this.getDate(date)
          return (
            <div
              className={classnames({
                "dateScrollMenu-date-button": true,
                "selected": date == currentGroup,
                "button-item": true,
              })}
              onClick={() => { this.onButtonClick(date) }}
              ref={node => this[date] = node} key={`${date}-${i}`} >
              {_.get(dateArr, '0')} · {_.get(dateArr, '1')}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { title, displayModal, onCloseClick, intl, } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        width={550}
        title={formatMessage({ id: title })}
        visible={displayModal}
        onOk={() => this.onOkClick()}
        onCancel={onCloseClick}
        okText={formatMessage({ id: 'ok' })}
        cancelText={formatMessage({ id: 'cancel' })}
      >
        <div className="time-picker-modal">
          {this.renderDatePickers()}
          {this.renderTimeOptions()}
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = (state) => ({
  lan: state.settings.lan,
})

const mapDispatchToProps = ({

})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DatePickerModal))
import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { injectIntl, FormattedMessage, } from 'react-intl';
import ItemInfoCheckout from '../../checkout-page/page-components/itemInfo'
import _ from 'lodash'
import { connect } from 'react-redux';

class ItemInfo extends Component {
    getItemDatas = () => {
        return _.get(this.props, 'order.order_item', [])
    }

    render() {

        return (
            <div>
                <ItemInfoCheckout propsItems={this.getItemDatas()} />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "pay-now", {}),
    order: _.get(state, 'pay-now.order.data', '')
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'PAY_NOW__SET_STATE', state })
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemInfo));
import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Row, Col, } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import './_itemInfo.scss'
import _ from 'lodash';

class ItemInfo extends Component {

    static defaultProps = {
        checkoutState: {}
    }

    getItemsInfoFromCart = () => {
        let { gid } = this.props
        let formattedItems = []
        let currentCartList = _.get(this.props, `cartList.${gid}.cart`, [])
        let currentProductList = _.get(this.props, `productList.${gid}`, [])
        currentCartList && currentCartList.length && currentProductList && currentProductList.length &&
            currentCartList.map(cartItem => {
                let product = currentProductList.find(pro => { return cartItem.pid == pro.pid })
                let pc = cartItem.pc ? cartItem.pc : product.pc
                let amt = parseFloat(pc) * parseFloat(cartItem.qty)
                let opt = []

                //first level options
                !_.isEmpty(cartItem) && !_.isEmpty(cartItem.options) && product.opt && product.opt.length &&
                    Object.keys(cartItem.options).map(id => {
                        let currentOption = product.opt.find(op => { return op.id == id })
                        let opts = []

                        //second level opts
                        cartItem.options[id] && cartItem.options[id].length && currentOption.opts && currentOption.opts.length &&
                            cartItem.options[id].map(op => {
                                let currentSubOpt = currentOption.opts.find(p => { return op == p.id })
                                opts.push({
                                    "id": currentSubOpt.id,
                                    "nm": currentSubOpt.nm
                                })
                            })

                        opt.push({
                            "nm": currentOption.nm,
                            "id": currentOption.id,
                            "opts": opts
                        })
                    })

                formattedItems.push({
                    "nm": product.nm,
                    "pid": product.pid,
                    "sku": product.sku,
                    "pc": pc,
                    "cnt": cartItem.qty,
                    "amt": amt.toFixed(2),
                    "opts": opt
                })
            })
        return formattedItems;
    }

    getItems = () => {
        return this.props.propsItems || this.getItemsInfoFromCart()
    }

    render() {
        let {
            lan,
            currency,
        } = this.props;

        return (
            <div className="itemInfo" >
                <Row className="order-item-header border">
                    <Col span={13} className="item-name  item title"><h2> <FormattedMessage id="item(s)" /> </h2></Col>
                    <Col span={4} className="single-item-price item title"><h2> <FormattedMessage id="price" /> </h2></Col>
                    <Col span={3} className="cnt title item"><h2> <FormattedMessage id="qty" /> </h2> </Col>
                    <Col span={4} className="item-price title item"><h2> <FormattedMessage id="amt" /> </h2></Col>
                </Row>
                {this.getItems().map((item, i) => {
                    return (
                        <div className="box-section" key={`product-item-${i}`}>
                            <div >
                                <Row className="order-item-header" >
                                    <Col span={13} className="item-name item"><h3>{item.nm}</h3></Col>
                                    <Col span={4} className="single-item-price item"><h5>{_helper.formatCurrency(item.pc, currency)}</h5></Col>
                                    <Col span={3} className="cnt item"><h5>x {item.cnt}</h5> </Col>
                                    <Col span={4} className="item-price item"><h4>{_helper.formatCurrency(item.amt, currency)}</h4></Col>
                                </Row>
                            </div>
                            {item.opts && item.opts.length
                                ? <div className="itemOpt">
                                    {item.opts.map((optionCat, i) => {
                                        let optStr = optionCat['nm'];
                                        let optItems = [];
                                        if (optionCat.opts && optionCat.opts.length) {
                                            optItems = optionCat.opts.map((optItem) => optItem.nm)
                                        }
                                        if (optItems && optItems.length) {
                                            optStr += ` - ${optItems.join(', ')}`
                                        }
                                        return (
                                            <div key={`product-optionCat-${i}`}>{optStr}</div>
                                        )
                                    })}
                                </div>
                                : ''}
                        </div>
                    )
                })}

            </div>
        )
    }
}


export default injectIntl(ItemInfo);
import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import _helper from 'utils/helper';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import countryData from 'utils/data/regionList.json';

const FormItem = Form.Item;


class AddressForm extends Component {

  render() {
    let {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    } = this.props;

    let { formatMessage } = this.props.intl;

    return (
      <Form onSubmit={handleSubmit}>
        <FormItem
          label={<FormattedMessage id="receiver" />}
          colon={false}
          help={touched.receiver && errors.receiver ? errors.receiver : ''}
          validateStatus={touched.receiver && errors.receiver ? 'error' : ''}
        >
          <Input
            name="receiver"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.receiver}
            placeholder={formatMessage({ id: 'email_phone' })}
          />
        </FormItem>
        <FormItem
          label={<FormattedMessage id="comment" />}
          colon={false}
          help={touched.comment && errors.comment ? errors.comment : ''}
          validateStatus={touched.comment && errors.comment ? 'error' : ''}
        >
          <Input.TextArea
            name="comment"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comment}
            placeholder={formatMessage({ id: 'comment' })}
            rows={2}
          />
        </FormItem>


        <FormItem>
          <Button type="primary" htmlType="submit" size="large" block>
            <FormattedMessage id="send" />
          </Button>
        </FormItem>
      </Form>
    )
  }
}

let validationSchema = (lan) => yup.object().shape({
  receiver: yup.string().required(_helper.getIsRequiredMessage(lan, 'receiver')),
  comment: yup.string(),
});

AddressForm = withFormik({
  mapPropsToValues: (props) => ({ receiver: '', comment: '' }),
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.transferGiftCard({
      ...values,
      code: props.code
    })
  },
  validationSchema: (props) => validationSchema(props.lan),
  enableReinitialize: true

})(AddressForm)
export default injectIntl(AddressForm);

import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { injectIntl, FormattedMessage, } from 'react-intl';
import { Steps, Icon } from 'antd';
import _ from 'lodash'
import { connect } from 'react-redux';

const Step = Steps.Step;

class PayNowSteps extends Component {


    render() {

        const { formatMessage } = this.props.intl

        return (
            <Steps className="steps-bar" size="small">
                <Step status="finish" title={formatMessage({ id: "shopping" })}
                    icon={<Icon type="shopping-cart" />} />
                <Step status="process" title={formatMessage({ id: "billing_shipping" })}
                    icon={<Icon type="loading" />} />
                <Step status="wait" title={formatMessage({ id: "done" })}
                    icon={<Icon type="smile-o" />} />
            </Steps>
        )
    }
}


const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    state: _.get(state, "pay-now", {}),
    order: _.get(state, 'pay-now.order.data', '')
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'PAY_NOW__SET_STATE', state })
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayNowSteps));
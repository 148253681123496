export default {
  "googleGeo": "https://maps.googleapis.com/maps/api/geocode/json",
  "order-list": "/api/rest/v7/ordlst",
  "order-detail": "/api/rest/v8/orddtl",
  "order-comments": "/api/rest/v7/comment",
  "bookmarks": "/api/rest/v7/bookmark",
  "login": "/api/rest/v8/login",
  "logout": "/api/rest/v7/logout",
  "verification-code": "/api/rest/v7/vcd",
  "ping": "/api/rest/v7/ping",
  "store-list": "/api/v7/slst",
  "product-search": "/api/v7/psearch",
  "user-profile": "/api/rest/v7/customerinfo",
  "new-email": "/api/rest/v7/newemail",
  "new-phone": "/api/rest/v7/newphone",
  "user-address": "/api/rest/v7/customerAddr",
  "gift-card": "/api/rest/v7/giftcard",
  "gift-card-history": "/api/rest/v7/gifthistory",
  'api-config': '/api/v7/appconfig',
  'store': '/api/v8/sdtl2',
  "category-product-list": "/api/v7/cplst2",
  "store-detail": "/api/v8/sdtl2",
  "cart-total": "/api/rest/v8/ctotal",
  "all-in-one-order": '/api/rest/v7/aio',
  "paypal-express": '/api/rest/v7/express',
  "forget-password": "/api/v7/forgetpwd",
  "update-password": "/api/rest/v7/updatepwd",
  "QR-parser": "/api/v7/qrparser",
  "instant-pay": "/api/v7/instantpay",
}
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _locale from 'locale';
import _helper from '../../utils/helper'
import _ from 'lodash'

import LoadingPage from 'containers/loading-page/index.jsx';
import LoginDialog from 'containers/auth/login';
import LogoutDialog from 'containers/logout';
import LoginPage from 'containers/login';
import AccountPage from 'containers/account-page';
import OrderDetailPage from 'containers/order-detail';
import OrderListPage from 'containers/order-list';
import BookmarkPage from 'containers/bookmarks';
import GiftCardPage from 'containers/gift-card-page';
import PointsPage from 'containers/points-page/index.jsx'
import AddressBookPage from 'containers/address-book/account';
import CheckoutPage from 'containers/checkout-page';
import GroupSalePage from 'containers/groupsale-page/groupsaleLanding/groupsale-landing.jsx'
import GroupSaleSearchPage from 'containers/groupsale-page/groupsaleSearch/groupsale-search.jsx'
import GroupSaleDetails from 'containers/groupsale-page/groupsaleDetails'
import PaypalPage from 'containers/paypal-page'
import PayNowPage from '../pay-now-page'
import SelfCheckoutPage from '../self-checkout-page'

import Locations from 'containers/locations'
import Notifications from 'containers/notifications'

import PrivateRoute from 'containers/private-route';

import _authActions from 'containers/auth/actions';
import _appConfigActions from 'containers/app-config/actions'
import _storeActions from 'containers/with-store-data/actions'

import { withRouter } from 'react-router-dom';

class AppContent extends Component {

  getRoutesData = () => {
    return [
      {
        path: "/account",
        key: "checkout",
        private: true,
        component: AccountPage
      },
      {
        path: "/account/orders/detail/:orderId",
        key: "order-detail",
        private: true,
        component: OrderDetailPage
      },
      {
        path: "/account/orders",
        key: "orders",
        private: true,
        component: OrderListPage
      },
      {
        path: "/account/gift-cards",
        key: "gift-cards",
        private: true,
        component: GiftCardPage
      },
      {
        path: "/account/gift-cards",
        key: "gift-cards",
        private: true,
        component: GiftCardPage
      },
      {
        path: "/account/bookmarks",
        key: "bookmarks",
        private: true,
        component: BookmarkPage
      },
      {
        path: "/account/address-book",
        key: "address-book",
        private: true,
        component: AddressBookPage
      },
      {
        path: "/account/points",
        key: "points",
        private: true,
        component: PointsPage
      },
      {
        path: "/checkout",
        key: "checkout",
        private: true,
        component: CheckoutPage
      },
      {
        path: "/login",
        key: "login",
        private: false,
        component: LoginPage
      },
      {
        path: "/paypal",
        key: "paypal",
        private: true,
        component: PaypalPage
      },
      {
        path: "/paynow",
        key: "paynow",
        private: false,
        component: PayNowPage
      },
      {
        path: "/deals",
        key: "deals",
        private: false,
        component: GroupSalePage
      },
      {
        path: "deals/browse",
        key: "dealsBrowser",
        private: false,
        component: GroupSaleSearchPage
      },
      {
        path: "/deals/details/:productId",
        key: "dealProduct",
        private: false,
        component: GroupSaleDetails
      },
      {
        path: "/deals/checkout",
        key: "dealsCheckout",
        private: true,
        component: CheckoutPage
      },
      {
        path: "/pay",
        key: "selfCheckoutDirect",
        subPaths: ['amt', 'ro', 'oid'],
        private: false,
        component: SelfCheckoutPage,
      },
      {
        path: "/pay/:gid",
        subPaths: ['amt', 'ro', 'oid', 'tc'],
        key: "selfCheckout",
        private: false,
        component: SelfCheckoutPage,
      },
    ]
  }

  componentWillMount() {
    let { setAuth, appReady, initAppConfig, fetchStoreInstance } = this.props;
    setAuth();
    if (!appReady) {
      initAppConfig()
    }
    if (_helper.getIndependentDomain()) {
      fetchStoreInstance()
    }
  }

  getSinglePathComponent = (route, subPath = '') => {
    const { lan = "en" } = this.props;
    const RouteComponent = route.private ? PrivateRoute : Route;
    return (
      <IntlProvider
        locale={lan}
        messages={_locale.getIntlMessages(lan, route.key)}
        key={`${route.key}${subPath ? `-${subPath}` : ''}-${lan}`}>
        <RouteComponent exact path={`${route.path}${subPath}`} component={route.component} />
      </IntlProvider>
    )
  }

  getMultiPathComponent = (route) => {
    const possibleSubsPaths = _helper.generateSubPaths(route.subPaths)
    const allComponents = possibleSubsPaths.map((path) => {
      return this.getSinglePathComponent(route, path)
    })
    return allComponents
  }

  getRouteComponents = () => {
    let results = [];
    this.getRoutesData().map(route => {
      if (!_.isEmpty(route.subPaths)) {
        results = results.concat(this.getMultiPathComponent(route))
      }
      results.push(this.getSinglePathComponent(route))
    })
    return results;
  }

  render() {
    let { authLoading, lan, appReady, storeReady } = this.props;

    if (authLoading //auth loading to init the app
      || !appReady //app config to init the app
      || (_helper.getIndependentDomain() && !storeReady) //if independent should get store ready
    ) {
      // wait for first setAuth to load so that isAuthenicated is set to correct value before ProtectedRoutes run.
      return (
        <div><LoadingPage /></div>
      )
    } else {
      return (
        <IntlProvider locale={lan}>
          <div>
            {this.getRouteComponents()}

            {/* Auth Components */}
            <LoginDialog />
            <LogoutDialog />
            {/* <LoadingMask /> */}

            {/* Renderless Components */}
            <Locations />
            <Notifications />
          </div>
        </IntlProvider>
      )
    }
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth'];
  let settingsState = state['settings'];
  let appConfigState = state['app-config'];
  let storeDataState = state['store-data']
  return {
    authLoading: authState.authLoading,
    lan: settingsState.lan,
    appReady: appConfigState.appReady,
    storeReady: storeDataState.storeReady,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: bindActionCreators(_authActions.setAuth, dispatch),
    initAppConfig: bindActionCreators(_appConfigActions.initAppConfig, dispatch),
    fetchStoreInstance: bindActionCreators(_storeActions.fetchStoreInstance, dispatch),
  }
}

AppContent = connect(mapStateToProps, mapDispatchToProps)(AppContent);
export default withRouter(AppContent);
import _helper from 'utils/helper';
import _ from 'lodash';

function initShoppingCart(params) {
  return {
    type: "SHOPPING_CART__INIT_SHOPPING_CART",
    payload: {
      gid: params.gid
    }
  }
}

function setShoppingCartItems(params) {
  return {
    type: "SHOPPING_CART__SET_CART_ITEMS",
    payload: params
  }
}

function updateCartListFromOld(gid) {
  let cartListFromOld = _helper.getLocalStorage(`g${gid}`);
  let shippingMethodFromOld = _helper.getLocalStorage(`checkoutShippingMethod`);
  if (cartListFromOld) {
    let realCart = cartListFromOld.shoppingCart ? cartListFromOld.shoppingCart : [];
    let cartItems = [];
    realCart.map(product => {
      let info = {
        pid: product.pid,
        qty: product.count,
        pc: product.price,
      };
      if (product.opt) {
        let opts = {};
        Object.keys(product.opt).map(op => {
          let values = [];
          if (product.opt[op]) {
            values = Object.keys(product.opt[op]);
          }
          opts[op] = values;
        });
        info.options = opts;
      }
      cartItems.push(info);
    });
    let cartObject = {
      shippingMethod: shippingMethodFromOld,
      coupon: cartListFromOld.coupon,
      cart: _.cloneDeep(cartItems)
    };
    return JSON.parse(JSON.stringify(cartObject));
  }
}

export default {
  initShoppingCart,
  setShoppingCartItems,
  updateCartListFromOld
}
import React, { Component } from 'react';
import { MdLocationOn } from 'react-icons/md';

class MapMarker extends Component {
  render() {
    let {
    } = this.props;

    return (
      <div>
        <MdLocationOn
          size="3em"
          color="red"
          style={{
            transform: 'translate(-50%, -80%)', // css inspired from https://github.com/google-map-react/google-map-react-examples/blob/master/src/components/Marker.js
          }}
        />
      </div>
    )
  }
}

export default MapMarker;
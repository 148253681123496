import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import _reverseConfig from 'config/config-reverse-map';
import { injectIntl } from 'react-intl';
import Paypal from './paypal.jsx'

class PaypalPage extends Component {

    render() {
        return (
            <div>
                <Paypal />
            </div>
        )
    }
}

const mapStateToProps = (state) => {


    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        push: bindActionCreators(push, dispatch),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaypalPage));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import _ from 'lodash';
import classnames from 'classnames';
import { Collapse, Divider, Icon, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import AccountPageLayout from 'components/account-page-layout';

import StatusSteps from './page-components/status-steps';
import OrderTotals from './page-components/order-totals';
import StoreInfo from './page-components/store-info';
import ExtraInfo from './page-components/extra-info';
import CommentsForm from './page-components/comments-form';
import SeparateSucharge from './page-components/separate-surcharge'
import SurchargePaidToMessage from './page-components/surcharge-paid-to-message'
import AutoReloader from './page-components/auto-reloader'
import OrderControlPanel from './page-components/order-control-panel'
import ErrorModal from './page-components/error-dialog'

import './_order-detail.scss';

const Panel = Collapse.Panel;

class OrderDetailPage extends Component {

  componentDidMount() {
    this.fetchOrderDetail();
  }

  fetchOrderDetail() {
    let { fetchOrderDetail, lan } = this.props;
    const orderId = this.getParamsOrderId()
    if (orderId) {
      fetchOrderDetail({ orderId: orderId, lan: lan });
    }
  }

  getParamsOrderId = (props = this.props) => {
    return _.get(props, 'match.params.orderId', '')
  }

  componentDidUpdate(prevProps) {
    let { orderDetailInstance, isAuthenticated } = this.props;

    if (orderDetailInstance.data['comments'] && orderDetailInstance.data['comments'] !== prevProps.orderDetailInstance.data['comments']) {
      // when adding a new comment, auto scrolls to the most updated message
      let messageWrapperDom = document.getElementById('messages-wrapper');
      if (messageWrapperDom) {
        messageWrapperDom.scrollTop = messageWrapperDom.scrollHeight
      }
    }
    const authDiff = isAuthenticated !== prevProps.isAuthenticated
    const orderIdDiff = !_.isEqual(this.getParamsOrderId(prevProps), this.getParamsOrderId())
    if (isAuthenticated && (authDiff || orderIdDiff)) {
      this.fetchOrderDetail()
    }
  }

  componentWillUnmount() {
    let { clearOrderDetailData } = this.props;
    clearOrderDetailData()
  }

  getAddress = (address, key) => {
    let result = _helper.formatAddress(address)
    let fullName = ''
    if (address.fn) {
      fullName += address.fn
    }
    if (address.ln) {
      fullName += " " + address.ln
    }
    if (fullName) {
      result.unshift(fullName)
    }
    return (
      <Panel header={<FormattedMessage id={key} />}>
        <div>
          {result.map(str => {
            return (
              <div key={`${key}-${str}`}>{str}</div>
            )
          })}
          {address.tn ?
            <div style={{ paddingTop: '5px' }}>
              <Icon type="phone" theme="filled" style={{ paddingRight: '3px', color: "#333333" }} />
              <a
                href={`tel:${address.tn}`}
                className="link">{_helper.formatPhoneNumber(address.tn)}</a>
            </div> : ''}
        </div>
      </Panel>
    )
  }

  // if the shipping method is pick up or delivery and use local delivery (shipping_cd == empty), then show the Requested time or Scheduled time based on following logic(this is for the delivery fee based on the distance):
  // 1. on pending: show Requested time 要求时间 and value
  // 2. on processing, when the est_ready_dt is empty show Requested time 要求时间 and value from the exp_dt_start and exp_dt_end
  // 3. on processing, when the est_ready_dt is not empty show Scheduled time 预计时间 and value
  // If the shipping method is delivery and use 3rd party (shipping_cd != empty), then hide the Requested time and Scheduled time; (this is normally through UPS, Fedex etc. can't know their time)
  // on cancelled, do not show label nor value
  // on complete or any other status, do not show label and value
  shouldShouldExpectedTime(order) {

    const shippingCode = order.data['shipping_code']
    const shippingMethod = order.data['shipping_method']
    const orderStatus = order.data['status']

    const statusValid = orderStatus === 1 || orderStatus === 2 //pending || processing
    const methodValid = shippingMethod === 2 || shippingMethod === 1 && _.isEmpty(shippingCode)

    return statusValid && methodValid
  }

  // if the shipping method is pick up or delivery and use local delivery (shipping_cd == empty), then show the Requested time or Scheduled time based on following logic(this is for the delivery fee based on the distance):
  // 1. on pending: show Requested time 要求时间 and value
  // 2. on processing, when the est_ready_dt is empty show Requested time 要求时间 and value from the exp_dt_start and exp_dt_end
  // 3. on processing, when the est_ready_dt is not empty show Scheduled time 预计时间 and value
  // If the shipping method is delivery and use 3rd party (shipping_cd != empty), then hide the Requested time and Scheduled time; (this is normally through UPS, Fedex etc. can't know their time)
  // on cancelled, do not show label nor value
  // on complete or any other status, do not show label and value
  expectedTimeValue(order) {
    const expectTime = order.data['exp_dt_full']
    const estTime = order.data['est_ready_dt_converted']
    const orderStatus = order.data['status']
    let result = {
      label: " ",
      value: null,
    }
    if (this.shouldShouldExpectedTime(order)) {
      if (orderStatus === 1) { //pending
        result = {
          label: 'requested_time',
          value: expectTime,
        }
      }
      if (orderStatus === 2) { //processing
        result = {
          label: estTime ? 'scheduled_time' : 'requested_time',
          value: estTime || expectTime,
        }
      }
    }
    return result;
  }

  render() {
    let {
      push,
      orderDetailInstance,
      pageLoading,
      postComment,
      commentsFormLoading,
      isLoading
    } = this.props;

    return [
      <AccountPageLayout
        title={<div className="order-detail-page-header-container">
          <div className="header-text-title">
            <FormattedMessage id="order_detail" />
          </div>
          <div>
            <OrderControlPanel />
          </div>
        </div>}
        subTitle={
          <div>
            <span><FormattedMessage id="ordered_on" /> {_helper.utcToLocal(orderDetailInstance.data['order_date'])}</span>
            <Divider type="vertical" />
            <span><FormattedMessage id="order_id" /> {orderDetailInstance.data['order_id']}</span>
          </div>}
      >

        <Spin spinning={pageLoading || isLoading}>

          <div className="order-detail-content-container">

            <div className="content-header">
              <StatusSteps
                status={orderDetailInstance.data['status']}
                fetchOrderDetail={() => this.fetchOrderDetail()}
                isLoading={isLoading}
              />
            </div>

            <div className="content-body">
              <div className="left-section-wrapper section-wrapper">
                <OrderTotals orderDetailInstance={orderDetailInstance} />
                <SeparateSucharge />
                <SurchargePaidToMessage />
                {orderDetailInstance.data['comments'] && orderDetailInstance.data['comments'].length
                  ? <div className="box">
                    <div className="box-section messages-wrapper" id="messages-wrapper">
                      {orderDetailInstance.data['comments'].map((comment, i) => {
                        return (
                          <div
                            key={`comment-${comment.time}-${i}`}
                            className={classnames({
                              "message-wrapper": true,
                              "store": comment['is_admin'],
                              "customer": !comment['is_admin']
                            })}>
                            <div className="message">{comment.comment}</div>
                            <div className="date">{_helper.utcToLocal(comment.time)}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div> : ''}
                <CommentsForm
                  postComment={(values) => {
                    this.props.fetchOrderDetail({
                      orderId: orderDetailInstance.data['order_id'],
                      lan: this.props.lan,
                      postingComment: true,
                      ...values,
                    })
                  }}
                  orderId={orderDetailInstance.data['order_id']}
                  commentsFormLoading={commentsFormLoading}
                />

              </div>
              <div className="right-section-wrapper section-wrapper">

                <div className="box">
                  <div className="box-section">
                    {orderDetailInstance.data['shipping_method']
                      ? <div>
                        <span className="bold"><FormattedMessage id="shipping_method" /></span>:
                        {_config['SHIPPING_MAPPING_TO_TEXT'][orderDetailInstance.data['shipping_method']] ?
                          <span> <span>&nbsp;</span> <FormattedMessage id={_config['SHIPPING_MAPPING_TO_TEXT'][orderDetailInstance.data['shipping_method']]} /></span> : ''}
                      </div>
                      : ''}
                    {orderDetailInstance.data['payment_method']
                      ? <div>
                        <span className="bold"><FormattedMessage id="payment_method" /></span>:
                        {_config['PAYMENT_METHOD_MAPPING'][orderDetailInstance.data['payment_method']] ?
                          <span> <span>&nbsp;</span> <FormattedMessage id={_config['PAYMENT_METHOD_MAPPING'][orderDetailInstance.data['payment_method']]} /> </span> : ''}
                      </div>
                      : ''}
                    {this.expectedTimeValue(orderDetailInstance).value
                      ? <div>
                        <span className="bold"><FormattedMessage id={this.expectedTimeValue(orderDetailInstance).label ? this.expectedTimeValue(orderDetailInstance).label : " "} /></span>:
                        <span>&nbsp;</span> {this.expectedTimeValue(orderDetailInstance).value}
                      </div>
                      : ''}
                  </div>
                </div>

                <StoreInfo push={push} orderDetailInstance={orderDetailInstance} />

                {orderDetailInstance.data['shipping_address'] || orderDetailInstance.data['billing_address']
                  ? <div className="box box-borderless">
                    <Collapse defaultActiveKey={'0'}>
                      {orderDetailInstance.data['shipping_method'] == 1 && orderDetailInstance.data['shipping_address']
                        ? this.getAddress(orderDetailInstance.data['shipping_address'], 'shipping_address') : null}
                      {orderDetailInstance.data['payment_method'] == 2 && orderDetailInstance.data['billing_address']
                        ? this.getAddress(orderDetailInstance.data['billing_address'], 'billing_address') : null}
                    </Collapse>
                  </div> : ''}


                <ExtraInfo orderDetailInstance={orderDetailInstance} />

              </div>
            </div>
          </div>
        </Spin>
      </AccountPageLayout>,
      <ErrorModal />,
      <AutoReloader />
    ]
  }
}

const mapStateToProps = (state) => {
  let orderDetailState = state['order-detail'];
  let settingState = state['settings']
  let authState = state['auth'];

  return {
    isAuthenticated: authState.isAuthenticated,
    pageLoading: orderDetailState.pageLoading,
    orderDetailInstance: orderDetailState.orderDetailInstance,
    commentsFormLoading: orderDetailState.commentsFormLoading,
    lan: settingState.lan,
    isLoading: orderDetailState.isLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    fetchOrderDetail: bindActionCreators((params = {}) => {
      return {
        type: "ORDER_DETAIL__FETCH_ORDER_DETAIL",
        payload: params
      }
    }, dispatch),
    clearOrderDetailData: bindActionCreators((params = {}) => {
      return {
        type: "ORDER_DETAIL__CLEAR_DATA",
      }
    }, dispatch),
    postComment: bindActionCreators((params) => {
      return {
        type: "ORDER_DETAIL__POST_COMMENT",
        payload: params
      }
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);
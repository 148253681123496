import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, List, } from 'antd';
import { FormattedMessage, injectIntl, } from 'react-intl';
import Img from 'react-image'
import ImgHolder from 'images/image_groupsale_large_gray.png'
import './_imageListModal.scss'

class ImageListModal extends Component {


    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static defaultProps = {
        displayModal: false,
        reviewData: [],
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        let {
            intl,

            displayModal,
            onCloseClick,
            onImageClick,
            imageData
        } = this.props;

        let {
            width,
            height
        } = this.state

        let { formatMessage } = intl;

        return (
            <div className="review-modal-container">
                <Modal
                    width={900}
                    visible={displayModal}
                    title={formatMessage({ id: 'customer_photos' })}
                    onCancel={onCloseClick}
                    footer={null}
                >
                    <div
                        style={{ maxHeight: `${height * 0.7}px`, overflowY: 'auto', overflowX: 'hidden' }}
                        className="content">
                        <List
                            grid={{ gutter: 16, column: 4 }}
                            dataSource={imageData}
                            renderItem={(item, i) => (
                                <List.Item
                                    key={`image-cell-${i}`}>
                                    <div className="img-parent" onClick={() => onImageClick(item)}>
                                        <Img
                                            onClick={() => { }}
                                            className="img"
                                            src={`${_config['IMG_PREFIX']}${item}`}
                                            unloader={<Img
                                                className="img"
                                                src={ImgHolder}
                                            />}
                                        />
                                    </div>
                                </List.Item>
                            )}
                            pagination={{
                                pageSize: 12,
                                total: imageData.length,
                                position: "bottom"
                            }}
                        />
                    </div>

                </Modal>
            </div>
        )
    }
}


export default injectIntl(ImageListModal);
import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import * as firebase from "firebase/app";
import '@firebase/messaging';
import _pageHelper from './helper'
import _action from './actions'
import { notification } from 'antd'
import { push } from 'connected-react-router';

const firebaseConfig = {
    apiKey: "AIzaSyDFUhmFAtss-qPDJJMNCbyiFmTxAr49zaI",
    authDomain: "goopter-1020.firebaseapp.com",
    databaseURL: "https://goopter-1020.firebaseio.com",
    projectId: "goopter-1020",
    storageBucket: "goopter-1020.appspot.com",
    messagingSenderId: "736031565696",
    appId: "1:736031565696:web:5f1d0180aa8e8eaaf4c365"
};

class FirebaseNotifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.initMessageWorker()
    }

    updateDeviceToken(token) {
        const updateData = { 'provider': 'firebase_web', 'device_token': token }
        this.props.setState(updateData)
        this.props.updateDeviceToken(updateData)
    }

    initMessageWorker = () => {
        if (!_pageHelper.isSafari()) {
            this.initFCM()
        }
    }

    initFCM() {
        if (!firebase.apps.length) {
            const initedFirebase = firebase.initializeApp(firebaseConfig);
            try {
                const messaging = initedFirebase.messaging();
                this.notifyMe(messaging)
            } catch (error) {
                console.log(error);
            }
        }
    }

    notifyMe(messaging) {
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        }

        else if (Notification.permission === "granted") {
            this.handleTokenAndMessage(messaging)
        }

        else if (Notification.permission !== 'denied' || Notification.permission === "default") {
            this.requestPermission(messaging)
        }
    }

    requestPermission(messaging) {
        const handleTokenAndMessage = (messaging) => this.handleTokenAndMessage(messaging)
        try {
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    handleTokenAndMessage(messaging)
                } else {
                    console.log("notification_disabled_warning")
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    handleTokenAndMessage(messaging) {
        this.getFirebaseToken(messaging)
        this.listenForTokenRefresh(messaging)
        this.listenForMessage()
    }

    getFirebaseToken = (messaging) => {
        const updateDeviceToken = (token) => this.updateDeviceToken(token)
        messaging.getToken().then(function (currentToken) {
            if (currentToken) {
                updateDeviceToken(currentToken);
                console.log('Firebase Get token success', currentToken);
            } else {
                // Show permission request.
                console.log('Firebase No Instance ID token available. Request permission to generate one.');
            }
        }).catch(function (err) {
            console.log('Firebase An error occurred while retrieving token. ', err);
        });
    }

    listenForTokenRefresh(messaging) {
        const updateDeviceToken = (token) => this.updateDeviceToken(token)
        messaging.onTokenRefresh(function () {
            messaging.getToken().then(function (refreshedToken) {
                updateDeviceToken(refreshedToken);
            }).catch(function (err) {
                console.log('Unable to retrieve refreshed token ', err);
            });
        });
    }

    listenForMessage = () => {
        const handleMessages = (payload) => this.handleMessages(payload)
        try {
            navigator.serviceWorker.addEventListener('message', function (event) {
                console.log('Received a message from service worker: ', event);
                const messageForegroundPayload = _.get(event, 'data.firebaseMessaging.payload.data.data', null)
                const messageBackgroundPayload = _.get(event, 'data.data.data', null)
                handleMessages(messageForegroundPayload || messageBackgroundPayload)
            });
        } catch (error) {
            console.log(error);
        }
    }

    handleMessages(payload = {}) {
        try {
            const data = JSON.parse(payload)
            console.log('new message str', payload)
            console.log('new message json data', data)
            const notiBody = _.get(data, 'body', '')
            const notiTitle = _.get(data, 'title', '')
            if (notiBody && notiTitle) {

                // disable below to have consist behavior between web1 and web2
                // notification.open({
                //     message: notiTitle,
                //     description: notiBody,
                //     onClick: () => this.handleMessagesClick(notiData),
                // });

                this.props.setState({ notiRefresh: !this.props.notiRefresh })
            }
        } catch (e) { console.log(e) }

    }

    handleMessagesClick = (data) => {
        const orderId = _.get(data, 'notification.body.id', '')
        if (orderId) {
            this.props.push(`/account/orders/detail/${orderId}`)
        }
    }

    render() {
        return null
    }
}

const mapStateToProps = state => ({
    token: _.get(state, 'notifications.device_token', ''),
    provider: _.get(state, 'notifications.provider', ''),
    notiRefresh: _.get(state, 'notifications.notiRefresh', false),
})

const mapDispatchToProps = {
    setState: _action.setNotificationsState,
    updateDeviceToken: _action.updateDeviceToken,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseNotifications);
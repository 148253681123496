function fetchAddressList() {
  return {
    type: "ADDRESS_BOOK__FETCH_ADDRESS_LIST"
  }
}

function saveAddress(params) {
  return {
    type: "ADDRESS_BOOK__SAVE_ADDRESS",
    payload: params
  }
}

function deleteAddress(params) {
  return {
    type: "ADDRESS_BOOK__DELETE_ADDRESS",
    payload: params
  }
}

function setDefaultAddress(params) {
  return {
    type: "ADDRESS_BOOK__SET_DEFAULT_ADDRESS",
    payload: params
  }
}

export default {
  fetchAddressList,
  saveAddress,
  deleteAddress,
  setDefaultAddress,
}
function removeBoomark(params) {
  return {
    type: "BOOKMARKS__REMOVE_BOOKMARK",
    payload: {
      type: params.type, // 'store', 'product', 'groupsale'
      id: params.id, // id of store, product or groupsale
    }
  }
}

export default {
  removeBoomark
}
import { put, takeEvery, select, call } from 'redux-saga/effects';
// import _config from 'config';
import _helper from '../../utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _config from 'config';
import { getLanguage, getAuthState, getCartState } from 'containers/app/reducers'
import _ from 'lodash'
import _pageHelper from './helper'

function* initCheckoutPage(action) {
	yield put({ type: "CHECKOUT_PAGE__SET_STATE", state: { isInitialized: false } })
	try {
		let { gid } = action.payload;
		let lan = yield select(getLanguage);
		let authState = yield select(getAuthState);
		let url = _config['API_ROOT'] + _apiRoutes['store-detail'];

		let searchParams = {
			gid,
			lan,
		}
		url = _helper.objToQuery(url, searchParams);
		const storeResponse = yield call(_api.get, url);

		if (storeResponse.RC === 200) {
			yield put({ type: "CHECKOUT_PAGE__INIT_CHECKOUT_PAGE_SUCCESS", response: { storeData: storeResponse.records, customerData: authState.userProfile }, payload: { ...action.payload } });
		} else {
			yield put({ type: "CHECKOUT_PAGE__INIT_CHECKOUT_PAGE_FAILED", payload: action.payload });
		}
	} catch (e) {
		yield put({ type: "CHECKOUT_PAGE__INIT_CHECKOUT_PAGE_FAILED", message: e.message, payload: action.payload });
	}
	yield put({ type: "CHECKOUT_PAGE__SET_STATE", state: { isInitialized: true } })
}

function* fetchCartTotal(action) {
	try {
		yield put({ type: "CHECKOUT_PAGE__SET_STATE", state: { getQuoteLoading: true } })
		let { gid, qid, cart, address, shippingMethod, coupon } = action.payload;
		let lan = yield select(getLanguage);
		let cartState = yield select(getCartState);
		let url = _apiRoutes['cart-total'];

		let parsedItems = cart.map((cartItem) => {
			let parsedData = {
				pid: cartItem.pid,
				qty: cartItem.qty,
				pc: cartItem.pc
			}
			if (cartItem.options) {
				parsedData.options = Object.keys(cartItem.options).map((optionKey) => {
					return {
						"Item1": optionKey,
						"Item2": cartItem.options[optionKey]
					}
				})
			}
			return parsedData
		})

		let postBody = {
			gid,
			items: parsedItems,
			shp_mtd: shippingMethod,
			cpn: coupon,
			tax_class: _.get(cart, '0.taxClass', null)
		}

		if (shippingMethod == 1 || shippingMethod == 4 || shippingMethod == 5) {
			postBody.addr = address
		}

		const cartTotalResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, "POST", { data: postBody });
		yield put({ type: "CHECKOUT_PAGE__FETCH_CART_TOTAL_SUCCESS", response: { cartTotalResponse }, payload: { ...action.payload, productList: cartState.productList[gid] } });
	} catch (e) {
		yield put({ type: "CHECKOUT_PAGE__FETCH_CART_TOTAL_FAILED", message: e.message, payload: action.payload });
	}
}

function* checkout(action) {
	try {
		yield put({ type: "CHECKOUT_PAGE__SET_STATE", state: { getQuoteLoading: true } })
		let { paymentMethod, creditCard, selectedShippingType,
			shippingMethod, storeInstance, billingAddress,
			deliveryDisplayDate,
			firstName, lastName, phone, priceInfo,
			pointsUsed, orderComment, selectedGiftCard,
			partySize, tableNumber, qid } = action.payload;
		const relatedOid = yield select((state) => { return _.get(state, `shopping-cart.cartList.${_pageHelper.getGid()}.relatedOid`) });
		let url = _apiRoutes['all-in-one-order'];

		let exp_dt_start = ''
		let exp_dt_end = ''

		if (deliveryDisplayDate && (typeof deliveryDisplayDate === 'string' || deliveryDisplayDate instanceof String)) {
			if (deliveryDisplayDate.includes('ASAP')) {
				exp_dt_start = "2000-01-01 00:00"
			} else {
				let dateTimes = deliveryDisplayDate.split(' ')
				let date = dateTimes[0]
				let timesStr = dateTimes[1]
				let times = timesStr.split('-')
				let startTime = times[0]
				let endTime = times[1]
				exp_dt_start = date + ' ' + startTime;
				exp_dt_end = date + ' ' + endTime;
			}
		}

		let postBody = {
			pay_mtd: paymentMethod,
			card: creditCard,
			shp_mtd: shippingMethod,
			shp_cd: selectedShippingType,
			c_id: storeInstance.data['c_id'],
			g_id: storeInstance.data['group_id'],
			adr_lst: [{
				...billingAddress,
				"type": 1,
			}],
			c_fnm: firstName,
			c_lnm: lastName,
			c_tn: phone,
			tips: priceInfo.tips ? Math.abs(priceInfo.tips) : 0,
			pt_amt: pointsUsed,
			ord_cmt: orderComment,
			gc_cd: selectedGiftCard ? selectedGiftCard.gc : '',
			pt_cnt: partySize,
			tb_num: tableNumber,
			exp_dt_start: exp_dt_start,
			exp_dt_end: exp_dt_end,
			q_id: qid,
			device: "h5",
			related_order_id: relatedOid,
			is_shared_domain: _helper.getIndependentDomain() ? 0 : 1
		}

		const checkoutResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, "POST", { data: postBody });


		yield put({ type: "CHECKOUT_PAGE__CHECKOUT_SUCCESS", response: { checkoutResponse }, payload: { ...action.payload } });

		if (checkoutResponse.RC == 200) {
			yield put({
				type: "SHOPPING_CART__SET_CART_ITEMS", payload: {
					gid: storeInstance.data['group_id'],
					items: null
				}
			});
		}
	} catch (e) {
		yield put({ type: "CHECKOUT_PAGE__CHECKOUT_FAILED", message: e.message, payload: action.payload });
	}
}



export default [
	takeEvery('CHECKOUT_PAGE__INIT_CHECKOUT_PAGE', initCheckoutPage),
	takeEvery('CHECKOUT_PAGE__FETCH_CART_TOTAL', fetchCartTotal),
	takeEvery('CHECKOUT_PAGE__CHECKOUT', checkout),
];


import React, { Component } from 'react';
import _config from 'config';
import _helper from '../../../utils/helper';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Divider, Row, Col } from 'antd';
import _ from 'lodash'
import { connect } from 'react-redux';

class OrderTotals extends Component {

  getTotalBeforeTax = () => {
    const subTotal = _.get(this.props, 'orderDetailInstance.data.sub_total', 0)
    const totalBeforeTax = _.get(this.props, 'orderDetailInstance.data.total_before_tax', 0)
    return subTotal !== totalBeforeTax ? totalBeforeTax : 0
  }

  getItemTotal = () => {
    const itemTotal = _.get(this.props, 'orderDetailInstance.data.item_ttl', 0)
    const showTotal = this.getStoreSurcharge().value || this.getOrderSurcharge().value
    return showTotal ? itemTotal : 0;
  }

  getStoreSurcharge = () => {
    const storeSurcharge = _.get(this.props, 'orderDetailInstance.data.store_surcharge', {});
    return {
      label: _.get(storeSurcharge, 'label', '') || 'store_surcharge',
      value: _.get(storeSurcharge, 'value', 0)
    }
  }

  getOrderSurcharge = () => {
    const orderSurcharge = _.get(this.props, 'orderDetailInstance.data.order_surcharge', {});
    const inTotal = _.get(orderSurcharge, 'is_separate', 0) === 0
    return {
      label: _.get(orderSurcharge, 'label', '') || 'order_surcharge',
      value: inTotal ? _.get(orderSurcharge, 'value', 0) : 0
    }
  }

  getTaxes = () => {
    return _.get(this.props, 'orderDetailInstance.data.taxes', {})
  }

  getTotalData = () => {
    let data = {
      itemTotal: {
        size: "normal",
        title: "item_total",
        number: this.getItemTotal()
      },
      storeSurcharge: {
        size: "normal",
        title: this.getStoreSurcharge().label,
        number: this.getStoreSurcharge().value
      },
      orderSucharge: {
        size: "normal",
        title: this.getOrderSurcharge().label,
        number: this.getOrderSurcharge().value
      },
      subtotal: {
        size: "normal",
        title: "subtotal",
        number: "sub_total"
      },
      storeDiscount: {
        size: "normal",
        title: "store_discount",
        number: "store_discount",
        prefix: "-",
      },
      couponDiscount: {
        size: "normal",
        title: "coupon_discount",
        number: "coupon_discount",
        prefix: "-"
      },
      giftCardPayment: {
        size: "normal",
        title: "gift_card_payment",
        number: "giftcard_payment",
        prefix: "-"
      },
      pointsPayment: {
        size: "normal",
        title: "points_payment",
        number: "points_payment",
        prefix: "-"
      },
      deliveryFee: {
        size: "normal",
        title: "shipping_and_handling",
        number: "shipping_fee",
      },
      totalBeforeTax: {
        size: "normal",
        title: "total_before_tax",
        number: this.getTotalBeforeTax(),
      },
    }

    //tax objects
    const taxes = this.getTaxes()
    if (!_.isEmpty(taxes)) {

      Object.keys(taxes).map((key) => {
        const value = parseFloat(taxes[key])
        if (!Number.isNaN(value)) {
          data[key] = {
            size: "normal",
            title: key,
            number: taxes[key]
          }
        }
      })
    } else { //to keep to work with pr, 
      data.tax = {
        size: "normal",
        title: "tax",
        number: "tax",
      }
    }

    data.tips = {
      size: "normal",
      title: "tips",
      number: "tips",
    }

    data.orderTotal = {
      size: "large",
      title: "order_total",
      number: "order_total",
    }

    return data
  }

  getTotals = () => {
    let { orderDetailInstance } = this.props;
    return Object.keys(this.getTotalData()).map((totalKey, i) => {
      let { size, title, number, prefix } = this.getTotalData()[totalKey];
      let validInfo = Number.isNaN(parseFloat(number)) ? _.get(orderDetailInstance, `data.${number}`, 0) : number
      if (validInfo) {
        return (
          <div
            key={`order-total-${totalKey}-${i}`}
            className={classnames({
              "totals-item": true,
              "large": size === "large"
            })}>
            <div className={classnames({
              "totals-name": true,
              "light": title !== "order_total",
            })}>
              <FormattedMessage id={title} />
            </div>
            <div className="totals-price">
              <h4 className={classnames({
                "large": size === "large"
              })}>
                {_helper.formatCurrency(validInfo, orderDetailInstance.data['currency'], prefix ? prefix : '')}
              </h4>
            </div>
          </div>
        )
      }
    })
  }

  render() {
    let {
      orderDetailInstance
    } = this.props;

    return (
      <div className="box order-items-wrapper">
        {orderDetailInstance.data['order_item'].map((item, i) => {
          return (
            <div className="box-section" key={`product-item-${i}`}>
              <div >
                <Row className="order-item-header" gutter={8}>
                  <Col span={14} className="item-name"><h3>{item.nm}</h3></Col>
                  <Col span={4} className="single-item-price"><h5>{_helper.formatCurrency(item.pc, orderDetailInstance.data['currency'])}</h5></Col>
                  <Col span={2} className="cnt"><h5>x {item.cnt}</h5> </Col>
                  <Col span={4} className="item-price"><h4>{_helper.formatCurrency(item.amt, orderDetailInstance.data['currency'])}</h4></Col>
                </Row>
              </div>
              {item.opts && item.opts.length
                ? <div>
                  {item.opts.map((optionCat, i) => {
                    let optStr = optionCat['nm'];
                    let optItems = [];
                    if (optionCat.opts && optionCat.opts.length) {
                      optItems = optionCat.opts.map((optItem) => optItem.nm.trim())
                    }
                    if (optItems && optItems.length) {
                      optStr += ` - ${optItems.join(', ')}`
                    }
                    return (
                      <div key={`product-optionCat-${i}`}>{optStr}</div>
                    )
                  })}
                </div>
                : ''}
            </div>
          )
        })}
        <div className="box-section totals-wrapper">
          {this.getTotals()}

          {orderDetailInstance.data['total_due']
            ? <div>
              <Divider style={{ margin: '10px 0' }} />
              <div className="totals-item large">
                <div className="totals-name"><FormattedMessage id="total_due" /></div>
                <div className="totals-price"><h4 className="large">{_helper.formatCurrency(orderDetailInstance.data['total_due'], orderDetailInstance.data['currency'])}</h4></div>
              </div>
            </div>
            : null}

          {orderDetailInstance.data['refund_amt']
            ? <div>
              <Divider style={{ margin: '10px 0' }} />
              <div className="totals-item large">
                <div className="totals-name"><FormattedMessage id="refunded" /></div>
                <div className="totals-price"><h4 className="large">{_helper.formatCurrency(orderDetailInstance.data['refund_amt'], orderDetailInstance.data['currency'])}</h4></div>
              </div>
            </div>
            : null}

        </div>
      </div>

    )
  }
}
const mapStateToProps = (state) => ({
  lan: _.get(state, "settings.lan", 'en')
})

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTotals);

import { call, put, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _addressBookActions from 'containers/address-book/actions';
import { message } from 'antd';


function* fetchAddressList(action) {
  try {
    yield put({ type: "ADDRESS_BOOK__SET_STATE", state: { addressListLoading: true } });

    let url = _apiRoutes['user-address'];
    let addressListResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'GET');
    yield put({ type: "ADDRESS_BOOK__FETCH_ADDRESS_LIST_SUCCESS", response: { addressListResponse }, payload: { ...action.payload } });
  } catch (e) {
    yield put({ type: "ADDRESS_BOOK__FETCH_ADDRESS_LIST_FAILED" });
  }
}

function* saveAddress(action) {
  try {
    yield put({ type: "ADDRESS_BOOK__SET_STATE", state: { saveAddressLoading: true } });

    let { id, firstName, lastName, phone, street, buzz, unit, country, region, city, zipCode, is_default } = action.payload;
    let url = _apiRoutes['user-address'];
    let postBody = {
      id,
      fn: firstName,
      ln: lastName,
      tn: phone,
      cntry: country,
      region,
      zipcd: zipCode,
      city,
      street,
      buzz,
      unit,
      is_default,
    }

    let addressResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', postBody);
    yield put(_addressBookActions.fetchAddressList())
    yield put({ type: "ADDRESS_BOOK__SAVE_ADDRESS_SUCCESS", response: { addressResponse }, payload: { ...action.payload } });
  } catch (e) {
    yield put({ type: "ADDRESS_BOOK__SAVE_ADDRESS_FAILED" });
  }
}

function* deleteAddress(action) {
  try {
    let { id } = action.payload;
    let url = _apiRoutes['user-address'];
    url += `/${id}`

    let addressResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'DELETE');
    yield put(_addressBookActions.fetchAddressList())
    yield put({ type: "ADDRESS_BOOK__DELETE_ADDRESS_SUCCESS", response: { addressResponse }, payload: { ...action.payload } });
  } catch (e) {
    yield put({ type: "ADDRESS_BOOK__DELETE_ADDRESS_FAILED" });
  }
}

function* setDefaultAddress(action) {
  try {
    yield put({ type: "ADDRESS_BOOK__SET_STATE", state: { saveAddressLoading: true } });
    let { addressId, isDefault } = action.payload;
    let url = _apiRoutes['user-address'];

    let addressResponse = yield _api.callCheckingAuthentication(_api.executeOauth, url, 'POST', { is_default: isDefault, id: addressId })

    yield put(_addressBookActions.fetchAddressList())
    yield put({ type: "ADDRESS_BOOK__SAVE_ADDRESS_SUCCESS", response: { addressResponse }, action: { ...action.payload } })
  } catch (e) {
    yield put({ type: "ADDRESS_BOOK__DELETE_ADDRESS_FAILED", message: e.message, action: action })
  }
}

export default [
  takeEvery('ADDRESS_BOOK__FETCH_ADDRESS_LIST', fetchAddressList),
  takeEvery('ADDRESS_BOOK__SAVE_ADDRESS', saveAddress),
  takeEvery('ADDRESS_BOOK__DELETE_ADDRESS', deleteAddress),
  takeEvery('ADDRESS_BOOK__SET_DEFAULT_ADDRESS', setDefaultAddress)
];


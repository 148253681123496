import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'
import { Input } from 'antd';

const { TextArea } = Input;

const COMMENT_MAX_LENGTH = 140

class CustomerCommentInput extends React.Component {
    static defaultProps = {

    }

    state = {}

    getValue = () => {
        return _.get(this.props, 'state.comment', '')
    }

    handleChange = (e) => {
        this.props.setState({ comment: e.target.value })
    }

    getInput = () => {
        return (
            <TextArea
                maxLength={COMMENT_MAX_LENGTH}
                allowClear
                className="self-checkout-customer-comment-input-input"
                rows={4}
                value={this.getValue()}
                onChange={e => this.handleChange(e)}
            />
        )
    }

    getCountingIndicator = () => {
        return (<div className="self-checkout-customer-comment-input-counting-indicator">
            {this.getValue().length}/{COMMENT_MAX_LENGTH}
        </div>)
    }

    render() {
        return (
            <div className="self-checkout-customer-comment-input-container" >
                {this.getInput()}
                {this.getCountingIndicator()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: _.get(state, 'self-checkout', true),
    lan: _.get(state, 'settings.lan', true),
})

const mapDispatchToProps = ({
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerCommentInput))
import storeDetail from './store-detail';
import orderDetail from './order-detail';
import giftCardDetail from './gift-card-detail';
import groupSale from './group-sale.jsx'

export default {
  storeDetail,
  orderDetail,
  giftCardDetail,
  groupSale
}
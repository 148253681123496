import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Icon, Input, List, } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_namesModal.scss'

class NamesModal extends Component {

    state = {
        isFirstNameExisted: true,
    }

    onOkClick = () => {
        let {
            onCloseClick,
            onOkClick
        } = this.props;

        let {
            firstNameInput,
            lastNameInput,
        } = this.state

        if (firstNameInput) {
            onOkClick(firstNameInput, lastNameInput ? lastNameInput : '');
            onCloseClick();
        } else {
            this.setState({
                isFirstNameExisted: false
            })
        }

    }


    render() {
        let {
            displayModal,
            onCloseClick,
            intl,
        } = this.props;

        let {
            firstNameInput,
            lastNameInput,
            isFirstNameExisted,
        } = this.state

        let { formatMessage } = intl;

        let data = [
            {
                placeholder: formatMessage({ id: 'first_name' }),
                value: firstNameInput,
                onChange: (e) => { this.setState({ firstNameInput: e.target.value, isFirstNameExisted: true }) },
                onEmptyClick: () => {
                    this.firstNameInput.focus();
                    this.setState({ firstNameInput: '' });
                },
                ref: node => this.firstNameInput = node,
                require: true,
            },
            {
                placeholder: formatMessage({ id: 'last_name' }),
                value: lastNameInput,
                onChange: (e) => { this.setState({ lastNameInput: e.target.value }) },
                onEmptyClick: () => {
                    this.lastNameInput.focus();
                    this.setState({ lastNameInput: '' });
                },
                ref: node => this.lastNameInput = node,
                require: false,
            },
        ]

        return (
            <Modal
                title={formatMessage({ id: 'name' })}
                visible={displayModal}
                onOk={() => this.onOkClick()}
                onCancel={onCloseClick}
                okText={formatMessage({ id: 'ok' })}
                cancelText={formatMessage({ id: 'cancel' })}
            >
                <div className="names-modal-container" >
                    {!isFirstNameExisted ?
                        <div className="missing-item-message">
                            <div> <Icon type="exclamation-circle" /> </div>
                            <div> &nbsp; <FormattedMessage id="please_input_belows" /> </div>
                        </div>
                        : ''}
                    <List
                        size="large"
                        bordered
                        dataSource={data}
                        renderItem={item =>
                            (<List.Item>
                                <Input
                                    className={classnames({
                                        'missing-item': item.require && !isFirstNameExisted
                                    })}
                                    placeholder={item.placeholder}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    suffix={item.value ? <Icon type="close-circle" onClick={item.onEmptyClick} /> : null}
                                    value={item.value}
                                    onChange={item.onChange}
                                    ref={item.ref}
                                />
                            </List.Item>)}
                    />
                </div>
            </Modal>
        )
    }
}


export default injectIntl(NamesModal);
import React, { Component } from 'react';
import _ from 'lodash';
import _config from 'config';
import _helper from 'utils/helper';
import { Modal, Icon } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import './_paymentModal.scss'
import PaymentMethodCollapse from 'containers/checkout-page/page-components/paymentInfo/paymentMethodCollapse'
import checkoutPage from '../../..';

class PaymentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static defaultProps = {
        displayModal: false,
        reviewData: [],
    }

    componentDidUpdate(prevsProps, prevsState) {
        this.handleValidatedCc(prevsProps)
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    onOkClick = () => {
        let {
            onCloseClick,
            checkoutState,
            setCheckoutState,
        } = this.props;

        if (checkoutState.activePaymentKey != 2 || checkoutState.activePaymentKey == 2 && checkoutState.creditCardValidated) {  // payment method 2 credit_card
            setCheckoutState('paymentMethod', checkoutState.activePaymentKey)
            onCloseClick();
        } else {
            this.setState({ validate: !this.state.validate })
        }
    }

    handleValidatedCc = (prevsProps) => {
        let {
            onCloseClick,
            setCheckoutState,
            checkoutState
        } = this.props;

        if (prevsProps.checkoutState.creditCardValidating != checkoutState.creditCardValidating && checkoutState.creditCardValidated) {
            setCheckoutState('paymentMethod', checkoutState.activePaymentKey)
            onCloseClick();
        }
    }

    render() {
        let {
            displayModal,
            onCloseClick,
            intl,

            lan,
            storeInstance,
            setCheckoutState,
            checkoutState,
        } = this.props;

        let {
            height,
            width,
        } = this.state

        let { formatMessage } = intl;

        return (
            <Modal
                title={formatMessage({ id: 'payment_method' })}
                visible={displayModal}
                onOk={() => this.onOkClick()}
                onCancel={onCloseClick}
                okText={formatMessage({ id: 'ok' })}
                cancelText={formatMessage({ id: 'cancel' })}
            >
                <div style={{ maxHeight: `${height * 0.7}px`, overflowY: 'auto', overflowX: 'hidden' }}
                    className="payment-modal-container" >
                    <PaymentMethodCollapse
                        validate={this.state.validate}
                        setCheckoutState={setCheckoutState}
                        checkoutState={checkoutState}
                        storeInstance={storeInstance}
                        lan={lan} />
                </div>
            </Modal>
        )
    }
}


export default injectIntl(PaymentModal);
import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import CommentInput from './add-comment-input'
import { Modal } from 'antd'

class AddCommentModal extends React.Component {

    str = (id, values) => { return this.props.intl.formatMessage({ id }, values) }

    shouldOpen = () => {
        return _.get(this.props, 'state.displayModalName', '') === 'add_comment'
    }

    onConfimClick = () => {
        this.closeModel()
    }

    closeModel = () => {
        this.props.setState({ 'displayModalName': false })
    }

    getDialogContent = () => {
        return (
            <CommentInput />
        )
    }

    render() {
        return (
            <div >
                <Modal
                    title={this.str('add_comment')}
                    visible={this.shouldOpen()}
                    onCancel={() => this.onConfimClick()}
                    footer={null}
                >
                    <div className="self-checkout-add-comment-dialog-container">
                        {this.getDialogContent()}
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    state: _.get(state, 'self-checkout', {}),
})

const mapDispatchToProps = {
    setState: (state) => ({ type: 'SELF_CHECKOUT__SET_STATE', state }),
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddCommentModal));
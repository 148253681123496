import React, { Component } from 'react';
// import _config from 'config';
// import _helper from 'utils/helper';
import GoogleMapReact from 'google-map-react';
import MapMarker from 'components/map-marker';
import { Icon } from 'antd';
import _helper from 'utils/helper';

class StoreInfo extends Component {
  render() {
    let {
      push,
      orderDetailInstance
    } = this.props;

    return (
      <div className="box">
        <div className="box-section">
          <h3 className="link store-name" onClick={() => { /* temp until store page is written in web_v2 */  window.location.href = _helper.getIndependentDomain() ? `/` : `/store/${orderDetailInstance.data['group_id']}` }}>{orderDetailInstance.data['store_name']}</h3>
          <div>{orderDetailInstance.data['store_address_formatted'].map(str => <div key={`store-${str}`}>{str}</div>)}</div>
          {orderDetailInstance.data['store_phone']
            ? <div style={{ paddingTop: '5px' }}>
              <Icon type="phone" theme="filled" style={{ paddingRight: '3px', color: "#333333" }} />
              <a
                href={`tel:${orderDetailInstance.data['store_phone']}`}
                className="link">{_helper.formatPhoneNumber(orderDetailInstance.data['store_phone'])}</a>
            </div>
            : ''}
        </div>
        {orderDetailInstance.data.store_address && orderDetailInstance.data.store_address['lat'] && orderDetailInstance.data.store_address['lon']
          ? <div className="store-map-wrapper">
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
              defaultCenter={[
                orderDetailInstance.data.store_address['lat'],
                orderDetailInstance.data.store_address['lon']
              ]}
              center={[
                orderDetailInstance.data.store_address['lat'],
                orderDetailInstance.data.store_address['lon']
              ]}
              zoom={11}
            >
              <MapMarker
                lat={orderDetailInstance.data.store_address['lat']}
                lng={orderDetailInstance.data.store_address['lon']}
              />
            </GoogleMapReact>

          </div> : ''}
      </div>
    )
  }
}

export default StoreInfo;
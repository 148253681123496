import React, { Component } from 'react';
import _config from 'config';
import _helper from 'utils/helper';
import { Slider, InputNumber, Checkbox, Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import './_groupsaleFilters.scss'

class GroupSaleFilter extends Component {

    state = {
        inputValue: 0,
        checkboxValue: '',
    }


    onSliderChange = (value) => {
        let {
            onDiscountChange,
            onDiscountClear
        } = this.props;
        this.setState({
            inputValue: value,
        });
        if (value == 0) {
            onDiscountClear()
        } else {
            onDiscountChange(value)
        }

    }

    onCheckBoxChange = (value) => {
        let {
            onAmtSoldChange
        } = this.props;
        if (value == this.state.checkboxValue) {
            value = ' '
        }
        this.setState({
            checkboxValue: value,
        });
        onAmtSoldChange(value)
    }

    render() {

        let {
            currentAmtSound,
            currentDiscount,
        } = this.props;

        let amountSoldCheckBoxes = [
            '>50', '>200', '>500', '>1000'
        ]

        return (
            <div className="category-filter-container">
                <div>
                    <h4 className="filter-title"><FormattedMessage id="discount_amount" /></h4>
                    <Slider
                        className="slider"
                        step={10}
                        max={60}
                        onChange={this.onSliderChange}
                        value={currentDiscount == undefined ? 0 : currentDiscount} />
                    <div className="slider-input">
                        <span><FormattedMessage id="discount_greater" /></span>
                        <InputNumber
                            className="input"
                            min={0}
                            max={60}
                            step={10}
                            value={currentDiscount == undefined ? 0 : currentDiscount}
                            onChange={this.onSliderChange}
                        />
                        <span>%</span>
                    </div>
                </div>
                <div>
                    <h4 className="filter-title"><FormattedMessage id="amount_sold" /></h4>
                    <Row style={{ width: '100%', margin: '10px 0' }}>
                        {amountSoldCheckBoxes.map((checkbox, i) => {
                            return (
                                <Col key={`check-item-${i}`} className="checkbox-item" span={24} >
                                    <Checkbox onChange={() => this.onCheckBoxChange(checkbox)} checked={currentAmtSound == checkbox}>{checkbox}</Checkbox>
                                </Col>
                            )
                        })}
                    </Row>
                </div>



            </div>
        )
    }
}


export default injectIntl(GroupSaleFilter);
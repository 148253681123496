import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _config from 'config';
import _helper from 'utils/helper';
import { List, Pagination, Modal, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import GroupSaleLayout from 'containers/groupsale-page/page-components/groupsaleLayout'
import GroupSaleCell from 'containers/groupsale-page/page-components/grouponCell/index.jsx'
import CatListBar from 'containers/groupsale-page/page-components/catListBar'
import TopAdsBar from 'containers/groupsale-page/page-components/top-ads'
import './_groupsaleLanding.scss'
import _actions from 'containers/groupsale-page/actions.jsx'

class GroupSalePage extends Component {

  state = {
    currentPage: 1,
    sortId: 106, //default front page 106	BEST_SELLING 
    topThreeSortId: 103, //top 3 items 103 RANKING ;
  }

  componentDidMount() {
    this.initGroupSaleData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lan != this.props.lan) {
      this.initGroupSaleData();
    }
  }

  initGroupSaleData() {
    let { initGroupSaleBrowser, lan, userLat, userLon, appConfigState } = this.props;
    let { sortId, topThreeSortId } = this.state;
    initGroupSaleBrowser({
      lan: lan,
      lat: userLat ? userLat : appConfigState.lat,
      lon: userLon ? userLon : appConfigState.lon,
      sortId: sortId,
      topThreeSortId: topThreeSortId,
    });
    this.fetchGroupSaleList()
  }

  onPaginationChange = (page) => {
    this.setState({
      currentPage: page,
    });
    this.fetchGroupSaleList(page)
  }

  fetchGroupSaleList(page = this.state.currentPage) {
    let { fetchGroupSaleList, lan, userLat, userLon, appConfigState } = this.props;
    let { sortId } = this.state;
    fetchGroupSaleList({
      lan: lan,
      lat: userLat ? userLat : appConfigState.lat,
      lon: userLon ? userLon : appConfigState.lon,
      sortId: sortId,
      // page: page,
    });
  }


  pushToOtherPage = (params) => {
    let { push, selectCat } = this.props
    let url = '/deals/browse'
    if (params) {
      url += "?"
      if (params.query) {
        url += `query=${_helper.reformatSearchString(params.query)}`
      }
      if (params.category) {
        url += `category=${params.category.id}`
      }
    }

    push(url)
  }

  pushToDetail = (id) => {
    let {
      push
    } = this.props
    push(`/deals/details/${id}`)
  }

  render() {
    let {
      push,
      intl,
      userProfile,

      gsShortcut,
      gsList,
      gsListPaging,
      gsTop3List,
      catList,
    } = this.props;

    let { formatMessage } = intl;

    let topThreeItems = gsTop3List && gsTop3List.length > 3 ? gsTop3List.slice(0, 3) : gsTop3List

    return [
      <GroupSaleLayout
        onShortCutClick={(value) => this.pushToOtherPage({ query: value })}
      >
        <div className="group-sale-landing-container">

          <div className="welcomeMessage">
            <span> <b>{formatMessage({ id: "welcomeMessage1" }, { who: userProfile && userProfile.first_name ? userProfile.first_name : formatMessage({ id: 'there' }) })}</b></span>
            <span> <FormattedMessage id="welcomeMessage2" /></span>
          </div>

          <List
            className="top-three-container"
            grid={{ gutter: 16, column: 3 }}
            dataSource={topThreeItems}
            renderItem={(item, i) => (
              <List.Item key={i}>
                <GroupSaleCell
                  onCellClick={() => this.pushToDetail(item.id)}
                  key={`top-three-cell-${i}`}
                  cellData={item} />
              </List.Item>
            )} />
          <CatListBar
            catList={catList}
            onCatClick={(value) => this.pushToOtherPage({ category: value })} />
          <div className="listTitle">
            <span> <FormattedMessage id="pop_on_groupter" /></span>
          </div>
          <List
            className="pop-list-container"
            grid={{ gutter: 16, column: 4 }}
            dataSource={gsList}
            renderItem={(item, i) => (
              <List.Item key={i}>
                <GroupSaleCell
                  key={`pop-list-cell-${i}`}
                  cellData={item}
                  onCellClick={() => this.pushToDetail(item.id)} />
              </List.Item>
            )} />
          <Pagination className="pagination" currentPage={this.state.currentPage} onChange={this.onPaginationChange} total={gsListPaging.total} />
        </div>
      </GroupSaleLayout>
    ]
  }
}

const mapStateToProps = (state) => {
  let authState = state['auth']
  let settingState = state['settings']
  let locationsState = state['locations']
  let appConfigState = state['app-config']
  let groupSaleState = state['group-sale']
  return {
    userProfile: authState.userProfile,
    lan: settingState.lan,
    userLat: locationsState.userLat,
    userLon: locationsState.userLon,
    appConfigState: appConfigState,

    gsList: groupSaleState.gsList,
    gsListPaging: groupSaleState.gsListPaging,
    gsTop3List: groupSaleState.gsTop3List,
    catList: groupSaleState.catList,
    gsShortcut: groupSaleState.gsShortcut,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    initGroupSaleBrowser: bindActionCreators((params) => {
      return _actions.initGroupSaleBrowser(params)
    }, dispatch),
    fetchGroupSaleList: bindActionCreators((params) => {
      return _actions.fetchGroupSaleList(params)
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GroupSalePage));
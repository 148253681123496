import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import { push } from 'connected-react-router';

class PayNowButtons extends React.Component {

    state = {}

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : ' ' }, values)

    componentDidUpdate(prevsProps) {
        this.checkShowModal(prevsProps)
    }

    checkShowModal = (prevsProps) => {
        const showUpdate = prevsProps.showModal !== this.props.showModal
        if (showUpdate) {
            this.props.showModal ? this.renderConfirmModel() : Modal.destroyAll()
        }
    }

    renderConfirmModel = () => {
        Modal.error({
            title: this.str(this.props.modalTitle),
            content: this.str(this.props.modalContent, this.props.modalKeywords),
            okText: this.str('ok'),
            onOk: () => this.props.closeModal()
        })
    }

    render() {
        return null
    }
}

const mapStateToProps = state => ({
    lan: _.get(state, "settings.lan", 'en'),
    order: _.get(state, "order-detail.orderDetailInstance.data", {}),
    showModal: _.get(state, "order-detail.showModal", false),
    modalTitle: _.get(state, "order-detail.modalTitle", false),
    modalContent: _.get(state, "order-detail.modalContent", false),
    modalKeywords: _.get(state, "order-detail.modalKeywords", false),
})

const mapDispatchToProps = {
    push,
    closeModal: () => ({ type: "ORDER_DETAIL__HIDE_ERROR_DIALOG" })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayNowButtons)));
import _helper from 'utils/helper';
import _config from 'config';
import _ from 'lodash';

function addCurrentLatLon(params) {
  return {
    type: "LOCATIONS_ADD_CURRENT_LAT_LON",
    payload: {
      userLat: params.userLat,
      userLon: params.userLon
    }
  }
}

function addAddressComp(params) {
  return {
    type: "LOCATIONS_ADD_ADDRESS_COMP",
    payload: params
  }
}

function getGeoLocation() {
  return {
    type: "LOCATIONS_GET_GEOLOACTION",
  }
}

export default {
  addCurrentLatLon,
  addAddressComp,
  getGeoLocation,
}

import React, { Component } from 'react';
import _config from 'config';
import _revertConfig from 'config/config-reverse-map'
import _helper from 'utils/helper';
import { Collapse, Radio, message } from 'antd';
import { injectIntl, } from 'react-intl';
import { FaPaypal, FaMoneyBill } from "react-icons/fa";
import CreditCardDetail from 'containers/checkout-page/page-components/paymentInfo/credit-card-details'
import './_paymentMethodCollage.scss'
import { connect } from 'react-redux';
import _ from 'lodash'
//import payment method icons

import CashIcon from 'images/Cash.png';
import PayLaterIcon from 'images/Paylater.png';
import CreditCardIcon from 'images/CreditCard.png';
import WeChatPayIcon from 'images/Wechat_Pay.png';
import PaypalIcon from 'images/Paypal.png';
import AlipayIcon from 'images/Alipay.png';
import UnionPayIcon from '../../../../../images/UnionPay.png'
import UnionPayExpressIcon from '../../../../../images/UnionPayExpress.png'
import _pageHelper from '../../../helper'

const Panel = Collapse.Panel;

class PaymentMethodCollapse extends Component {

	state = {
		displayNamesModal: false,
		displayPhoneModal: false
	}

	callback = (key) => {
		let {
			setCheckoutState, checkoutState, intl
		} = this.props;

		let { formatMessage } = intl;

		if (checkoutState.displayPriceInfo.totalDue) {
			if (key) {
				setCheckoutState('activePaymentKey', key)
			}
		} else {
			message.destroy()
			if (checkoutState.selectedGiftCard && checkoutState.pointsUsed) {
				message.warning(formatMessage({ id: 'giftcard_points_pays_order_total' }))
			} else if (checkoutState.pointsUsed) {
				message.warning(formatMessage({ id: 'points_pays_order_total' }))
			} else {
				message.warning(formatMessage({ id: 'giftcard_pays_order_total' }))
			}
		}

	}

	componentDidUpdate(prevProps, presState) {
		let { checkoutState, setCheckoutState } = this.props
		if (prevProps.checkoutState.paymentMethod != checkoutState.paymentMethod && checkoutState.paymentMethod != checkoutState.activePaymentKey) {
			setCheckoutState('activePaymentKey', checkoutState.paymentMethod)
		}
	}

	isSelfCheckout = () => {
		const cart = _.get(this.props, 'cart', [])
		return Array.isArray(cart) && cart.findIndex((product) => { return product.selfCheckout }) !== -1
	}

	hasGiftCart = () => {
		const products = _.get(this.props, 'products', [])
		return Array.isArray(products) && !_.isEmpty(products.find(item => {
			return item.pt === 1
		}))
	}

	render() {
		let {
			storeInstance,
			intl,
			lan,
			validate,
			checkoutState,
			setCheckoutState,
		} = this.props;

		let { activePaymentKey } = checkoutState

		let { formatMessage } = intl;

		let { accept_alipay, accept_cash,
			accept_creditcard, accept_express,
			accept_paylater, accept_wechat,
			accept_union_pay, accept_union_pay_express,
		} = storeInstance.data

		const customPanelStyle = {
			background: 'white',
			borderRadius: 4,
			overflow: 'hidden',
		};

		let data = [
			{
				requirement: accept_cash && !this.isSelfCheckout(),
				header_icon: CashIcon,
				header: 'cash',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('cash'),
				icon: "wallet",
				iconSepcial: <FaMoneyBill size="5em" />,
				description: formatMessage({ id: 'cash_despcrition' })
			},
			{
				requirement: accept_paylater && !this.isSelfCheckout(),
				header_icon: PayLaterIcon,
				header: 'pay_later',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('pay_later'),
				icon: "wallet",
				description: formatMessage({ id: 'pay_later_despcrition' })
			},
			{
				requirement: accept_creditcard,
				header_icon: CreditCardIcon,
				header: 'credit_card',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('credit_card'),
				icon: "credit-card",
				specail: <CreditCardDetail
					lan={lan}
					validate={validate}
					checkoutState={checkoutState}
					setCheckoutState={setCheckoutState}
				/>
			},
			{
				requirement: accept_wechat && !this.hasGiftCart(),
				header_icon: WeChatPayIcon,
				header: 'wechat_pay',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('wechat_pay'),
				icon: "wechat",
				description: formatMessage({ id: 'wechat_pay_despcrition' })

			},
			{
				requirement: accept_express,
				header_icon: PaypalIcon,
				header: 'paypal_express',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('paypal_express'),
				icon: "wallet",
				iconSepcial: <FaPaypal size="5em" />,
				description: formatMessage({ id: 'paypal_expres_despcrition' })

			},
			{
				requirement: accept_alipay && !this.hasGiftCart(),
				header_icon: AlipayIcon,
				header: 'alipay',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('alipay'),
				icon: "alipay",
				description: formatMessage({ id: 'alipay_despcrition' })
			},
			{
				requirement: accept_union_pay,
				header_icon: UnionPayIcon,
				header: 'union_pay',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('union_pay'),
				description: formatMessage({ id: 'union_pay_despcrition' })
			},
			{
				requirement: accept_union_pay_express,
				header_icon: UnionPayExpressIcon,
				header: 'union_pay_express',
				id: _revertConfig.PAYMENT_METHOD_MAPPING('union_pay_express'),
				description: formatMessage({ id: 'union_pay_express_despcrition' })
			},
		]

		return (
			<Collapse
				activeKey={activePaymentKey}
				onChange={(value) => this.callback(value)}
				accordion>
				{
					data.map((each, i) => {
						if (each.requirement) return (
							<Panel
								showArrow={false}
								header={<div>
									<Radio
										onChange={() => this.callback(each.id)}
										checked={each.id == activePaymentKey}
									>
										<img className="header-img" src={each.header_icon} />
										<span className="header-text">
											{formatMessage({ id: each.header })}
										</span>
									</Radio>
								</div>}
								key={each.id}
								style={customPanelStyle}>
								{each.specail ? each.specail :
									<div className="collapse-panel-container">
										{/* <div className="icon"> 
																				{each.iconSepcial ? each.iconSepcial : <Icon style={{ fontSize: '5em' }} type={each.icon} />} 
																				</div> */}
										<div className="description"> {each.description}</div>
									</div>
								}
							</Panel>
						)
					})
				}
			</Collapse>
		)
	}
}


const mapStateToProps = state => ({
	cart: _.get(state, `shopping-cart.cartList.${_pageHelper.getGid()}.cart`, []),
	products: _.get(state, `shopping-cart.productList.${_pageHelper.getGid()}`, []),
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentMethodCollapse));
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Menu, Icon, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import _config from 'config';
import _helper from 'utils/helper';
import Img from 'react-image'
import classnames from 'classnames';
import _actionsSettings from 'containers/settings/actions'
import _actionsAuth from 'containers/auth/actions'

import './_navbar.scss';

const { SubMenu } = Menu;

class NavBar extends Component {
  handleChangeLanguage(lan) {
    let { changeLanguage } = this.props;

    changeLanguage({ lan });
  }

  goToOtherPage = () => {
    let { gid } = this.props
    if (gid && !_helper.getIndependentDomain()) {
      /* temp until store page is written in web_v2 */ window.location.href = `/store/${gid}`
    } else {
     /* temp until store page is written in web_v2 */ window.location.href = `/`
    }
  }

  render() {
    let { logout, logoUrl, logoText, userProfile, push, gid } = this.props

    return (
      <div className="navbar-container"
      >
        <div className={classnames({
          "logo-wrapper": true,
          "width": !logoText
        })} >
          {logoText ?
            <div onClick={() => this.goToOtherPage()} className="logo-text">{logoText}</div> :
            <Img
              className="logo"
              onClick={() => this.goToOtherPage()}
              src={`${_config['IMG_PREFIX']}${logoUrl}`}
              unloader={<Img className="logo"
                onClick={() => this.goToOtherPage()}
                src={"https://res.cloudinary.com/goopter/f_auto,q_auto,c_limit,c_fit/goopter_logo3_dyiuq6.png"}
              />}
            />
          }
        </div>
        <Menu
          mode="horizontal"
          style={{ lineHeight: '64px', width: '500px', display: 'flex', justifyContent: 'flex-end', background: '#f9f9f9' }}
          selectedKeys={["1", "2", "3"]}
        >
          {/* <Menu.Item key="2">nav 2</Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item> */}
          <SubMenu title={<span className="myaccount-selection" ><Icon type="setting" /><FormattedMessage id="language" /></span>}>
            {['en', 'zh', 'zh-Hant', 'jp'].map((lan, i) => {
              return <Menu.Item className="myaccount-language" key={lan} onClick={() => { this.handleChangeLanguage(lan) }}><FormattedMessage id={lan} /></Menu.Item>
            })}
          </SubMenu>
          {userProfile ?
            <Menu.Item onClick={() => logout()} >{<span className="myaccount-selection"><Icon type="logout" /><FormattedMessage id="logout" /></span>}</Menu.Item> :
            <Menu.Item onClick={() => push('/login')} >{<span className="myaccount-selection"><Icon type="login" /><FormattedMessage id="login" /></span>}</Menu.Item>
          }
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let settingsState = state['settings'];
  let authState = state['auth'];
  return {
    lan: settingsState.lan,
    userProfile: authState.userProfile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    changeLanguage: bindActionCreators((params) => {
      return _actionsSettings.changeLanguage({ lan: params.lan })
    }, dispatch),
    logout: bindActionCreators((params) => {
      return {
        type: "AUTH__TOGGLE_LOGOUT_DIALOG",
        payload: { type: 'normal', show: true }
      }
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
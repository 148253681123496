import _helper from 'utils/helper';
import _model from 'utils/model'
import _config from 'config/index.jsx'
import _revertConfig from 'config/config-reverse-map/'
import _ from 'lodash';
import _pageHelper from './helper'

export function getDefaultState() {
  return {
    getQuoteLoading: false,
    isInitialized: false,
    storeInstance: new _model.storeDetail(),
    firstName: '',
    lastName: '',
    shippingAddress: {},
    phone: '',
    billingAddress: null,
    paymentMethod: null,
    activePaymentKey: 0,
    qid: null,
    shippingMethod: null,
    shippingTypes: [],
    selectedShippingType: '',
    pointsBalance: 0,
    pointsUsed: 0,
    selectedGiftCard: null,
    gsid: null,
    allowedPeriods: {},
    deliveryDisplayDate: '',
    tableNumber: 1,
    partySize: 1,
    validPhone: true,
    priceInfo: {
      grandTotal: 0,
      tax: 0,
      discounts: 0,
      shippingFee: 0,
      pointsBalance: 0,
      couponDiscount: 0,
      storeDiscount: 0,
      tips: 0,
      subTotal: 0,
      tipsBtnSelectedValue: 0,
    },
    displayPriceInfo: {
      totalPaid: 0,
      totalDue: 0,
      giftcardPayment: 0,
      pointsPayment: 0,
    },
    creditCard: null,
    creditCardValidated: false,
    displayModal: false,
    displayModalTitle: '',
    displayModalContent: '',
    displayModalKeywords: {},
    displayModalType: '',
    displayModalHanldingType: '',
    displayModalName: '',
    confirmCheckoutSuccess: false,
  }
}

function initCheckoutPage(state, action, status) {
  let finalState = _.cloneDeep(state);

  finalState = getDefaultState()
  if (status) {
    // set up initial customer infor
    let { storeData, customerData } = action.response;
    finalState.storeInstance = new _model.storeDetail(storeData);
    if (customerData) {
      finalState.firstName = customerData['first_name']
      finalState.lastName = customerData['last_name']
      finalState.shippingAddress = customerData['defaultAddress']
      finalState.phone = customerData['phone']
      finalState.billingAddress = customerData['defaultAddress']
    }

    //set up initial payment method
    if (finalState.storeInstance) {

      let initialPaymentMethod = -1

      let paymentData = {
        accept_cash: "cash",
        accept_paylater: "pay_later",
        accept_wechat: "wechat_pay",
        accept_alipay: "alipay",
        accept_express: "paypal_express",
        accept_creditcard: "credit_card",
        accept_union_pay: "union_pay",
        accept_union_pay_express: "union_pay_express",
      }

      let allowedPayments = Object.keys(paymentData).map(key => {
        if (finalState.storeInstance.data[key]) {
          return key
        }
      })

      if (allowedPayments && allowedPayments.length == 1) {
        initialPaymentMethod = _revertConfig.PAYMENT_METHOD_MAPPING(paymentData[allowedPayments[0]])
      }

      finalState.paymentMethod = initialPaymentMethod
      finalState.activePaymentKey = initialPaymentMethod
      finalState.initialPaymentMethod = initialPaymentMethod
      finalState.countryCode = finalState.storeInstance.data.country
    }
  }

  finalState.isInitialized = true;
  return finalState;
}

function handleCartTotalResponse(state, action, status) {
  let finalState = _.cloneDeep(state);
  const store = _.cloneDeep(_.get(state, `storeInstance.data`, {}))

  if (status && action && action.response) {
    let { RC, records, message } = action.response.cartTotalResponse;
    let { shippingMethod, address, productList, coupon } = action.payload;
    finalState.shippingMethod = shippingMethod
    finalState.shippingAddress = address
    if (records) {
      let grantTotal = records.g_total ? records.g_total : 0
      let tax = records.tax ? records.tax : 0
      let discounts = records.dis_ttl ? records.dis_ttl : 0
      let couponDiscount = records.cpn_dis ? records.cpn_dis : 0
      let storeDiscount = records.store_dis ? records.store_dis : 0
      let pointsBalance = records.p_bal ? records.p_bal : 0
      finalState.qid = records.qid;
      finalState.priceInfo.grandTotal = parseFloat(grantTotal);
      finalState.priceInfo.tax = parseFloat(tax);
      finalState.priceInfo.discounts = parseFloat(discounts);
      finalState.priceInfo.couponDiscount = parseFloat(couponDiscount);
      finalState.priceInfo.storeDiscount = parseFloat(storeDiscount);
      finalState.priceInfo.surcharge = parseFloat(records.surcharge || 0);
      finalState.pointsBalance = parseFloat(pointsBalance);
      finalState.priceInfo.subTotal = parseFloat(grantTotal) + parseFloat(Math.abs(discounts)) - parseFloat(tax)
      finalState.priceInfo.tipsBtnSelectedValue = grantTotal > _pageHelper.getTipsSetting(store).cut ? _pageHelper.getDefaultTips(store, shippingMethod, true) : null
      finalState.priceInfo.tips = grantTotal <= _pageHelper.getTipsSetting(store).cut ? _pageHelper.getDefaultTips(store, shippingMethod, false) : 0
    }

    if (RC == 200) {
      //set up initial shipping fee
      if (shippingMethod == 1) { //1 == delivery
        // third pary shipping
        if (finalState.storeInstance.data['delivery_fee'] == -1) {

          if (!_.isEqual(finalState.shippingTypes, records['shp_mtd']) && records['shp_mtd']) {
            finalState.shippingTypes = records['shp_mtd']
            finalState.priceInfo.shippingFee = records['shp_mtd'][0]['methods'][0]['fee']
            finalState.selectedShippingType = records['shp_mtd'][0]['methods'][0]['code']
          } else if (!records['shp_mtd']) {
            finalState.shippingTypes = getDefaultState().shippingTypes
            finalState.priceInfo.shippingFee = 0;
            finalState.selectedShippingType = getDefaultState().selectedShippingType
          }

          // regular shipping
        } else {
          if (records['shp_fee']) {
            finalState.priceInfo.shippingFee = parseFloat(records.shp_fee);
          } else {
            // this should only happen if there are errors to getquote like no address provided or a change of address that is invalid or out of shipping range
            finalState.priceInfo.shippingFee = 0;
          }
          finalState.shippingTypes = getDefaultState().shippingTypes
          finalState.selectedShippingType = getDefaultState().selectedShippingType
        }
      } else {
        finalState.priceInfo.shippingFee = 0;
        finalState.shippingTypes = getDefaultState().shippingTypes
        finalState.selectedShippingType = getDefaultState().selectedShippingType
      }

      //set up allowed period
      //1 == delivery 2 == take out
      if ((shippingMethod == 1 && address || shippingMethod == 2) && records['allowed_periods'] && Object.keys(records['allowed_periods']).length) {

        if (!_.isEqual(finalState.allowedPeriods, records['allowed_periods'])) {
          let firstDate = Object.keys(records['allowed_periods'])[0];
          let firstDatePeriod = records['allowed_periods'][firstDate]
          // setting default delivery/pickup time to first date first time, usually the ASAP field.
          finalState.allowedPeriods = records['allowed_periods']
          //set display date to asap or full date of first available period
          if (firstDate && firstDatePeriod) {
            finalState.deliveryDisplayDate = firstDate + " " + firstDatePeriod[0];
          }

        }
      }

      //coupon applied result
      if (coupon) {
        if (!records.cpn_dis || records.cpn_dis == 0) {
          finalState.displayModal = true;
          finalState.displayModalTitle = 'coupon_invalid';
          finalState.displayModalContent = 'invalid_coupon';
          finalState.displayModalKeywords = { couponCode: coupon }
          finalState.displayModalType = 'error'
          finalState.displayModalHanldingType = 'continue'
        } else {
          finalState.displayModal = true;
          finalState.displayModalTitle = 'coupon_valid';
          finalState.displayModalContent = 'coupon_applied';
          finalState.displayModalKeywords = { couponCode: coupon }
          finalState.displayModalType = 'success'
          finalState.displayModalHanldingType = 'continue'
        }
      }

    } else if (RC == 206) {
      finalState.shippingFee = getDefaultState().shippingFee;
      finalState.shippingAddress = getDefaultState().shippingAddress;

      finalState.displayModal = true;
      finalState.displayModalTitle = 'distance_exceeded';
      finalState.displayModalContent = 'exceed_delivery_distance';
      finalState.displayModalHanldingType = 'continue';
      finalState.displayModalKeywords = { delivery_distance: finalState.storeInstance.data['max_delivery_distance'] };
      finalState.displayModalType = 'error'

    } else if (RC == 460) {
      finalState.shippingMethod = getDefaultState().shippingMethod;

      finalState.displayModal = true;
      finalState.displayModalType = 'error'
      finalState.displayModalTitle = 'error';
      finalState.displayModalContent = 'invalid_shipping_method';
      finalState.displayModalHanldingType = 'continue';

    } else if (RC == 207) {
      finalState.shippingFee = getDefaultState().shippingFee;
      finalState.shippingAddress = getDefaultState().shippingAddress;

      finalState.displayModal = true;
      finalState.displayModalType = 'error'
      finalState.displayModalTitle = 'error';
      finalState.displayModalContent = 'invalid_address';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 400) {

      if (message == "The input address is not valid") {
        finalState.shippingFee = getDefaultState().shippingFee;
        finalState.shippingAddress = getDefaultState().shippingAddress;

        finalState.displayModal = true;
        finalState.displayModalType = 'error'
        finalState.displayModalTitle = 'error';
        finalState.displayModalContent = 'invalid_address';
        finalState.displayModalHanldingType = 'continue';

      } else {
        finalState.displayModal = true;
        finalState.displayModalType = 'error';
        finalState.displayModalTitle = 'error';
        finalState.displayModalContent = 'item_error';
        finalState.displayModalHanldingType = 'back_to_store';

        localStorage.removeItem(`g${finalState.gid}`)
        localStorage.removeItem(`gs${finalState.gid}`)
        localStorage.removeItem(`gc${finalState.gid}`)
      }

    } else if (RC == 485) {
      // only returned for groupsale chechkouts as of August 13, 2018
      finalState.shippingFee = getDefaultState().shippingFee;
      finalState.shippingAddress = getDefaultState().shippingAddress;

      let allowedCountries = finalState.groupsaleInstance.data['shipping_rules'].map((country) => {
        return country.country_code;
      })
      finalState.displayModal = true;

      if (allowedCountries) {
        finalState.displayModalTitle = 'error';
        finalState.displayModalContent = 'shipping_country_text';
        finalState.displayModalKeywords = { countries: allowedCountries ? allowedCountries.join(', ') : '' };
        finalState.displayModalHanldingType = 'continue';
        finalState.displayModalType = 'error'
      }

    } else if (RC == 1000) {
      let oosItems = [];
      let products = Array.isArray(productList) ? productList : []
      Object.keys(records.oos).map((responsePid) => {
        products.map((cartItem) => {
          if (cartItem.pid == responsePid) {
            oosItems.push(cartItem.nm)
          }
        })
      })
      finalState.displayModal = true;
      finalState.displayModalTitle = 'error';
      finalState.displayModalContent = 'item_oos';
      finalState.displayModalHanldingType = 'back_to_store';
      finalState.displayModalKeywords = { oos_items: oosItems.join(', ') };
      finalState.displayModalType = 'error'
    } else {
      finalState.displayModal = true;
      finalState.displayModalTitle = 'error';
      finalState.displayModalContent = 'unexpected_error';
      finalState.displayModalHanldingType = 'back_to_store';
      finalState.displayModalType = 'error'
    }
    // turn off isLoading on first load of getQuote
  } else {
    console.log('hello', status, action, action.response)
    finalState.displayModal = true;
    finalState.displayModalTitle = 'error';
    finalState.displayModalContent = 'unexpected_error';
    finalState.displayModalHanldingType = 'back_to_store';
    finalState.displayModalType = 'error'
  }
  finalState.getQuoteLoading = false;
  return finalState;
}

function updateTips(state, action) {
  let finalState = _.cloneDeep(state);
  let { amount, type } = action.payload
  if (type == 'flat') {
    finalState.priceInfo.tips = amount ? amount : 0
    finalState.priceInfo.tipsBtnSelectedValue = null
  } else if (type === 'percent') {
    finalState.priceInfo.tipsBtnSelectedValue = amount
  }

  return finalState;
}

function updateDisplayPrice(state, action) {

  let finalState = _.cloneDeep(state);
  let { grandTotal, tax, discounts, shippingFee, subTotal } = finalState.priceInfo;

  let totalPaid = 0, pointsPaid = 0, giftCardPaid = 0, tips = 0, orderTotal = 0;

  //update tips 
  if (finalState.priceInfo.tipsBtnSelectedValue != null) {
    tips = _.round(((grandTotal + shippingFee) * (parseFloat(finalState.priceInfo.tipsBtnSelectedValue) * 0.01)), 2)
    finalState.priceInfo.tips = tips
  } else {
    tips = finalState.priceInfo.tips
  }

  orderTotal = _.round((grandTotal + shippingFee + tips), 2)

  //update points payment
  if (finalState.storeInstance.data['p2v'] > 0 && finalState.pointsUsed >= 0) {
    pointsPaid = parseFloat(finalState.pointsUsed) / parseFloat(finalState.storeInstance.data['p2v']);
    totalPaid += pointsPaid
  }

  //update giftcard payment
  let selectedGiftCard = finalState.selectedGiftCard
  if (selectedGiftCard && orderTotal > totalPaid) {
    let remainingOrderTotal = orderTotal - totalPaid;
    if (remainingOrderTotal > parseFloat(selectedGiftCard.bal)) {
      totalPaid += parseFloat(selectedGiftCard.bal)
      giftCardPaid = selectedGiftCard.bal
    } else if (remainingOrderTotal <= parseFloat(selectedGiftCard.bal)) {
      totalPaid += parseFloat(remainingOrderTotal)
      giftCardPaid = remainingOrderTotal;
    }
  }

  // payment method change if total paid for the whole order total
  if (totalPaid == orderTotal) {
    finalState.creditCard = getDefaultState().card;
    if (pointsPaid && giftCardPaid) {
      finalState.paymentMethod = 5;
      finalState.activePaymentKey = null
    } else if (pointsPaid > 0) {
      finalState.paymentMethod = 4;
      finalState.activePaymentKey = null
    } else if (giftCardPaid) {
      finalState.paymentMethod = 3;
      finalState.activePaymentKey = null
    }
  } else if (finalState.paymentMethod == 5 || finalState.paymentMethod == 4 || finalState.paymentMethod == 3) {
    finalState.paymentMethod = finalState.initialPaymentMethod;
    finalState.activePaymentKey = finalState.initialPaymentMethod;
  }

  let totalDue = orderTotal - totalPaid > 0 ? orderTotal - totalPaid : 0
  let totalBeforeTax = orderTotal - tax - tips - totalPaid > 0 ? orderTotal - tax - tips - totalPaid : 0

  finalState.displayPriceInfo.orderTotalCal = orderTotal
  finalState.displayPriceInfo.orderTotal = _.round(totalDue, 2)
  finalState.displayPriceInfo.totalBeforeTax = _.round(totalBeforeTax, 2)
  finalState.displayPriceInfo.pointsPayment = _.round(pointsPaid, 2)
  finalState.displayPriceInfo.giftcardPayment = _.round(giftCardPaid, 2)
  finalState.displayPriceInfo.totalPaid = _.round(totalPaid, 2)
  finalState.displayPriceInfo.totalDue = _.round(totalDue, 2)

  return finalState;
}

function handleCheckoutResponse(state, action, status) {
  let finalState = _.cloneDeep(state);

  if (status) {
    let { RC, records } = action.response.checkoutResponse;
    if (RC == 200) {

      const payMethod = _.get(finalState, 'paymentMethod', '')
      const redirectUrl = `${window.location.origin}/account/orders/detail/${records.ord_id}`
      const wechatPayUrl = _.get(records, 'payment.wechat.pay_url', '')
      const aliPayUrl = _.get(records, 'payment.alipay.pay_url', '')
      const unionPayUrl = _.get(records, 'payment.unionpay.pay_url', '')
      const unionPayExpresUrl = _.get(records, 'payment.unionpayexpress.pay_url', '')
      const payPalPayUrl = String(payMethod) == 7 && `/paypal?orderId=${records.ord_id}`
      const payUrl = aliPayUrl || wechatPayUrl || unionPayUrl || unionPayExpresUrl || payPalPayUrl || ''

      finalState.orderId = records.ord_id;
      finalState.payUrl = payUrl ? payUrl + `&redirect=${redirectUrl}` : '';

      localStorage.removeItem(`g${finalState.gid}`)
    } else if (RC == 400) {

      finalState.displayModalContent = 'invalid_request';
      finalState.displayModalHanldingType = 'continue';

    } else if (RC == 456) {

      finalState.displayModalContent = 'transaction_declined';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 459) {

      finalState.displayModalContent = 'price_over_limit';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 460) {

      finalState.displayModalContent = 'invalid_shp_mtd';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 461) {

      finalState.displayModalContent = 'invalid_credit_card';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 463) {

      finalState.displayModalContent = 'invalid_pay_mtd';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 464) {

      finalState.displayModalContent = 'invalid_shp_mtd';
      finalState.displayModalHanldingType = 'continue';
    } else if (RC == 1000) {

      let oosItems = [];

      Object.keys(records.oos).map((responsePid) => {
        finalState.productList.map((cartItem) => {
          if (cartItem.pid == responsePid) {
            oosItems.push(cartItem.nm)
          }
        })
      })

      finalState.displayModalContent = 'item_oos';
      finalState.displayModalHanldingType = 'back_to_store';
      finalState.alertDialogKeywords = { oos_items: oosItems.join(', ') };
    } else {

      finalState.displayModalContent = 'unsuccessful_checkout';
      finalState.displayModalHanldingType = 'continue';
    }

    if (RC == 200) {
      finalState.confirmCheckoutSuccess = true
      finalState.displayModalType = 'success';
      finalState.displayModalTitle = 'new_order_created';
      finalState.displayModalHanldingType = 'back_to_store';
      finalState.displayModalContent = 'checkout_success';
      finalState.displayModalKeywords = { order_id: records.ord_id };

    } else {
      finalState.displayModalTitle = 'error';
      finalState.displayModalType = 'error';
      finalState.confirmCheckoutSuccess = false
    }

  } else {
    finalState.displayModalType = 'error';
    finalState.displayModalTitle = 'error';
    finalState.displayModalContent = 'unsuccessful_checkout';
    finalState.displayModalHanldingType = 'continue';
    finalState.confirmCheckoutSuccess = false
  }

  finalState.getQuoteLoading = false
  if (finalState.paymentMethod != 6 && finalState.paymentMethod != 7 && finalState.paymentMethod != 8) {
    finalState.displayModal = true;
  }

  return finalState
}


export default {
  'checkout-page': (state = getDefaultState(), action) => {
    switch (action.type) {
      case 'CHECKOUT_PAGE__SET_STATE':
        return Object.assign({}, _.cloneDeep(state), action.state);
      case 'CHECKOUT_PAGE__INIT_CHECKOUT_PAGE_SUCCESS':
        return initCheckoutPage(state, action, true);
      case 'CHECKOUT_PAGE__INIT_CHECKOUT_PAGE_FAILED':
        return initCheckoutPage(state, action, false);
      case 'CHECKOUT_PAGE__FETCH_CART_TOTAL_SUCCESS':
        return handleCartTotalResponse(state, action, true);
      case 'CHECKOUT_PAGE__FETCH_CART_TOTAL_FAILED':
        return handleCartTotalResponse(state, action, false);
      case 'CHECKOUT_PAGE__CHECKOUT_SUCCESS':
        return handleCheckoutResponse(state, action, true);
      case 'CHECKOUT_PAGE__CHECKOUT_FAILED':
        return handleCheckoutResponse(state, action, false);
      case 'CHECKOUT_PAGE__UPDATE_DISPLAY_PRICE':
        return updateDisplayPrice(state, action);
      case 'CHECKOUT_PAGE__UPDATE_TIPS':
        return updateTips(state, action);
      default:
        return state;
    }
  }
}


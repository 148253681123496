import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import scriptLoader from 'react-async-script-loader';
import classnames from 'classnames';
import _ from 'lodash';
import _config from 'config';
import _helper from '../../utils/helper';
import _reverseConfig from 'config/config-reverse-map';
import { Divider, Spin, Modal, Steps, Icon, Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import './_paypal.scss'

const Step = Steps.Step;
class Paypal extends Component {

  state = {
    imageLoaded: true
  }

  componentDidMount() {
    this.initPage()
  }

  componentDidUpdate(prevProps) {
    this.checkShowModal(prevProps)
  }

  checkShowModal = (prevProps) => {
    let { paypalState } = this.props;
    let { alertDialogDisplay } = paypalState
    if (prevProps.paypalState.alertDialogDisplay != alertDialogDisplay && alertDialogDisplay) {
      this.showModal()
    }
  }

  initPage = () => {
    const orderId = _.get(_helper.getUrlParameters(), 'orderId', '')
    this.props.initPalpalPage({
      orderId, lan: this.props.lan
    })
  }

  handleOnAuthorize(data) {
    const orderId = _.get(_helper.getUrlParameters(), 'orderId', '')
    this.props.payPalOnAuthorize({
      orderId,
      paymentID: data.paymentID,
      payerID: data.payerID
    })
  }

  handleOnCancel() {
    const orderId = _.get(_helper.getUrlParameters(), 'orderId', '')
    this.props.payPalOnCancel({ orderId })
  }

  showModal = () => {
    let { paypalState, push, intl, orderId, setPaypalState } = this.props;

    let { alertDialogTitle, alertDialogText, alertDialogType } = paypalState

    let { formatMessage } = intl
    let id = _.get(_helper.getUrlParameters(), 'orderId', '')
    let title = alertDialogTitle ? formatMessage({ id: alertDialogTitle }) : '';
    let content = alertDialogText ? formatMessage({ id: alertDialogText }) : ''
    let okayText = formatMessage({ id: 'go_to_order' })
    let goToOrderPage = () => push(`/account/orders/detail/${id}`)

    if (alertDialogType == "error") {
      Modal.error({
        title: title,
        content: content,
        onOk() {
          setPaypalState('alertDialogDisplay', false)
          goToOrderPage()
        },
        okText: okayText,
      });
    } else {
      Modal.success({
        title: title,
        content: content,
        onOk() {
          setPaypalState('alertDialogDisplay', false)
          goToOrderPage()
        },
        okText: okayText,
      });
    }
  }

  renderStepBars = () => {
    return (
      <Steps className="steps-bar" size="small">
        <Step status="finish" title={<FormattedMessage id="shopping" />} icon={<Icon type="shopping-cart" />} />
        <Step status="process" title={<FormattedMessage id="billing_shipping" />} icon={<Icon type="loading" />} />
        <Step status="wait" title={<FormattedMessage id="done" />} icon={<Icon type="smile-o" />} />
      </Steps>
    )
  }

  renderOrderItemsHeaders = () => {
    return (
      <div className="order-items-wrapper">
        <Row className="order-item-header" gutter={8}>
          <Col span={14} className="item-name"><h3><FormattedMessage id="item(s)" /></h3></Col>
          <Col span={4} className="single-item-price"><h3><FormattedMessage id="price" /></h3></Col>
          <Col span={2} className="cnt"><h3><FormattedMessage id="qty" /></h3></Col>
          <Col span={4} className="item-price"><h3><FormattedMessage id="amt" /></h3></Col>
        </Row>
      </div>
    )
  }

  renderOrderItemsDetail = () => {
    const orderDetailInstance = this.props.order
    return (
      <div className="order-items-wrapper">
        {_.get(orderDetailInstance, 'order_item', []).map((item, i) => {
          return (
            <div className="box-section" key={`product-item-${i}`}>
              <div >
                <Row className="order-item-header" gutter={8}>
                  <Col span={14} className="item-name"><h3>{item.nm}</h3></Col>
                  <Col span={4} className="single-item-price"><h5>
                    {_helper.formatCurrency(item.pc, orderDetailInstance['currency'])}</h5></Col>
                  <Col span={2} className="cnt"><h5>{item.cnt}</h5> </Col>
                  <Col span={4} className="item-price"><h4>{_helper.formatCurrency(item.amt, orderDetailInstance['currency'])}</h4></Col>
                </Row>
              </div>
              {item.opts && item.opts.length
                ? <div>
                  {item.opts.map((optionCat, i) => {
                    let optStr = optionCat['nm'];
                    let optItems = [];
                    if (optionCat.opts && optionCat.opts.length) {
                      optItems = optionCat.opts.map((optItem) => optItem.nm.trim())
                    }
                    if (optItems && optItems.length) {
                      optStr += ` - ${optItems.join(', ')}`
                    }
                    return (
                      <div key={`product-optionCat-${i}`}>{optStr}</div>
                    )
                  })}
                </div>
                : ''}
            </div>
          )
        })}
      </div>
    )
  }

  renderPriceDetails = () => {
    let { paypalState, currency, lan } = this.props;

    const storeSurcharge = () => {
      return _.get(paypalState, 'priceInfo.store_surcharge', {})
    }
    const orderSurcharge = () => {
      const surchargeObj = _.get(paypalState, 'priceInfo.order_surcharge', {})
      const inTotal = _.get(surchargeObj, 'is_separate', 0) === 0
      return inTotal ? surchargeObj : {}
    }
    const showItemTotal = _.get(storeSurcharge(), 'value', 0) || _.get(orderSurcharge(), 'value', 0)

    const totalsParams = {
      itemTotal: {
        size: "normal",
        title: "item_total",
        number: showItemTotal ? "item_ttl" : 0
      },
      storeSurcharge: {
        size: "normal",
        title: _helper.getTransString(_.get(storeSurcharge(), 'label', {}), lan),
        number: _.get(storeSurcharge(), 'value', 0)
      },
      orderSucharge: {
        size: "normal",
        title: _helper.getTransString(_.get(orderSurcharge(), 'label', {}), lan),
        number: _.get(orderSurcharge(), 'value', 0)
      },
      subtotal: {
        size: "normal",
        title: "subtotal",
        number: "subtotal"
      },
      storeDiscount: {
        size: "normal",
        title: "discount",
        number: "storeDiscount",
        prefix: "-",
      },
      couponDiscount: {
        size: "normal",
        title: "discount",
        number: "couoponDiscount",
        prefix: "-",
      },
      deliveryFee: {
        size: "normal",
        title: "shipping_and_handling",
        number: "shipping",
      },
      tax: {
        size: "normal",
        title: "tax",
        number: "tax",
      },
      tip: {
        size: "normal",
        title: "tip",
        number: "tip",
      },
    }

    return Object.keys(totalsParams).map((totalKey, i) => {
      let { size, title, number, prefix } = totalsParams[totalKey];
      let validInfo = Number.isNaN(parseFloat(number)) ? _.get(paypalState, `priceInfo.${number}`, 0) : number
      if (validInfo) {
        return (
          <div
            key={`order-total-${totalKey}-${i}`}
            className={classnames({
              "totals-item": true,
              "large": size === "large"
            })}>
            <div className={classnames({
              "totals-name": true,
              "light": title !== "order_total",
            })}>
              <FormattedMessage id={title} />
            </div>
            <div className="totals-price">
              <h4 className={classnames({
                "large": size === "large"
              })}>{_helper.formatCurrency(validInfo, currency, prefix ? prefix : '')}</h4>
            </div>
          </div>
        )
      }
    })
  }

  renderPriceTotal = () => {
    let {
      paypalState,
      currency,
    } = this.props;
    return paypalState.priceInfo['total'] ?
      <div>
        <Divider style={{ margin: '10px 0' }} />
        <div className="totals-item large">
          <div className="totals-name"><FormattedMessage id="total_due" /></div>
          <div className="totals-price"><h4 className="large">{_helper.formatCurrency(paypalState.priceInfo['total'], currency)}</h4></div>
        </div>
      </div>
      : ''
  }

  renderPriceInfo = () => {
    return (
      <div>
        {this.renderPriceDetails()}
        {this.renderPriceTotal()}
      </div>
    )
  }

  renderPayPalButton = () => {
    let { paypalState, } = this.props;
    let { payPalId, showPayPalButton } = paypalState

    let payPalButtonCom = showPayPalButton && _.get(window, 'paypal.Button', null)
    const PayPalButton = payPalButtonCom && payPalButtonCom.driver('react', { React, ReactDOM });
    return (
      <div className="paypal-btn-wrapper">{PayPalButton && <PayPalButton
        commit={true}
        env='production'
        payment={() => { return payPalId }}
        onAuthorize={(data, actions) => { this.handleOnAuthorize(data, actions) }}
        onCancel={(data) => { this.handleOnCancel(data) }}
        style={{
          size: 'responsive',    // small | medium | large | responsive
          shape: 'pill',     // pill | rect
          color: 'gold'      // gold | blue | silver | black
        }} />}
      </div>
    )
  }

  render() {
    let { paypalState: { isLoading } = {}, } = this.props;
    return (
      <div className="paypal-page">
        <Spin spinning={isLoading}>
          {this.renderStepBars()}
          <Divider />
          {this.renderOrderItemsHeaders()}
          <Divider />
          {this.renderOrderItemsDetail()}
          <Divider />
          {this.renderPriceInfo()}
          {this.renderPayPalButton()}
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lan: _.get(state, 'settings.lan', 'en'),
  order: _.get(state, 'paypal-page.order', {}),
  paypalState: _.get(state, 'paypal-page', {}),
  currency: _.get(state, 'paypal-page.currency', {}),
})

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
    initPalpalPage: bindActionCreators((params) => {
      return {
        type: "PAYPAL_PAGE__INIT_PAYPAL",
        payload: params
      }
    }, dispatch),
    payPalOnAuthorize: bindActionCreators((params) => {
      return {
        type: "PAYPAL_PAGE__AUTHORIZE",
        payload: params
      }
    }, dispatch),
    payPalOnCancel: bindActionCreators((params) => {
      return {
        type: "PAYPAL_PAGE__CANCEL",
        payload: params
      }
    }, dispatch),
    setPaypalState: bindActionCreators((key, value) => {
      return {
        type: "PAYPAL_PAGE__SET_STATE",
        state: { [key]: value }
      }
    }, dispatch),
  }
}

Paypal = connect(mapStateToProps, mapDispatchToProps)(Paypal);
export default injectIntl(scriptLoader('https://www.paypalobjects.com/api/checkout.js')(Paypal));
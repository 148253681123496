import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import {
  Menu,
  Icon,
  Row,
  Col,
  Input,
  Button,
  Popconfirm,
  Modal,
  message
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import LoginModal from "containers/groupsale-page/page-components/loginModal";
import queryString from "query-string";
import _helper from "utils/helper";
import _actionsSettings from "containers/settings/actions";
import _actionsAuth from "containers/auth/actions";

import GroupSaleMapSearchBar from "containers/groupsale-page/page-components/groupsale-mapsearch";
import TopBar from "containers/groupsale-page/page-components/top-bar";
import "./_navbar.scss";

const { SubMenu } = Menu;

class NavBar extends Component {
  state = {
    searchValue: "",
    loginModalVisible: false,
    searchValue: "",
    locValue: "",
    urlParams: {},
    inputValid: true
  };

  componentDidMount() {

  }

  componentWillReceiveProps(props) {
    if (props.queryLat != this.state.lat && props.queryLng != this.state.lon) {
      this.setState({
        inputValid: true,
        lat: props.queryLat,
        lon: props.queryLng
      });
    }

    if (props.userLat != this.state.lat && props.userLon != this.state.lon) {
      this.setState({
        inputValid: true,
        lat: props.userLat,
        lon: props.userLon
      });
    }

  }

  onSearchClick = () => {
    let { onSearchClick, intl } = this.props;
    let { formatMessage } = this.props.intl;
    let { inputValid } = this.state;
    if (inputValid) {
      this.pushToOtherPage();
    } else {
      message.warning(
        formatMessage({ id: "please_select_location_from_the_list" })
      );
    }
  };

  pushToOtherPage = () => {
    let urlParams = queryString.parse(this.props.location.search);
    let { push } = this.props;
    let { searchValue, lat, lon } = this.state;
    let url = "/deals/browse";
    url += "?";
    //add url original logics
    if (urlParams) {
      if (urlParams.category) {
        url += `&category=${urlParams.category}`;
      }
      if (urlParams.category2) {
        url += `&category2=${urlParams.category2}`;
      }
      if (urlParams.category3) {
        url += `&category3=${urlParams.category3}`;
      }
      if (urlParams.amtSold) {
        url += `&amtSold=${urlParams.amtSold}`;
      }
      if (urlParams.discount) {
        url += `&discount=${urlParams.discount}`;
      }
      if (urlParams.sort) {
        url += `&sort=${urlParams.sort}`;
      }
    }
    if (searchValue || lat || lon) {
      if (searchValue) {
        url += `query=${_helper.reformatSearchString(searchValue)}`;
      }
      if (lat) {
        url += `&lat=${lat}`;
      }
      if (lon) {
        url += `&lon=${lon}`;
      }
    }
    push(url);
  };

  onSearchInputChange = e => {
    this.setState({ searchValue: e.target.value });
  };

  handleChangeLanguage(lan) {
    let { changeLanguage } = this.props;

    changeLanguage({ lan });
  }

  render() {
    let {
      logout,
      isAuthenticated,
      push,
      userProfile,
      location,
      loginDialogDisplay,
      toggleLoginDialogDisplay,
      getLocationDetails
    } = this.props;
    return (
      <div className="group-navbar-container">
        <div className="top-bar-container">
          <TopBar />
        </div>
        <div className="nav-bar-container">
          <div className="logo-wrapper">
            <img
              onClick={() => {
                push("/deals");
              }}
              className="logo"
              src="https://res.cloudinary.com/goopter/f_auto,q_auto,c_limit,c_fit/goopter_logo3_dyiuq6.png"
            />
          </div>
          <FormattedMessage id="search">
            {placeHolder => (
              <Input
                onChange={e => this.onSearchInputChange(e)}
                placeholder={placeHolder}
                prefix={
                  <Icon
                    type="environment"
                    style={{ color: "rgba(0,0,0,.25)" }}
                  />
                }
                style={{ width: 400 }}
              />
            )}
          </FormattedMessage>

          <GroupSaleMapSearchBar
            getLocationDetails={getLocationDetails}
            // onPlacesChanged={place => {
            //   let placeObj = place[0];

            // }}
            onValueClear={() =>
              this.setState({
                inputValid: true,
                lat: 0,
                lon: 0
              })
            }
            style={{ color: 'black', marginLeft: '10px', width: 380 }} />

          <Button
            onClick={() => this.onSearchClick()}
            type="primary"
            icon="search"
            className="nav-bar-searchButton"
            style={{ marginLeft: "2px", border: "1px solid white" }}
          />

          <LoginModal
            loginModalVisible={loginDialogDisplay}
            onClose={() => {
              toggleLoginDialogDisplay(false);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let settingsState = state["settings"];
  let authState = state["auth"];
  let routeState = state["router"];
  let locationState = state["locations"];
  return {
    lan: settingsState.lan,
    isAuthenticated: authState.isAuthenticated,
    userProfile: authState.userProfile,
    loginDialogDisplay: authState.loginDialogDisplay,
    location: routeState.location,
    queryLat: locationState.queryLat,
    queryLng: locationState.queryLng,
    userLat: locationState.userLat,
    userLon: locationState.userLon

  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    changeLanguage: bindActionCreators(params => {
      return _actionsSettings.changeLanguage({ lan: params.lan });
    }, dispatch),
    logout: bindActionCreators(params => {
      return {
        type: "AUTH__TOGGLE_LOGOUT_DIALOG",
        payload: { type: "normal", show: true }
      };
    }, dispatch),
    toggleLoginDialogDisplay: bindActionCreators(bool => {
      return {
        type: "AUTH__SET_STATE",
        state: {
          loginDialogDisplay: bool
        }
      };
    }, dispatch),
    getLocationDetails: bindActionCreators(params => {
      return {
        type: "LOCATIONS_GET_LOCATION_DETIALS",
        payload: params
      };
    }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NavBar));

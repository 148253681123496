import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'
import './power-by-section.scss'

class PowerBySection extends React.Component {

    render() {
        return (
            <div className="power-by-section-container">
                <FormattedMessage id="powered_by_goopter" />
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    lan: state.settings.lan,
    state: _.get(state, 'selfCheckout', {})
})

const mapDispatchToProps = ({

})


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PowerBySection))


import { call, put, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import _config from 'config';
import _api from 'utils/api';
import _apiRoutes from 'utils/api/routes';
import _helper from 'utils/helper';
import _locale from 'locale';
import { getLanguage } from 'containers/app/reducers'
import { message } from 'antd';
import _action from './actions.jsx'

function* initShoppingCart(action) {
  try {
    let { gid } = action.payload;

    let lan = yield select(getLanguage);
    let cartList = yield select((state) => state['shopping-cart']['cartList']);

    //temp until fully migrated
    if (_action.updateCartListFromOld(gid)) {
      cartList[gid] = _action.updateCartListFromOld(gid)
    }

    if (cartList && cartList[gid] && cartList[gid]['cart'] && cartList[gid]['cart'].length) {
      // has items in shopping cart for the current store
      let url = _config['API_ROOT'] + _apiRoutes['category-product-list'];
      let pids = cartList[gid]['cart'].map((item) => item.pid)
      pids = _.uniq(pids);

      url = _helper.objToQuery(url, {
        gid,
        lan,
        ids: pids.join(',')
      });

      let productResponse = yield call(_api.get, url)
      console.log(productResponse)
      if (productResponse.RC === 200) {
        yield put({
          type: "SHOPPING_CART__INIT_SHOPPING_CART_SUCCESS",
          response: { productList: productResponse.records }, payload: action.payload,
          cartList: cartList[gid],
        })
      } else {
        yield put({ type: "SHOPPING_CART__INIT_SHOPPING_CART_FAILED", payload: { ...action.payload, reason: 'failed_list' } })
      }
    } else {
      yield put({ type: "SHOPPING_CART__INIT_SHOPPING_CART_FAILED", payload: { ...action.payload, reason: 'no_items' } })
    }

  } catch (e) {
    yield put({ type: "SHOPPING_CART__INIT_SHOPPING_CART_FAILED", message: { e }, payload: { key: "language", ready: true } })
  }
}

export default [
  takeEvery('SHOPPING_CART__INIT_SHOPPING_CART', initShoppingCart),
]



import _ from 'lodash';
import _config from 'config';
import _api from 'utils/api';
import moment from 'moment';

export default class GroupSale {
  constructor(groupSaleDetail) {
    groupSaleDetail = Object.assign({}, {
      c_ids: [1],
      i_urls: [],
      b_lst: [],
      opts: [],
      flg: [],
      sflg: [],
    }, groupSaleDetail);
    this.initGroupSaleDetail(groupSaleDetail);
  }

  initGroupSaleDetail(groupSaleDetail) {
    this.data = {
      'group_sale_id': groupSaleDetail['id'],
      'group_id': groupSaleDetail['g_id'],
      'tag_id': groupSaleDetail['tag_id'],
      'c_id': groupSaleDetail['c_ids'][0] ? groupSaleDetail['c_ids'][0] : 1,
      'c_id2': groupSaleDetail['c_ids'][1] ? groupSaleDetail['c_ids'][1] : null,
      'name': groupSaleDetail['nm'],
      'short_description': groupSaleDetail['s_dsc'],
      'view_count': groupSaleDetail['view_count'],
      'spc': groupSaleDetail['spc'],
      'pc': groupSaleDetail['pc'],
      'sold': groupSaleDetail['s_cnt'],
      'stock': groupSaleDetail['stock'],
      'currency': groupSaleDetail['currency'],
      'rnk': groupSaleDetail['rnk'],
      'r_cnt': groupSaleDetail['r_cnt'],
      'reviews': groupSaleDetail['reviews'],
      'reviews_short': this.getShortReview(groupSaleDetail['reviews']),
      'reviews_imgs': this.getReviewImgs(groupSaleDetail['reviews']),
      'reviews_imgs_short': this.getReviewImgs(groupSaleDetail['reviews'], true),
      'location': groupSaleDetail['location'],
      'store_name': groupSaleDetail['s_nm'],
      'opts': groupSaleDetail['opts'],
      'highlights': groupSaleDetail['hl'],
      'store_info': groupSaleDetail['s_info'],
      'url': groupSaleDetail['url'] ? (groupSaleDetail['url'].includes('http') ? groupSaleDetail['url'] : ('https://' + groupSaleDetail['url'])) : '',
      'deal_detail': groupSaleDetail['dtl'],
      'images': groupSaleDetail['i_urls'],
      'video': groupSaleDetail['v_url'],
      'fine_print': groupSaleDetail['fp'],
      'edt': groupSaleDetail['edt'],
      'sdt': groupSaleDetail['sdt'],
      'branch_list': groupSaleDetail['b_lst'],
      'related_list': groupSaleDetail['r_lst'],
      'limit': groupSaleDetail['limit'],

      'pickup': groupSaleDetail['sflg'][0],
      'delivery': groupSaleDetail['sflg'][1],

      'v2p': groupSaleDetail['flg'][2] ? groupSaleDetail['flg'][2] : 0,
      'pflg': groupSaleDetail['pflg'],
      'status': groupSaleDetail['st'],
      'shipping_rules': groupSaleDetail['shipping_rules'] ? groupSaleDetail['shipping_rules'] : null,
    }
  }

  getShortReview(reviews) {
    if (reviews && reviews.length > 3) {
      return reviews.slice(0, 3)
    } else {
      return null
    }
  }

  getReviewImgs(reviews, short = false) {
    let imgsAll = []
    if (reviews) {
      reviews.map(review => {
        if (review && review.imgs && review.imgs.length) {
          review.imgs.map(img => {
            if (imgsAll.indexOf(img) == -1) {
              imgsAll.push(img)
            }
          })
        }
      })
    }
    if (short) {
      if (imgsAll && imgsAll.length > 4) {
        return imgsAll.slice(0, 4)
      } else {
        return null
      }
    }
    return imgsAll
  }

  updateBranchDistance(distanceInfo) {
    this.data['branch_list'][distanceInfo.index].distance = distanceInfo
  }

}

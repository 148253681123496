import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import _config from "config";
import _helper from "utils/helper";
import { Rate, Icon, Row, Col, List, Button } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import GroupSaleLayout from "containers/groupsale-page/page-components/groupsaleLayout";
import PicVideoCarousel from "containers/groupsale-page/page-components/picVideoCarousel";
import ReviewCell from "containers/groupsale-page/page-components/reviewCell";
import ReviewListModal from "containers/groupsale-page/page-components/reviewListModal";
import ImageListModal from "containers/groupsale-page/page-components/imageListModal";
import FullImageModal from "containers/groupsale-page/page-components/fullImageModal";
import ItemSelect from "containers/groupsale-page/page-components/selectItems";
import StoreInfo from "containers/groupsale-page/page-components/storeInfo";
import Img from "react-image";
import ImgHolder from "images/image_groupsale_large_gray.png";
import "./_groupsaleDetails.scss";
import _actions from "containers/groupsale-page/actions.jsx";
import _actionsShoppingCart from "containers/shopping-cart/actions.jsx";
import classnames from "classnames";
import moment from "moment";
import LoginModal from "containers/groupsale-page/page-components/loginModal";
import { unwatchFile } from "fs";

class GroupSaleDetails extends Component {
  state = {
    loginModalVisible: false
  };

  componentDidMount() {
    this.fetchGroupSaleDetail();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lan != this.props.lan) {
      this.fetchGroupSaleDetail();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let leftCol = ReactDOM.findDOMNode(this.refs["group-sale-detail-lef-col"]);
    let leftColTop = leftCol.getBoundingClientRect().top;
    let leftColBottom = leftCol.getBoundingClientRect().bottom;
    if (leftColTop <= 0) {
      this.setState({ rightSticky: true });
    } else {
      this.setState({ rightSticky: false });
    }
    if (leftColBottom <= 0) {
      this.setState({ rightSticky: false });
    }
  };

  fetchGroupSaleDetail() {
    let { fetchGroupSaleDetail, lan, match } = this.props;

    fetchGroupSaleDetail({
      lan: lan,
      pid: match.params.productId
    });
  }

  pushToOtherPage = params => {
    let { push, selectCat } = this.props;
    let url = "/deals/browse";
    if (params) {
      url += "?";
      if (params.query) {
        url += `query=${_helper.reformatSearchString(params.query)}`;
      }
      if (params.category) {
        url += `category=${params.category.id}`;
      }
    }

    push(url);
  };

  getHighLightElements = () => {
    let { groupSaleInstance } = this.props;

    let hightlightsElements = [];
    if (groupSaleInstance.data["edt"]) {
      hightlightsElements.push({
        icon: <Icon type="clock-circle" />,
        text: (
          <div>
            {this.getExpired() ? (
              <FormattedMessage id="expired" />
            ) : (
                _helper.utcToLocal(groupSaleInstance.data["edt"], {
                  time: false,
                  type: "medium"
                })
              )}
          </div>
        )
      });
    }

    if (
      groupSaleInstance.data["view_count"] &&
      groupSaleInstance.data["view_count"] > 10
    ) {
      hightlightsElements.push({
        icon: <Icon type="eye" />,
        text: (
          <span>

            <span>{groupSaleInstance.data["view_count"]}</span>+
            <span>

              <FormattedMessage id="viewed_today" />
            </span>
          </span>
        )
      });
    }

    if (groupSaleInstance.data["rnk"]) {
      hightlightsElements.push({
        icon: (
          <span className="rating-stars">
            <Rate
              style={{ fontSize: "17px" }}
              disabled
              allowHalf
              defaultValue={groupSaleInstance.data["rnk"]}
            />
          </span>
        ),
        text: (
          <span>
            <span>{groupSaleInstance.data["r_cnt"]} </span>
            <span>
              <FormattedMessage id="ratings" />
            </span>
          </span>
        )
      });
    }

    if (
      groupSaleInstance.data["sold"] &&
      groupSaleInstance.data["sold"] > 10 &&
      hightlightsElements.length < 3
    ) {
      hightlightsElements.push({
        icon: <Icon type="usergroup-add" />,
        text: (
          <span>

            <span>{groupSaleInstance.data["sold"]}</span>+
            <span>

              <FormattedMessage id="sold" />
            </span>
          </span>
        )
      });
    }

    return hightlightsElements;
  };

  getExpired = () => {
    let { groupSaleInstance } = this.props;
    let today = moment();
    let expiredDate = moment(groupSaleInstance.data["edt"]);
    let result = expiredDate.isBefore(today);

    return result;
  };

  onBuyClick = values => {
    let {
      name,
      push,
      userProfile,
      groupSaleInstance,
      toggleLoginDialogDisplay
    } = this.props;
    let url = "";
    this.setShoppingCartItems(values);

    if (userProfile) {
      url += `/deals/checkout?query=${groupSaleInstance.data["group_id"]}`;
      push(url);
    } else {
      toggleLoginDialogDisplay(true);
    }
  };

  setShoppingCartItems(values) {
    let { setShoppingCartItems, groupSaleInstance } = this.props;
    let quantity = 0;
    let opts = {};
    let shippingMethod = "";
    let available_shippings = []
    if (values.optsData && values.optsData.length) {
      //get quantity
      let quantityOpt = values.optsData.find(value => {
        return value.originalId == "quantity";
      });
      quantity =
        quantityOpt && quantityOpt.selected ? quantityOpt.selected.nm : 0;
      //get opts
      values.optsData.map(value => {
        if (
          value.selected &&
          value.originalId != "quantity" &&
          value.originalId != "shippingmethod"
        ) {
          if (!_.includes(Object.keys(opts), String(value.originalId))) {
            opts[value.originalId] = [];
          }
          opts[value.originalId].push(value.selected.id);
        }
      });
      //get shipping method
      let shpOpt = values.optsData.find(value => {
        return value.originalId == "shippingmethod";
      });
      shippingMethod =
        shpOpt && shpOpt.selected
          ? _config.SHIPPING_MAPPING_TO_TEXT[shpOpt.selected.id]
          : "freeShipping";
      //get available shipping
      if (groupSaleInstance.data["pickup"]) {
        available_shippings.push(2)
      }
      if (groupSaleInstance.data["delivery"]) {
        available_shippings.push(1)
      }
    }
    let gid = groupSaleInstance.data["group_id"];
    let items = {
      cart: [
        {
          pid: groupSaleInstance.data["group_sale_id"],
          qty: quantity,
          options: opts,
          pc: values.newPrice
        }
      ],
      available_shippings,
      shippingMethod: shippingMethod
    };

    setShoppingCartItems({
      gid,
      items,
    });
  }

  render() {
    let {
      push,
      intl,
      userProfile,
      loginDialogDisplay,
      toggleLoginDialogDisplay,
      groupSaleInstance,
      storeInstance,
      storeLoading,
      fromSaleDetails
    } = this.props;

    let { formatMessage } = intl;

    return [
      <GroupSaleLayout
        onShortCutClick={value => this.pushToOtherPage({ query: value })}
      >
        <div className="group-sale-details-container">
          <div className="header-section">
            <div className="title">{groupSaleInstance.data["name"]}</div>
            {groupSaleInstance.data["rnk"] ? (
              <div className="rate">
                <span>
                  <span className="rating-stars">
                    <Rate
                      style={{ fontSize: "17px" }}
                      disabled
                      allowHalf
                      defaultValue={groupSaleInstance.data["rnk"]}
                    />
                  </span>
                  <span className="rating-numbers">
                    {Number(groupSaleInstance.data["rnk"]).toFixed(1)}
                  </span>
                  <span className="rating-count">

                    ({groupSaleInstance.data["r_cnt"]}
                    <span>
                      <FormattedMessage id="ratings" />
                    </span>
                    )
                  </span>
                </span>
              </div>
            ) : (
                ""
              )}

            {groupSaleInstance.data["location"] ? (
              <div className="location">
                <span className="icon">
                  <Icon type="environment" />
                </span>
                <span className="icon">

                  <span> {groupSaleInstance.data["location"]} </span>
                </span>
              </div>
            ) : (
                ""
              )}

            {groupSaleInstance.data["short_description"] ? (
              <div className="location">
                <span className="icon">
                  <Icon type="tag" />
                </span>
                <span className="icon">

                  {groupSaleInstance.data["short_description"]}
                </span>
              </div>
            ) : (
                ""
              )}
          </div>

          <Row>
            <Col ref="group-sale-detail-lef-col" span={16} className="left-col">
              <PicVideoCarousel
                imgs={groupSaleInstance.data["images"]}
                video={groupSaleInstance.data["video"]}
              />

              <div className="write-up">
                {groupSaleInstance.data["highlights"] ? (
                  <div className="section ">
                    <div className="write-up-title">

                      <FormattedMessage id="highlights" />
                    </div>
                    <p>{groupSaleInstance.data["highlights"]}</p>
                  </div>
                ) : null}

                {groupSaleInstance.data["reviews"] ? (
                  <div className="section">
                    <div className="write-up-title">

                      <FormattedMessage id="customer_reviews" />
                    </div>
                    <div className="rate">
                      <span>
                        <span className="rating-stars">
                          <Rate
                            style={{ fontSize: "17px" }}
                            disabled
                            allowHalf
                            defaultValue={groupSaleInstance.data["rnk"]}
                          />
                        </span>
                        <span className="rating-numbers">
                          {Number(groupSaleInstance.data["rnk"]).toFixed(1)}
                        </span>
                        <span className="rating-count">

                          ({groupSaleInstance.data["r_cnt"]}
                          <span>
                            <FormattedMessage id="ratings" />
                          </span>
                          )
                        </span>
                      </span>
                    </div>
                    <div>
                      <div>
                        <div className="tips-subtitle">

                          <FormattedMessage id="relevant_reviews" />
                        </div>
                        <List
                          itemLayout="horizontal"
                          dataSource={
                            groupSaleInstance.data["reviews_short"]
                              ? groupSaleInstance.data["reviews_short"]
                              : groupSaleInstance.data["reviews"]
                          }
                          renderItem={(item, i) => (
                            <List.Item key={i}>
                              <ReviewCell
                                key={`review-cell-${i}`}
                                review={item}
                              />
                            </List.Item>
                          )}
                        />
                        <div>
                          {groupSaleInstance.data["reviews_short"] ? (
                            <Button
                              onClick={() =>
                                this.setState({ displayReviewListModal: true })
                              }
                              type="primary"
                              size="large"
                            >
                              <FormattedMessage id="see_all_reviews" />
                            </Button>
                          ) : (
                              ""
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                    ""
                  )}

                {groupSaleInstance.data["reviews_imgs"] &&
                  groupSaleInstance.data["reviews_imgs"].length ? (
                    <div className="section">
                      <div className="write-up-title">

                        <FormattedMessage id="customer_photos" />
                      </div>
                      <List
                        grid={{ gutter: 16, column: 4 }}
                        dataSource={
                          groupSaleInstance.data["reviews_imgs_short"]
                            ? groupSaleInstance.data["reviews_imgs_short"]
                            : groupSaleInstance.data["reviews_imgs"]
                        }
                        renderItem={(item, i) => (
                          <List.Item key={i}>
                            <div
                              className="img-parent"
                              onClick={
                                i == 3 &&
                                  groupSaleInstance.data["reviews_imgs"].length > 4
                                  ? () => {
                                    this.setState({
                                      displayImageListModal: true
                                    });
                                  }
                                  : () => {
                                    this.setState({
                                      displayFullImageModal: true,
                                      currentImgUrl: item
                                    });
                                  }
                              }
                            >
                              <Img
                                className="img"
                                src={`${_config["IMG_PREFIX"]}${item}`}
                                unloader={<Img className="img" src={ImgHolder} />}
                                container={children => {
                                  if (
                                    i == 3 &&
                                    groupSaleInstance.data["reviews_imgs"]
                                      .length > 4
                                  ) {
                                    return (
                                      <div>
                                        <div>{children} </div>
                                        <div className="view-all-img-overlay">

                                          <div className="text">
                                            <FormattedMessage id="view_all_img" />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return children;
                                  }
                                }}
                              />
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                  ) : null}

                {groupSaleInstance.data["deal_detail"] ? (
                  <div className="section">
                    <div className="write-up-title">

                      <FormattedMessage id="what_you_will_get" />
                    </div>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: groupSaleInstance.data["deal_detail"]
                        }}
                      />
                    </p>
                  </div>
                ) : null}

                {groupSaleInstance.data["fine_print"] ? (
                  <div className="section">
                    <div className="write-up-title">

                      <FormattedMessage id="the_fine_print" />
                    </div>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: groupSaleInstance.data["fine_print"]
                        }}
                      />
                    </p>
                  </div>
                ) : null}

                {groupSaleInstance.data["store_name"] ? (
                  <div className="section">
                    <div className="write-up-title">

                      <FormattedMessage id="about" />
                      {groupSaleInstance.data["store_name"]}
                    </div>
                    <p>
                      {groupSaleInstance.data["s_info"] ? (
                        <p>{groupSaleInstance.data["s_info"]}</p>
                      ) : (
                          ""
                        )}
                    </p>
                    <StoreInfo
                      storeInstance={storeInstance}
                      storeName={groupSaleInstance.data["store_name"]}
                      storeLoading={storeLoading}
                    />
                  </div>
                ) : null}
              </div>
            </Col>

            <Col
              ref="group-sale-detail-right-col"
              span={8}
              className={classnames({
                "right-col": true,
                "sticky-on-top": this.state.rightSticky
              })}
            >
              <Row className="deal-highlights">
                {this.getHighLightElements().map((element, i) => {
                  return (
                    <Col key={i} span={24 / this.getHighLightElements().length}>
                      <div className="icon-container">{element.icon}</div>
                      <div className="text">{element.text}</div>
                    </Col>
                  );
                })}
              </Row>
              <div className="buy">
                <ItemSelect
                  name="buybutton"
                  expired={this.getExpired()}
                  limit={
                    groupSaleInstance.data["limit"] != undefined &&
                      groupSaleInstance.data["limit"] <
                      groupSaleInstance.data["stock"]
                      ? groupSaleInstance.data["limit"]
                      : groupSaleInstance.data["stock"]
                  }
                  onBuyClick={value => this.onBuyClick(value)}
                  oldPrice={groupSaleInstance.data["pc"]}
                  newPrice={groupSaleInstance.data["spc"]}
                  amtSold={groupSaleInstance.data["sold"]}
                  optsData={groupSaleInstance.data["opts"]}
                  currency={groupSaleInstance.data["currency"]}
                  pickup={groupSaleInstance.data["pickup"]}
                  delivery={groupSaleInstance.data["delivery"]}
                />
              </div>
            </Col>
          </Row>
        </div>
      </GroupSaleLayout>,
      <LoginModal
        redirectPath={`/deals/checkout?query=${groupSaleInstance.data["group_id"]}`}
        loginModalVisible={loginDialogDisplay}
        onClose={() => {
          toggleLoginDialogDisplay(false);
        }}
      />,
      <ReviewListModal
        displayModal={this.state.displayReviewListModal}
        onCloseClick={() => this.setState({ displayReviewListModal: false })}
        reviewData={groupSaleInstance.data["reviews"]}
      />,
      <FullImageModal
        displayModal={this.state.displayFullImageModal}
        onCloseClick={() => this.setState({ displayFullImageModal: false })}
        onGoToImageGalleryClick={() =>
          this.setState({
            displayFullImageModal: false,
            currentImgUrl: "",
            displayImageListModal: true
          })
        }
        imageData={this.state.currentImgUrl}
        imageListData={groupSaleInstance.data["reviews_imgs"]}
      />,
      <ImageListModal
        displayModal={this.state.displayImageListModal}
        onImageClick={value =>
          this.setState({
            displayFullImageModal: true,
            currentImgUrl: value,
            displayImageListModal: false
          })
        }
        onCloseClick={() => this.setState({ displayImageListModal: false })}
        imageData={groupSaleInstance.data["reviews_imgs"]}
      />
    ];
  }
}

const mapStateToProps = state => {
  let authState = state["auth"];
  let settingState = state["settings"];
  let groupSaleState = state["group-sale"];
  return {
    userProfile: authState.userProfile,
    lan: settingState.lan,
    groupSaleInstance: groupSaleState.groupSaleInstance,
    storeInstance: groupSaleState.storeInstance,
    storeLoading: groupSaleState.storeLoading,
    loginDialogDisplay: authState.loginDialogDisplay
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    fetchGroupSaleDetail: bindActionCreators(params => {
      return _actions.fetchGroupSaleDetail(params);
    }, dispatch),
    setShoppingCartItems: bindActionCreators(params => {
      return _actionsShoppingCart.setShoppingCartItems(params);
    }, dispatch),
    logout: bindActionCreators(params => {
      return {
        type: "AUTH__TOGGLE_LOGOUT_DIALOG",
        payload: { type: "normal", show: true }
      };
    }, dispatch),
    toggleLoginDialogDisplay: bindActionCreators(bool => {
      return {
        type: "AUTH__SET_STATE",
        state: {
          loginDialogDisplay: bool
        }
      };
    }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GroupSaleDetails));


import _ from 'lodash'

const getGid = (props) => {
    const gidFromRoute = _.get(props, 'match.params.gid', '')
    const gidFromState = _.get(props, 'state.gid', '')
    return gidFromRoute ? gidFromRoute : gidFromState
}

const getAmt = (props) => {
    const amtFromRoute = _.get(props, 'match.params.amt', 0)
    const formattedAmt = parseFloat(amtFromRoute).toFixed(2)
    return isNaN(formattedAmt) ? '0' : formattedAmt
}

const getTaxClass = (props) => {
    const tc = _.get(props, 'match.params.tc', 0)
    return tc
}

const getOid = (props) => {
    const aioFromRoute = _.get(props, 'match.params.oid', 0)
    return aioFromRoute
}

const isReadOnly = (props) => {
    const roFromRoute = _.get(props, 'match.params.ro', 0)
    return parseInt(roFromRoute) === 1
}

export default {
    getGid,
    getAmt,
    getOid,
    isReadOnly,
    getTaxClass
}